import { Paper, keyframes, styled } from "@mui/material";
import { getLoyaltyCardProperties } from "constants/loyaltyCardProperties";

export const addCardStyle = () => {
    return {
        border: "1px dashed lightgrey",
        ":hover": { backgroundColor: "#ebf5f9" },
        ...getLoyaltyCardProperties(),
        marginTop: "40px"
    };
}

export const addCardActionAreaStyle = () => {
    return {
        display: "grid",
        height: "100%",
        width: "100%"
    };
}

export const addCardActionAreaButtonStyle = () => {
    return {
        textAlign: "center",
        backgroundColor: "transparent"
    };
}

export const addCardActionAreaButtonIconStyle = () => {
    return {
        fontSize: "40px",
        width: "80px",
        height: "80px",
        color: "lightgrey",
        borderRadius: "50px",
        border: "1px dashed lightgrey",
        marginTop: "10%",
        backgroundColor: "transparent",
        ":hover": {
            backgroundColor: "#ebf5f9",
            color: "white"
        }
    };
}

export const loyaltyBackgroundButtonActiveStyle = () => {
    return {
        color: "#1976d2",
        borderRadius: "10px",
        border: "1px solid grey"
    };
}


export const logoStyle = () => {
    return {
        height: "100px",
        width: "auto"
    };
}


export const mainTag = () => {
    return {
        textAlign: "center",
        position: "absolute",
        width: "100%",
        // height: "450px",
        padding: "90px 0px 50px 0px"
    };
}

export const mainTagPage = () => {
    return {
        textAlign: "left",
        position: "absolute",
        width: "100%",
        // height: "450px",
        padding: "90px 0px 50px 0px"
    };
}

export const mainMenuTag = () => {
    return {
        textAlign: "center",
        position: "absolute",
        width: "100%",
        gap: "0px",
        padding: "20px 0px 50px 0px",
        marginTop: "40px"
    };
}

//  ===================================================== REQUEST
export const requestButton = () => {
    return {
        width: "100%",
        height: "100%",
        color: "#54b1d2",
        fontWeight: "900",
        fontSize: "18px",
        textTransform: "initial",
        padding: "20px 30px",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: "#54b1d2",
            border: "1px solid #54b1d2"
        }
    }
}

export const requestButtonIcon = () => {
    return {
        fontSize: "40px!important"
    }
}

export const requestButtonFontAwesomeIcon = () => {
    return {
        fontSize: "40px!important",
        width: 40,
        height: 40
    }
}

export const requestFabButtonStyle = () => {
    return {
        position: 'fixed',
        bottom: 16,
        right: 16,
        backgroundColor: "#54b1d2",
        color: "white",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: "white",
        }
    }
}

export const requestTitleStyle = () => {
    return {
        fontSize: "18px",
        ...lumanosimoTitleStyle()
    }
}

export const requestTextStyle = () => {
    return {
        fontWeight: 600,
        fontSize: "12px",
        fontFamily: "Lumanosimo"
    }
}

export const requestTextareaBoxStyle = () => {
    return {
        display: 'flex',
        borderTop: '1px solid',
        borderColor: 'divider',
        flex: 'auto',
    }
}

// -============================== Outlet Actions


export const outletActionButtonIcon = () => {
    return {
        fontSize: "50px!important"
    }
}

export const outletActionButtonFontAwesomeIcon = () => {
    return {
        fontSize: "40px!important",
        width: 45,
        height: 45
    }
}

// ==================ERROR INDICATORS

export const textFieldError = () => {
    return {
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px"
        },
        "& .MuiFormHelperText-root.Mui-error": {
            color: "white",
            backgroundColor: "rgba(202, 48, 48, 0.7)",
            borderRadius: 1,
            fontSize: 12,
            margin: "7px 0 0",
            padding: "2px 10px",
            textAlign: "center",
            width: "fit-content",
            alignSelf: "center",
            ":before": {
                borderBottom: "5px solid #ca3030",
                borderLeft: "3px solid transparent",
                borderRight: "3px solid transparent",
                content: '""',
                position: "relative",
                top: "-22px",
                left: "50%"
            }
        }
    };
}

export const selectError = () => {
    return {
        color: "white",
        backgroundColor: "rgba(202, 48, 48, 0.7)",
        borderRadius: 1,
        fontSize: 12,
        margin: "7px 0 0",
        padding: "4px 10px",
        textAlign: "center",
        width: "fit-content",
        alignSelf: "center",
        display: "inline-table",
        ":before": {
            borderBottom: "5px solid #ca3030",
            borderLeft: "3px solid transparent",
            borderRight: "3px solid transparent",
            content: '""',
            position: "relative",
            top: "-24px",
            left: "50%"
        }
    };
}


// ==================AUTH/ LOGIN/ REGISTER

export const socialLoginBtnGridStyle = (first = false) => {
    return {
        minHeight: "10vh"
    }
}
export const socialLoginBtnStyle = (first = false) => {
    return {
        width: "60%",
        mt: first ? "initial" : "20px"
    }
}

export const useeLoginBtnStyle = () => {
    return {
        justifyContent: "center",
        "-webkit-align-items": "center",
        " -webkit-box-align": "center",
        "-ms-flex-align": "center",
        alignItems: "center",
        color: "#000",
        backgroundColor: "#fff",
        borderRadius: "12px",
        fontWeight: 700,
        textTransform: "none",
        boxShadow: "0px 3px 9px 0px #00000029",
        padding: "20px 30px",
        fontSize: "0.875rem",
        lineHeight: "1.75",
        letterSpacing: "0.02857em",
        height: "30px"
    }
}

export const authTitleStyle = () => {
    return {
        fontSize: "22px",
        ...lumanosimoTitleStyle(),
        mb: 5,
        overflow: "initial"
    }
}

export const authBackBtnStyle = () => {
    return {
        width: "20px",
        color: "#000",
        backgroundColor: "#fff",
        fontWeight: 700,
        textTransform: "none",
        padding: "20px 30px",
        height: "30px"
    }
}

export const autDrawerPaperStyle = () => {
    return {
        textAlign: "-webkit-center",
        minWidth: "300px"
    }
}

export const authInfoStyle = () => {
    return {
        fontWeight: 400,
        fontSize: "12px",
        textWrap: "balance",
        textAlign: "justify",
        mt: 4,
        mb: 2
    }
}

export const authInfoTitleStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "22px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "justify"
    }
}

export const authInfoTextStyle = () => {
    return {
        fontWeight: 400,
        margin: "20px",
        fontSize: "15px",
        textWrap: "balance",
        textAlign: "justify"
    }
}

// ==================Fieldset style

export const fieldsetBox = (open) => {
    return {
        borderRadius: "5px",
        padding: "0px",
        border: open ? '1px solid lightgrey' : "none"
    };
}

export const fieldsetBoxContainer = () => {
    return {
        margin: "0px 5px 15px 0px",
        width: "calc(100% - 16px)"
    };
}

export const fieldsetBoxLegend = () => {
    return {
        color: "grey",
        textAlign: "left",
        marginLeft: "30px"
    };
}

export const fieldsetBoxLegendCollapsable = () => {
    return {
        ...fieldsetBoxLegend(),
        cursor: "pointer"
    };
}


// ================== Loyalty

export const loyaltyEmptyInfoTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "12px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}

export const loyaltyTitleStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "22px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}


// ================== Coupon

export const couponEmptyInfoTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "12px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}

export const couponLogoStyle = () => {
    return {
        height: "80px",
        width: "auto"
    };
}

export const couponTitleStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "22px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}
// ================== ORDER

export const orderEmptyInfoTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "12px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}

export const orderTitleStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "22px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}

export const pastOrderLogoStyle = () => {
    return {
        height: "50px",
        width: "auto",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        boxShadow: "0px 3px 9px 0px #00000029",
    };
}

export const pastOrderOutletTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey",
        paddingBottom: "2px"
    }
}

export const pastOrderDateTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey"
    }
}

export const pastOrderCartTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey"
    }
}

export const pastOrderAddFeedbackButtonStyle = () => {
    return {
        padding: "5px 10px",
        fontSize: "8px",
        color: "grey",
        textTransform: "initial"
    }
}

export const pastOrderAddFeedbackButtonIconStyle = () => {
    return {
        fontSize: "5px",
        // color: "grey",
        ":hover": {
            color: "white"
        }
    }
}

export const pastOrderMoreOrdersButtonStyle = () => {
    return {
        marginTop: "25px",
        padding: "20px",
        fontSize: "13px",
        color: "black",
        borderRadius: "30px",
        textTransform: "initial"
    }
}

export const getOrderButtonIcon = () => {
    return {
        width: 40,
        height: 40,
        fill: "#54b1d2"
    }
}

export const orderBackBtnStyle = () => {
    return {
        color: "#000",
        backgroundColor: "#fff",
        fontWeight: 700,
        textTransform: "none",
        padding: "20px 30px",
        height: "30px",
        fontSize: "10px",
        float: "left"
    }
}

export const orderIconStyle = () => {
    return {
        fontSize: "20px",
        color: "grey",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const orderFontAwesomeIconStyle = () => {
    return {
        fontSize: "20px!important",
        width: 20,
        height: 20,
        color: "grey",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const orderHeaderTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey",
        paddingBottom: "2px"
    }
}

export const orderTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey"
    }
}

export const orderCartTitleTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "17px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}

export const orderReviewGridStyle = () => {
    return {
        margin: "20px",
        padding: "10px 0px",
        background: "#f2f2f2",
        borderRadius: "10px"
    }
}

export const orderCartItemQuantityTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "12px",
        // marginRight: "10px",
        padding: "10px 12px",
        textWrap: "balance",
        textAlign: "center",
        background: "#f2f2f2",
        borderRadius: "10px"
    }
}

export const orderCommentTextStyle = () => {
    return {
        display: "block",
        width: "90%",
        overflow: "hidden",
        textAlign: "justify",
        fontSize: "10px",
        color: "grey"
    }
}

export const orderItemHeaderTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px"
    }
}
export const orderItemNoteTextStyle = () => {
    return {
        fontWeight: 600,
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey"
    }
}

export const getOrderAllergyIconStyle = () => {
    return {
        width: 40,
        height: 40,
        fill: "#54b1d2"
    }
}

export const getItemExtraIconStyle = () => {
    return {
        width: 20,
        height: 20
    }
}

export const orderItemFontAwesomeIconStyle = () => {
    return {
        fontSize: "15px!important",
        width: 15,
        height: 15,
        color: "black",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const orderItemPriceTextStyle = () => {
    return {
        display: "block",
        width: "90%",
        overflow: "hidden",
        textAlign: "justify",
        fontSize: "10px",
        color: "black"
    }
}

export const orderItemTaxTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        textAlign: "left",
        marginLeft: "10px"
    }
}

export const orderItemTaxPriceTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        paddingLeft: "8px",
        textAlign: "left",
    }
}

export const orderItemTotalTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        textAlign: "left",
        marginLeft: "10px"
    }
}

export const orderItemTotalPriceTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        paddingLeft: "8px",
        textAlign: "left",
    }
}

export const orderDiscountTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        textAlign: "left",
        marginLeft: "10px"
    }
}

export const orderDiscountValueTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        paddingLeft: "15px",
        textAlign: "left",
    }
}

export const orderItemMuiIconStyle = () => {
    return {
        fontSize: "15px!important",
        width: 15,
        height: 15,
        color: "black",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const orderDiscountTextsStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey",
        marginLeft: "10px"
    }
}

export const settingFabButtonStyle = () => {
    return {
        position: 'fixed',
        bottom: 30,
        right: 20,
        backgroundColor: "#54b1d2",
        color: "white",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: "white",
        }
    }
}

// ================== LANGUAGE

export const languageIconStyle = () => {
    return {
        fontSize: "20px",
        color: "grey",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const languageTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey"
    }
}

export const orderTipCardStyle = (isFocused = false) => {
    return {
        width: 'fit-content',
        borderRadius: "20px",
        width: "100%",
        height: "100%",
        boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        background: isFocused ? "#47a6d2" : "initial",
        color: isFocused ? "white" : "black",
    }
}
export const orderTipCardActionStyle = () => {
    return {
        width: '100%',
        height: '60px',
    }
}
export const orderTipCardActionTextStyle = () => {
    return {
        textAlign: "center",
        fontWeight: '900',
        ...lumanosimoTitleStyle()
    }
}
export const orderTipCardActionTextPriceStyle = () => {
    return {
        textAlign: "center",
        fontSize: "10px"
    }
}

// ================== OUTLET DETAILS

export const outletDetailsIconStyle = () => {
    return {
        fontSize: "20px",
        color: "grey",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const outletDetailsTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey"
    }
}

export const outletInfoHeaderTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "16px",
        color: "grey",
        paddingBottom: "2px",
        ...lumanosimoTitleStyle()
    }
}

export const outletInfoTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "break-spaces",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey"
    }
}

// ================== MENU

export const menuCategoryHeaderTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "16px",
        color: "grey",
        paddingBottom: "2px"
    }
}

// ================== MENU ITEM

export const menuItemButtonStyle = (buttonBackgroundColor, buttonFont, buttonColor, buttonFontSize) => {
    return {
        background: buttonBackgroundColor,
        fontFamily: buttonFont,
        color: buttonColor,
        fontSize: buttonFontSize,
        textTransform: "initial"
    }
}

export const menuItemHeaderTextStyle = (menuIitemStyle) => {
    return {
        fontWeight: "bold",
        wordWrap: "break-word",
        textWrap: "wrap",
        ...menuIitemStyle,
    }
}

export const menuItemDescriptionHeaderTextStyle = (menuIitemStyle) => {
    return {
        wordWrap: "break-word",
        textWrap: "wrap",
        ...menuIitemStyle,
    }
}

export const menuItemPriceHeaderTextStyle = (menuIitemStyle) => {
    return {
        marginTop: "10px",
        marginBottom: "10px",
        fontWeight: "bold",
        wordWrap: "break-word",
        textWrap: "wrap",
        ...menuIitemStyle,
    }
}

export const menuItemPriceIconStyle = (menuIitemStyle) => {
    return {
        placeItems: "center",
        paddingTop: "2px",
        iconPositionStart: false,
        ...menuIitemStyle,
        fontSize: "16px",
    }
}

export const menuItemPhotoGridStyle = (isMobile) => {
    return {
        padding: "10px",
        // margin: "20px",
        marginLeft: "0px",
        marginTop: "0px",
        borderRadius: "15px",
        border: isMobile ? "none" : "1px solid lightgrey",
        width: "100%",
        textAlign: "left"
    }
}

export const menuItemPhotoDivStyle = (itemPhotoStyle) => {
    return {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginLeft: "5px",
        width: "112px",
        height: "98px",
        borderRadius: itemPhotoStyle === "SQUARE" ? "10px" : "50%",
        right: 0,
        float: "right",
        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
    }
}

export const menuItemGridPhotoDivStyle = (itemPhotoStyle) => {
    return {
        marginLeft: "5px",
        height: "131px",
        borderRadius: itemPhotoStyle === "SQUARE" ? "10px" : "50%",
        right: 0,
        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
    }
}

export const menuTitleStyle = () => {
    return {
        fontSize: "18px",
        ...lumanosimoTitleStyle()
    }
}

// export const menuCategoryHeaderTextStyle = () => {
//     return {
//         fontWeight: "bold",
//         // display: "block",
//         // width: "100%",
//         // overflow: "hidden",
//         // whiteSpace: "nowrap",
//         // textOverflow: "ellipsis",
//         display: "block",
//         width: "90%",
//         overflow: "hidden",
//         textAlign: "justify",
//         fontSize: "16px",
//         color: "grey",
//         paddingBottom: "2px"
//     }
// }



export const menuPhotoDivStyle = (menuPhotoStyle, includeBoxShadow = true) => {
    return {
        marginLeft: "5px",
        width: "100%",
        height: "98px",
        borderRadius: menuPhotoStyle === "SQUARE" ? "10px" : "50%",
        right: 0,
        float: "right",
        boxShadow: includeBoxShadow ? "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" : "initial"
    }
}
export const menuParentCategoryHeaderTextStyle = (sx) => {
    return {
        fontWeight: "bold",
        wordWrap: "break-word",
        textWrap: "wrap",
        fontSize: "22px",
        color: "black",
        ...sx
    }
}

export const menuParentCategoryDescriptionHeaderTextStyle = (sx) => {
    return {
        wordWrap: "break-word",
        textWrap: "wrap",
        fontSize: "12px",
        color: "black",
        ...sx
    }
}

export const menuNavigationHorizontalStyle = () => {
    return {
        position: "sticky",
        top: "60px",
        zIndex: "100"
    }
}

export const menuParentCategoryPhotoDivStyle = (itemPhotoStyle, includeBoxShadow = true) => {
    return {
        marginLeft: "5px",
        width: "112px",
        height: "98px",
        borderRadius: itemPhotoStyle === "SQUARE" ? "10px" : "50%",
        right: 0,
        float: "right",
        boxShadow: includeBoxShadow ? "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" : "initial"
    }
}

export const getOrderSuccessText = () => {
    return {
        ...orderDiscountTextStyle(),
        textAlign: "center",
        ...lumanosimoTitleStyle()
    }
}

// ================== MENU CATEGORIES STICKY 

export const topHeaderMenuBoxAppBar = (singleCategory = false) => {
    return {
        background: "white",
        height: singleCategory ? "30px" : "80px",
        alignItems: "right",
        width: "100%",
    };
}
export const topHeaderMenuBoxAppBarSticky = (singleCategory = false) => {
    return {
        background: "white",
        height: singleCategory ? "30px" : "80px",
        alignItems: "right",
        width: "100%",
        marginTop: "60px"
    };
}

// ==================== CART BUTTON
export const cartButtonStackStyle = () => {
    return {
        width: "100%"
    }
}

export const cartButtonTotalNumberItemStyle = () => {
    return {
        width: "20%"
    }
}

export const cartButtonTotalNumberTextStyle = () => {
    return {
        backgroundColor: "white",
        width: "20px",
        maxWidth: "50px",
        color: "black",
        borderRadius: "5px"
    }
}

export const cartButtonLabelItemStyle = (paymentWithCard) => {
    return {
        width: paymentWithCard ? "60%" : "100%",
    }
}


export const cartButtonTotalAmmountItemStyle = () => {
    return {
        width: "30%"
    }
}

export const cartButtonTotalAmmountTextStyle = () => {
    return {
        backgroundColor: "transparent",
        width: "20px",
        maxWidth: "50px",
        color: "black",
        width: "100%"
    }
}

// ==================== Item Nutrients
export const itemNutrientText = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "10px",
        color: "#b4b4b4"
    }
}
export const itemNutrientBoldText = () => {
    return {
        fontWeight: "bold",
        ...itemNutrientText()
    }
}

export const itemNutrientCardTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "11px",
        // marginRight: "10px",
        padding: "10px 5px",
        textWrap: "balance",
        textAlign: "center",
        background: "#f2f2f2",
        borderRadius: "10px",
        alignSelf: "center"
    }
}

export const itemNutrientDetailTitleStyle = () => {
    return {
        fontSize: "12px",
        ...lumanosimoTitleStyle(),
        overflow: "initial"
    }
}
export const itemNutrientSubDetailTitleStyle = () => {
    return {
        fontSize: "9px",
        ...lumanosimoTitleStyle(),
        overflow: "initial",
        paddingLeft: "10px"
    }
}


export const itemNutrientDetailGramsTitleStyle = () => {
    return {
        fontSize: "10px",
        ...lumanosimoTitleStyle(),
        overflow: "initial"
    }
}

export const itemNutrientDetailTextStyle = () => {
    return {
        fontSize: "10px",
        overflow: "initial"
    }
}

// ==================== RATING

export const ratingMessageTitleTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "18px",
        color: "grey",
        paddingBottom: "2px",
        textAlign: "left",
        marginLeft: "10px",
        ...lumanosimoTitleStyle(),
    }
}

export const ratingCheckbox = () => {
    return {
        wordWrap: "break-word",
        textWrap: "wrap",
        textAlign: "justify",
        '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
            wordWrap: "break-word",
            textWrap: "wrap",
            fontSize: "13px",
            color: "black",
            marginTop: "20px",
            textAlign: "justify",
            wordWrap: "break-word",
            textWrap: "wrap",
        }
    }
}

export const ratingInfoText = () => {
    return {
        wordWrap: "break-word",
        textWrap: "wrap",
        fontSize: "11px",
        color: "grey",
        marginTop: "40px",
        textAlign: "justify",
        ...lumanosimoTitleStyle(),
    }
}

export const ratingIconStyle = () => {
    return {
        fontSize: "20px",
        color: "green",
        border: "1px solid green",
        padding: "5px",
        borderRadius: "30px"
    }
}

// ================== DELETE ACCOUNT

export const deleteAccountIconStyle = () => {
    return {
        fontSize: "20px",
        color: "grey",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}
export const deleteAccountDialogHeaderTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "22px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center",
        color: "red"
    }
}

export const deleteAccountDialogTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        textAlign: "justify",
        fontSize: "16px",
        color: "grey"
    }
}

export const deleteAccountDialogActionsQuestionTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        textAlign: "justify",
        fontSize: "18px",
        color: "grey"
    }
}

export const deleteAccountDialogActionsConfirmTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        textAlign: "justify",
        fontSize: "18px",
        color: "black",
        textAlign: "center",
    }
}

export const deleteAccountHeaderTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "18px",
        color: "red"
    }
}

export const deleteAccountTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey"
    }
}


// ==================SPLASH SCREEN
export const splashScreenContainer = (seconds = 5) => {
    return {
        // position: "absolute",
        textAlign: "center",
        height: "100vh",
        width: "100%",
        minHeight: "300px",
        //animate
        // background: "linear-gradient(90deg, #fafafa 0px, #d9d9d9 50%, #fafafa 100%)",
        backgroundSize: "100% 100%",
        animation: `${SplashScreenAnimation} ${seconds}s ease-in-out`
    };
}
const SplashScreenAnimation = keyframes`
   0% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 `;

export const splashScreenContainerImage = () => {
    return {
        width: "50%",
        height: "50%"
        // position: "absolute",
        // top: "50%",
        // left: "50%"
    };
}
export const splashScreenContainerTextContainer = () => {
    return {
        // position: "absolute",
        bottom: "20px",
        width: "100%"
    };
}
export const splashScreenContainerTextContainerText = () => {
    return {
        fontSize: "30px",
        color: "#fff",
        textAlign: "center",
        fontFamily: "'Pacifico', cursive",
        paddingTop: "20px"
    };
}

// WIFI

export const textWifiHeaderTypography = () => {
    return {
        fontSize: '16px',
        paddingBottom: 0,
        fontWeight: "bold",
        paddingTop: "5px",
        textAlign: "center",
        color: "#54b1d2"
    };
}

export const textWifiItemTypography = () => {
    return {
        fontSize: 'md',
        textAlign: "left",
        height: "25px",
        paddingBottom: 0,
        width: "100%",
        fontWeight: "bold",
        paddingTop: "5px",
    };
}

export const activeWifiIcon = () => {
    return {
        // boxShadow: "0px 0px 15px 0px #dcdcdc",
        fontSize: 13,
        marginLeft: "-30px",
        paddingTop: "85px",
        zIndex: "1000"
    };
}

// ==================== GLOBAL
export const dividerStyle = () => {
    return {
        borderRightWidth: 5,
        width: "100%",
        margin: "20px 0px"
    }
}

export const textAlignLeft = () => {
    return {
        textAlign: "left"
    }
}

export const textAlignRight = () => {
    return {
        textAlign: "right"
    }
}
export const textAlignCenter = () => {
    return {
        textAlign: "center"
    }
}
export const justifyContentCenter = () => {
    return {
        justifyContent: "center"
    }
}

export const lumanosimoTitleStyle = () => {
    return {
        fontWeight: 900,
        fontFamily: "Lumanosimo"
    }
}

export const drawerTopStyle = (anchor) => {
    return {
        position: "sticky",
        top: "0",
        zIndex: "100",
        background: "transparent",
        textAlign: "-webkit-center",
        borderRadius: anchor === "bottom" ? "10px 10px 0px 0px" : "initial",
        paddingTop: "10px",
        marginBottom: "10px",
        // display: "flex"
    }
}

export const ItemPaper = styled(Paper)(({ sx }) => ({
    background: "transparent",
    padding: "0px",
    textAlign: 'center',
    boxShadow: "none",
    border: "0px",
    fontWeight: "bold",
    ...sx
}));


export const cardItemButtonGroupStackButtonIcon = () => {
    return {
        marginRight: "0px"
    }
}
export const cardItemContentStackButtonIcon = () => {
    return {
        marginTop: "-10px",
        zIndex: -1
    }
}
export const cardItemContentActionArea = () => {
    return {
        justifyContent: 'flex-end',
        height: "100%",
        width: "100%",
        textAlign: "center",
        padding: "10px 0"
    };
}

export const cardItemContent = () => {
    return {
        width: "320px",
        height: "190px",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "20px",
        marginBottom: "20px",
        boxShadow: 2,
        overflow: "visible"
    };
}