import { CartActionTypes } from "./CartActionTypes";

export const addItemToCart = (outletId, organizationId, item, modifiersPrice, pvcRecyclingTax) => ({
    type: CartActionTypes.ADD_ITEM_TO_CART,
    payload: { outletId, organizationId, item, modifiersPrice, pvcRecyclingTax }
});

export const removeItemFromCart = (outletId, itemID, index) => ({
    type: CartActionTypes.REMOVE_ITEM_FROM_CART,
    payload: { outletId, itemID, index }
});

export const updateItemInCart = (outletId, itemID, newItem, modifiersPrice, indexInCart) => ({
    type: CartActionTypes.UPDATE_ITEM_IN_CART,
    payload: { outletId, itemID, newItem, modifiersPrice, indexInCart }
});

export const updateCartComments = (outletId, comments) => ({
    type: CartActionTypes.UPDATE_CART_COMMENTS,
    payload: { outletId, comments }
});

export const updateCartCoupon = (outletId, coupon) => ({
    type: CartActionTypes.UPDATE_CART_COUPON,
    payload: { outletId, coupon }
});

export const updateCartTipAmount = (outletId, tipAmount) => ({
    type: CartActionTypes.UPDATE_CART_TIP_AMOUNT,
    payload: { outletId, tipAmount }
});

export const updateCartOrderId = (outletId, orderID) => ({
    type: CartActionTypes.UPDATE_CART_ORDER_ID,
    payload: { outletId, orderID }
});

export const updateCartAllergies = (outletId, alergies) => ({
    type: CartActionTypes.UPDATE_CART_ALLERGIES,
    payload: { outletId, alergies }
});

export const updateCartPvcRecyclingTax = (outletId, updateCartPvcRecyclingTax) => ({
    type: CartActionTypes.UPDATE_CART_PVC_RECYCLING_TAX,
    payload: { outletId, updateCartPvcRecyclingTax }
});

export const updateCartEquipment = (outletId, equipment) => ({
    type: CartActionTypes.UPDATE_CART_EQUIPMENT,
    payload: { outletId, equipment }
});

export const clearOutletCart = (outletId) => ({
    type: CartActionTypes.CLEAR_OUTLET_CART,
    payload: { outletId }
});

export const updateCartPaymentMethod = (outletId, paymentMethods) => ({
    type: CartActionTypes.UPDATE_CART_PAYMENT_METHOD,
    payload: { outletId, paymentMethods }
});

export const updateCartTaxPercentage = (outletId, taxPercent) => ({
    type: CartActionTypes.UPDATE_CART_TAX_PERCENTAGE,
    payload: { outletId, taxPercent }
});

export const updateCartPersons = (outletId, persons) => ({
    type: CartActionTypes.UPDATE_CART_PERSONS,
    payload: { outletId, persons }
});

export const updateOutletMenuLang= (outletId, menuLang) => ({
    type: CartActionTypes.UPDATE_OUTLET_MENU_LANG,
    payload: { outletId, menuLang }
});
