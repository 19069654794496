
import { InputAdornment, TextField } from '@mui/material';
import React from 'react';

/**
 * The Custom Text Field, that display customized {@link TextField}.
 * 
 * @param {string} id the text field id
 * @param {string} name the text field name
 * @param {string} type the text field type
 * @param {int} step  For number type case, the number step counter
 * @param {int} min  For number type case, the min value
 * @param {int} max  For number type case, the max value
 * @param {string} className the text field class
 * @param {string} label the text field label
 * @param {string} label the text field start input-adorment label
 * @param {string} defaultValue the text field default value
 * @param {string} placeholder the text field placeholder
 * @param {string} helperText the text field helper text
 * @param {boolean} required if the value is required at this text field 
 * @param {boolean} error if text field is at an error state
 * @param {function} onChange function that triggered when value is changed
 * @param {*} sx  Applies the theme textfield styles.
 *
 * @returns {JSX.Element} A React component that renders a text field with icon.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTextFieldWithIcons({ id = "", name = "", type = "", step, min, max, className, label, startIcon, endIcon, defaultValue, placeholder, helperText, required = true, error = false, onChange, sx }) {
    return (
        <TextField
            id={id}
            name={name}
            type={type}
            step={step}
            min={min}
            max={max}
            className={className}
            label={label}
            defaultValue={defaultValue}
            placeholder={placeholder}
            helperText={helperText}
            required={required}
            error={error}
            onChange={onChange}
            style={{
                background: "white",
                border: "none",
                width: "99.5%",
                ...sx
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="end" >
                        {startIcon}
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" >
                        {endIcon}
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default CustomTextFieldWithIcons;