import React from "react";
import CustomGrid from 'components/Grid/CustomGrid';
import { mainTag, orderBackBtnStyle, orderDiscountTextStyle } from 'themes/defaultThemes';
import useDocumentTitle from "utils/useDocumentTitle";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import CustomTopHeader from "components/Header/CustomTopHeader";
import { lottieDefaultOptions } from "constants/animations/lottieOptions";
import { LottieFail } from "assets/lottie/LottieAnimations";
import Lottie from "lottie-react";
import CustomIconButton from "components/Button/CustomIconButton";
import { ArrowBackIcon } from "assets/mui/MuiIcons";
import { Grid } from "@mui/material";
import { getLanguageFromURL } from "utils/language";
import CustomTypography from "components/Typography/CustomTypography";

/**
 * The FailOrderComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FailOrderComponent({ order = {} }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();


    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        order: order
    });

    const [isLoaded, setIsLoaded] = useState(false);

    // const { outletURL } = useParams();
    const params = new URLSearchParams(location.search);
    const equipmentLabel = params.get("e");
    const outletURL = params.get("outletURL");

    //change document title
    useDocumentTitle(outletURL);

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="failed-payemnt" sx={mainTag()}>
            <CustomTopHeader key="th" outletURL={outletURL} />

            <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                <CustomIconButton
                    icon={<ArrowBackIcon />}
                    sx={orderBackBtnStyle()}
                    onClick={() => navigate(`/${getLanguageFromURL()}/checkout/${outletURL}?e=${equipmentLabel}`)}
                    label={t('order.fail.backButton')}
                />
            </Grid>

            <Lottie
                {...lottieDefaultOptions}
                animationData={LottieFail}
            />

            <Grid item xs={12} md={12} sx={{ justifyContent: "center" }}>
                <CustomTypography variant="body2" text={t('order.fail.infoPayment')} sx={orderDiscountTextStyle()} />
            </Grid>
        </CustomGrid>
    );
}

export default FailOrderComponent;