import {
    CardMedia,
    Grid,
    ListItem
} from '@mui/material';
import CustomTypography from 'components/Typography/CustomTypography';
import { menuParentCategoryDescriptionHeaderTextStyle, menuParentCategoryHeaderTextStyle, menuParentCategoryPhotoDivStyle } from 'themes/defaultThemes';
import { getMenuParentCategoryPhoto } from 'utils/functions/assets';

/**
 * The CustomMenuParentCategoryInstance, that display each parentCategory parent category.
 * 
 * @param {object} item the parentCategory item details
 * @param {string} priceColor the color for price to have
 * @param {string} lang the lang code
 * @param {fucntion} handleItemDialogState Callback that handles the open item details dialog for more details and to fill in fields to add one or more to cart.
 *
 * @returns {JSX.Element} A React component that renders a parentCategory item.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuParentCategoryInstance({
    organizationID,
    parentCategory,
    menuConfiguration,
    handleParentCategoryClick,
}) {
    const selectedLayout = menuConfiguration.parentCategoryLayout;

    return (
        <ListItem onClick={() => handleParentCategoryClick(true, parentCategory.id)}
            sx={{
                width: selectedLayout.includes("LIST") ? "inherit" : "-webkit-fill-available",
                marginBottom: "10px",
                marginLeft: "10px",
                marginRight: "10px",
                boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                background: menuConfiguration?.parentCategoryBackgroundColor
            }}
        >
            <Grid container>
                {selectedLayout === "LIST" &&
                    <>
                        <Grid item xs={7} md={7}>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={parentCategory.name} sx={menuParentCategoryHeaderTextStyle({ fontSize: menuConfiguration?.parentCategoryHeaderFontSize, color: menuConfiguration?.parentCategoryHeaderColor })} />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    {parentCategory.description?.length > 0 &&
                                        <CustomTypography variant="body2" text={parentCategory.description} sx={menuParentCategoryDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.parentCategoryDescFontSize, color: menuConfiguration?.parentCategoryDescColor })} />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        {parentCategory.photos?.length > 0 &&
                            <Grid item xs={5} md={5}>
                                <CardMedia
                                    sx={menuParentCategoryPhotoDivStyle(menuConfiguration?.parentCategoryPhotoStyle)}
                                    image={getMenuParentCategoryPhoto(organizationID, parentCategory.id, parentCategory.photos[0])}
                                />
                            </Grid>
                        }
                    </>
                }

                {selectedLayout === "GRID" &&
                    <>
                        <Grid item xs={12} md={12}>
                            {parentCategory?.photo?.length > 0 &&
                                <CardMedia
                                    sx={menuParentCategoryPhotoDivStyle(menuConfiguration?.parentCategoryPhotoStyle)}
                                    image={getMenuParentCategoryPhoto(organizationID, parentCategory.id, parentCategory.photo[0])}
                                />
                            }
                            {parentCategory?.photos?.length === 0 &&
                                <CardMedia
                                    sx={menuParentCategoryPhotoDivStyle(menuConfiguration?.parentCategoryPhotoStyle)}
                                />
                            }
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                            <CustomTypography variant="body2" text={parentCategory.name} sx={menuParentCategoryHeaderTextStyle({ fontSize: menuConfiguration?.parentCategoryHeaderFontSize, color: menuConfiguration?.parentCategoryHeaderColor })} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            {parentCategory.description?.length > 0 &&
                                <CustomTypography variant="body2" text={parentCategory.description} sx={menuParentCategoryDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.parentCategoryDescFontSize, color: menuConfiguration?.parentCategoryDescColor })} />
                            }
                        </Grid>
                    </>
                }
            </Grid>
        </ListItem>
    );
}

export default CustomMenuParentCategoryInstance;