import { Grid } from "@mui/material";
import { LottieAccountDelete, LottieSuccessAnimation } from "assets/lottie/LottieAnimations";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeader from "components/Header/CustomTopHeader";
import CustomTypography from "components/Typography/CustomTypography";
import { lottieDeleteAccountOptions } from "constants/animations/lottieOptions";
import Lottie from "lottie-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteAccountTextStyle, lumanosimoTitleStyle, mainTag } from 'themes/defaultThemes';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The SuccessAccountDeleteComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SuccessAccountDeleteComponent() {
    const { t } = useTranslation();
    const [showAnimation1, setShowAnimation1] = useState(true);
    const [showAnimation2, setShowAnimation2] = useState(false);

    function handleAnimationFinish() {
        // Trigger the second animation when the first one finishes
        // When the first animation finishes, hide it and show the second animation
        setShowAnimation1(false);
        setShowAnimation2(true);
    };

    //change document title
    useDocumentTitle(t('customer.deleletAccount.successTitle'));

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="success-delete-account" sx={mainTag()}>
            <CustomTopHeader key="th" />

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12} md={12} sx={{ textAlign: "-webkit-center" }}>

                            {showAnimation1 && (
                                <Lottie
                                    {...lottieDeleteAccountOptions}
                                    animationData={LottieAccountDelete}
                                    onComplete={handleAnimationFinish}
                                />
                            )}
                            {showAnimation2 && (
                                <Lottie
                                    {...lottieDeleteAccountOptions}
                                    animationData={LottieSuccessAnimation}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ margin: "0 20px", textAlign: "justify" }}>
                            <CustomTypography variant="body2" text={t('customer.deleletAccount.success')} sx={{ ...deleteAccountTextStyle(), ...lumanosimoTitleStyle() }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomGrid>
    );
}

export default SuccessAccountDeleteComponent;