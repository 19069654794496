export function findCurrency(currencyCode) {
    switch (currencyCode) {
        case "EUR":
            return "€"
        default:
            return "€"
    }
}

export function priceTextWithCurrency(currencyCode, price) {
    return `${findCurrency(currencyCode)} ${parseFloat(price).toFixed(2)}`;
}

export default class functions {
    static findCurrency(currencyCode) { return findCurrency(currencyCode); }
    static priceTextWithCurrency(currencyCode, price) { return priceTextWithCurrency(currencyCode, price); }
}
