import { Grid } from '@mui/material';
import outletApi from 'api/outlet';
import { ArrowBackIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBackBtnStyle } from 'themes/defaultThemes';
import AnalyticsRatings from '../analytics/AnalyticsRatings';
import CustomLanguageUpdateCard from '../language/CustomLanguageUpdateCard';
import CustomMenuLanguageCard from "./CustomMenuLanguageCard";
import CustomOutletInfoCard from './CustomOutletInfoCard';
import CustomOutletInformationCard from "./CustomOutletInformationCard";
import CustomReviewsCard from "./CustomReviewsCard";

/**
 * The CustomOutletDetailsCard, that display outlet details.
 * 
 * @param {*} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @param {*} children The children of the drawer
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomOutletDetailsCard({
    organizationID,
    outletID,
    outlet,
    updateLanguageField,
    updateLanguagePreferrence,
    lang,
    outletURL = "",
    showLanguage = true
}) {
    const { t } = useTranslation();
    const [openOutletInfo, setOpenOutletInfo] = useState(false);
    const [openLanguageUpdate, setOpenLanguageUpdate] = useState(false);
    const [openRatings, setOpenRatings] = useState(false);
    const [outletInfo, setOutletInfo] = useState(null);

    useEffect(() => {
        getOutletInfo();
    }, []);

    /**
   * The rest endpoint to get the menu page details.
   */
    function getOutletInfo() {
        outletApi.fetchInfo(outletURL).then((r) => {
            Promise.resolve()
                .then(() => {
                    setOutletInfo(r.data.returnobject);
                })
        }).catch((e) => {
        });
    }

    return (
        <Grid container sx={{ padding: "10px", paddingTop: "30px" }} gap={2}>
            {(!openOutletInfo && !openLanguageUpdate && !openRatings) &&
                <>
                    {outletInfo?.showReviews &&
                        <CustomReviewsCard
                            handleReviewsClick={() => {
                                setOpenOutletInfo(false);
                                setOpenLanguageUpdate(false);
                                setOpenRatings(true);
                            }}
                        />
                    }

                    <CustomOutletInformationCard
                        handleOutletInfoClick={() => {
                            setOpenOutletInfo(true);
                            setOpenLanguageUpdate(false);
                            setOpenRatings(false);
                        }}
                    />

                    {showLanguage &&
                        <CustomMenuLanguageCard
                            lang={lang}
                            handleLanguageClick={() => {
                                setOpenOutletInfo(false);
                                setOpenLanguageUpdate(true);
                                setOpenRatings(false);
                            }}

                        />
                    }
                </>
            }

            {openOutletInfo &&
                <>
                    <CustomIconButton
                        icon={<ArrowBackIcon />}
                        sx={orderBackBtnStyle()}
                        onClick={() => {
                            setOpenOutletInfo(false);
                            setOpenLanguageUpdate(false);
                        }}
                        label={t('outlet.backButton')}
                    />

                    <CustomOutletInfoCard
                        organizationID={organizationID}
                        outletID={outletID}
                        outletURL={outletURL}
                    />
                </>
            }

            {openLanguageUpdate &&
                <>
                    <CustomIconButton
                        icon={<ArrowBackIcon />}
                        sx={orderBackBtnStyle()}
                        onClick={() => {
                            setOpenOutletInfo(false);
                            setOpenLanguageUpdate(false);
                        }}
                        label={t('outlet.backButton')}
                    />

                    <CustomLanguageUpdateCard
                        language={lang}
                        updateLanguagePreferrence={updateLanguagePreferrence}
                        updateLanguageField={updateLanguageField}
                        outletID={outletID}
                        outletLanguages={outlet?.settings?.outletLanguages}
                    />
                </>
            }

            {openRatings &&
                <>
                    <CustomIconButton
                        icon={<ArrowBackIcon />}
                        sx={orderBackBtnStyle()}
                        onClick={() => {
                            setOpenOutletInfo(false);
                            setOpenRatings(false);
                            setOpenLanguageUpdate(false);
                        }}
                        label={t('outlet.backButton')}
                    />
                    <AnalyticsRatings outletID={outletID} />
                </>
            }
        </Grid>
    );
}

export default CustomOutletDetailsCard;