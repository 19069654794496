import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

/**
 * The RequestLoadingSkeleton, that display a box with animated skeletons 
 * untils the request page is ready to render.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function RequestLoadingSkeleton() {

    return (
        <Box sx={{ width: "100%", padding: "10px 40px" }}>
            <Skeleton variant="rounded" width={"100%"} height={50} sx={{ fontSize: "17px", marginTop: "20px", marginBottom: "20px", marginleft: "40%" }} />

            {/* Skeleton for back button */}
            <Skeleton variant="rounded" width={100} height={30} />

            {/* Skeleton for euipment, persons, payment method */}
            <Grid container spacing={2} gap={2} sx={{marginTop: "30px"}}>
                <Grid item xs={5} md={5}>
                    <Skeleton variant="rounded" height={80} />
                </Grid>
                <Grid item xs={5} md={5}>
                    <Skeleton variant="rounded" height={80} />
                </Grid>
                <Grid item xs={5} md={5}>
                    <Skeleton variant="rounded" height={80} />
                </Grid>
                <Grid item xs={5} md={5}>
                    <Skeleton variant="rounded" height={80} />
                </Grid>
            </Grid>

            {/* Skeleton for send button */}
            <Skeleton variant="rounded" width={100} height={50} sx={{ marginTop: "20px", borderRadius: "40px", bottom: "20px", right: "10px", position: "fixed" }} />
        </Box>
    );
}

export default RequestLoadingSkeleton;