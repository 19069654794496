import React, { useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import Authenticate from 'containers/Authenticate';
import { AuthConsumer } from 'context/AuthContext';
import Anonymous from 'components/Routes/Anonymous';
import AuthRoutes from "components/Routes/AuthRoutes";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import { useSnackbar } from "hooks/useSnackbar";
import { SnackbarProvider } from "context/SnackbarContext";
import WebFont from "webfontloader";

/**
 * The Auth controller.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function AuthController() {
    const { snackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Lumanosimo']
            }
        });
    }, []);

    return (
        <React.Fragment>
            <AuthConsumer>
                {({ isAuthenticated }) => (
                    isAuthenticated ? (
                        <AuthRoutes key={"auth-routes"} />
                    ) : (
                        <Anonymous key={"anon-routes"} />
                    )
                )}
            </AuthConsumer>
            <CustomSnackbar
                isOpen={snackbar.open}
                autoHideDuration={3000}
                message={snackbar.message}
                backgroundColor={snackbar.backgroundColor}
                handleSnackbarOpen={closeSnackbar}
            />
        </React.Fragment>
    )
}

function App() {
    return (
        <Router>
            <Authenticate>
                <SnackbarProvider>
                    <AuthController />
                </SnackbarProvider>
            </Authenticate>
        </Router>
    )
}

export default App;