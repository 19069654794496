import { request } from 'constants/alias';
import { API } from "constants/config";

/**
 * The rest request which attempts to fetch the outlet menu page details (menu, outlet basic info).
 * 
 * @param outletUrl the entered code
 * 
 * @returns an object with an attribute containing the vat details
 */
export async function fetchMenuPage(outletUrl, lang = "en", from = "", to = "", loyaltyCatgory = "", equipmentLabel = "") {
    let data = {};
    if (lang !== undefined && lang !== "") {
        data = { ...data, lang: lang }
    }
    if (from !== "") {
        data = { ...data, from: from }
    }
    if (to !== "") {
        data = { ...data, to: to }
    }
    if (loyaltyCatgory !== "") {
        data = { ...data, loyaltyCatgory: loyaltyCatgory }
    }
    if (equipmentLabel !== "") {
        data = { ...data, equipmentLabel: equipmentLabel }
    }

    return await request.get(`${API}display/menu/${outletUrl}`, {params: data});
}

export async function fetchItemDetails(outletUrl, itemID, lang = "en", loyaltyCatgory = "") {
    let data = {};
    if (outletUrl !== undefined && outletUrl !== "") {
        data = { outletUrl: outletUrl }
    }
    if (lang !== undefined && lang !== "") {
        data = { lang: lang }
    }
    if (loyaltyCatgory !== "") {
        data = { ...data, loyaltyCatgory: loyaltyCatgory }
    }

    return await request.get(`${API}display/menu/${outletUrl}/item/${itemID}`, data);
}

export async function fetchOfferDetails(outletUrl, offerID, lang = "en", loyaltyCatgory = "") {
    let data = {};
    if (outletUrl !== undefined && outletUrl !== "") {
        data = { outletUrl: outletUrl }
    }
    if (lang !== undefined && lang !== "") {
        data = { lang: lang }
    }
    if (loyaltyCatgory !== "") {
        data = { ...data, loyaltyCatgory: loyaltyCatgory }
    }

    return await request.get(`${API}display/menu/${outletUrl}/offer/${offerID}`, data);
}

const menuApi = {
    fetchMenuPage,
    fetchItemDetails,
    fetchOfferDetails
}

export default menuApi;