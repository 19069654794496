import {
    Grid
} from '@mui/material';
import { ReviewsIcon } from 'assets/mui/MuiIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { outletDetailsIconStyle, outletDetailsTextStyle } from 'themes/defaultThemes';

/**
 * The CustomReviewsCard, that display outlet menu reviews.
 * 
 * @param {fucntion} handleReviewsClick Callback that handles the outlet menu reviews update dialog to open.
 *
 * @returns {JSX.Element} A React component that renders outlet menu reviews.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomReviewsCard({ handleReviewsClick }) {
    const { t } = useTranslation();

    return (
        <Grid item xs={12} md={12} onClick={handleReviewsClick}>
            <Grid container sx={{ textAlign: "left" }}>
                <Grid item xs={2} md={2}>
                    <ReviewsIcon sx={outletDetailsIconStyle} />
                </Grid>

                <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                    <CustomTypography variant="body2" text={t('outlet.reviews')} sx={outletDetailsTextStyle()} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CustomReviewsCard;