export const getMultiLangOptions = () => {
    return [
        "en",
        // "de",
        // "fr",
        // "it",
        // "ru",
        "el",
        // "uk",
        // "es",
        // "nl",
        // "cs",
        // "pl",
        // "bg",
        // "ar",
        // "et",
        // "lt",
        // "ro",
        // "tr"
    ];
}