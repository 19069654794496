import React, { createContext, useState } from 'react';

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({ open: false, message: '', backgroundColor: '#a71313' });

    const openSnackbar = (message, backgroundColor = '#a71313') => {
        setSnackbar({ open: true, message, backgroundColor });
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, message: '', backgroundColor: '' });
    };

    return (
        <SnackbarContext.Provider value={{ snackbar, openSnackbar, closeSnackbar }}>
            {children}
        </SnackbarContext.Provider>
    );
};
