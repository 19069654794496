import { Button, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

/**
 * The Custom Button, that display an button.
 * 
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomIconButton({ keyValue, variant = "text", label = "", icon, onClick, link, sx, iconSx,
    tooltipTitle, backgroundColor = "#2596be",
    isFocused = false, disabled = false,
    target,
    rel
}) {
    return (
        <ThemeProvider theme={createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            backgroundColor: isFocused ? backgroundColor : "#fff",
                            color: isFocused ? "#fff" : backgroundColor,
                            margin: "0px",
                            fontSize: "8px",
                            fontWeight: "900",
                            ":hover": {
                                backgroundColor: backgroundColor,
                                color: "white"
                            },
                            ...sx
                        },
                        startIcon: {
                            ...iconSx,
                            marginLeft: "4px"
                        }
                    }
                }
            }
        })}>
            {!disabled &&
                <Tooltip arrow title={tooltipTitle}>
                    <Button variant={variant} key={keyValue} startIcon={icon} href={link} onClick={onClick} >{label}</Button>
                </Tooltip>
            }
            {disabled &&
                <Button variant={variant} key={keyValue} startIcon={icon} href={link} onClick={onClick} disabled={disabled} target={target} rel={rel}>{label}</Button>

            }
        </ThemeProvider>
    );
}

export default CustomIconButton;