import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, FormControl, FormHelperText, Grid, InputLabel, SvgIcon, Typography, useMediaQuery, Box } from "@mui/material";
import { GoogleOAuthProvider } from '@react-oauth/google';
import userApi from 'api/user';
import logo from "assets/images/logo.png";
import {
    ArrowBackIcon,
    InfoIcon,
    VisibilityIcon,
    VisibilityOffIcon,
} from "assets/mui/MuiIcons";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomSwippableDrawer from 'components/Drawer/CustomSwippableDrawer';
// import AppleLogin from "components/Login/AppleLoginButton";
// import FacebookLogin from "components/Login/FacebookLoginButton";
import GoogleLogin from "components/Login/GoogleLoginButton";
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTextFieldWithIcons from 'components/Text/CustomTextFieldWithIcons';
import { getDefaultAuthIsError, getDefaultAuthLoginIsError, getDefaultAuthRegistrationIsError, getDefaultCustomerLoginObject } from 'constants/defaultObjects';
import { PASSWORD_REQUIRED_LENGTH } from 'constants/fieldProperties';
import { getMultiLangOptions } from 'constants/multilangOptions';
import { AuthConsumer } from 'context/AuthContext';
import { useSnackbar } from 'hooks/useSnackbar';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import { autDrawerPaperStyle, authBackBtnStyle, authInfoStyle, authInfoTextStyle, authInfoTitleStyle, authTitleStyle, selectError, socialLoginBtnGridStyle, socialLoginBtnStyle, textFieldError, useeLoginBtnStyle } from 'themes/defaultThemes';
import { formIsAllValid, formValChangeWithParentElement, onSelectChange } from 'utils/form-validation';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/functions';
import { strengthColor, strengthIndicatorNumFunc } from 'utils/password-strength';

/**
 * The CustomLoginSignupDialog, that display a customized dialog for sign up using OTP.
 * 
 * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @param {string} id the dialog id
 * @param {string} title the dialog title
 * @param {string} message the dialog message
 * @param {string} cancelLabel the dialog cancel label
 * @param {string} actionLabel the dialog action label
 * @param {function} action the function that handles the dialog action button click
 * @param {function} handleOpen the function that handles the dialog open state
 *
 * @returns {JSX.Element} A React component that renders a dialog to view item details.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomLoginSignupDialog({ isOpen = false, action, handleOpen, organizationID, isFromCheckoutTriggered, handleLoginCheckout }) {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:468px)');
    // @param {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);
    const { openSnackbar } = useSnackbar();

    // create a counter to enable the resend code logic after 45sec
    const timerIdRef = useRef(0);
    const [counter, setCounter] = useState(5);
    useEffect(() => {
        return () => clearInterval(timerIdRef.current);
    }, []);

    /**
     * Callback that handles the start of the time interval for the resend code button to be shown on end.
     * @returns nothing
     */
    function startHandler() {
        if (timerIdRef.current) {
            return;
        }
        timerIdRef.current = setInterval(() => setCounter((prevCounter) => (prevCounter === 0) ? stopHandler() : prevCounter - 1), 1000);
    }

    /**
     * Callback that handles the end of the time interval for the resend code button to be shown.
     */
    function stopHandler() {
        clearInterval(timerIdRef.current);
        timerIdRef.current = 0;
        return 0;
    }

    const [useeLoginRegistration, setUseeLoginRegistration] = useState(false);
    const [useeLogin, setUseeLogin] = useState(null);
    const [useeRegistration, setUseeRegistration] = useState(null);
    const [useeInformation, setUseeInformation] = useState(false);

    const [actionButtonLoading, setActionButtonLoading] = useState(false);

    /**
     * @type {object}
     * 
     * @property {string} mobileNumber the user mobile number
     * @property {string} code the OTP code from customer side
     * @property {object} isError the password to attempt login
     * @property {boolean} redirect if a redirect should be performed
     * @property {object} userAuth the authenticated user infromation
     * @property {object} alertBox holds snackbar infromation and style
     * @property {object} organization holds the organization information
     */
    const [data, setData] = useState({
        customer: getDefaultCustomerLoginObject(),
        passwordShown: false,
        shownPasswordIcon: VisibilityOffIcon,
        // //form fields
        // mobileNumber: "",
        // code: "",
        // showOtp: false,
        // error messages per field
        isError: getDefaultAuthIsError
    });


    // ====================== LOGIN
    /**
     * Function to trigger Sign-in when a user press "Sign in" button
     */
    function login(contextLogin) {
        setActionButtonLoading(true);
        if (formValidation()) {
            let requestData = {
                username: data.customer.email,
                password: data.customer.password
            }
            contextLogin(requestData).then((r) => {
                if (r.data.code === "SUCCESS") {
                    setActionButtonLoading(false);
                    setUseeInformation(false);
                    handleLoginCheckout();
                    setOpen(false);
                    handleOpen(false);
                    openSnackbar(t('auth.loginSuccessfully'), "#177910");
                    // window.location.reload();
                } else {
                    setActionButtonLoading(false);
                    formValChangeWithParentElementWithNameAndValue(
                        (r.data.message === "Phone already being used for another account") ? "isError.phone" : "isError.password", t(`isError.${r.data.message}`), data, setData)
                }

            });
            setActionButtonLoading(false);
        }
    }


    function register(contextLogin) {
        let customer = data.customer;
        customer.password = customer.newPassword;

        if (formValidation()) {
            userApi.register(customer).then((r) => {
                if (r.data.code === "SUCCESS") {
                    if (r.data.message !== "Email address already being used for another account. Try login instead.") {
                        Promise.resolve()
                            .then(() => {
                                formValChangeWithParentElementWithNameAndValue("customer.password", data.customer.newPassword, data, setData);
                            })
                            .then(() => {
                                setUseeRegistration(false);
                                setUseeInformation(false);
                                setUseeLogin(true);
                                openSnackbar(t('auth.registerSuccessfully'), "#177910");
                            })
                            .then(() => {
                                setOpen(false);
                                login(contextLogin)
                            })
                    }
                } else {
                    formValChangeWithParentElementWithNameAndValue("isError.password", t('isError.accountAlreadyExists'), data, setData);
                }
            }).catch((e) => {
            });
        }
    }

    // ====================== PASSWORD
    /**
     * Password toggle handler
     */
    function togglePassword() {
        // When the handler is invoked
        // inverse the boolean state of passwordShown and icon to be shown
        setData({
            ...data,
            shownPasswordIcon: !data.passwordShown ? VisibilityIcon : VisibilityOffIcon,
            passwordShown: !data.passwordShown
        });
    };


    // ====================== FORGET PASSWORD

    /**
     * Callback that trigers the send OTP code to customer mobile number.
     */
    function sendCode() {
        if (formValidation()) {
            // smsApi.sendCodeToCustomer(organizationID, data.mobileNumber).then((r) => {

            setData({
                ...data,
                showOtp: true
                // showOtp: (r.data.code === "SUCCESS") ? true : false
            });
            setCounter(45);
            startHandler();
            // }).catch((e) => {
            // })
        }
    }

    /**
     * Function that triggers form validation and print out if the form is valid or not.
     * @returns true if form is Valid
     */
    function formValidation() {
        const credentials = {
            isError: createErrorMessages(),
            email: data.customer.email,
        }
        if (formIsAllValid(credentials)) {
            return true;
        } else {
            return false;
        }
    }



    // for password strength
    const [strength, setStrength] = React.useState(0);
    const [level, setLevel] = React.useState();

    function changePassword(value) {
        const temp = strengthIndicatorNumFunc(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };


    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns the object with the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };

        if (useeLogin) {
            isError = {
                ...isError,
                ...getDefaultAuthLoginIsError
            }

            if (data.customer.email?.length < 3) isError.email = t('isError.email');
            if (data.customer.newPassword?.length < PASSWORD_REQUIRED_LENGTH) isError.password = t('isError.password');
        }

        if (useeRegistration) {
            isError = {
                ...isError,
                ...getDefaultAuthRegistrationIsError
            }

            if (data.customer?.email.length < 3) isError.email = t('isError.email');
            if (data.customer?.firstName?.length < 1) isError.email = t('isError.firstName');
            if (data.customer?.lastName?.length < 1) isError.email = t('isError.lastName');
            if (data.customer?.newPassword?.length < PASSWORD_REQUIRED_LENGTH) isError.password = t('isError.password');
        }
        // if (!data.showOtp) isError.mobileNumber = data.mobileNumber.length < 10 ? t('isError.mobileNumber') : "";
        //  if (data.showOtp) isError.code = data.code.length < 1 ? t('isError.code') : "";

        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function that handles the visualize modal open or close state.
     * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen) {
        setOpen(isOpen);
        handleOpen(isOpen, {});
    }

    function reset() {
        setData({
            ...data,
            //form fields
            mobileNumber: "",
            code: "",
            showOtp: false,
            // error messages per field
            isError: {
                mobileNumber: "",
                mobileNumber: "",
            }
        });
    }

    // ======================= back arrow

    function onBackArrowClick() {
        if (!useeLoginRegistration && !useeLogin && !useeRegistration && !useeInformation) {
            setUseeLoginRegistration(false)
        } else if (useeLoginRegistration && !useeLogin && !useeRegistration) {
            setUseeLogin(false)
            setUseeLoginRegistration(false)
        } else if (useeLoginRegistration && useeLogin && !useeRegistration) {
            setUseeLogin(false)
            setUseeLoginRegistration(true)
        } else if (useeLoginRegistration && !useeLogin && useeRegistration) {
            setUseeRegistration(false)
            setUseeLoginRegistration(true)
        } else if (useeInformation) {
            setUseeInformation(false)
            setUseeLoginRegistration(false)
        }
    }

    //  ====================================== RENDER
    const { isError } = data;


    function body(handleLogin) {
        return <Grid container sx={{ padding: "5px 15px" }}>
            {(!useeLoginRegistration && !useeInformation) &&
                <>
                    {isFromCheckoutTriggered &&
                        <Grid item xs={12}>
                            <InputLabel sx={{ ...authInfoStyle(), color: "red" }}>{t('auth.checkoutLoginInfo')}</InputLabel>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                            <Grid
                                container
                                direction='column'
                                justifyContent='center'
                                alignItems='center'
                                sx={socialLoginBtnGridStyle()}
                            >
                                <Grid item sx={socialLoginBtnStyle(true)}>
                                    <GoogleLogin handleDialogClose={handleDialogState} handleLoginCheckout={handleLoginCheckout} />
                                </Grid>
                                {/* <Grid item sx={socialLoginBtnStyle()}>
                                <FacebookLogin />
                            </Grid>
                            <Grid item sx={socialLoginBtnStyle()}>
                                <AppleLogin />
                            </Grid> */}
                            </Grid>
                        </GoogleOAuthProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <CustomIconButton
                            sx={{ ...useeLoginBtnStyle(), height: "30px", width: "60%" }}
                            icon={<img src={logo} width={20} />}
                            onClick={() => setUseeLoginRegistration(true)}
                            label={`${t('auth.login')} / ${t('auth.register')}`}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel sx={authInfoStyle()} onClick={() => { setUseeLoginRegistration(false); setUseeInformation(true) }}>
                            <Grid container sx={{ paddingTop: "10px" }}>
                                <Grid item>
                                    <InfoIcon sx={{ marginRight: "10px", width: "15px" }} />
                                </Grid>

                                <Grid item sx={{ alignSelf: "center" }}>
                                    {t('auth.loginRegisterInfo')}
                                </Grid>
                            </Grid>
                        </InputLabel>
                    </Grid>
                </>
            }


            {(!useeLoginRegistration && useeInformation) &&
                <Grid item xs={12}>
                    <InputLabel sx={authInfoTitleStyle()}>{t('auth.info')}</InputLabel>
                    <InputLabel sx={authInfoTextStyle()}>{t('auth.infoDetails')}</InputLabel>
                </Grid>
            }

            {(useeLoginRegistration && !useeLogin && !useeRegistration && !useeInformation) &&
                <Grid item xs={12}>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        sx={socialLoginBtnGridStyle()}
                        gap={2}
                    >
                        <Grid item sx={socialLoginBtnStyle(true)}>
                            <CustomIconButton
                                sx={useeLoginBtnStyle()}
                                onClick={() => {
                                    formValChangeWithParentElementWithNameAndValue("customer", getDefaultCustomerLoginObject(), data, setData);
                                    setUseeLogin(true);
                                }}
                                label={t('auth.loginEmail')}
                            />
                        </Grid>

                        <Grid item sx={socialLoginBtnStyle(true)}>
                            <CustomIconButton
                                sx={useeLoginBtnStyle()}
                                onClick={() => {
                                    formValChangeWithParentElementWithNameAndValue("customer", getDefaultCustomerLoginObject(), data, setData);
                                    setUseeRegistration(true)
                                }}
                                label={t('auth.registerEmail')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }


            {(useeLoginRegistration && useeLogin && !useeInformation) &&
                <>
                    <Grid item xs={12}>
                        <InputLabel sx={authTitleStyle()}>{t('auth.login')}</InputLabel>
                    </Grid>

                    <Grid item xs={12}>
                        <form id="login" onSubmit={(e) => { return false; }}>
                            <Grid container spacing={2} sx={{ textAlign: "left" }}>
                                <Grid item xs={12} md={12}>
                                    <InputLabel required={true} htmlFor="email">{t('customer.email')}</InputLabel>
                                    <CustomTextField
                                        type="text"
                                        name="customer.email"
                                        id="email"
                                        autoComplete="on"
                                        required={true}
                                        defaultValue={data.customer.email}
                                        error={isError.email.length > 0 ? true : false}
                                        helperText={isError.email}
                                        sx={textFieldError()}
                                        onChange={(event) => formValChangeWithParentElement(event, data, setData, t('isError.label'))}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <InputLabel required={true} htmlFor="currentPassword">{t('customer.currentPassword')}</InputLabel>
                                    <CustomTextFieldWithIcons
                                        type={data.passwordShown ? "text" : "password"}
                                        name="customer.password"
                                        id="password"
                                        value={data.customer.password}
                                        autoComplete="on"
                                        error={isError.password.length > 0 ? true : false}
                                        helperText={isError.password}
                                        endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                        onChange={(event) => formValChangeWithParentElement(event, data, setData, t('isError.label'))}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} mt={2} sx={{ textAlign: "-webkit-center" }}>
                                    <LoadingButton
                                        id="btn-login"
                                        type="button"
                                        form="login"
                                        data-redirect="stay"
                                        onClick={() => login(handleLogin)}
                                        sx={useeLoginBtnStyle()}
                                        loading={actionButtonLoading}
                                        loadingPosition="end"
                                    >
                                        {t('auth.login')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </>
            }


            {(useeLoginRegistration && useeRegistration && !useeInformation) &&
                <>
                    <Grid item xs={12}>
                        <InputLabel sx={authTitleStyle()}>{t('auth.register')}</InputLabel>
                    </Grid>

                    <Grid item xs={12}>
                        <form id="user" onSubmit={(e) => { return false; }}>
                            <Grid container spacing={2} sx={{ textAlign: "left" }}>
                                <Grid item xs={6} md={6}>
                                    <InputLabel required={true} htmlFor="firstName">{t('customer.firstName')}</InputLabel>
                                    <CustomTextField
                                        type="text"
                                        name="customer.firstName"
                                        id="firstName"
                                        required={true}
                                        error={isError.firstName.length > 0 ? true : false}
                                        defaultValue={data.customer.firstName}
                                        onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        helperText={isError.firstName}
                                        sx={textFieldError()}
                                    />
                                </Grid>

                                <Grid item xs={6} md={6}>
                                    <InputLabel required={true} htmlFor="lastName">{t('customer.lastName')}</InputLabel>
                                    <CustomTextField
                                        type="text"
                                        name="customer.lastName"
                                        id="lastName"
                                        required={true}
                                        defaultValue={data.customer.lastName}
                                        error={isError.lastName.length > 0 ? true : false}
                                        onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        helperText={isError.lastName}
                                        sx={textFieldError()}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputLabel required={true} htmlFor="email">{t('customer.email')}</InputLabel>
                                    <CustomTextField
                                        type="email"
                                        name="customer.email"
                                        id="email"
                                        placeholder="name@provider.gr"
                                        required={true}
                                        defaultValue={data.customer.email}
                                        error={isError.email.length > 0 ? true : false}
                                        helperText={isError.email}
                                        sx={textFieldError()}
                                        onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                    />
                                </Grid>

                                <Grid item xs={7.8} md={6}>
                                    <InputLabel required={false} htmlFor="phone">{t('customer.phone')}</InputLabel>
                                    <PhoneInput
                                        value={data.customer.phone}
                                        name="customer.phone"
                                        id="phone"
                                        placeholder="Add your phone number"
                                        required={true}
                                        className={isError.phone.length > 0 ? "is-invalid" : ""}
                                        // onChange={onPhoneChange}
                                        country="gr"
                                        inputStyle={{ paddingLeft: "70px", width: "100%" }}
                                    />
                                    {isError.phone.length > 0 &&
                                        <FormHelperText sx={selectError()}>
                                            {isError.phone}
                                        </FormHelperText>
                                    }
                                </Grid>

                                <Grid item xs={4.1} md={6}>
                                    <InputLabel required={true} htmlFor="customer.lang">{t('customer.lang')}</InputLabel>
                                    <CustomSelectMultilingual
                                        id="lang"
                                        translationPath="languages"
                                        name="customer.lang"
                                        required={true}
                                        isMultiple={false}
                                        defaultValue={data.customer.lang}
                                        options={getMultiLangOptions()}
                                        onChange={(event) => onSelectChange(event, data, setData)}
                                    />
                                </Grid>

                                {/* <Grid item xs={12} md={6}>
                                <InputLabel required={false} htmlFor="username">{t('customer.username')}</InputLabel>
                                <CustomTextField
                                    type="text"
                                    name="customer.username"
                                    id="username"
                                    placeholder="Add at least 4 characters"
                                    required={true}
                                    // error={isError.username.length > 0 ? true : false}
                                    helperText={isError.username}
                                    sx={textFieldError()}
                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                />
                            </Grid> */}

                                <Grid item xs={6} sm={6}>
                                    <InputLabel required={true} htmlFor="newPassword">{t('customer.newPassword')}</InputLabel>
                                    <CustomTextFieldWithIcons
                                        type={data.passwordShown ? "text" : "password"}
                                        name="customer.newPassword"
                                        id="newPassword"
                                        placeholder="Type your new password"
                                        autoComplete="new-password"
                                        required={true}
                                        error={isError.password?.length > 0 ? true : false}
                                        onChange={(event) => { changePassword(event.target.value); formValChangeWithParentElement(event, data, setData, t('table.valueReuired')) }}
                                        helperText={isError.password}
                                        endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                        sx={textFieldError()}
                                    />
                                    <FormControl fullWidth>
                                        <Box sx={{ mb: 2 }}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item>
                                                    <Box
                                                        style={{ backgroundColor: level?.color }}
                                                        sx={{
                                                            width: 85,
                                                            height: 8,
                                                            borderRadius: '7px'
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" fontSize="0.75rem">
                                                        {level?.label}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                    <InputLabel required={true} htmlFor="verifyPassword">{t('customer.verifyPassword')}</InputLabel>
                                    <CustomTextFieldWithIcons
                                        type={data.passwordShown ? "text" : "password"}
                                        name="customer.verifyPassword"
                                        id="verifyPassword"
                                        placeholder={t('customer.retypePassword')}
                                        autoComplete="off"
                                        endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                        onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} mt={2} sx={{ textAlign: "-webkit-center" }}>
                                    <LoadingButton
                                        id="btn-register"
                                        type="button"
                                        form="login"
                                        data-redirect="stay"
                                        onClick={() => register(handleLogin)}//contextLogin
                                        sx={useeLoginBtnStyle()}
                                        loading={actionButtonLoading}
                                        loadingPosition="end"
                                    >
                                        {t('auth.register')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </>
            }
        </Grid>
    }

    return (
        <AuthConsumer>
            {({ handleLogin }) => (
                <>
                    {!isMobile &&
                        <Dialog
                            open={open}
                            onClose={() => { handleDialogState(false) }}
                            aria-describedby="auth-login-dialog"
                        >
                            {(useeLoginRegistration || (!useeInformation && useeLoginRegistration)) &&
                                <CustomIconButton
                                    icon={<ArrowBackIcon />}
                                    sx={authBackBtnStyle()}
                                    onClick={onBackArrowClick}
                                    label={undefined}
                                />
                            }

                            <DialogContent sx={{ textAlign: "-webkit-center" }}>
                                {body(handleLogin)}
                            </DialogContent>
                        </Dialog>
                    }

                    {isMobile &&
                        <CustomSwippableDrawer
                            open={open}
                            onClose={() => handleDialogState(false)}
                            onOpen={() => handleDialogState(true)}
                            anchor={isMobile ? "bottom" : "left"}
                            paperSx={autDrawerPaperStyle}
                        >
                            {/* {(!useeLoginRegistration || (useeInformation && !useeLoginRegistration)) && */}
                            {(useeLoginRegistration || (useeInformation && !useeLoginRegistration)) &&
                                <CustomIconButton
                                    icon={<ArrowBackIcon />}
                                    sx={authBackBtnStyle()}
                                    onClick={onBackArrowClick}
                                    label={undefined}
                                />
                            }

                            {body(handleLogin)}
                        </CustomSwippableDrawer>
                    }
                </>
            )}
        </AuthConsumer>
    );
}

export default CustomLoginSignupDialog;