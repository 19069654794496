import { Avatar, Grid } from '@mui/material';
import { GoogleIcon } from 'assets/mui/MuiIcons';
import CustomRating from 'components/Rating/CustomRating';
import CustomTypography from 'components/Typography/CustomTypography';
import { orderHeaderTextStyle, orderReviewGridStyle, pastOrderDateTextStyle, pastOrderOutletTextStyle, ratingIconStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';

/**
 * The Custom Rating field, that display customized customer {@link Rating}.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomRatingCardCustomer({ rating, ratingStyle = "star" }) {

    return (
        <Grid item xs={12} md={12} sx={orderReviewGridStyle()}>
            <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                <Grid item xs={3} md={3} sx={{ paddingRight: "10px" }}>
                    {(rating.customer?.logo && rating?.customer?.logo !== "" && rating?.customer?.logo !== null) &&
                        <Avatar src={rating.customer?.logo} sx={{ bgcolor: "#ddf3ffa1", width: 50, height: 50, margin: "0px 20px" }} />
                    }
                    <CustomTypography variant="body2" text={rating?.customer?.name} sx={{ ...pastOrderOutletTextStyle(), marginTop: "5px" }} />
                </Grid>

                <Grid item xs={7.5} md={7.5} sx={{ alignSelf: "center" }}>
                    <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                        <Grid item xs={10} md={10}>
                            <CustomRating
                                readOnly={true}
                                size={"medium"}
                                iconStyle={ratingStyle}
                                value={rating?.starRating}
                                sx={{ alignItems: "center" }}
                            />
                        </Grid>
                        {rating?.hasSentToGoogleReview &&
                            <Grid item xs={2} md={2}>
                                <GoogleIcon sx={ratingIconStyle} />
                            </Grid>
                        }
                        <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
                            {rating?.message !== "" &&
                                <CustomTypography variant="body2" text={rating?.message} sx={orderHeaderTextStyle()} />
                            }
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
                            <CustomTypography variant="body2" text={`${dateTimeFormatter(rating?.dateCreated, "DD/MM/yyyy - HH:mm")} `} sx={{ ...pastOrderDateTextStyle(), textAlign: "right" }} />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CustomRatingCardCustomer;