import styled from '@emotion/styled';
import { Button, Fab, Grid, Paper, Stack } from '@mui/material';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { cartButtonStackStyle, cartButtonTotalAmmountTextStyle, cartButtonTotalNumberTextStyle, lumanosimoTitleStyle } from 'themes/defaultThemes';
import { calculateQuantitiesSum, calculateTotalAmount } from 'utils/cart-functions';
import { replaceLastTwoChars } from 'utils/functions';
import { findCurrency } from 'utils/functions/order';

/**
 * The CustomCartButton, that display an cart button on mobile screens on menu page.
 * 
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCartButton({ outlet, menuConfiguration, currency, isMobile = false, addOrderCallback, brandConfiguration }) {
    const { t } = useTranslation();

    // cart
    const cart = useSelector(state => state.cart.carts[outlet?.id]?.cart || null);

    return (
        isMobile ?
            <Fab
                variant="extended"
                onClick={addOrderCallback}
                sx={{
                    width: "300px",
                    borderRadius: "10px",
                    position: 'fixed',
                    bottom: 16,
                    left: "10%",
                    background: menuConfiguration?.buttonBackgroundColor ? menuConfiguration?.buttonBackgroundColor : brandConfiguration?.buttonBackgroundColor,
                    color: menuConfiguration?.buttonColor ? menuConfiguration?.buttonColor : brandConfiguration?.buttonColor,
                    ":hover": { background: replaceLastTwoChars(menuConfiguration?.buttonBackgroundColor ? menuConfiguration?.buttonBackgroundColor : brandConfiguration?.buttonBackgroundColor, 'bf') }
                }}>
                <Stack direction="row" spacing={2} sx={cartButtonStackStyle()}>
                    <Grid container>
                        <Grid item xs={4}>
                            <CustomTypography
                                variant="body2"
                                text={calculateQuantitiesSum(cart)}
                                sx={cartButtonTotalNumberTextStyle()}
                            />
                        </Grid>

                        <Grid item xs={4} sx={{ textAlign: "-webkit-center" }}>
                            <CustomTypography
                                variant="body2"
                                text={t('cart.label')}
                                sx={{ ...cartButtonTotalAmmountTextStyle(), ...lumanosimoTitleStyle(), fontWeight: "900" }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <CustomTypography
                                variant="body2"
                                text={`${calculateTotalAmount(cart)} ${findCurrency(currency)}`}
                                sx={{ ...cartButtonTotalAmmountTextStyle(), float: "right" }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Fab>
            :
            <Button
                ariant="extended"
                onClick={addOrderCallback}
                sx={{
                    width: "280px",
                    borderRadius: "10px",
                    position: 'relative',
                    bottom: -5,
                    height: "40px",
                    // display: "block",
                    background: menuConfiguration?.buttonBackgroundColor ? menuConfiguration.buttonBackgroundColor : brandConfiguration?.buttonBackgroundColor,
                    color: menuConfiguration?.buttonColor ? menuConfiguration?.buttonColor : brandConfiguration?.buttonColor,
                    ":hover": { background: replaceLastTwoChars(menuConfiguration?.buttonBackgroundColor ? menuConfiguration?.buttonBackgroundColor : brandConfiguration?.buttonBackgroundColor, 'bf') }
                }}>
                <Stack direction="row" spacing={2} sx={cartButtonStackStyle()}>
                    <Grid container>
                        <Grid item xs={4}>
                            <CustomTypography
                                variant="body2"
                                text={calculateQuantitiesSum(cart)}
                                sx={cartButtonTotalNumberTextStyle()}
                            />
                        </Grid>

                        <Grid item xs={4} sx={{ textAlign: "-webkit-center" }}>
                            <CustomTypography
                                variant="body2"
                                text={t('cart.label')}
                                sx={{ ...cartButtonTotalAmmountTextStyle(), ...lumanosimoTitleStyle(), fontWeight: "900" }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <CustomTypography
                                variant="body2"
                                text={`${calculateTotalAmount(cart)} ${findCurrency(currency)}`}
                                sx={{ ...cartButtonTotalAmmountTextStyle(), float: "right" }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Button>
    );
}

export default CustomCartButton;