export const CartActionTypes = {
    ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
    REMOVE_ITEM_FROM_CART: 'REMOVE_ITEM_FROM_CART',
    UPDATE_ITEM_IN_CART: 'UPDATE_ITEM_IN_CART',
    UPDATE_CART_COMMENTS: 'UPDATE_CART_COMMENTS',
    UPDATE_CART_ALLERGIES: 'UPDATE_CART_ALLERGIES',
    UPDATE_CART_PVC_RECYCLING_TAX: 'UPDATE_CART_PVC_RECYCLING_TAX',
    UPDATE_CART_EQUIPMENT: 'UPDATE_CART_EQUIPMENT',
    CLEAR_OUTLET_CART: 'CLEAR_OUTLET_CART',
    UPDATE_CART_PAYMENT_METHOD: 'UPDATE_CART_PAYMENT_METHOD',
    UPDATE_CART_TAX_PERCENTAGE: 'UPDATE_CART_TAX_PERCENTAGE',
    UPDATE_CART_PERSONS: 'UPDATE_CART_PERSONS',
    UPDATE_CART_ORDER_ID: 'UPDATE_CART_ORDER_ID',
    UPDATE_CART_COUPON: 'UPDATE_CART_COUPON',
    UPDATE_CART_TIP_AMOUNT: 'UPDATE_CART_TIP_AMOUNT',
    UPDATE_OUTLET_MENU_LANG: 'UPDATE_OUTLET_MENU_LANG'
};
