
import { Card, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import CustomFlipCard from 'components/Card/loyalty/CustomFlipCard';
import CustomLoyaltyCardBackSide from 'components/Card/loyalty/CustomLoyaltyCardBackSide';
import CustomLoyaltyCardFrontSide from 'components/Card/loyalty/CustomLoyaltyCardFrontSide';
import { MINIO_HOST } from 'constants/hosts';
import { getFlipCardConfiguration, getLoyaltyCardProperties } from 'constants/loyaltyCardProperties';
import { useState } from 'react';

/**
 * The Custom Loyalty Card, that display customized {@link Card}.
 * 
 * @property {string} name the Checkbox name value
 * @property {string} selected If `true`, the component is checked.
 * @property {string} label the Checkbox text value
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {function} onChange Callback that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomLoyaltyCard({ category,
    organizationID = "",
    logoImgSrc = "",
    cardProperties,
    isFlippedValue = false,
    userFullName = ""
}) {
    const flipConfiguration = getFlipCardConfiguration();
    const [isFlipped, setFlipped] = useState(isFlippedValue);

    /**
     * Callback that handles the card flip
     */
    function flipCard() {
        setFlipped(!isFlipped);
    }

    let style = {};
    if (category.backgroundType === "IMAGE") {

        let categoryBackgroundImage = !category.backgroundImage.includes("https://") ? `${MINIO_HOST}organization/${organizationID}/loyalty/category/${category.id}/${category.backgroundImage}` : category.backgroundImage;
        style = {
            backgroundImage: `url(${categoryBackgroundImage})`,
            backgroundSize: "cover"
        }
    } else {
        if (!category.gradientBackground)
            style = {
                backgroundColor: `${category.backgroundColor1}!important`,
            }
        else
            style = {
                background: `linear-gradient(to right bottom, ${category.backgroundColor1}, ${category.backgroundColor2})!important`,
            }
    }

    return (
        <Grid>
            <Stack direction="row" sx={{ marginTop: "-30px", zIndex: -1 }} key={`stack-card-${category.id}`}>
                {!cardProperties.showBarcode &&
                    <CustomLoyaltyCardFrontSide
                        category={category}
                        cardProperties={cardProperties}
                        organizationID={organizationID}
                        logoImgSrc={logoImgSrc}
                        userFullName={userFullName}
                        sx={{
                            ...getLoyaltyCardProperties(),
                            ...style
                        }} />
                }
                {cardProperties.showBarcode &&
                    <CustomFlipCard
                        isFlipped={isFlipped}
                        flipSpeedBackToFront={flipConfiguration.flipSpeedBackToFront}
                        flipConfiguration={flipConfiguration.flipSpeedFrontToBack}
                    >
                        <CustomLoyaltyCardFrontSide
                            category={category}
                            cardProperties={cardProperties}
                            onClick={flipCard}
                            organizationID={organizationID}
                            logoImgSrc={logoImgSrc}
                            userFullName={userFullName}
                            sx={{
                                ...getLoyaltyCardProperties(),
                                ...style
                            }} />
                        <CustomLoyaltyCardBackSide
                            category={category}
                            cardProperties={cardProperties}
                            onClick={flipCard}
                            sx={{
                                ...getLoyaltyCardProperties(),
                                ...style
                            }} />
                    </CustomFlipCard>
                }
            </Stack>
        </Grid>
    );
}

export default CustomLoyaltyCard;