export const getDateFormatter = () => {
    return "DD MMMM yyyy"
}

export const getDateTimeFormatter = () => {
    return "DD MMMM yyyy HH:mm"
}

export const getOrderDateTimeFormatter = () => {
    return "DD MMMM yyyy HH:mm"
}


export const getDateTimeShortFormatter = () => {
    return "DD-MM-yyyy HH:mm"
}


export const hoursMinutesPattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;