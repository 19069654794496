import {
    NewIcon,
    PepperIcon,
    VeganIcon,
    VegetarianIcon,
    CeleryIcon,
    EggsIcon,
    FishIcon,
    MustardIcon,
    WheatIcon,
    LupineIcon,
    MilkIcon,
    MusselIcon,
    PeanutIcon,
    SesameIcon,
    ShrimpIcon,
    SoyIcon
} from 'assets/svg/SvgIcons';
import { getOrderAllergyIconStyle } from 'themes/defaultThemes';

export function getAllergies(allergies) {
    let allergiesList =[];
    if(allergies.includes("eggs")) {
        allergiesList.push(<EggsIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("wheat")) {
        allergiesList.push(<WheatIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("fish")) {
        allergiesList.push(<FishIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("mustard")) {
        allergiesList.push(<MustardIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("celery")) {
        allergiesList.push(<CeleryIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("nuts")) {
        allergiesList.push(<PeanutIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("milk")) {
        allergiesList.push(<MilkIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("crustaceans")) {
        allergiesList.push(<ShrimpIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("soya")) {
        allergiesList.push(<SoyIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("lupin")) {
        allergiesList.push(<LupineIcon {...getOrderAllergyIconStyle()} />)
    }
    if(allergies.includes("seasame")) {
        allergiesList.push(<SesameIcon {...getOrderAllergyIconStyle()} />)
    }
    return allergiesList;
}

export default class functions {
    static getAllergies(allergies) { return getAllergies(allergies); };
}