import { Grid, InputLabel, Stack, Button } from "@mui/material";
import orderApi from "api/order";
import vivaPaymentApi from "api/vivaPayment";
import { NoOrders } from "assets/lottie/LottieAnimations";
import { ArrowBackIcon } from "assets/mui/MuiIcons";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomSinglePastOrderCard from "components/Card/order/CustomSinglePastOrderCard";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeader from "components/Header/CustomTopHeader";
import CustomTypography from "components/Typography/CustomTypography";
import { lottieOrderOptions } from "constants/animations/lottieOptions";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ItemPaper, cartButtonLabelItemStyle, cartButtonStackStyle, cartButtonTotalAmmountItemStyle, cartButtonTotalAmmountTextStyle, mainTag, orderBackBtnStyle, orderEmptyInfoTextStyle, orderTitleStyle } from 'themes/defaultThemes';
import { findCurrency } from "utils/functions/order";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The OrderComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OrderComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { orderID } = useParams();

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        order: null,
        outlet: null,
        equipment: null,
        rating: null,
        allowPayment: false
    });

    // for search
    const [search, setSearch] = useState({
        lang: "en"
    });

    useEffect(() => {
        getOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the outlet orders.
     */
    function getOrder() {
        orderApi.fetchOne(orderID).then((r) => {
            if (r.data.code === "SUCCESS") {
                setData({
                    ...data,
                    order: r.data.returnobject.order,
                    outlet: r.data.returnobject.outlet,
                    equipment: r.data.returnobject.equipment,
                    rating: r.data.returnobject.rating,
                    allowPayment: r.data.returnobject.allowPayment
                });
            }
        }).catch((e) => {
            // console.log(e);
        });
    }

    /**
     * The rest endpoint to get the outlet orders.
     */
    function payOrder() {
        let orderDetails = {
            // organizationID: data.organization.id,
            amount: data.order?.totalAmmount,
            isCustomer: true,
            order: { id: data.order?.id },
            paymentType: "O"
        }
        vivaPaymentApi.getPaymentUrl(orderDetails).then((r) => {
            if (r.data.code === "SUCCESS") {
                window.location.href = `${r.data.returnobject.redirectURL}&outletURL=${data.outlet?.url}`;
            }
        }).catch((e) => {
            // console.log(e);
        });
    }

    //change document title
    useDocumentTitle(t("order.singleLabel"));

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="test2" sx={mainTag()}>
            <CustomTopHeader key="th" />

            <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                    <CustomIconButton
                        icon={<ArrowBackIcon />}
                        sx={orderBackBtnStyle()}
                        onClick={() => navigate(-1)}
                        label={t('order.backButton')}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} md={12}>
                            <InputLabel sx={orderTitleStyle()}>{t('order.singleLabel')}</InputLabel>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            {data.order !== null &&
                                <CustomSinglePastOrderCard
                                    order={data.order}
                                    outlet={data.outlet}
                                    equipment={data.equipment}
                                    handlePastOrderAddFeedbackClick={() => navigate(`/${getLanguageFromURL()}/order/${data.order.id}/feedback/add`)}
                                    rating={data.rating}
                                />
                            }

                            {data.order === null &&
                                <div style={{ textAlign: "center" }}>
                                    <Lottie
                                        animationData={NoOrders}
                                        {...lottieOrderOptions}
                                    />

                                    <InputLabel sx={orderEmptyInfoTextStyle()}>{t('order.notFound')}</InputLabel>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                {!data.order?.paid && data.allowPayment &&
                    <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                        <Button
                            variant="extended"
                            onClick={() => payOrder()}
                            sx={{
                                width: "300px",
                                borderRadius: "10px",
                                position: 'fixed',
                                bottom: 16,
                                height: "40px",
                                left: "10%",
                                background: "lightgrey",
                                color: "darkgrey",
                                ":hover": { background: `grey` }
                            }}>
                            <Stack direction="row" spacing={2} sx={cartButtonStackStyle()}>
                                <ItemPaper sx={cartButtonLabelItemStyle()}>
                                    {t('checkout.buttons.payOrder')}
                                </ItemPaper>

                                <ItemPaper sx={cartButtonTotalAmmountItemStyle()}>
                                    <CustomTypography
                                        variant="body2"
                                        text={`${data.order?.totalAmmount} ${findCurrency(data.outlet?.currency)}`}
                                        sx={cartButtonTotalAmmountTextStyle()}
                                    />
                                </ItemPaper>
                            </Stack>
                        </Button>
                    </Grid>
                }
            </Grid>
        </CustomGrid>
    );
}

export default OrderComponent;