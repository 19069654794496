import { Divider, Grid } from '@mui/material';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import CustomMenuOffer from './CustomMenuOffer';

/**
 * The CustomMenuOfferCategory, that display each category
 *
 * @param {object} category the menu category details
 * @param {function} handleItemDialogState Callback that handles the item details dialog to open
 * 
 * @returns {JSX.Element} A React component that renders a menu category.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuOfferCategory({
    offers,
    handleOfferDialogState,
    isFirst = false,
    organizationID,
    menuConfiguration,
    selectedLayout,
    menuLang = "en"
}) {
    const { t } = useTranslation();

    return (
        <>
            <CustomTypography variant="h5" text={t(`offers.label.${menuLang}`)} sx={{ marginBottom: "10px", marginTop: isFirst ? 0 : "50px" }} />
            {/* , fontSize: menuConfiguration?.itemHeaderFontSize */}

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", marginBottom: "12px" }} />

            <Grid container gap={1.5}>
                {offers?.map((offer) =>
                    <CustomMenuOffer
                        offer={offer}
                        handleOfferDialogState={(isOpen, offer) => handleOfferDialogState(isOpen, offer, null, "offers")}
                        organizationID={organizationID}
                        menuConfiguration={menuConfiguration}
                        selectedLayout={selectedLayout}
                    />
                )}
            </Grid>
        </>
    );
}

export default CustomMenuOfferCategory;