
import { Circle as CircleIcon, CopyAll, QrCode, Wifi } from '@mui/icons-material';
import { Card, CardActionArea, Divider, Grid, Popover, SvgIcon } from '@mui/material';
import { Stack } from '@mui/system';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomChip from 'components/Chip/CustomChip';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultWifiConfigurationObject } from 'constants/defaultObjects';
import { useSnackbar } from 'hooks/useSnackbar';
import { t } from 'i18next';
import { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { activeWifiIcon, cardItemContent, cardItemContentActionArea, cardItemContentStackButtonIcon, textWifiHeaderTypography, textWifiItemTypography } from 'themes/defaultThemes';

/**
 * The Custom Wifi configuration preview Card, that display a customized {@link Card}.
 * 
 * @property {string} name the Checkbox name value
 * @property {string} selected If `true`, the component is checked.
 * @property {string} label the Checkbox text value
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {function} onChange Callback that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomWifiConfigurationPreviewCard({
   wifiConfiguration = getDefaultWifiConfigurationObject(),
   index = 0,
   sx = {} }) {
   const { openSnackbar } = useSnackbar();

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   function handleClick(event) {
      setAnchorEl(event.currentTarget);
   }

   function handleClose() {
      setAnchorEl(null);
   }

   const wifiString = `WIFI:T:${wifiConfiguration.encryption};S:${wifiConfiguration.ssid};P:${wifiConfiguration.password};;`;

   return (
      <Grid>
         <Stack direction="row" sx={cardItemContentStackButtonIcon()} key={`stack-card-${index}`}>
            <Card variant="outlined"
               key={`card-${index}-stack`}
               sx={{
                  ...cardItemContent(),
                  minWidth: "320px",
                  minHeight: "220px",
                  width: "auto",
                  ...sx
               }}
            >
               <CardActionArea sx={cardItemContentActionArea()} key={`card- back-ontent-${index}`}>
                  {/* PRINTER FRIENDLY NAME */}
                  <Grid container>
                     <Grid item xs={10.5}>
                        <CustomTypography
                           level="h6"
                           sx={{ ...textWifiHeaderTypography(), height: "20px" }}
                           text={wifiConfiguration?.locationName?.en}
                        />
                     </Grid>
                     <Grid item xs={1.5}>
                        <CustomIconButton
                           icon={<QrCode />}
                           sx={{ borderRadius: "10px", minWidth: "10px!important", width: "10px", float: "right", marginRight: "10px" }}
                           variant="outlined"
                           onClick={handleClick}
                        />
                        <Popover
                           open={open}
                           anchorEl={anchorEl}
                           onClose={handleClose}
                           anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                           }}
                        >
                           <QRCode value={wifiString} size={200} />
                        </Popover>
                     </Grid>
                  </Grid>

                  <Divider sx={{ paddingTop: "10px" }} />

                  {/* REST OF WIFI DETAILS */}
                  <Grid container direction="column" sx={{ height: "100%", paddingTop: "10px" }}>
                     <Grid direction="row" sx={{ height: "100%", display: "flex", justifyContent: "left", paddingLeft: 0, marginLeft: "10px" }} >
                        <CustomIconButton
                           icon={<Wifi
                              style={{
                                 fontSize: "60px",
                                 padding: "5px"
                              }} />}
                           sx={{
                              borderRadius: "50px",
                              height: "fit-content",
                              placeSelf: "center",
                              boxShadow: "0px 0px 15px 0px #dcdcdc",
                              marginTop: "-40px"
                           }}
                           iconSx={{
                              backgroundColor: "transparent",
                              padding: "0px",
                              margin: "0px"
                           }}
                        />
                        <SvgIcon component={CircleIcon} sx={{ ...activeWifiIcon(), color: wifiConfiguration?.enabled ? "green" : "red" }} />
                     </Grid>

                     <Grid direction="row">
                        <Grid item xs={12} md={12} spacing={1} >
                           <Grid direction="row" sx={{ textAlign: "left" }}>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomTypography
                                    level="h6"
                                    sx={{ ...textWifiItemTypography(), textDecoration: "underline" }}
                                    text={`${t('wifi.ssid')}:`}
                                 />
                              </Grid>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomChip label={wifiConfiguration?.ssid} rowIndex={0} />
                                 {/* <CustomIconButton
                                    icon={<CopyAll />}
                                    sx={{ borderRadius: "10px", minWidth: "10px!important", width: "10px", float: "right", marginRight: "10px" }}
                                    onClick={() => navigator.clipboard.writeText(wifiConfiguration.ssid)}
                                    variant="outlined"
                                 /> */}
                              </Grid>
                           </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} spacing={1}>
                           <Grid direction="row" sx={{ textAlign: "left" }}>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomTypography
                                    level="h6"
                                    sx={{ ...textWifiItemTypography(), textDecoration: "underline" }}
                                    text={`${t('wifi.password')}:`}
                                 />
                              </Grid>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomChip label={wifiConfiguration?.password} rowIndex={0} />
                                 <CustomIconButton
                                    icon={<CopyAll />}
                                    sx={{ borderRadius: "10px", minWidth: "10px!important", width: "10px", float: "right", marginRight: "10px" }}
                                    onClick={() => {
                                       navigator.clipboard.writeText(wifiConfiguration.password);
                                       openSnackbar(t('wifi.copyNotification'), "#177910");
                                    }}
                                    variant="outlined"
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={12} spacing={1}>
                           <Grid container sx={{ textAlign: "left" }}>
                              <Grid item xs={6.5}>
                                 <CustomTypography
                                    level="h6"
                                    sx={{ ...textWifiItemTypography(), textDecoration: "underline" }}
                                    text={`${t('wifi.encryption')}:`}
                                 />
                              </Grid>
                              <Grid item xs={5}>
                                 <CustomChip label={wifiConfiguration?.encryption} rowIndex={0} />
                              </Grid>
                           </Grid>
                        </Grid> */}
                     </Grid>
                  </Grid>
               </CardActionArea>
            </Card>
         </Stack>
      </Grid>
   );
}

export default CustomWifiConfigurationPreviewCard;