import { Grid } from "@mui/material";
import React from "react";
import GoogleLogin from "components/Login/GoogleLoginButton";
import FacebookLogin from "components/Login/FacebookLoginButton";
import AppleLogin from "components/Login/AppleLoginButton";

import { GoogleOAuthProvider } from "@react-oauth/google";

export default function index() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ minHeight: "30vh" }}
      >
        <Grid item sx={{ width: "30%" }}>
          <GoogleLogin />
        </Grid>
        <Grid item sx={{ width: "30%", mt: "20px" }}>
          <FacebookLogin />
        </Grid>
        <Grid item sx={{ width: "30%", mt: "20px" }}>
          <AppleLogin />
        </Grid>
      </Grid>
    </GoogleOAuthProvider>
  );
}
