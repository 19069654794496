const EN = {
  register: "Register",
  registerSuccess: "You have been registered successfully.",
  signIn: "Sign in",
  signUpHere: "Sign up here!",
  forgotPassword: "Forgot Password?",
  noAccount: "Don't have an account?",
  haveAccount: "Already have an account?",
  backToSignIn: "Back to Sign In",
  username: "Username",
  usernameRequired: "Username is required",
  usernameRule: "Username must be at least 4 characters long and contain only letters, numbers, underscore is optional",
  password: "Password",
  aboutLabel: "About",
  aboutURLPath: "https://usee.gr",
  signInURLPath: "/en/sign-in",
  forgotPasswordURLPath: "/en/forgot-password",
  errorPages: {
    pageNotFound: 'The page you were looking for was not found!!',
    accessIsForbidden: 'You are not allowed to access this page!!',
    authorizationFailed: 'You are not authorized to access this page!!',
    somethingWentWrong: 'Oops! Something’s wrong here. Please contact the system administrator.',
  },
  actions: {
    add: "Add",
    create: "Create",
    clickHere: "here",
    delete: "Delete",
    edit: "Edit",
    new: "New",
    newItem: "New",
    overview: "Overview",
    save: "Save",
    view: "View",
    selectOption: "Select an option",
    search: "Search",
    searchFor: "Search for",
    upload: "Upload",
    download: "Download",
    cancel: "Cancel",
    send: "Send",
    update: "Update",
    back: "Back"
  },
  button: {
    sendCode: "Send code",
    update: "Update"
  },
  auth: {
    alert: {
      please: "Please"
    },
    register: "Register",
    login: "Login",
    loginSuccessfully: "You have successfully login.",
    registerSuccessfully: "You have successfully register.",
    logoutSuccessfully: "You have successfully logout.",
    registerEmail: "Register with email",
    loginEmail: "Login with email",
    logout: "Logout",
    phone: "Phone Number",
    enterPhone: "Enter your Phone Number",
    enterPhoneVerfCode: "You'll receive a 4 digit verification code at",
    createAccount: "Create Account",
    vverifyCode: "Verify your phone number",
    codeIsSentTo: "Code is sent to",
    didNotReceive: "Didn't receive code?",
    resendNewCode: "Resend new code",
    consentSignUpOTPStart: "By providing my phone numbe, I hereby agree and accept the",
    consentSignUpOTPAnd: "and",
    consentSignUpOTPAfter: "in use of the Usee app.",
    loginRegisterInfo: "Information about your connection or registration.",
    privacyPolicyLabel: "Privacy Policy",
    termsOfUseLabel: "Terms of Use",
    privacyPolicyURLPath: "/en/privacy-policy",
    termsOfUseURLPath: "/en/terms-of-use",
    info: "Information about your connection or registration.",
    infoDetails: "To the email you use during registration, we will send you the confirmation of your orders, updates on the exclusive offers we secure for you, as well as the new stores that enter efood and serve you. Of course, you can unsubscribe from our list at any time. For more information you can see our privacy policy.",
    checkoutLoginInfo: "To continue with your order, you have to login first."
  },
  isError: {
    label: "The value is required.",
    mobileNumber: "The value is required.",
    code: "The value is required.",
    email: "Email is required.",
    password: "The password is required.",
    firstName: "The first name is required.",
    lastName: "The last name is required.",
    username: "The username is required.",
    phone: "The phone is required.",
    newPasswordLength: "Password should have at least length 8.",
    passwordsUnMatch: "New password and verify password do not match.",
    accountAlreadyExists: "Email address already being used for another account. Try login instead.",
    "Email already being used for another account": "Email address already being used for another account. Try login instead.",
    "Phone already being used for another account": "Telephone number already associated with another account. Try login instead."
  },
  customer: {
    lang: "lang",
    username: "Username",
    password: "Password",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Mobile",
    currentPassword: "Current Password",
    newPassword: "New Password",
    verifyPassword: "Retype New Password",
    createPassword: "Create Password",
    changePassword: "Change your Password",
    retypePassword: "Retype Password",
    profile: {
      label: "Profile",
      "Profile has been updated successfully": "Profile has been updated successfully",
    },
    language: {
      label: "Preferred Language"
    },
    deleteAccount: {
      label: "Delete account",
      desc: "Permanetly delete your data from Usee and all stores.",
      info: "Please note tat deleting your account will have the following consequences:",
      info1: "You will no longer have access to your account",
      info2: "We will delete all data related to your account. We will keep data that we are legally required to store.",
      info3: "You will not be able to undo the deletion.",
      actionInfo: "Are you sure you want to proceed?",
      confirmByTypingCodeBefore: "Confirm by typing",
      confirmByTypingCodeAfter: "below.",
      btn: "Delete FOREVER",
      codesNotMatching: "The inserted code not match the given. Try again.",
      successTitle: "Account Deleted",
      success: "We have now permenently deleted your user account. We are sorry to see you leave. You are always welcome to join us again!"
    }
  },
  menu: {
    sidenav: {
      categories: "Categories"
    },
    appBar: {
      profile: "Profile",
      myOrders: "My Orders",
      myRatings: "My Ratings",
      loyalty: "Loyalty",
      myCoupons: "My Coupons",
      messages: "Messages",
      notifications: "Notifications",
      settings: "Settings",
      logout: "Logout"
    },
    noMenus: "No menus found at the moment.",
    back: {
      categories: "Parent Categories",
      parentCategories: "Menus"
    }
  },
  menus: {
    label: "Menu",
    info: {
      label: "Info",
      payment: {
        paymentMethods: "Payment methods",
        card: "Card",
        paypal: "Paypal",
        cash: "Cash"
      },
      aboutStore: "About the outlet",
      address: "Outlet address",
      workingHours: "orking Hours"
    },
    reviews: {
      label: "Reviews",
      reviewComment: "Based on the last 365 days",
      userReviews: "User reviews",
      beforeUserReviewsDescription: "See in detail the",
      afterUserReviewsDescription: "reviews for the outlet."
    },
    dialog: {
      comments: "Comments",
      buttons: {
        addToCart: "Add to Cart",
        continue: "Continue",
        close: "Close"
      }
    }
  },
  cart: {
    label: "Cart",
    emptyLabel: "Empty Cart",
    emptyDescription: "Fill in your cart with items.",
    options: {
      delivery: "Devilery",
      dineIn: "Dine in",
      pickUp: "Pick up"
    },
    discount: "Discount",
    totalAmmount: "Total amount",
    analyzeOrder: "Analyze order",
    allergies: {
      label: "Allergies",
      celery: "Celery",
      eggs: "Eggs",
      fish: "Fish",
      gluten: "Gluten",
      lupin: "Lupin",
      milk: "Milk",
      molluscs: "Molluscs",
      mustard: "Mustard",
      nuts: "Nuts",
      soya: "Soya",
      sesame: "Sesame",
      wheat: "Wheat",
      pleaseSelectAllThatApply: "Please select all that apply"
    },
    equipmentLabel: "Table:",
    order: {
      label: "Order",
      confirmed: "Confirmed",
    }
  },
  outlet: {
    reviews: "Feedbacks",
    outletInformation: "Outlet Information",
    backButton: "Back",
    workingHours: "Working Hours",
    address: "Address",
    socialProfiles: "Social",
    vatNumber: "VAT Number",
    telephones: "Telephones",
    photos: "Photos",
    // page
    type: {
      PAYMENT: "Pay",
      CUSTOMER_REQUEST: "Call Waiter",
      MENU: "Order",
      OTHER: "Other",
      WIFI: "Wifi",
      RATING: "Rating"
    },
  },
  wifi: {
    ssid: "Name",
    password: "Password",
    locationName: "Location Name",
    encryption: "Encryption",
    empty: "There are no available Wifi details at the moment.",
    copyNotification: "Password copied to clipboard."
  },
  request: {
    title: "Requests",
    pageTitle: "Call Waiter",
    request: "Is there a particular request you have in mind?",//"What are your requirements at the moment?"
    btns: {
      send: "Send",
      ok: "Ok"
    },
    info: "Fill in with a short text...",
    requestCharLimit: "char",
    requestCharsLimit: "chars",
    requestPlaceholder: "Type in here…",
    allowedRequestTypes: {
      PAYMENT: "Pay",
      CLEAN_TABLE: "Clean Table",
      MENU: "Order",
      OTHER: "Other",
    },
    popup: {
      success: "Success",
      fail: "Fail",
      info: "Info",
      alreadySendRequestMessage: "You have already send a request.",
      successMessage: "The request has been submitted successfully.",
      failMessage: "The request has not been submitted."
    }
  },
  languages: {
    EN: "English",
    DE: "German",
    FR: "French",
    IT: "Italian",
    RU: "Russian",
    EL: "Greek",
    UK: "Ukrainian",
    ES: "Spanish",
    NL: "Dutch",
    CS: "Czech",
    PL: "Polish",
    BG: "Bulgarian",
    AR: "Arabic",
    ET: "Estonian",
    LT: "Lithuanian",
    RO: "Romanian",
    TR: "Turkish",
    en: "English",
    de: "German",
    fr: "French",
    it: "Italian",
    ru: "Russian",
    el: "Greek",
    uk: "Ukrainian",
    es: "Spanish",
    nl: "Dutch",
    cs: "Czech",
    pl: "Polish",
    bg: "Bulgarian",
    ar: "Arabic",
    et: "Estonian",
    lt: "Lithuanian",
    ro: "Romanian",
    tr: "Turkish"
  },
  loyalty: {
    label: "Loyalty Club",
    empty: "You have no loyalty cards yet",
    emptyOrganization: "There is no loyalty card for this organization",
    points: "points",
    organizationName: "Organization Name"
  },
  coupon: {
    label: "Coupons",
    empty: "You have no coupons yet",
    emptyOrganization: "There is no coupons for this organization",
    organizationName: "Organization Name",
    expiresOn: "Expires on:",
    code: "Code:"
  },
  checkout: {
    label: "Checkout",
    backButton: "Back",
    paymentTotal: "Payment total",
    paymentMethod: "Payment method: ",
    cart: "Cart",
    persons: "Persons",
    pvcRecyclingTax: "Environmental fee included",
    discount: "Discount:",
    loyalty: "Loyalty",
    coupon: "Coupon:",
    comments: "Comments",
    info: "Fill in with a short text...",
    requestCharLimit: "char",
    requestCharsLimit: "chars",
    requestPlaceholder: "Type in here…",
    buttons: {
      sendOrder: "Send order",
      payOrder: "Pay order",
      sendOrderDisabledAlert: "Orders disabled. An employee will assist you shortly.",
    },
    discount: {
      couponNotExists: "Do you have any coupon code? Click here...",
      couponExists: "There are coupons available.",
      textWithAvailableCoupons: "Type the coupon code or select a coupon and win a discount to your order.",
      textWithNoAvailableCoupons: "Type the coupon code and win a discount to your order. Thank you for your understanding and patience.",
      invalid: "There are no coupons with this code."
    },
    payment: {
      paymentMethods: "Payment methods",
      card: "Card",
      paypal: "Paypal",
      cash: "Cash",
    },
    equipmentInvalid: "The inserted equipment label not found or is not active. Please try again!",
    tax: "VAT value: ",
    loyaltyPointsToEarn: "If you proceed, you will gain",
    points: "points",
    availableTooltip: "Available quantity"
  },
  order: {
    label: "Past Orders",
    singleLabel: "Your order",
    empty: "You have no orders yet",
    notFound: "The order is not found",
    addFeedback: "Add Rating",
    moreOrders: "More Orders",
    backButton: "Back to orders",
    outletName: "Outlet:",
    equipmentLabel: "Location:",
    orderTotal: "Order total:",
    paymentTotal: "Payment total",
    paymentMethod: "Payment method: ",
    cart: "Cart",
    rating: "Your opinion counts",
    ratingDescription: "Rate your order",
    allergies: "Your Allergies",
    notes: "Note:",
    pvcRecyclingTax: "Environmental fee included",
    discount: "Discount:",
    loyalty: "Loyalty",
    coupon: "Coupon:",
    unPaidOrders: "Un-paid orders",
    unPaidOrdersButton: "Pay order",
    rating: {
      orderLabel: "Order",
      label: "Feedback",
      message: "Feedback message",
      checkboxInfo: "Opt-in for auto-feedback to Google Reviews.",
      ratingInfo: "Thank you for choosing to provide feedback! Your valuable input helps us improve our service for you and others. We appreciate your participation in shaping our community's experience!",
      btn: "Rate Now",
      back: "Back to Order",
    },
    fail: {
      backButton: "Checkout",
      infoPayment: "Your payment failed. Please try again."
    },
    success: {
      tab: "Success",
      backButton: "Checkout",
      infoPayment: "Your order placed successfully.",
      orderNumber: "Order Number:",
      loyaltyPointsGainBefore: "You gained",
      loyaltyPointsGainAfter: "points from this order.",
      back: "Back to home"
    },
    tip: {
      label: "Tip",
      checkout: {
        label: "Choose a tip",
        info: "Our staff will be grateful."
      }
    }
  },
  item: {
    notes: "Notes",
    notesInfo: "Notes (if there are special instructions)",
    itemCharLimit: "char",
    itemCharsLimit: "chars",
    allergies: "Allergies",
    pvcRecyclingTax: "PVC Recycling Tax",
    points: "points",
    caloriesShort: "Cal",
    proteinShort: "Prot",
    carbShort: "Carb",
    min: "Min",
    max: "Max",
    nutrients: {
      label: "Nutrients",
      grams: "Grams",
      gramsAfterLabel: "g",
      gramsDetailsLabel: "Quantity per ",
      gramsPortionLabel: "portion",
      energy: "Energy",
      enrgeyAfterLabel: "MJ",
      calories: "Calories",
      caloriesAfterLabel: "kcal",
      protein: "Protein",
      proteinAfterLabel: "g",
      carb: "Carbohydrates",
      carbAfterLabel: "g",
      sugars: "Sugars",
      sugarsDetailsLabel: "(of which sugars)",
      sugarsAfterLabel: "g",
      fat: "Fat",
      fatDetailsLabel: "Total Fat",
      fatAfterLabel: "g",
      sat_fat: "Saturated fat",
      sat_fatDetailsLabel: "(of which Saturates)",
      sat_fatAfterLabel: "g",
      trans_fat: "Trans fat",
      trans_fatDetailsLabel: "(of which Trans)",
      trans_fatAfterLabel: "g",
      fiber: "Fiber",
      fiberDetailsLabel: "(of which fibers)",
      fiberAfterLabel: "g",
      sodium: "Sodium",
      sodiumAfterLabel: "g",
      vitaminA: "Vitamin A",
      vitaminAAfterLabel: "mg",
      vitaminC: "Vitamin C",
      vitaminCAfterLabel: "mg",
      calcium: "Calcium",
      calciumAfterLabel: "mg",
      iron: "Iron",
      ironAfterLabel: "mg",
      salt: "Salt",
      saltAfterLabel: "g",
    },
    requiredChoice: "Required Choice",
    optionalChoice: "Optional Choice",
    ok: "Ok"
  },
  offers: {
    label: {
      en: "Offers",
      el: "Προσφορές"
    }
  },
  settings: {
    label: "Settings",
    language: "Language: ",
    updateLanguage: "Update Language"
  },
  socialProfile: {
    label: "Social Profiles",
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    LINKEDIN: "Linkedin",
    PINTEREST: "Pinterest",
    SNAPCHAT: "Snapchat",
    TIKTOK: "TikTok",
    TWITTER: "Twitter",
    YOUTUBE: "Youtube"
  },
  workingHours: {
    label: "Working Hours",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesdey",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday"
  },
  rating: {
    outlet: {
      pageTitle: "Outlet Rating",
      label: "Rating",
      message: "Rating message",
      checkboxInfo: "Opt-in for auto-feedback to Google Reviews.",
      ratingInfo: "Thank you for choosing to provide rating! Your valuable input helps us improve our service for you and others. We appreciate your participation in shaping our community's experience!",
      btn: "Rate Now",
      back: "Back to Outlet",
    },
    label: "Ratings",
    outletName: "Outlet Name",
    emptyOutlet: "There are no ratings for this outlet",
    emptyRatings: "No ratings have been added yet.",
    moreRatings: "More Ratings",
    basedOnBefore: "Based on",
    basedOnAfter: "reviews",
  },
  qrCode: {
    scan: "Scan the QR Code",
    info: "Scan the QR code to proceed. Hold your device steady and align the QR code within the frame."
  }
}

export default EN;
