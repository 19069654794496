export const getTranslationDefaultObject = () => {
    return {
        en: "",
        de: "",
        fr: "",
        it: "",
        ru: "",
        el: "",
        uk: "",
        es: "",
        nl: "",
        cs: "",
        pl: "",
        bg: "",
        ar: "",
        et: "",
        lt: "",
        ro: "",
        tr: "",
        text: ""
    }
}

export const getDefaultRequestObject = (outletURL = "", equipmentLabel = "", type = "", draftOrder = null) => {
    return {
        outletURL: outletURL,
        equipmentLabel: equipmentLabel,
        request: "",
        draftOrder: draftOrder,
        label: getTranslationDefaultObject(),
        icon: "",
        type: type,
    };
}


// ================ CUSTOMER
export const getDefaultCustomerLoginObject = () => {
    return {
        email: "",
        password: "",
    };
}

export const getDefaultCustomerRegisterObject = () => {
    return {
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "30",
        newPassword: "",
        verifyPassword: "",
        enabled: true,
        lang: "en"
    };
}

export const getDefaultAuthIsError = {
    email: "",
    password: "",
    username: "",
    firstName: "",
    lastName: "",
    newPassword: "",
    verifyPassword: "",
    phone: ""
}

export const getDefaultAuthLoginIsError = {
    username: "",
    firstName: "",
    lastName: "",
    newPassword: "",
    verifyPassword: "",
    phone: ""
}
export const getDefaultAuthRegistrationIsError = {
    email: "",
    password: ""
}

export const getCartDivider = {
    borderRightWidth: 5,
    width: "80%",
    marginLeft: "11%",
    marginTop: "20px"
}

// ================ CUSTOMER
export const getOutletSplashConfiguration = {
    showLogo: true,
    showDescription: false,
    description: "",
    backgroundColor: "#47a6d2",
    descriptionColor: "#000000",
    durationInSeconds: 5,
    enable: false
}


// ================ WIFI
export const getDefaultWifiConfigurationObject = () => {
    return {
        locationName: getTranslationDefaultObject(),
        ssid: "",
        password: "",
        enableQRcode: true,
        enabled: true
    };
}


// ================ RATING
export const getDefaultRatingObject = (type = "REGULAR") => {
    return {
        message: "",
        starRating: 5,
        consentSentToGoogleReview: false,
        type: type
    };
}
