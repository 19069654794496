import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
/**
 * The rest request which attempts to get an existing request information.
 * 
 * @param {*} lang TThe lang code for multilingual fields
 * @param {*} outletUrl The url of the loyalty outlet to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the loyalties 
 */
export async function fetchAll(search) {
   let data = {};
   if (search.lang !== undefined) {
      data = { lang: search.lang }
   }
   if (search.outletUrl !== undefined) {
      data = { ...data, outletUrl: search.outletUrl }
   }

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer/request`, data));
   else return await request.get(includeParamsToUrl(`${API}customer/request`));
}

/**
 * The rest request which return required information to be presented on add new request page.
 * 
 * @returns an object with an attribute containing the requested information to be presented on request page
 */
export async function fetchNew(outletUrl, isCustomer) {
   let data = {
      isCustomer: isCustomer
   };
   if (outletUrl !== null && outletUrl !== "" && outletUrl !== undefined) {
      data = { ...data, outletUrl: outletUrl }
   }

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer/request/add`, data));
   else return await request.get(includeParamsToUrl(`${API}customer/request/add`));
}

/**
 * The rest request which attempts to create a new request.
 * 
 * @param {*} data A JSON object which contains new request infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data, triggered = false) {
   return await request.post(`${API}customer/request?triggered=${triggered}`, data);
}

const requestApi = {
   fetchNew,
   fetchAll,
   create
}

export default requestApi;