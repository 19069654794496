import {
   Save as SaveIcon,
   Visibility as VisibilityIcon,
   VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import { Fab, FormControl, FormHelperText, Grid, InputLabel, SvgIcon, Typography, useMediaQuery } from "@mui/material";
import { Box } from '@mui/system';
import userApi from "api/user";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomDialogChildren from 'components/Dialog/CustomDialogChildren';
import { getDialogTypeAnimation } from 'components/Dialog/animation/dialogTypeAnimation';
import CustomSwippableDrawerChildren from 'components/Drawer/CustomSwippableDrawerChildren';
import CustomFieldset from "components/Fieldset/CustomFieldset";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeader from 'components/Header/CustomTopHeader';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTextField from 'components/Text/CustomTextField';
import CustomTextFieldWithIcons from 'components/Text/CustomTextFieldWithIcons';
import { PASSWORD_REQUIRED_LENGTH } from 'constants/fieldProperties';
import { getLangOptions } from 'constants/langOptions';
import { getMultiLangOptions } from 'constants/multilangOptions';
import i18n from 'i18n';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import { mainTag, mainTagPage, requestFabButtonStyle, selectError, textFieldError } from 'themes/defaultThemes';
import { formIsValid, formValChangeWithParentElement, onSelectChange } from "utils/form-validation";
import { equalsIgnoreCase } from "utils/functions";
import { strengthColor, strengthIndicatorNumFunc } from 'utils/password-strength';
import useDocumentTitle from "utils/useDocumentTitle";
import { isValidEmail, validatePhoneNumber } from 'utils/validator-functions';

/**
 * The ProfileComponent that triggers the modification of an existing customer.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ProfileComponent() {
   const { t } = useTranslation();
   const isMobile = useMediaQuery('(max-width:468px)');

   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);

   // for password strength
   const [strength, setStrength] = React.useState(0);
   const [level, setLevel] = React.useState();

   /**
    * @type {object}
    * @property {object} userAuth the authenticated customer infromation
    * @property {object} customer an empty object if add or the selected customer to be editted
    * @property {*} icon the legend fa icon
    * @property {object} isError list of indication of empty form required field 
    *                           after submit to present error message to the
    *                           fields/attributes with true value
    */
   const [data, setData] = useState({
      error: null,
      userAuth: null,
      // customerID: customerID,
      customer: null,
      outletIds: [],
      countries: [],
      languages: getMultiLangOptions(),
      passwordShown: false,
      shownPasswordIcon: VisibilityOffIcon,
      // error messages per field
      isError: {
         username: "",
         firstName: "",
         lastName: "",
         email: "",
         phone: "",
         roles: "",
         newPassword: "",
         verifyPassword: ""
      }
   });

   // pop dialog for delete
   const [dialog, setDialog] = useState({
      isOpen: false,
      type: "success",
      message: ""
   });


   //change document title
   useDocumentTitle(t('customer.profile.label'));

   useEffect(() => {
      getcustomer();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   /**
    * Password toggle handler
    */
   function togglePassword() {
      // When the handler is invoked
      // inverse the boolean state of passwordShown and icon to be shown
      setData({
         ...data,
         shownPasswordIcon: !data.passwordShown ? VisibilityIcon : VisibilityOffIcon,
         passwordShown: !data.passwordShown
      });
   };

   /**
    * The rest endpoint to get the customer default (add) or current (edit) information.
    */
   function getcustomer() {
      userApi.profile().then((r) => {
         setData({
            ...data,
            countries: r.data.returnobject.countryList,
            userAuth: r.data.returnobject.userAuth,
            customer: {
               ...r.data.returnobject.profile,
               newPassword: "",
               verifyPassword: "",
               currentPassword: ""
            }
         });
         setIsLoaded(true);
      }).catch((e) => {
         // console.log(e);
      })
   }



   /**
    * Gets called when the customer clicks on the save button, and triggers 
    * the edit of the selected customer.
    */
   function editcustomer() {
      if (formValidation()) {
         let customer = data.customer;
         customer.password = undefined;
         if (customer.currentPassword === "")
            customer.currentPassword = undefined;
         if (customer.newPassword === "")
            customer.newPassword = undefined;
         if (customer.verifyPassword === "")
            customer.verifyPassword = undefined;
         userApi.update(customer).then((r) => {
            handleDialogState(true, (r.data.code === "SUCCESS") ? "success" : "info", t(`customer.${r.data.message}`));
            if (r.data.code === "SUCCESS") {
               i18n.changeLanguage(data.customer.lang);
            }
         }).catch((e) => {
            handleDialogState(true, "fail", e.message)
         });
      }
   }

   /**
    * Function that triggers form validation and print out if the form is valid or not.
    * @returns true if form is Valid
    */
   function formValidation() {
      let isError = createErrorMessages();
      if (formIsValid(isError)) {
         return true;
      } else {
         return false;
      }
   }

   /**
    * Function that create error messages for each required field that are not filled out.
    * @returns object containing the error messages for each form field
    */
   function createErrorMessages() {
      let isError = { ...data.isError };

      if (data.customer.email.length < 1 || !isValidEmail(data.customer.email)) isError.email = t('isError.email');
      // if (data.customer.phone.length < 10 || !validatePhoneNumber(data.customer.phone)) isError.phone = t('isError.phone');
      if (data.customer.firstName.length < 1) isError.email = t('isError.firstName');
      if (data.customer.lastName.length < 1) isError.email = t('isError.lastName');
      if (data.customer.username.length < 1) isError.username = t('isError.username');
      if ((data.customer.verifyPassword !== undefined && data.customer.newPassword !== undefined) && (data.customer.verifyPassword.length > 1 || data.customer.newPassword.length > 1) && data.customer.verifyPassword !== data.customer.newPassword) isError.password = t('isError.passwordsUnMatch');

      setData({
         ...data,
         isError
      });
      return isError;
   }

   function changePassword(value) {
      const temp = strengthIndicatorNumFunc(value);
      setStrength(temp);
      setLevel(strengthColor(temp));
   };

   /**
    * Function that handles the delete modal open or close data.
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} type The type of the dialog 'success', 'fail' or 'info'.
    */
   function handleDialogState(isOpen = false, type = "success", message = "") {
      setDialog({
         ...dialog,
         isOpen: isOpen,
         type: type,
         message: message
      });
   }
   // ===============================================================================RENDER

   if (!isLoaded) {
      return (
         <LoadingSkeleton lines={9} />
      );
   } else {
      const { isError } = data;

      return (
         <CustomGrid role="main" id={"customer-profile"} sx={mainTagPage()}>
            <CustomTopHeader key="th" />

            <CustomGrid container={false}>
               <form id="customer" onSubmit={(e) => { return false; }}>
                  {data.isAdd &&
                     <input type="hidden" />
                  }

                  <Grid container spacing={2}>
                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="firstName">{t('customer.firstName')}</InputLabel>
                        <CustomTextField
                           type="text"
                           name="customer.firstName"
                           id="firstName"
                           required={true}
                           error={isError.firstName.length > 0 ? true : false}
                           defaultValue={data.customer.firstName}
                           onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                           helperText={isError.firstName}
                           sx={textFieldError()}
                        />
                     </Grid>

                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="lastName">{t('customer.lastName')}</InputLabel>
                        <CustomTextField
                           type="text"
                           name="customer.lastName"
                           id="lastName"
                           required={true}
                           defaultValue={data.customer.lastName}
                           error={isError.lastName.length > 0 ? true : false}
                           onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                           helperText={isError.lastName}
                           sx={textFieldError()}
                        />
                     </Grid>

                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="email">{t('customer.email')}</InputLabel>
                        <CustomTextField
                           type="email"
                           name="customer.email"
                           id="email"
                           placeholder="name@provider.gr"
                           required={true}
                           defaultValue={data.customer.email}
                           error={isError.email.length > 0 ? true : false}
                           helperText={isError.email}
                           sx={textFieldError()}
                           onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                        />
                     </Grid>

                     <Grid item xs={12} md={6}>
                        <InputLabel required={false} htmlFor="phone">{t('customer.phone')}</InputLabel>
                        <PhoneInput
                           value={data.customer.phone}
                           name="customer.phone"
                           id="phone"
                           placeholder="Add your phone number"
                           required={true}
                           className={isError.phone.length > 0 ? "is-invalid" : ""}
                           // onChange={onPhoneChange}
                           country="gr"
                           inputStyle={{ paddingLeft: "70px", width: "100%" }}
                        />
                        {isError.phone.length > 0 &&
                           <FormHelperText sx={selectError()}>
                              {isError.phone}
                           </FormHelperText>
                        }
                     </Grid>

                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="customer.lang">{t('customer.lang')}</InputLabel>
                        <CustomSelectMultilingual
                           id="lang"
                           translationPath="languages"
                           name="customer.lang"
                           required={true}
                           isMultiple={false}
                           defaultValue={data.customer.lang}
                           options={data.languages}
                           onChange={(event) => onSelectChange(event, data, setData)}
                        />
                     </Grid>

                     {/* <Grid item xs={12} md={6}>
                        <InputLabel required={false} htmlFor="username">{t('customer.username')}</InputLabel>
                        <CustomTextField
                           type="text"
                           name="customer.username"
                           id="username"
                           placeholder="Add at least 4 characters"
                           required={true}
                           helperText={isError.username}
                           sx={textFieldError()}
                           onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                        />
                     </Grid> */}

                     <Grid item xs={12} sm={4}>
                        <InputLabel required={true} htmlFor="currentPassword">{t('customer.currentPassword')}</InputLabel>
                        <CustomTextFieldWithIcons
                           type={data.passwordShown ? "text" : "password"}
                           name="user.currentPassword"
                           id="currentPassword"
                           placeholder="Type your current password"
                           autoComplete="off"
                           endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                           onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                        />
                     </Grid>

                     <Grid item xs={12} sm={4}>
                        <InputLabel required={true} htmlFor="newPassword">{t('customer.newPassword')}</InputLabel>
                        <CustomTextFieldWithIcons
                           type={data.passwordShown ? "text" : "password"}
                           name="user.newPassword"
                           id="newPassword"
                           placeholder="Type your new password"
                           autoComplete="new-password"
                           error={isError.newPassword.length > 0 ? true : false}
                           onChange={(event) => { changePassword(event.target.value); formValChangeWithParentElement(event, data, setData, t('table.valueReuired')) }}
                           helperText={isError.newPassword}
                           endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                           sx={textFieldError()}
                        />

                        {/* {strength !== 0 && ( */}
                        <FormControl fullWidth>
                           <Box sx={{ mb: 2 }}>
                              <Grid container spacing={2} alignItems="center">
                                 <Grid item>
                                    <Box
                                       style={{ backgroundColor: level?.color }}
                                       sx={{
                                          width: 85,
                                          height: 8,
                                          borderRadius: '7px'
                                       }}
                                    />
                                 </Grid>
                                 <Grid item>
                                    <Typography variant="subtitle1" fontSize="0.75rem">
                                       {level?.label}
                                    </Typography>
                                 </Grid>
                              </Grid>
                           </Box>
                        </FormControl>
                     </Grid>

                     <Grid item xs={12} sm={4}>
                        <InputLabel required={true} htmlFor="verifyPassword">{t('customer.verifyPassword')}</InputLabel>
                        <CustomTextFieldWithIcons
                           type={data.passwordShown ? "text" : "password"}
                           name="user.verifyPassword"
                           id="verifyPassword"
                           placeholder="Retype your new password"
                           autoComplete="new-password"
                           data-parsley-equalto="#newPassword"
                           error={isError.verifyPassword.length > 0 ? true : false}
                           onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                           helperText={isError.verifyPassword}
                           endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                           sx={textFieldError()}
                        />
                     </Grid>
                  </Grid>
               </form>

               <Fab color="primary"
                  aria-label="add"
                  sx={requestFabButtonStyle()}
                  onClick={editcustomer}
               >
                  <SaveIcon />
               </Fab>
            </CustomGrid>

            {(dialog.isOpen && !isMobile) &&
               <CustomDialogChildren
                  isOpen={dialog.isOpen}
                  id="dialog"
                  title={t(`request.popup.${dialog.type}`)}
                  cancelLabel={t("request.btns.ok")}
                  message={dialog.message}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
                  children={getDialogTypeAnimation(dialog.type)}
               />
            }
            {(dialog.isOpen && isMobile) &&
               <CustomSwippableDrawerChildren
                  open={dialog.isOpen}
                  id="dialog"
                  title={t(`request.popup.${dialog.type}`)}
                  cancelLabel={t("request.btns.ok")}
                  message={dialog.message}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
                  onClose={() => handleDialogState(false, "", "")}
                  children={getDialogTypeAnimation(dialog.type)}
               />
            }
         </CustomGrid>
      );
   }
}

export default ProfileComponent;