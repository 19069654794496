import { Grid, Stack, Typography } from "@mui/material";
import outletApi from 'api/outlet';
import CustomWifiConfigurationPreviewCard from "components/Card/wifi/CustomWifiConfigurationPreviewCard";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeaderChildren from "components/Header/CustomTopHeaderChildren";
import OutletWifiLoadingSkeleton from "components/Loading/OutletWifiLoadingSkeleton";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mainTag } from 'themes/defaultThemes';
import { getLanguageFromURL } from 'utils/language';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The WifiComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function WifiComponent() {
    const { outletURL } = useParams();

    //change document title
    useDocumentTitle(outletURL);

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        language: getLanguageFromURL(),
        outlet: null,
        wifiConfigurations: null,
    });

    const [isLoaded, setIsLoaded] = useState(false);
    const [isOpenLogin, setIsOpenLogin] = useState(false);


    useEffect(() => {
        getOutletWifiConfiguration();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getOutletWifiConfiguration() {
        let userAuthenticated = localStorage.getItem("token");
        outletApi.fetchWifiConfiguration(outletURL, userAuthenticated !== null).then((r) => {
            if ((r.data.code === "SUCCESS" || r.data?.code === "PERMISSION")) {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    outlet: r.data.returnobject.outlet,
                    wifiConfigurations: r.data.returnobject.wifiConfigurations,
                });
            }
            setIsLoaded(true);
        }).catch((e) => {
            setIsLoaded(true);
            // console.log(e);
        });
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <CustomGrid role="main" id="outlet" sx={mainTag()}>
            <CustomTopHeaderChildren
                key="th"
                isOpen={isOpenLogin}
                handleLoginCheckout={() => getOutletWifiConfiguration()}
                setIsOpen={setIsOpenLogin}
                pageRequireLoginRegistation={isOpenLogin}
            />
            <OutletWifiLoadingSkeleton lines={9} />
        </CustomGrid>
    } else
        return (
            <CustomGrid role="main" id="outlet" sx={mainTag()}>
                <CustomTopHeaderChildren
                    key="th"
                    isOpen={isOpenLogin}
                    handleLoginCheckout={() => getOutletWifiConfiguration()}
                    setIsOpen={setIsOpenLogin}
                    pageRequireLoginRegistation={isOpenLogin}
                />
                <Grid item xs={12} sx={{ textAlign: "-webkit-center" }}>
                    {data.wifiConfigurations?.length > 0 &&
                        <Stack Stack direction="row" sx={{ width: "100%", alignItems: "center" }}>
                            <Grid container gap={1} sx={{ width: "100%", justifyContent: "center" }}>
                                {data.wifiConfigurations?.map((wifiConfiguration, index) =>
                                    <CustomWifiConfigurationPreviewCard wifiConfiguration={wifiConfiguration} index={index} />
                                )}
                            </Grid>
                        </Stack>
                    }

                    {(data.wifiConfigurations?.length === 0 || data.wifiConfigurations === null) &&
                        <Typography level="span">{'wifi.empty'}</Typography>
                    }
                </Grid>
            </CustomGrid>
        );
}

export default WifiComponent;