import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to get an existing loyalty information.
 * 
 * @param {*} lang TThe lang code for multilingual fields
 * @param {*} outletUrl The url of the loyalty outlet to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the loyalties 
 */
export async function fetchAll(search) {
    let data = {};

    if (search.lang !== undefined) {
        data = { lang: search.lang }
    }

    if (search.outletUrl && search.outletUrl !== null && search.outletUrl !== "") {
        data = { ...data, outletUrl: search.outletUrl }
    }

    if (search.organizationName && search.organizationName !== null && search.organizationName !== "") {
        data = { ...data, organizationName: search.organizationName }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer/loyalty`, data));
    else return await request.get(includeParamsToUrl(`${API}customer/loyalty`));
}

const loyaltyApi = {
    fetchAll,
}

export default loyaltyApi;