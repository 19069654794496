import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Divider,
    Grid,
    InputLabel,
    Tooltip
} from '@mui/material';
import { faLeaf } from 'assets/fontAwesome/FontAwesomeIcons';
import { EditIcon, PlaceIcon, ReceiptIcon } from 'assets/mui/MuiIcons';
import CustomCartButton from 'components/Button/CustomCartButton';
import CustomTypography from 'components/Typography/CustomTypography';
import { getCartDivider } from 'constants/defaultObjects';
import { useTranslation } from 'react-i18next';
import { cartCartTitleTextStyle, cartHeaderTextStyle, cartIconStyle, cartItemFontAwesomeIconStyle, cartItemMuiIconStyle, cartItemTaxPriceTextStyle, cartItemTaxTextStyle, cartItemTotalPriceTextStyle, cartItemTotalTextStyle, cartTextStyle } from 'themes/cart/cartTheme';
import { orderItemTotalPriceTextStyle, orderItemTotalTextStyle } from 'themes/defaultThemes';
import { priceTextWithCurrency } from 'utils/functions/order';
import CustomSingleCardItem from './CustomSingleCardItem';
import CheckoutEquipmentPopover from 'components/Popover/CheckoutEquipmentPopover';
import { calculateTax, calculateTotalAmount } from 'utils/cart-functions';

/**
 * The CustomCartDetailsCard, that display cart details.
 * 
 * @param {object} cart the past cart details
 *
 * @returns {JSX.Element} A React component that renders a past cart.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCartDetailsCard({
    cart,
    outlet,
    equipment,
    menuConfiguration,
    updateCartCommentsCallback,
    updateCartAllergiesCallback,
    updateCartPvcRecyclingTaxCallback,
    addOrderCallback,
    equipmentAnchorEl, openEquipment, updateEquipmentCloseCallback, updateEquipmentValue, equipmentLabel, equipmentInvalid, updateEquipmentCallback, updateEquipmentOpenCallback,
    handleItemDialogState
}) {
    const { t } = useTranslation();


    return (
        <Grid container sx={{ justifyContent: "center" }} >
            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <PlaceIcon sx={cartIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('cart.equipmentLabel')} sx={cartHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                <CustomTypography variant="body2" text={`${equipment?.label ? equipment.label + " - " : ""} ${cart.type}`} sx={{ ...cartTextStyle(), width: "auto" }} />
                                <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateEquipmentOpenCallback} /></Tooltip>
                                <CheckoutEquipmentPopover
                                    anchorEl={equipmentAnchorEl}
                                    open={openEquipment}
                                    handleClose={updateEquipmentCloseCallback}
                                    inputValue={cart?.equipment?.label}
                                    handleInputChange={updateEquipmentValue}
                                    handleUpdate={updateEquipmentCallback}
                                    equipmentInvalid={equipmentInvalid}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                <Grid container gap={1}>
                    <Grid item xs={12} md={12}>
                        <InputLabel sx={cartCartTitleTextStyle()}>{t('cart.label')}</InputLabel>
                    </Grid>

                    {cart?.items?.map((cartItem, index) =>
                        <Grid item xs={12} md={12}>
                            <CustomSingleCardItem outlet={outlet} cartItem={cartItem} currency={outlet.currency} handleItemDialogState={handleItemDialogState} cartIndex={index} />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Divider light flexItem sx={getCartDivider} />

            {cart.pvcRecyclingTax > 0 &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container >
                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <FontAwesomeIcon key={"icon-env-tax"} icon={faLeaf} style={cartItemFontAwesomeIconStyle()} />
                        </Grid>

                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={t('cart.pvcRecyclingTax')} sx={cartItemTaxTextStyle()} />
                        </Grid>

                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={priceTextWithCurrency(outlet.currency, cart.pvcRecyclingTax)} sx={cartItemTaxPriceTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }

            {/* <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <PercentIcon sx={cartItemMuiIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('cart.discount')} sx={cartDiscountTextStyle()} />
                            </Grid>

                            {cart?.discount?.loyalty?.discountPercentage > 0 &&
                                <Grid item xs={12} md={12}>
                                    <Grid container>
                                        <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                            <CustomTypography variant="body2" text={`${t('cart.loyalty')} (${cart.discount.loyalty.name}):`} sx={cartDiscountTextsStyle()} />
                                        </Grid>

                                        <Grid item xs={2} md={2}>
                                            <CustomTypography variant="body2" text={`${cart.discount.loyalty.discountPercentage} %`} sx={cartDiscountValueTextStyle()} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            {cart?.discount?.coupon?.value > 0 &&
                                <Grid item xs={12} md={12}>
                                    <Grid container>
                                        <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                            <CustomTypography variant="body2" text={t('cart.coupon')} sx={cartDiscountTextsStyle()} />
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <CustomTypography variant="body2" text={priceTextWithCurrency(outlet.currency, cart.discount.coupon.value)} sx={cartItemTotalPriceTextStyle()} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> 

            <Divider light flexItem sx={getCartDivider} />
            */}

            {cart?.taxPercent > 0 &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            {/* <ReceiptIcon sx={orderItemMuiIconStyle} /> */}
                        </Grid>

                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={`${t('checkout.tax')}  (${cart?.taxPercent}%)`} sx={orderItemTotalTextStyle()} />
                        </Grid>

                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={priceTextWithCurrency(outlet?.currency, calculateTax(cart))} sx={orderItemTotalPriceTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }


            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <ReceiptIcon sx={cartItemMuiIconStyle} />
                    </Grid>

                    <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={t('cart.totalAmmount')} sx={cartItemTotalTextStyle()} />
                    </Grid>

                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={priceTextWithCurrency(outlet.currency, calculateTotalAmount(cart))} sx={cartItemTotalPriceTextStyle()} />
                    </Grid>
                </Grid>
            </Grid>

            <CustomCartButton
                outlet={outlet}
                menuConfiguration={menuConfiguration}
                totalAmmount={cart?.totalAmmount + cart?.tipAmount}
                items={cart?.quantitiesSum}
                currency={outlet.currency}
                updateCartCommentsCallback={updateCartCommentsCallback}
                updateCartAllergiesCallback={updateCartAllergiesCallback}
                updateCartPvcRecyclingTaxCallback={updateCartPvcRecyclingTaxCallback}
                isMobile={false}
                addOrderCallback={addOrderCallback}
            />
        </Grid>
    );
}

export default CustomCartDetailsCard;