import { calculateLoyaltyPointsEarn, calculateQuantitiesSum, calculateTotalAmount } from "utils/cart-functions";
import { CartActionTypes } from "./CartActionTypes";

const initialState = {
    carts: {}
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case CartActionTypes.ADD_ITEM_TO_CART:
            const { outletId, organizationId, item, modifiersPrice } = action.payload;

            // Check if the outlet record exists
            const outletExists = state.carts.hasOwnProperty(outletId) && state.carts[outletId].cart.items !== undefined;
            const newCart = outletExists ? { ...state.carts[outletId].cart } : { items: [] };

            // Check if the item already exists in the cart
            const existingItemIndex = outletExists ? newCart?.items?.findIndex(cartItem =>
                cartItem.id === item?.id &&
                cartItem.name === item?.name &&
                // cartItem.nameEn === item?.nameEn &&
                cartItem.notes === item?.notes &&
                cartItem.price === item?.price &&
                cartItem.pvcRecyclingTax === item?.pvcRecyclingTax &&
                cartItem?.modifierListIds.length === item?.modifierListIds.length && // Check lengths first

                // Compare each modifierListId separately
                cartItem?.modifierListIds.every((modifier, index) => {
                    return modifier.modifierListId === item?.modifierListIds[index].modifierListId &&
                        // Check if modifiers array lengths match
                        cartItem.modifierListIds[index].modifiers.length === item.modifierListIds[index]?.modifiers?.length &&
                        // Compare each modifier object in the modifiers array
                        cartItem.modifierListIds[index].modifiers.every((cartModifier, modifierIndex) =>
                            cartModifier.id === item.modifierListIds[index].modifiers[modifierIndex].id &&
                            cartModifier.price === item.modifierListIds[index].modifiers[modifierIndex].price
                        )
                })
            ) : -1;
            if (existingItemIndex !== -1) {
                // Item exists, increase the quantity
                newCart.items[existingItemIndex].quantity += item.quantity;
            } else {
                // Item does not exist, add as new item
                newCart.items.push(item);
            }
            // update analytics fields
            newCart.totalAmmount += calculateTotalAmount(newCart);
            newCart.quantitiesSum += + calculateQuantitiesSum(newCart);
            newCart.loyaltyPointsEarned += calculateLoyaltyPointsEarn(newCart);

            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: outletExists ? {
                        ...state.carts[outletId],
                        cart: newCart
                    } : {
                        // Create a new outlet record if it doesn't exist
                        cart: {
                            organizationID: organizationId,
                            outletID: outletId,
                            allergies: [],
                            pvcRecyclingTax: 0.0,
                            comments: "",
                            equipment: {
                                id: "",
                                label: ""
                            },
                            items: [item],
                            type: "DINE_IN",
                            persons: 1,
                            paymentMethod: "card",
                            isPaid: false,
                            loyaltyPointsEarned: item.loyaltyPointsEarned,
                            quantitiesSum: item.quantity,
                            tipAmount: 0.0,
                            totalAmmount: item.price * item.quantity + item.pvcRecyclingTax * item.quantity + modifiersPrice,
                            discount: {
                                loyalty: null,
                                coupon: null
                            },
                            taxPercent: 0
                            // Initialize other properties as needed
                        }
                    }
                }
            };
        case CartActionTypes.UPDATE_CART_ORDER_ID: {
            const { outletId, orderID } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            orderID: orderID
                        }
                    }
                }
            };
        }
        case CartActionTypes.UPDATE_ITEM_IN_CART: {
            const { outletId, itemID, newItem, indexInCart } = action.payload;
            let existingItems = state.carts[outletId]?.cart?.items;
            if (existingItems[indexInCart]?.id === itemID)
                existingItems[indexInCart] = newItem;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            items: existingItems
                        }
                    }
                }
            };
        }
        case CartActionTypes.UPDATE_CART_COMMENTS: {
            const { outletId, comments } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            comments: comments
                        }
                    }
                }
            };
        }
        case CartActionTypes.UPDATE_CART_COUPON: {
            const { outletId, coupon } = action.payload;
            // Check if the outlet record exists
            let discount = state.carts[outletId].discount ? state.carts[outletId].discount : null;
            if (coupon !== null) { discount = { coupon: coupon, loyalty: null } }
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            discount: discount
                        }

                    }
                }
            };
        }
        case CartActionTypes.UPDATE_CART_TIP_AMOUNT: {
            const { outletId, tipAmount } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            tipAmount: tipAmount
                        }

                    }
                }
            };
        }
        case CartActionTypes.UPDATE_CART_ALLERGIES: {
            const { outletId, allergies } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            allergies: allergies
                        }
                    }
                }
            };
        }
        case CartActionTypes.UPDATE_CART_PVC_RECYCLING_TAX: {
            const { outletId, pvcRecyclingTax } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            pvcRecyclingTax: pvcRecyclingTax
                        }
                    }
                }
            };
        }
        case CartActionTypes.UPDATE_CART_EQUIPMENT: {
            const { outletId, equipment } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            equipment: equipment
                        }
                    }
                }
            };
        }
        case CartActionTypes.REMOVE_ITEM_FROM_CART: {
            const { outletId, itemID, index } = action.payload;
            // Get the current cart items
            const currentCartItems = state.carts[outletId]?.cart?.items || [];

            // Make a copy of the current cart items array
            const updatedCartItems = [...currentCartItems];

            if (updatedCartItems !== null && updatedCartItems[index].id === itemID) {
                // Remove the item at the specified index
                updatedCartItems.splice(index, 1);
            }

            // Update the state with the new cart items
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            items: updatedCartItems
                        }
                    }
                }
            };
        }
        case CartActionTypes.CLEAR_OUTLET_CART: {
            const { outletId } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId].cart,
                            outletID: outletId,
                            allergies: [],
                            pvcRecyclingTax: 0.0,
                            comments: "",
                            equipment: {
                                id: "",
                                label: ""
                            },
                            items: [],
                            type: "DINE_IN",
                            persons: 1,
                            paymentMethod: "card",
                            isPaid: false,
                            loyaltyPointsEarned: 0,
                            quantitiesSum: 0,
                            totalAmmount: 0.0,
                            tipAmount: 0.0,
                            discount: {
                                loyalty: null,
                                coupon: null
                            },
                            taxPercent: 0
                            // Initialize other properties as needed
                        }
                    }
                }
            };
        }
        case CartActionTypes.UPDATE_CART_PAYMENT_METHOD: {
            const { outletId, paymentMethods } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            paymentMethod: paymentMethods.length === 0 ? "card" : paymentMethods[0]
                        }
                    }
                }
            };
        }
        case CartActionTypes.UPDATE_CART_TAX_PERCENTAGE: {
            const { outletId, taxPercent } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            taxPercent: taxPercent
                        }
                    }
                }
            };
        }
        case CartActionTypes.UPDATE_CART_PERSONS: {
            const { outletId, persons } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        cart: {
                            ...state.carts[outletId]?.cart,
                            persons: persons
                        }
                    }
                }
            };
        }
        case CartActionTypes.UPDATE_OUTLET_MENU_LANG: {
            const { outletId, menuLang } = action.payload;
            return {
                ...state,
                carts: {
                    ...state.carts,
                    [outletId]: {
                        ...state.carts[outletId],
                        menuLang: menuLang
                    }
                }
            };
        }
        // Implement additional cases for other properties
        default:
            return state;
    }
};

export default cartReducer;