import { Element } from 'react-scroll';
import CustomMenuCategory from './CustomMenuCategory';
import CustomMenuOfferCategory from './CustomMenuOfferCategory';

/**
 * The CustomMenuList, that display the outlet menu.
 *
 * @param {object} parentCategories the parentCategories details
 * @param {function} handleItemDialogState Callback that handles the item details dialog to open
 * 
 * @returns {JSX.Element} A React component that renders an outlet menu.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuList({
    parentCategory,
    handleItemDialogState,
    handleOfferDialogState,
    organizationID,
    menuConfiguration,
    selectedLayout,
    offers,
    menuLang = "en"
}) {
    let showOffers = offers?.length > 0;

    return (
        <>
            {/* <CustomTypography variant="body2" text={parentCategory.name} sx={menuCategoryHeaderTextStyle()} /> */}
            {/* offers */}
            {showOffers &&
                <Element id={"offers"} name={"offers"}>
                    <CustomMenuOfferCategory
                        offers={offers}
                        handleOfferDialogState={handleOfferDialogState}
                        organizationID={organizationID}
                        menuConfiguration={menuConfiguration}
                        isFirst={true}
                        selectedLayout={selectedLayout}
                        menuLang={menuLang}
                    />
                </Element>
            }

            {/* categories */}
            {parentCategory?.categories?.map((category, index) =>
                <>
                    <Element id={category.id} name={category.id}>
                        <CustomMenuCategory
                            category={category}
                            handleItemDialogState={handleItemDialogState}
                            organizationID={organizationID}
                            menuConfiguration={menuConfiguration}
                            isFirst={showOffers ? false : (index === 0)}
                            selectedLayout={selectedLayout}
                        />
                    </Element>
                </>
            )}
        </>
    );
}

export default CustomMenuList;