import { Grid, InputLabel, Stack } from "@mui/material";
import ratingApi from 'api/rating';
import { LottieNoRatings } from "assets/lottie/LottieAnimations";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeader from "components/Header/CustomTopHeader";
import CustomRatingCardOutlet from "components/Rating/CustomRatingCardOutlet";
import { lottieOrderFeedbacksOptions } from "constants/animations/lottieOptions";
import { MORE_RATING_ADD_SIZE } from "constants/fieldProperties";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { loyaltyEmptyInfoTextStyle, loyaltyTitleStyle, mainTag, pastOrderMoreOrdersButtonStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from "utils/functions";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The FeedbackListComponent, that presents an outlet feedbacks component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FeedbackListComponent() {
    const { t } = useTranslation();
    const { outletURL } = useParams();

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        ratings: null,
        pagination: {
            size: 10,
            page: 0
        }
    });

    const [isLoaded, setIsLoaded] = useState(false);

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);

    // for search
    const [search, setSearch] = useState({
        outletName: null
    });

    const [enableMoreButton, setEnableMoreButton] = useState(true);

    useEffect(() => {
        getRatings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getRatings();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
    }, [search.outletName])

    /**
     * The rest endpoint to get the outlet ratings.
     */
    function getRatings() {
        let data = {
            ...search
        }
        ratingApi.fetchAll(data).then((r) => {
            Promise.resolve()
                .then(() => {
                    if (r.data.code === "SUCCESS") {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            ratings: r.data.returnobject.ratings,
                            pagination: {
                                ...data.pagination,
                                size: r.data.returnobject.ratings.size
                            }
                        });
                        if (r.data.returnobject.ratings.totalPages <= r.data.returnobject.ratings.number + 1) {
                            setEnableMoreButton(false)
                        }
                    }
                })
                .then(() => {
                    setIsFirstTime(false);
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        });
    }


    //change document title
    useDocumentTitle(t("order.rating.label"));

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="feedbacks" sx={mainTag()}>
            <CustomTopHeader key="th" outletURL={outletURL} />

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} md={12}>
                            <InputLabel sx={loyaltyTitleStyle()}>{t('rating.label')}</InputLabel>
                        </Grid>

                        {/* <Grid item xs={12} md={12} sx={{ margin: "0 10px" }}>
                            <CustomTextFieldWithIcons
                                type="text"
                                name="outletName"
                                id="outletName"
                                label={t('rating.outletName')}
                                required={false}
                                defaultValue={search.outletName}
                                endIcon={<SearchIcon />}
                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("outletName", event.target.value, search, setSearch)}
                            />
                        </Grid> */}

                        <Grid item>
                            <Stack direction="row" sx={{ padding: "10px", alignItems: "center" }}>
                                <Grid container gap={2}>
                                    {data.ratings?.content?.map((rating) => (
                                        <CustomRatingCardOutlet rating={rating} />
                                    ))}

                                    {enableMoreButton &&
                                        <Grid item xs={12} md={12}>
                                            <CustomIconButton
                                                sx={pastOrderMoreOrdersButtonStyle()}
                                                onClick={() => formValChangeWithParentElementWithNameAndValue("pagination.size", data.pagination.size + MORE_RATING_ADD_SIZE, data, setData)}
                                                variant="outlined"
                                                label={t('order.moreOrders')}
                                            />
                                        </Grid>
                                    }

                                    {data.ratings?.content?.length === 0 &&
                                        <div style={{ textAlign: "center" }}>
                                            <Lottie
                                                animationData={LottieNoRatings}
                                                {...lottieOrderFeedbacksOptions}
                                            />
                                            <InputLabel sx={loyaltyEmptyInfoTextStyle()}>{search.outletName && search.outletName !== null && search.outletName !== "" ? t('rating.emptyOutlet') : t('rating.emptyRatings')}</InputLabel>
                                        </div>
                                    }
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomGrid>
    );
}

export default FeedbackListComponent;