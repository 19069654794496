import Cookies from 'js-cookie'

export function getSession() {
   const jwt = Cookies.get('usee-cookie');
   let session = "";

   try {
      if (jwt) {
         const base64Url = jwt.split('.')[1];
         const base64 = base64Url.replace('-', '+').replace('_', '/');
         // what is window.atob ?
         // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
         session = JSON.parse(window.atob(base64));
      }
   } catch (error) {
      // console.log(error)
   }
   return session
}

/**
 * Function that removes the cookie
 **/
export function logOut() {
   Cookies.remove('usee-cookie')
   Cookies.remove('auth_token')
}

/**
 * Function thatsets the cookie
 **/
export function setCookie(cookie) {
   Cookies.set('usee-cookie', cookie)

}

export default class CookieService {
   static getSession() { return getSession() }
   static logOut() { return logOut() }
   static setCookie(cookie) { return setCookie(cookie) }
}