import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to get an existing coupon information.
 * 
 * @param {*} lang TThe lang code for multilingual fields
 * @param {*} outletUrl The url of the coupon outlet to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the loyalties 
 */
export async function fetchAll(search) {
    let data = {};

    if (search.lang !== undefined) {
        data = { lang: search.lang }
    }

    if (search.outletUrl && search.outletUrl !== null && search.outletUrl !== "") {
        data = { ...data, outletUrl: search.outletUrl }
    }

    if (search.organizationName && search.organizationName !== null && search.organizationName !== "") {
        data = { ...data, organizationName: search.organizationName }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer/coupon`, data));
    else return await request.get(includeParamsToUrl(`${API}customer/coupon`));
}

/**
 * The rest request which attempts to validate a user provided coupon.
 * @param {*} organizationID The id of the coupon organization id
 * @param {*} couponCode The id of the coupon code to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the coupon
 */
export async function validateCouponCode(organizationID, couponCode) {
    let data = {};
    if (organizationID !== null && organizationID !== "" && organizationID !== undefined) {
        data.organizationID = organizationID;
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}coupon/validate/${couponCode}`, data));
    else return await request.get(includeParamsToUrl(`${API}coupon/validate/${couponCode}`));
}

const couponApi = {
    fetchAll,
    validateCouponCode
}

export default couponApi;