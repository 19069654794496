import { AppBar, Dialog, DialogContent, Grid, InputLabel, List, useMediaQuery } from "@mui/material";
import equipmentApi from "api/equipment";
import menuApi from "api/menu";
import { NoMenus } from "assets/lottie/LottieAnimations";
import { ArrowBackIcon } from "assets/mui/MuiIcons";
import CustomCartButton from "components/Button/CustomCartButton";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomCartCard from "components/Card/cart/CustomCartCard";
import CustomItemCard from "components/Card/item/CustomItemCard";
import CustomOfferCard from "components/Card/item/CustomOfferCard";
import CustomOutletDetailsCard from "components/Card/outlet/CustomOutletDetailsCard";
import CustomDialogChildren from "components/Dialog/CustomDialogChildren";
import { getDialogTypeAnimation } from "components/Dialog/animation/dialogTypeAnimation";
import CustomSwippableDrawer from "components/Drawer/CustomSwippableDrawer";
import CustomGrid from "components/Grid/CustomGrid";
import Splashscreen from "components/Grid/Splash/SplashScreen";
import CustomTopHeaderChildren from "components/Header/CustomTopHeaderChildren";
import MenuLoadingSkeleton from "components/Loading/MenuLoadingSkeleton";
import CustomMenuInstance from "components/Menu/CustomMenuInstance";
import CustomMenuList from "components/Menu/CustomMenuList";
import CustomMenuParentCategoryInstance from "components/Menu/CustomMenuParentCategoryInstance";
import CustomOutletSection from "components/Section/CustomOutletSection";
import CustomMenuTabsWithChip from "components/Tab/CustomMenuTabsWithChip";
import CustomTypography from "components/Typography/CustomTypography";
import { lottieDefaultOptions } from "constants/animations/lottieOptions";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { scroller } from "react-scroll";
import { addItemToCart, updateCartAllergies, updateCartComments, updateCartEquipment, updateCartPvcRecyclingTax, updateItemInCart, updateOutletMenuLang } from 'store/cart/cartActions';
import { updateScanDetails } from "store/scan/scanActions";
import { updateOutletSplashConfiguration } from "store/splash/splashAction";
import { autDrawerPaperStyle, mainMenuTag, menuItemPhotoGridStyle, menuNavigationHorizontalStyle, menuTitleStyle, orderBackBtnStyle, textAlignLeft, topHeaderMenuBoxAppBar, topHeaderMenuBoxAppBarSticky } from "themes/defaultThemes";
import { calculateQuantitiesSum, calculateTotalAmount } from "utils/cart-functions";
import { enableOffersOnSelectedMenu, formValChangeWithParentElementWithNameAndValue, getDisplayedOffers } from "utils/functions";
import { getDocumentTitle } from "utils/functions/menu";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from 'utils/useDocumentTitle';

/**
 * The Menu view page that presents an `Outlet` urlpath
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function MenuViewComponent() {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:468px)');
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [isOpenLogin, setIsOpenLogin] = useState(false);

    const { outletURL } = useParams();
    const params = new URLSearchParams(location.search);
    const equipmentLabel = params.get("e");
    const ss = params.get("ss");

    // from outlet settings
    const [enableCart, setEnableCart] = useState(false);

    // splash
    const outletSplashConfiguration = useSelector(state => state.splash[outletURL] || null);
    const [splashCount, setSplashCount] = useState(0);
    const [showSplash, setShowSplash] = useState(true);

    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && ss === "1") {
            const interval = setInterval(() => {
                setSplashCount((prevCount) => prevCount + 1);
            }, 1000); // Increment count every second

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ss]);


    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && splashCount >= outletSplashConfiguration?.splashConfiguration.durationInSeconds && showSplash) {
            setShowSplash(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [splashCount]);

    const [data, setData] = useState({
        userAuth: null,
        //form fields
        organization: {},
        outlet: null,
        equipment: {},
        menus: [],
        offers: [],
        menuConfiguration: {},
        outletAverageRating: 0,
        outletCountRating: 0,
        showSplash: false,
        // menu language
        language: getLanguageFromURL()
    });


    // pop dialog for 
    const [dialog, setDialog] = useState({
        isOpen: false,
        type: "success",
        message: "",
        item: null,
        offer: null,
        cartIndex: -1,
        cartItemDefault: null,
        category: null
    });

    const [open, setOpen] = useState(false);

    //change document title
    useDocumentTitle(getDocumentTitle(data.outlet, ` | ${t('menus.label')}`));

    // to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);

    const [selectedLayout, setSelectedLayout] = useState("LIST");
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [selectedParentCategory, setSelectedParentCategory] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        if (ss !== "1") setShowSplash(false);
        getMenuPageDetails();
        window.addEventListener("scroll", handleScroll, { passive: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // cart
    const cart = useSelector(state => state.cart.carts[data.outlet?.id]?.cart || null);
    const menuLang = useSelector(state => state.cart.carts[data.outlet?.id]?.menuLang || null);
    const dispatch = useDispatch();
    dispatch(updateScanDetails(outletURL, equipmentLabel));


    /**
     * The rest endpoint to get the menu page details.
     */
    function getMenuPageDetails(lang) {
        // currently selected menu, selected menu lang configured or brower lang
        menuApi.fetchMenuPage(outletURL, lang ? lang : (menuLang !== null ? menuLang : data.language), "", "", "", equipmentLabel).then((r) => {
            if (r.data.code === "SUCCESS" || r.data?.code === "PERMISSION") {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            organization: r.data.returnobject.organization,
                            outlet: r.data.returnobject.outlet,
                            menus: r.data.returnobject.menus,
                            menuConfiguration: r.data.returnobject.menuConfiguration,
                            equipment: r.data.returnobject.equipment,
                            outletAverageRating: r.data.returnobject.outletAverageRating,
                            outletCountRating: r.data.returnobject.outletCountRating,
                            // offers: r.data.returnobject.offers,
                            offers: null,
                            showSplash: r.data.returnobject.ss
                        });
                    })
                    .then(() => {
                        dispatch(updateCartEquipment(r.data.returnobject.outlet.id, r.data.returnobject.outlet.equipment));
                    })
                    .then(() => {
                        dispatch(updateOutletSplashConfiguration(
                            outletURL,
                            r.data.returnobject.outlet.settings.splashScreenConfiguration,
                            r.data.returnobject.organization.id,
                            r.data.returnobject.outlet.id,
                            r.data.returnobject.outlet.logo,
                            "menu"
                        ));
                    })
                    .then(() => {
                        if (r.data.returnobject.menus?.length === 1) {
                            setSelectedMenu(r.data.returnobject.menus[0].id);
                            if (r.data.returnobject.menus[0].parentCategories?.length === 1) {
                                let categories = r.data.returnobject.menus[0].parentCategories[0]?.categories?.map((category) => { return { value: category.id, name: category.name } })
                                setCategories(categories);
                                setSelectedParentCategory(r.data.returnobject.menus[0].parentCategories[0].id);
                            }

                            if (r.data.returnobject.menuConfiguration?.layout?.length > 0) {
                                setSelectedLayout(r.data.returnobject.menus[0].settings.layout[0]);
                            }
                        }
                    })
                    .then(() => {
                        setEnableCart(r.data.returnobject.outlet.settings.enableCart);
                    })
                    .then(() => {
                        if (r.data?.code === "PERMISSION") {
                            setIsOpenLogin(true);
                        } else {
                            setIsOpenLogin(false);
                        }
                    })
                    .then(() => {
                        if (ss) {
                            // Remove "ss" parameter from URL
                            params.delete("ss");
                            const newUrl = `${window.location.pathname}?${params.toString()}`;
                            window.history.replaceState({}, '', newUrl);
                        }
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
            }
        }).catch((e) => {
            // console.log(e);
        });
    }
    function addItemToCartCallback(cartItem, modifiersPrice) {
        dispatch((dialog.cartIndex != null && dialog.cartIndex > -1) ?
            updateItemInCart(data.outlet.id, cartItem.id, cartItem, modifiersPrice, dialog.cartIndex)
            :
            addItemToCart(data.outlet.id, data.organization.id, cartItem, modifiersPrice));
    }

    function updateCartCommentsCallback(comments) {
        dispatch(updateCartComments(data.outlet.id, comments));
    }

    function updateCartAllergiesCallback(allergies) {
        dispatch(updateCartAllergies(data.outlet.id, allergies));
    }

    function updateCartPvcRecyclingTaxCallback(pvcRecyclingTax) {
        dispatch(updateCartPvcRecyclingTax(data.outlet.id, pvcRecyclingTax));
    }


    // ================== Dialogs
    /**
     * Function that handles the visualize modal open or close state.
     * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleAnimationDialogState(isOpen, type = "success", message = "") {
        setOpen(false)
        setDialog({
            ...dialog,
            isOpen: isOpen,
            type: type,
            message: message,
            item: null,
            cartIndex: -1,
            cartItemDefault: null
        });
    }

    /**
     * Function that handles the visualize modal open or close state.
     * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen) {
        setOpen(isOpen);
        setDialog({
            ...dialog,
            isOpen: isOpen,
            item: null,
            cartIndex: -1,
            cartItemDefault: null,
            category: null
        });
    }

    /**
     * Function that handles the item itemDialog open or close state.
     * @property {boolean} isOpen If the values is `true`, the itemDialog should be open and visible.
     * @property {object} item the item to be displayed
     */
    function handleItemDialogState(isOpen, item, cartIndex = -1, cartItemDefault, extraDetails) {
        setOpen(isOpen)
        setDialog({
            ...dialog,
            isOpen: false,
            type: "",
            message: "",
            item: item,
            cartIndex: cartIndex,
            cartItemDefault: cartItemDefault,
            extraDetails: {
                categoryID: extraDetails?.id,
                categoryName: extraDetails?.nameEN
            }
        });
    }


    function body() {
        if (dialog.item !== null) {
            return <CustomItemCard
                organizationID={data.organization.id}
                outletURL={outletURL}
                itemID={dialog.item.id}
                isOpen={dialog.isOpen}
                action={addItemToCartCallback}
                handleOpen={handleItemDialogState}
                menuConfiguration={data.menus?.find(menu => menu.id === selectedMenu)?.settings}
                outletAllowComments={data.outlet.settings.allowComments}
                enableCart={enableCart}
                currency={data.outlet.currency}
                cartIndex={dialog.cartIndex}
                cartItemDefault={dialog.cartItemDefault}
                extraDetails={{
                    menuID: selectedMenu,
                    menuName: data.menus?.find(menu => menu.id === selectedMenu)?.nameEN,
                    parentCategoryID: selectedParentCategory,
                    parentCategoryName: data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.nameEN,
                    regularItem: true,
                    ...dialog.extraDetails
                }}
            />
        } else if (dialog.offer !== null) {
            return <CustomOfferCard
                organizationID={data.organization.id}
                outletURL={outletURL}
                offerID={dialog.offer.id}
                isOpen={dialog.isOpen}
                action={addItemToCartCallback}
                handleOpen={handleItemDialogState}
                menuConfiguration={data.menus.find(menu => menu.id === selectedMenu)?.settings}
                outletAllowComments={data.outlet.settings.allowComments}
                enableCart={enableCart}
                currency={data.outlet.currency}
                cartIndex={dialog.cartIndex}
                cartItemDefault={dialog.cartItemDefault}
                extraDetails={{
                    menuID: selectedMenu,
                    parentCategoryID: selectedParentCategory,
                    menuName: data.menus?.find(menu => menu.id === selectedMenu)?.nameEN,
                    parentCategoryName: data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.nameEN,
                    regularItem: true,
                    ...dialog.extraDetails
                }}
            />
        } else {
            return <CustomOutletDetailsCard
                handleReviewsClick={() => navigate({
                    pathname: "feedback",
                    search: createSearchParams({
                        e: equipmentLabel
                    }).toString()
                })}
                lang={data.language}
                updateLanguagePreferrence={updateLanguagePreferrence}
                updateLanguageField={(event) => formValChangeWithParentElementWithNameAndValue("language", event.target.value, data, setData)}
                outlet={data.outlet}
                organizationID={data.organization.id}
                outletID={data.outlet.id}
                outletURL={outletURL}
            />
        }
    }

    // ============================== SCROLL
    const [hasReachBottom, setHasReachBottom] = useState(false);
    const [value, setValue] = useState(0);

    function handleScroll(e) {
        const currentScrollPos = window.pageYOffset;
        setIsSticky(currentScrollPos > 300);
        if (categories !== null) {
            const scrollingElement = e.target.scrollingElement;
            const isBottom = (scrollingElement.scrollHeight - scrollingElement.scrollTop) - scrollingElement.clientHeight <= 130 && scrollingElement.scrollTop > 400;
            const isTop = scrollingElement.scrollTop <= 400;
            const hasEnabledOffersTab = enableOffersOnSelectedMenu(data.offers, data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.categories);

            setHasReachBottom(isBottom);
            if (isBottom) {
                // console.log(isTop, isBottom)
                setValue(hasEnabledOffersTab ? categories?.length : categories?.length - 1)
            }
            if (isTop && !isBottom) {
                // console.log(isTop, isBottom)
                setValue(0)
            }
        }
    };

    function scrollTo(categoryId) {
        scroller.scrollTo(categoryId, {
            duration: 800,
            delay: 0,
            offset: -100,
            smooth: 'easeInOutQuart'
        })
    }

    function addOrderCallback() {
        //move to checkout page        
        navigate(`/${getLanguageFromURL()}/checkout/${outletURL}?e=${equipmentLabel}`);
    }

    // ==============================  CATEGORIES TABS SCROLL to STICKY
    const [isSticky, setIsSticky] = useState(false);

    // ========================= EQUIPMENT 
    const [equipmentAnchorEl, setEquipmentAnchorEl] = useState(null);
    const [equipment, setEquipment] = useState('');
    const [equipmentInvalid, setEquipmentInvalid] = useState(false);

    function updateEquipmentOpenCallback(event) {
        setEquipment(cart?.equipment?.label)
        setEquipmentAnchorEl(event.currentTarget);
    }

    function updateEquipmentCloseCallback() {
        setEquipmentAnchorEl(null);
    }

    function updateEquipmentValue(event) {
        setEquipment(event.target.value);
    }

    function updateEquipmentCallback() {
        validateNewEquipmentLabel(equipment);
    }

    const openEquipment = Boolean(equipmentAnchorEl);

    /**
     * The rest endpoint to validate the new inputed equipment label and return its details (id, label).
     */
    function validateNewEquipmentLabel() {
        equipmentApi.validateExistance(data?.outlet?.id, equipment).then((r) => {
            if (r.data?.code === "SUCCESS" && r.data?.returnobject !== null) {
                dispatch(updateCartEquipment(data?.outlet?.id, r.data?.returnobject))
                // Optionally close the popover
                updateEquipmentCloseCallback();
                setEquipmentInvalid(false);
                updateURLParameter('e', r.data?.returnobject?.label);
            } else {
                setEquipmentInvalid(true);
            }
        }).catch((e) => {
            // console.log(e);
        });
    }

    function updateURLParameter(param, value) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(param, value);
        navigate(`?${newSearchParams.toString()}`);
    };

    // ===============
    function updateLanguagePreferrence(event) {
        getMenuPageDetails(event.target.value);
        dispatch(updateOutletMenuLang(event.target.value));
        handleDialogState(false);
    }

    function updateSelectedMenu(menuID) {
        setSelectedMenu(menuID);
        if (data.menus?.find((menu) => menu.id === menuID)?.parentCategories?.length === 1) {
            let parentCategoryID = data.menus.find((menu) => menu.id === menuID).parentCategories[0]?.id;
            setSelectedParentCategory(parentCategoryID);
            let categories = data.menus?.find(menu => menu.id === menuID)?.parentCategories.find(parentCategory => parentCategory.id === parentCategoryID)?.categories.map((category) => { return { value: category.id, name: category.name } })
            setCategories(categories);
            setValue(0)
        }
    }

    function goBack(from) {
        if (from === "categories") {
            setSelectedParentCategory(null);
            setCategories(null);
            if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories.length === 1)
                setSelectedMenu(null);
        } else if (from === "parentCategories")
            setSelectedMenu(null);
    }

    // ===============================================================================RENDER
    if (showSplash) {
        return <Splashscreen outletSplashConfiguration={outletSplashConfiguration}></Splashscreen>
    } else {
        if (!isLoaded) {
            return <CustomGrid role="main" id="outlet" sx={mainMenuTag()}>
                <CustomTopHeaderChildren
                    isOpen={isOpenLogin}
                    handleLoginCheckout={() => getMenuPageDetails()}
                    setIsOpen={setIsOpenLogin}
                    pageRequireLoginRegistation={isOpenLogin}
                />
                <MenuLoadingSkeleton isMobile={isMobile} />
            </CustomGrid>
        } else {
            return (
                <CustomGrid role="main" id="menu" sx={{ ...mainMenuTag(), margin: isMobile ? "40px 0 50px 0" : "40px 0 5px 0" }}>
                    <CustomTopHeaderChildren
                        isOpen={isOpenLogin}
                        handleLoginCheckout={() => getMenuPageDetails()}
                        setIsOpen={setIsOpenLogin}
                        pageRequireLoginRegistation={isOpenLogin}
                    />

                    <Grid container justifyContent={"center"} justifyItems={"center"} sx={{ width: "100%", margin: "0px" }}>
                        <Grid item xs={12} sx={{ padding: "0px!important" }}>
                            <CustomOutletSection
                                outlet={data.outlet}
                                handleOpen={handleDialogState}
                                organizationID={data.organization.id}
                                outletConfiguration={data.outlet.settings}
                                outletAverageRating={data.outletAverageRating}
                                outletCountRating={data.outletCountRating}
                            />
                        </Grid>

                        {data.menus?.length === 0 &&
                            <>
                                <Grid item xs={12} sx={{ padding: "10px 0px!important" }}>
                                    <Lottie
                                        {...lottieDefaultOptions}
                                        animationData={NoMenus}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel sx={menuTitleStyle()}>{t('menu.noMenus')}</InputLabel>
                                </Grid>
                            </>
                        }

                        {data.menus?.length > 1 && selectedMenu === null &&
                            <Grid item xs={12} sm={12}>
                                <Grid container sx={{ marginTop: "20px" }}>
                                    <Grid item xs={isMobile ? 12 : 8}>
                                        {data.outlet?.settings?.menuLayout === "LIST" &&
                                            <List>
                                                {data.menus?.map((menu) =>
                                                    <CustomMenuInstance
                                                        menu={menu}
                                                        selectedLayout={data.outlet.settings.menuLayout}
                                                        organizationID={data.organization.id}
                                                        handleMenuClick={(selected, menuID) => updateSelectedMenu(menuID)}
                                                    />
                                                )}
                                            </List>
                                        }

                                        {data.outlet?.settings?.menuLayout === "GRID" &&
                                            <Grid container spacing={2} sx={{ placeContent: "center" }}>
                                                {data.menus?.map((menu) =>
                                                    <CustomMenuInstance
                                                        menu={menu}
                                                        selectedLayout={data.outlet.settings.menuLayout}
                                                        organizationID={data.organization.id}
                                                        handleMenuClick={(selected, menuID) => updateSelectedMenu(menuID)}
                                                    />
                                                )}
                                            </Grid>
                                        }
                                    </Grid>

                                    {!isMobile && enableCart &&
                                        <Grid item xs={0} sm={4}>
                                            <CustomCartCard
                                                cart={cart}
                                                outlet={data.outlet}
                                                equipment={data.equipment}
                                                updateCartCommentsCallback={updateCartCommentsCallback}
                                                updateCartAllergiesCallback={updateCartAllergiesCallback}
                                                updateCartPvcRecyclingTaxCallback={updateCartPvcRecyclingTaxCallback}
                                                isMobile={false}
                                                menuConfiguration={data.menus.find(menu => menu.id === selectedMenu)?.settings}
                                                addOrderCallback={addOrderCallback}
                                                //equipment update
                                                equipmentAnchorEl={equipmentAnchorEl}
                                                openEquipment={openEquipment}
                                                updateEquipmentCloseCallback={updateEquipmentCloseCallback}
                                                equipmentLabel={cart?.equipment?.label}
                                                updateEquipmentValue={updateEquipmentValue}
                                                updateEquipmentCallback={updateEquipmentCallback}
                                                equipmentInvalid={equipmentInvalid}
                                                updateEquipmentOpenCallback={updateEquipmentOpenCallback}
                                                handleItemDialogState={handleItemDialogState}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }

                        {data.menus?.length >= 1 && selectedMenu !== null && selectedParentCategory === null &&
                            <Grid item xs={12} sm={12}>
                                <Grid container sx={{ marginTop: "20px" }}>
                                    {data.menus?.length > 1 &&
                                        <Grid item xs={12}>
                                            <CustomIconButton
                                                icon={<ArrowBackIcon />}
                                                sx={orderBackBtnStyle()}
                                                onClick={(event) => goBack("parentCategories")}
                                                label={t('menu.back.parentCategories')}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={isMobile ? 12 : 8}>
                                        {data.menus?.find(menu => menu.id === selectedMenu)?.settings?.parentCategoryLayout === "LIST" &&
                                            <List>
                                                {data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.map((parentCategory) =>
                                                    <CustomMenuParentCategoryInstance
                                                        parentCategory={parentCategory}
                                                        organizationID={data.organization.id}
                                                        menuConfiguration={data.menus?.find(menu => menu.id === selectedMenu)?.settings}
                                                        handleParentCategoryClick={(selected, parentCategoryID) => {
                                                            let categories = data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories.find(parentCategory => parentCategory.id === parentCategoryID)?.categories.map((category) => { return { value: category.id, name: category.name } })
                                                            setCategories(categories);
                                                            console.log(categories)
                                                            setSelectedParentCategory(parentCategoryID);
                                                            setValue(0);
                                                        }}
                                                    />
                                                )}
                                            </List>
                                        }

                                        {data.menus?.find(menu => menu.id === selectedMenu)?.settings?.parentCategoryLayout === "GRID" &&
                                            <Grid container spacing={0} sx={{ placeContent: "center" }}>
                                                {data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.map((parentCategory) =>
                                                    <CustomMenuParentCategoryInstance
                                                        parentCategory={parentCategory}
                                                        organizationID={data.organization.id}
                                                        menuConfiguration={data.menus?.find(menu => menu.id === selectedMenu)?.settings}
                                                        handleParentCategoryClick={(selected, parentCategoryID) => {
                                                            let categories = data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === parentCategoryID)?.categories.map((category) => { return { value: category.id, name: category.name } })
                                                            setCategories(categories);
                                                            console.log(categories)
                                                            setSelectedParentCategory(parentCategoryID)
                                                            setValue(0);
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        }
                                    </Grid>

                                    {!isMobile && enableCart &&
                                        <Grid xs={0} sm={4}>
                                            <CustomCartCard
                                                cart={cart}
                                                outlet={data.outlet}
                                                equipment={data.equipment}
                                                updateCartCommentsCallback={updateCartCommentsCallback}
                                                updateCartAllergiesCallback={updateCartAllergiesCallback}
                                                updateCartPvcRecyclingTaxCallback={updateCartPvcRecyclingTaxCallback}
                                                isMobile={false}
                                                menuConfiguration={data.menus.find(menu => menu.id === selectedMenu)?.settings}
                                                addOrderCallback={addOrderCallback}
                                                //equipment update
                                                equipmentAnchorEl={equipmentAnchorEl}
                                                openEquipment={openEquipment}
                                                updateEquipmentCloseCallback={updateEquipmentCloseCallback}
                                                equipmentLabel={cart?.equipment?.label}
                                                updateEquipmentValue={updateEquipmentValue}
                                                updateEquipmentCallback={updateEquipmentCallback}
                                                equipmentInvalid={equipmentInvalid}
                                                updateEquipmentOpenCallback={updateEquipmentOpenCallback}
                                                handleItemDialogState={handleItemDialogState}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                        }

                        {data.menus?.length > 0 && selectedMenu !== null && selectedParentCategory !== null &&
                            <>
                                {/* MENU */}
                                <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
                                    <main id="view-menu" style={{ overflow: "visible" }}>
                                        <AppBar position={isSticky ? 'sticky' : 'static'}
                                            style={
                                                isSticky ?
                                                    topHeaderMenuBoxAppBarSticky(((enableOffersOnSelectedMenu(data.offers, data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.categories) ? 1 : 0) + data.menus.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.categories?.length) < 2) :
                                                    topHeaderMenuBoxAppBar(((enableOffersOnSelectedMenu(data.offers, data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.categories) ? 1 : 0) + data.menus.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.categories?.length) < 2)
                                            }
                                        >
                                            <CustomMenuTabsWithChip
                                                // customize style for navigation menu
                                                {...Object.entries(data.menus.find(menu => menu.id === selectedMenu)?.settings)
                                                    .filter(([key, value]) => key.startsWith('navigation'))
                                                    .reduce((acc, [key, value]) => {
                                                        acc[key] = value;
                                                        return acc;
                                                    }, {})}
                                                categories={data.menus.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory).categories}
                                                sx={menuNavigationHorizontalStyle()}
                                                handleChange={scrollTo}
                                                hasReachBottom={hasReachBottom}
                                                value={value}
                                                setValue={setValue}
                                                layout={data.menus?.find(menu => menu.id === selectedMenu)?.settings?.layout}
                                                selectedLayout={selectedLayout}
                                                setSelectedLayout={setSelectedLayout}
                                                goBack={goBack}
                                                showGoBack={
                                                    data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.length > 1 ||
                                                    (data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.length === 1 && data.menus?.length > 1)
                                                }
                                                menuLang={data.language}
                                                includeOffersTab={enableOffersOnSelectedMenu(data.offers, data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.categories)}
                                            />
                                        </AppBar>

                                        <Grid container gap={1} sx={{ marginTop: "10px" }}>
                                            {!isMobile &&
                                                <Grid xs={0} sm={2.45} sx={{ ...textAlignLeft(), marginLeft: "20px" }}>
                                                    <CustomTypography variant="body2" text={t("menu.sidenav.categories")} sx={{ fontSize: data.outlet.settings.fontSize, cursor: "pointer", fontWeight: "bold", marginLeft: "3px", marginBottom: "10px", marginTop: "20px" }} />

                                                    <CustomMenuTabsWithChip
                                                        // customize style for navigation menu
                                                        {...Object.entries(data.menus.find(menu => menu.id === selectedMenu)?.settings)
                                                            .filter(([key, value]) => key.startsWith('navigation'))
                                                            .reduce((acc, [key, value]) => {
                                                                acc[key] = value;
                                                                return acc;
                                                            }, {})}
                                                        orientation="vertical"
                                                        boxShadow="0"
                                                        categories={data.menus.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory).categories}
                                                        sx={menuNavigationHorizontalStyle()}
                                                        handleChange={scrollTo}
                                                        hasReachBottom={hasReachBottom}
                                                        value={value}
                                                        setValue={setValue}
                                                        includeOffersTab={enableOffersOnSelectedMenu(data.offers, data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.categories)}
                                                        menuLang={data.language}
                                                    />
                                                </Grid>
                                            }


                                            <Grid item xs={12} sm={6} sx={menuItemPhotoGridStyle(isMobile)}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <CustomMenuList
                                                            organizationID={data.organization.id}
                                                            parentCategory={data.menus.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)}
                                                            menuConfiguration={data.menus.find(menu => menu.id === selectedMenu)?.settings}
                                                            handleItemDialogState={handleItemDialogState}
                                                            selectedLayout={selectedLayout}
                                                            addItemToCart={addItemToCartCallback}
                                                            offers={getDisplayedOffers(data.offers, data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.categories)}
                                                            menuLang={data.language}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {!isMobile && enableCart &&
                                                <Grid xs={0} sm={3.2}>
                                                    <CustomCartCard
                                                        cart={cart}
                                                        outlet={data.outlet}
                                                        equipment={data.equipment}
                                                        updateCartCommentsCallback={updateCartCommentsCallback}
                                                        updateCartAllergiesCallback={updateCartAllergiesCallback}
                                                        updateCartPvcRecyclingTaxCallback={updateCartPvcRecyclingTaxCallback}
                                                        isMobile={false}
                                                        menuConfiguration={data.menus.find(menu => menu.id === selectedMenu)?.settings}
                                                        addOrderCallback={addOrderCallback}
                                                        //equipment update
                                                        equipmentAnchorEl={equipmentAnchorEl}
                                                        openEquipment={openEquipment}
                                                        updateEquipmentCloseCallback={updateEquipmentCloseCallback}
                                                        equipmentLabel={cart?.equipment?.label}
                                                        updateEquipmentValue={updateEquipmentValue}
                                                        updateEquipmentCallback={updateEquipmentCallback}
                                                        equipmentInvalid={equipmentInvalid}
                                                        updateEquipmentOpenCallback={updateEquipmentOpenCallback}
                                                        handleItemDialogState={handleItemDialogState}
                                                    />
                                                </Grid>
                                            }

                                        </Grid>
                                    </main>
                                </Grid>
                            </>
                        }
                    </Grid>


                    {(isMobile && data.outlet.settings.enableCart &&
                        cart?.items?.length > 0) &&
                        <CustomCartButton
                            menuConfiguration={data.menus?.find(menu => menu.id === selectedMenu)?.settings}
                            totalAmmount={calculateTotalAmount(cart)}
                            items={calculateQuantitiesSum(cart)}
                            currency={data.outlet.currency}
                            updateCartCommentsCallback={updateCartCommentsCallback}
                            updateCartAllergiesCallback={updateCartAllergiesCallback}
                            updateCartPvcRecyclingTaxCallback={updateCartPvcRecyclingTaxCallback}
                            isMobile={true}
                            addOrderCallback={addOrderCallback}
                            outlet={data.outlet}
                            brandConfiguration={data.outlet.settings}
                        />
                    }

                    {(!isMobile && open) &&
                        <Dialog
                            open={open}
                            onClose={() => { handleDialogState(false) }}
                            aria-describedby="auth-login-dialog"
                            paperSx={autDrawerPaperStyle}
                        >
                            <DialogContent sx={{ textAlign: "-webkit-center", minWidth: "300px" }}>
                                {body()}
                            </DialogContent>
                        </Dialog>
                    }

                    {(isMobile && open) &&
                        <CustomSwippableDrawer
                            open={open}
                            onClose={() => handleDialogState(false)}
                            onOpen={() => handleDialogState(true)}
                            anchor={isMobile ? "bottom" : "left"}
                            paperSx={{ ...autDrawerPaperStyle(), padding: dialog.item !== null ? "0 0 10px 0" : "initial" }}
                        >
                            {body()}
                        </CustomSwippableDrawer>
                    }

                    {(!isMobile && dialog.isOpen && dialog.item !== null) &&
                        <CustomDialogChildren
                            isOpen={dialog.isOpen}
                            id="dialog"
                            title={t(`request.popup.${dialog.type}`)}
                            cancelLabel={t("request.btns.ok")}
                            message={dialog.message}
                            handleOpen={handleAnimationDialogState}
                            actionColor="#e91e1e"
                            children={getDialogTypeAnimation(dialog.type)}
                        />
                    }
                </CustomGrid>
            )
        }
    }
}

export default MenuViewComponent;  