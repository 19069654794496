import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function OutletActionsWebView({ url }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log(url)

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate(-1); // Go back to the previous page
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  return (
    <div>
      <iframe src={url} style={{ width: '100%', height: '100vh' }} />
      <button onClick={() => navigate(-1)}>{t('actions.back')}</button>
    </div>
  );
}

export default OutletActionsWebView;
