import {
    Grid
} from '@mui/material';
import { LanguageIcon } from 'assets/mui/MuiIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { languageIconStyle, languageTextStyle } from 'themes/defaultThemes';

/**
 * The CustomMenuLanguageCard, that display customer current language.
 * 
 * @param {object} lang the current customer language
 * @param {fucntion} handleLanguageClick Callback that handles the language update dialog to open.
 *
 * @returns {JSX.Element} A React component that renders customer current language.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuLanguageCard({ lang="en", handleLanguageClick }) {
    const { t } = useTranslation();

    return (
        <Grid item xs={12} md={12} onClick={handleLanguageClick}>
            <Grid container sx={{ textAlign: "left" }}>
                <Grid item xs={2} md={2}>
                    <LanguageIcon sx={languageIconStyle} />
                </Grid>

                <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                    <CustomTypography variant="body2" text={`${t('settings.language')} (${lang.toUpperCase()})`} sx={languageTextStyle()} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CustomMenuLanguageCard;