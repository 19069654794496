import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Card } from '@mui/material';
import { Box, Stack } from '@mui/system';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import CustomCartDetailsCard from './CustomCartDetailsCard';

/**
 * The CustomCartCard, that display a custom cart card.
 * 
 * @param {object} cartItems the cart items
 *
 * @returns {JSX.Element} A React component that renders a cart card.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCartCard({
    cart, outlet, equipment, menuConfiguration, addOrderCallback,
    equipmentAnchorEl, openEquipment, updateEquipmentCloseCallback, updateEquipmentValue, equipmentLabel, equipmentInvalid, updateEquipmentCallback, updateEquipmentOpenCallback,
    handleItemDialogState
}) {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', maxWidth: '100%', height: "100%" }} >
            <Card elevation={3} sx={{
                justifyContent: "center", alignItems: "center", height: "100%", border: "none", flex: "1 1 0%", display: "flex", width: "100%", maxWidth: '100%',
                [`& .MuiPaper-root`]: { display: "contents", width: "100%", justifyContent: "center", alignItems: "center", }
            }}>
                {(cart === undefined || cart?.items === undefined || cart?.items?.length === 0) &&
                    <Stack
                        spacing={2}
                        style={{
                            display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"
                        }}>
                        <ShoppingBasketIcon sx={{ fontSize: "80px", color: "grey", backgroundColor: "#f1f3f8c", borderRadius: "70px", padding: "20px" }} />
                        <CustomTypography variant="body2" text={t('cart.emptyLabel')} sx={{ fontWeight: "bold", fontSize: "22px", width: "100%" }} />
                        <CustomTypography variant="body2" text={t('cart.emptyDescription')} sx={{ fontSize: "14px" }} />
                    </Stack>
                }

                {cart?.items?.length > 0 &&
                    <CustomCartDetailsCard
                        cart={cart}
                        outlet={outlet}
                        equipment={equipment}
                        menuConfiguration={menuConfiguration}
                        addOrderCallback={addOrderCallback}
                        equipmentAnchorEl={equipmentAnchorEl}
                        openEquipment={openEquipment}
                        updateEquipmentCloseCallback={updateEquipmentCloseCallback}
                        equipmentLabel={equipmentLabel}
                        updateEquipmentValue={updateEquipmentValue}
                        updateEquipmentCallback={updateEquipmentCallback}
                        equipmentInvalid={equipmentInvalid}
                        updateEquipmentOpenCallback={updateEquipmentOpenCallback}
                        handleItemDialogState={handleItemDialogState}

                    />
                }
            </Card>
        </Box>
    );
}

export default CustomCartCard;