import { faCreditCard, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid } from '@mui/material';
import Popover from '@mui/material/Popover';
import CustomIconButton from 'components/Button/CustomIconButton';
import { useTranslation } from 'react-i18next';
import { getPaymentMethodButtonIcon, paymentMethodButton, paymentMethodButtonIcon } from 'themes/checkout/checkoutTheme';
function CheckoutPaymentMethodPopover({ anchorEl, open, handleClose, inputValue, handleUpdate }) {
  const id = open ? 'simple-popover' : undefined;
  const { t } = useTranslation();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box style={{ padding: '20px' }}>
        <Grid container gap={1}>
          <Grid item xs="auto">
            <CustomIconButton
              keyValue={`btn-search-cash`}
              onClick={() => handleUpdate("cash")}
              icon={<FontAwesomeIcon key={"icon-cash-search"} icon={faMoneyBill1Wave} sx={getPaymentMethodButtonIcon()} />}
              iconSx={paymentMethodButtonIcon()}
              label={t('checkout.payment.cash')}
              sx={paymentMethodButton()}
              variant='outlined'
              backgroundColor="#dcf1f7"
              isFocused={inputValue === "cash"}
            />
          </Grid>

          <Grid item xs="auto">
            <CustomIconButton
              keyValue={`btn-search-card`}
              onClick={() => handleUpdate("card")}
              icon={<FontAwesomeIcon key={"icon-card-search"} icon={faCreditCard} sx={getPaymentMethodButtonIcon()} />}
              iconSx={{ ...paymentMethodButtonIcon(), color: "cornflowerblue" }}
              label={t('checkout.payment.card')}
              sx={paymentMethodButton()}
              variant='outlined'
              backgroundColor="#dcf1f7"
              isFocused={inputValue === "card"}
            />
          </Grid>
          {/* <Grid item xs={3} md={3} sx={{ alignSelf: "center" }}>
            <Button
              onClick={handleUpdate}
              color="primary"
              variant="contained"
            >
              {t('button.update')}
            </Button>
          </Grid> */}
        </Grid>
      </Box>
    </Popover>
  );
}

export default CheckoutPaymentMethodPopover;