import {
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import outletApi from 'api/outlet';
import {
    SaveIcon
} from 'assets/mui/MuiIcons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loyaltyTitleStyle, settingFabButtonStyle } from 'themes/defaultThemes';
import { getFlagIcon } from 'utils/functions/language';

/**
 * The CustomLanguageUpdateCard, that display customer current language.
 * 
 * @param {object} lang the current customer language
 * @param {fucntion} handleLanguageClick Callback that handles the language update dialog to open.
 *
 * @returns {JSX.Element} A React component that renders customer current language.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomLanguageUpdateCard({ language = "en", updateLanguageField, updateLanguagePreferrence, outletID, userLanguages, outletLanguages }) {
    const { t } = useTranslation();
    const [languages, setLanguages] = useState(userLanguages ? userLanguages : outletLanguages);

    useEffect(() => {
        if (outletID) getOutletMenuLanguages();
    }, []);

    /**
   * The rest endpoint to get the menu page details.
   */
    function getOutletMenuLanguages() {
        outletApi.fetchOutletMenuLanguages(outletID).then((r) => {
            Promise.resolve()
                .then(() => {
                    setLanguages(r.data.returnobject);
                })
        }).catch((e) => {
        });
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ marginBottom: "50px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ justifyContent: "center" }}>
                        <InputLabel sx={loyaltyTitleStyle()}>{t('settings.updateLanguage')}</InputLabel>
                    </Grid>

                    {languages?.map((lang) =>
                        <Grid item key={lang} xs={12} md={6} sx={{ textAlign: "left", marginLeft: "30px", paddingTop: 0 }}>
                            <FormControl>
                                <RadioGroup
                                    name="language"
                                    value={language}
                                    onChange={updateLanguageField}
                                >
                                    <FormControlLabel
                                        value={lang}
                                        control={<Radio />}
                                        label={
                                            <Grid container sx={{ paddingTop: "10px" }}>
                                                <Grid item>
                                                    <img src={getFlagIcon(lang.toLowerCase())} width="30" alt={lang} style={{ marginRight: "10px", marginTop: "8px", border: "1px solid lightgrey" }} />
                                                </Grid>
                                                <Grid item>
                                                    <p style={{ marginTop: "7px" }}>{t(`languages.${lang.toLowerCase()}`) + " " + `(${lang.toUpperCase()})`}</p>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Fab color="primary"
                aria-label="add"
                sx={{ ...settingFabButtonStyle(), position: "absolute" }}
                onClick={updateLanguagePreferrence}
            >
                <SaveIcon />
            </Fab>
        </Grid>
    );
}

export default CustomLanguageUpdateCard;