import { Delete } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import Popover from '@mui/material/Popover';
import CustomIconButton from 'components/Button/CustomIconButton';
import GroupedItemButtons from 'components/Button/GroupedItemButtons';

function CartItemPopover({ anchorEl, open, handleClose, inputValue, handleUpdate, removeItemCallback }) {
  const id = open ? 'simple-popover' : undefined;

  /**
   * Callback that handles the counter increment.
   * 
   * @param {*} counter the old counter value
   */
  function handleIncrement() {
    inputValue += 1;
    handleUpdate(inputValue);
  }

  /**
   * Callback that handles the counter decrement.
   * 
   * @param {*} counter the old counter value
   */
  function handleDecrement() {
    inputValue -= 1;
    handleUpdate(inputValue);
  }
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box style={{ padding: '20px' }}>
        <Grid container gap={1}>
          <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
            <GroupedItemButtons
              counter={inputValue}
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement} />

          </Grid>

          <Grid item xs={3} md={3} sx={{ alignSelf: "center" }}>
            <CustomIconButton
              icon={<Delete />}
              // sx={authBackBtnStyle()}
              onClick={removeItemCallback}
              label={undefined}
            />
          </Grid>

          {/* <Grid item xs={3} md={3} sx={{ alignSelf: "center" }}>
            <Button
              onClick={handleUpdate}
              color="primary"
              variant="contained"
            >
              {t('button.update')}
            </Button>
          </Grid> */}
        </Grid>
      </Box>
    </Popover>
  );
}

export default CartItemPopover;