import Barcode from "react-barcode";

function CustomBarcode({
    value = "012356789012356789",
    width = 1.75,
    height = 60,
    format = "CODE128",
    displayValue = true,
    fontOptions = "",
    font = "monospace",
    textAlign = "center",
    textPosition = "bottom",
    textMargin = 2,
    fontSize = 20,
    background = "#ffffff",
    lineColor = "#000000",
    hasCustomMargin = "false",
    margin = 10,
    marginTop = undefined,
    marginBottom = undefined,
    marginLeft = undefined,
    marginRight = undefined
}) {

    function findWidthForFormat(format = "CODE128") {
        if (format === "CODE39")
            return 0.8;
        else if (format === "MSI")
            return 1.15;
        else if (format === "codabar")
            return 1.35;
        return 1.95;
    }
    return (
        <Barcode
            value={"012356789012356789"}
            // width={parseFloat(width)}
            width={findWidthForFormat(format)}
            height={parseFloat(height)}
            format={format}
            displayValue={displayValue}
            fontOptions={fontOptions}
            font={font}
            textAlign={textAlign}
            textPosition={textPosition}
            textMargin={parseInt(textMargin)}
            fontSize={parseInt(fontSize)}
            background={background}
            lineColor={lineColor}
            margin={!hasCustomMargin ? parseInt(margin) : undefined}
            marginTop={hasCustomMargin ? parseInt(marginTop) : undefined}
            marginBottom={hasCustomMargin ? parseInt(marginBottom) : undefined}
            marginLeft={hasCustomMargin ? parseInt(marginLeft) : undefined}
            marginRight={hasCustomMargin ? parseInt(marginRight) : undefined}
        />
    )
}
export default CustomBarcode;
