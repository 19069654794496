export const getCouponCardProperties = () => {
    return {
        width: "310px",
        height: "270px",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "20px",
        boxShadow: 3,
        margin: "20px",
        boxShadow: 2,
        overflow: "visible"
    };
}

export const getCouponDefaultBarcodeConfiguration = () => {
    return {
        value: "012356789012356789",
        width: 1,
        height: 30,
        format: "CODE128",
        displayValue: true,
        fontOptions: "none", //TODO: remember to get the value of the field
        font: "monospace",
        textAlign: "center",
        textPosition: "bottom",
        textMargin: 2,
        fontSize: 20,
        background: "#ffffff",
        lineColor: "#000000",
        hasCustomMargin: false,
        margin: 10,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10
    };
}

export const getFlipCardConfiguration = () => {
    return {
        flipSpeedBackToFront: 2,
        flipSpeedFrontToBack: 2,
        cardStyles: { back: {}, front: {} },
        cardZIndex: 'auto',
        containerStyle: {},
        flipDirection: "horizontal",
    };
}