
/**
 * A test page
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function TestComponent() {

}

export default TestComponent;