import { Grid } from '@mui/material';
import { GoogleIcon, ReviewsIcon } from 'assets/mui/MuiIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { orderHeaderTextStyle, orderIconStyle, orderReviewGridStyle, pastOrderDateTextStyle, ratingIconStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';
import CustomRating from './CustomRating';

/**
 * The Custom Rating field, that display customized customer {@link Rating}.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomRatingCard({ outlet, rating }) {
    
    return (
        <Grid item xs={12} md={12} sx={orderReviewGridStyle()}>
            <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                <Grid item xs={2} md={2}>
                    <ReviewsIcon sx={orderIconStyle} />
                </Grid>
                <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                    <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                        <Grid item xs={10} md={10}>
                            <CustomRating
                                readOnly={true}
                                size={"medium"}
                                iconStyle={outlet?.ratingStyle}
                                value={rating?.starRating}
                                sx={{ alignItems: "center" }}
                            />
                        </Grid>
                        {rating?.hasSentToGoogleReview &&
                            <Grid item xs={2} md={2}>
                                <GoogleIcon sx={ratingIconStyle} />
                            </Grid>
                        }
                        <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
                            {rating?.message != "" &&
                                <CustomTypography variant="body2" text={rating?.message} sx={orderHeaderTextStyle()} />
                            }
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
                            <CustomTypography variant="body2" text={`${dateTimeFormatter(rating?.dateCreated, "DD/MM/yyyy - HH:mm")} `} sx={{ ...pastOrderDateTextStyle(), textAlign: "right" }} />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CustomRatingCard;