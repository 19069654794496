import { Grid, Tab, Tabs } from '@mui/material';
import { ArrowBackIcon, GridViewIcon, TocIcon } from 'assets/mui/MuiIcons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { replaceLastTwoChars } from 'utils/functions';

/**
 * The CustomPreviewMenuMobileTabsWithChip, that display the preview style of the navigation menu for categories using tabs and chips.
 * 
 * @param {object} item the menu item details
 * @param {string} priceColor the color for price to have
 * @param {string} lang the lang code
 * @param {fucntion} handleItemDialogState Callback that handles the open item details dialog for more details and to fill in fields to add one or more to cart.
 *
 * @returns {JSX.Element} A React component that renders a menu item.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuTabsWithChip({
    categories,
    navigationMenuFont = "Arial",
    navigationMenuFontSize = "12px",
    navigationMenuBackgroundColor = "#fff",
    navigationMenuSelectedOptionFontColor = "#47a6d2",
    navigationMenuUnSelectedOptionFontColor = "#000",
    navigationMenuStyle = "ROUND",
    width = "100%",
    orientation = "horizontal",
    boxShadow = "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    handleChange,
    sx,
    hasReachBottom,
    value,
    setValue,
    layout,
    selectedLayout,
    setSelectedLayout,
    goBack,
    showGoBack,
    includeOffersTab = false,
    menuLang = "en"
}) {
    const { t } = useTranslation();
    let backgroundChipColor = replaceLastTwoChars(navigationMenuSelectedOptionFontColor, "1c");
    return (
        <Grid container sx={{ boxShadow: boxShadow, alignItems: "center", height: ((includeOffersTab ? 1 : 0) + categories?.length) > 1 ? "80px" : "30px" }}>
            {/* SELECT LAYOUT STYLE */}
            <Grid item xs={layout?.length > 1 ? 3 : 1} sm={layout?.length > 1 ? 3 : 1} sx={{ width: "fit-content", height: ((includeOffersTab ? 1 : 0) + categories?.length) > 1 ? "55px" : "30px" }}>
                {showGoBack &&
                    <ArrowBackIcon sx={{ color: navigationMenuSelectedOptionFontColor }} onClick={(event) => goBack("categories")} />
                }
                {layout?.length > 1 &&
                    <>
                        <TocIcon sx={{ color: selectedLayout === "LIST" ? navigationMenuSelectedOptionFontColor : backgroundChipColor }} onClick={(event) => setSelectedLayout("LIST")} />
                        <GridViewIcon sx={{ color: selectedLayout === "GRID" ? navigationMenuSelectedOptionFontColor : backgroundChipColor }} onClick={(event) => setSelectedLayout("GRID")} />
                    </>
                }
            </Grid>

            {((includeOffersTab ? 1 : 0) + categories?.length) > 1 &&
                <Grid item xs={layout?.length > 1 ? 9 : 10} sm={layout?.length > 1 ? 9 : 11}>
                    <Tabs
                        value={value}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        selectionFollowsFocus={true}
                        orientation={orientation}
                        TabIndicatorProps={{
                            style: {
                                // backgroundColor: navigationMenuSelectedOptionFontColor,
                                display: "none"
                            }
                        }}
                        sx={{
                            background: navigationMenuBackgroundColor,
                            marginBottom: "40px",
                            width: width,
                            font: navigationMenuFont,
                            ...sx,
                        }}
                    >
                        {includeOffersTab &&
                            <Link
                                activeClass={value === 0 ? "active" : ""}
                                to={"offers"}
                                spy={true}
                                // hashSpy={true} //for include #value to url search params
                                smooth={true}
                                className={"offers"}
                                isDynamic={true}
                                offset={-160}
                                onSetActive={(to, element) => setValue(hasReachBottom ? (includeOffersTab ? categories.length : categories.length - 1) : 0)}
                                onClick={() => { if (handleChange) handleChange("offers"); setValue(0) }}
                            >
                                <Tab
                                    key={0}
                                    value={0}
                                    classes={{ indicator: navigationMenuSelectedOptionFontColor }}
                                    label={t(`offers.label.${menuLang}`)}
                                    style={{
                                        fontSize: navigationMenuFontSize,
                                        borderRadius: navigationMenuStyle === "ROUND" ? "50px" : "10px",
                                        fontWeight: "bold",
                                        margin: "10px 1px",
                                        textTransform: "initial",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        color: value === 0 ? navigationMenuSelectedOptionFontColor : navigationMenuUnSelectedOptionFontColor,
                                        backgroundColor: value === 0 ? backgroundChipColor : "initial",
                                        "&:hover": {
                                            backgroundColor: backgroundChipColor,
                                            color: navigationMenuSelectedOptionFontColor
                                        },
                                        "&:focus": {
                                            backgroundColor: backgroundChipColor,
                                            color: navigationMenuSelectedOptionFontColor,
                                        },
                                        "&:active": {
                                            background: backgroundChipColor,
                                            color: navigationMenuSelectedOptionFontColor,
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: backgroundChipColor,
                                            color: navigationMenuSelectedOptionFontColor
                                        },
                                    }} />
                            </Link>
                        }

                        {categories?.map((category, index) =>
                            <Link
                                activeClass={value === (includeOffersTab ? index + 1 : index) ? "active" : ""}
                                to={category.id}
                                spy={true}
                                // hashSpy={true} //for include #value to url search params
                                smooth={true}
                                className={category.value}
                                isDynamic={true}
                                offset={-160}
                                onSetActive={(to, element) => setValue(includeOffersTab ? index + 1 : index)}
                                onClick={() => { if (handleChange) handleChange(category.id); setValue(includeOffersTab ? index + 1 : index) }}
                            >
                                <Tab
                                    key={includeOffersTab ? index + 1 : index}
                                    value={includeOffersTab ? index + 1 : index}
                                    classes={{ indicator: navigationMenuSelectedOptionFontColor }}
                                    label={category.name}
                                    sx={{
                                        fontSize: navigationMenuFontSize,
                                        borderRadius: navigationMenuStyle === "ROUND" ? "50px" : "10px",
                                        fontWeight: "bold",
                                        margin: "10px 1px",
                                        textTransform: "initial",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        color: value === (includeOffersTab ? index + 1 : index) ? navigationMenuSelectedOptionFontColor : navigationMenuUnSelectedOptionFontColor,
                                        backgroundColor: value === (includeOffersTab ? index + 1 : index) ? backgroundChipColor : "initial",
                                        "&:hover": {
                                            backgroundColor: backgroundChipColor,
                                            color: navigationMenuSelectedOptionFontColor
                                        },
                                        "&:focus": {
                                            backgroundColor: backgroundChipColor,
                                            color: navigationMenuSelectedOptionFontColor,
                                        },
                                        "&:active": {
                                            background: backgroundChipColor,
                                            color: navigationMenuSelectedOptionFontColor,
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: backgroundChipColor,
                                            color: navigationMenuSelectedOptionFontColor
                                        },
                                    }} />
                            </Link>
                        )}
                    </Tabs>
                </Grid>
            }
        </Grid>
    );
}

export default CustomMenuTabsWithChip;