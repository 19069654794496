
import { Grid } from '@mui/material';
import React from 'react';

/**
 * The Custom Grid, that display customized {@link Grid}.
 * 
 * @param {*} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @param {*} children The children of the drawer
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomGrid({ children, sx, spacing = { xs: 0, sm: 0, md: 0 }, role = "main", id, direction = "row", container = true, gap = 1 }) {
    return (
        <Grid container={container} gap={gap} direction={direction} spacing={spacing} sx={{ padding: "20px", ...sx }} role={role} id={id}>
            {children}
        </Grid>
    );
}

export default CustomGrid;