import { Grid, LinearProgress, useMediaQuery } from '@mui/material';
import ratingApi from 'api/rating';
import { LottieNoRatings } from 'assets/lottie/LottieAnimations';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomRating from 'components/Rating/CustomRating';
import CustomRatingCardCustomer from 'components/Rating/CustomRatingCardCustomer';
import CustomTypography from 'components/Typography/CustomTypography';
import { lottieOrderFeedbacksOptions } from 'constants/animations/lottieOptions';
import { MORE_RATING_ADD_SIZE } from 'constants/fieldProperties';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { lumanosimoTitleStyle, pastOrderMoreOrdersButtonStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';

/**
 * The AnalyticsRatings, that display customized {@link Grid}to provide the list of analytics ratings details
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AnalyticsRatings({ outletID }) {
    const isMobile = useMediaQuery('(max-width:468px)');
    const { t } = useTranslation();
    const [countsData, setCountsData] = useState(null);
    const [data, setData] = useState({
        ratings: null,
        pagination: {
            size: 10,
            page: 0
        }

    });
    const [isLoaded, setIsLoaded] = useState(false);
    const [enableMoreButton, setEnableMoreButton] = useState(true);
    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);

    // for search
    const [search, setSearch] = useState({
        organizationID: "",
        outletID: outletID,
        customerID: ""
    });

    function getData() {
        ratingApi.fetchCounts(search).then((r) => {
            setCountsData((r.data.returnobject !== null && r.data.returnobject?.length > 0) ? r.data?.returnobject[0] : null);
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })

        ratingApi.fetchAll(search).then((r) => {
            Promise.resolve()
                .then(() => {
                    if (r.data.code === "SUCCESS") {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            ratings: r.data.returnobject.ratings,
                            pagination: {
                                ...data.pagination,
                                size: r.data.returnobject?.ratings?.size
                            }
                        });
                        if (r.data.returnobject.ratings?.totalPages <= r.data.returnobject.ratings?.number + 1) {
                            setEnableMoreButton(false)
                        }
                    }
                })
                .then(() => {
                    setIsFirstTime(false);
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        });
    }

    useEffect(() => {
        getData();
    }, [search]);


    return (
        <>
            <Grid container sx={{ textAlign: "center", placeContent: "center" }}>
                {(isLoaded && countsData != null) &&
                    <Grid item xs={12} sm={12} sx={{ marginTop: isMobile ? "20px" : "initial" }}>
                        <Grid container gap={2} sx={{ background: "white", padding: "20px", borderRadius: "10px" }}>
                            <Grid item xs={12} am={4} sm={"auto"}>
                                <CustomTypography
                                    variant="span"
                                    text={countsData?.average.toFixed(1) || 0}
                                    sx={{ fontSize: "60px" }}
                                />
                                <CustomRating
                                    iconStyle={countsData?.ratingStyle}
                                    value={countsData?.average?.toFixed(1)}
                                    size={"medium"}
                                    sx={{ alignItems: "center", marginBottom: "10px" }}
                                    readOnly={true}
                                />
                                <CustomTypography
                                    variant="span"
                                    text={`${t('rating.basedOnBefore')} ${countsData?.total} ${t('rating.basedOnAfter')}`}
                                    sx={{ fontSize: "13px", color: "grey" }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={8} sx={{ marginTop: isMobile ? "20px" : "initial" }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <CustomRating
                                                    iconStyle={countsData?.ratingStyle}
                                                    value={5}
                                                    size={"small"}
                                                    readOnly={true}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <CustomTypography
                                                    variant="span"
                                                    text={`(${countsData?.five})`}
                                                    sx={{ fontSize: "14px" }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sx={{ alignSelf: "center" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={(countsData?.five / countsData?.total) * 100}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <CustomRating
                                                    iconStyle={countsData?.ratingStyle}
                                                    value={4}
                                                    size={"small"}
                                                    readOnly={true}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <CustomTypography
                                                    variant="span"
                                                    text={`(${countsData?.four})`}
                                                    sx={{ fontSize: "14px" }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sx={{ alignSelf: "center" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={(countsData?.four / countsData?.total) * 100}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <CustomRating
                                                    iconStyle={countsData?.ratingStyle}
                                                    value={3}
                                                    size={"small"}
                                                    readOnly={true}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <CustomTypography
                                                    variant="span"
                                                    text={`(${countsData?.three})`}
                                                    sx={{ fontSize: "14px" }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sx={{ alignSelf: "center" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={(countsData?.three / countsData?.total) * 100}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <CustomRating
                                                    iconStyle={countsData?.ratingStyle}
                                                    value={2}
                                                    size={"small"}
                                                    readOnly={true}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <CustomTypography
                                                    variant="span"
                                                    text={`(${countsData?.two})`}
                                                    sx={{ fontSize: "14px" }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sx={{ alignSelf: "center" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={(countsData?.two / countsData?.total) * 100}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <CustomRating
                                                    iconStyle={countsData?.ratingStyle}
                                                    value={1}
                                                    size={"small"}
                                                    readOnly={true}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <CustomTypography
                                                    variant="span"
                                                    text={`(${countsData?.one})`}
                                                    sx={{ fontSize: "14px" }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sx={{ alignSelf: "center" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={(countsData?.one / countsData?.total) * 100}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {(isLoaded && data.ratings?.content?.length > 0) &&
                    <Grid item xs={12} sm={8}>
                        <Grid container>
                            {data.ratings?.content?.map((rating) => (
                                <CustomRatingCardCustomer rating={rating} ratingStyle={countsData?.ratingStyle}/>
                            ))}

                            {enableMoreButton &&
                                <Grid item xs={12} md={12}>
                                    <CustomIconButton
                                        sx={pastOrderMoreOrdersButtonStyle()}
                                        onClick={() => formValChangeWithParentElementWithNameAndValue("pagination.size", data.pagination.size + MORE_RATING_ADD_SIZE, data, setData)}
                                        variant="outlined"
                                        label={t('rating.moreRatings')}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                }

                {(!isLoaded || data.ratings?.content?.length === 0) &&
                    <>
                        <div style={{ textAlign: "center" }}>
                            <Lottie
                                animationData={LottieNoRatings}
                                {...lottieOrderFeedbacksOptions}
                            />

                            <CustomTypography
                                variant="body2"
                                text={t('rating.emptyRatings')}
                                sx={{ lumanosimoTitleStyle }}
                            />
                        </div>
                    </>
                }
            </Grid>
        </>
    );
}

export default AnalyticsRatings;