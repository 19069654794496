import CloseRounded from '@mui/icons-material/CloseRounded';
import {
   Button,
   CircularProgress,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   IconButton
} from '@mui/material';
import React, { useEffect, useState } from 'react';

/**
 * The CustomDialogChildren, that display a customized dialog
 * 
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {*} children the body of the dialog
 * @property {*} handleOpen Callback that handles the dialog open state
 * @property {*} handleAction Callback that handles the dialog action state
 * @property {string} cancelLabel the cancel button label
 * @property {string} actionLabel the action button label
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomDialogChildren({
   isOpen = false,
   id = "",
   title,
   message,
   children,
   handleOpen,
   handleAction,
   cancelLabel,
   actionLabel,
   includeCloseIcon = false,
   counter
}) {
   // @property {boolean} if the values is `true`, the modal should be open and visible.
   const [open, setOpen] = useState(isOpen);

   /**
    * Function that handles the visualize modal open or close state.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleDialogState(isOpen) {
      setOpen(isOpen);
      handleOpen(isOpen);
   }


   useEffect(() => {
      setOpen(isOpen)
   }, [isOpen])


   return (
      <Dialog
         id={id}
         open={open}
         onClose={() => { handleDialogState(false) }}
      >
         <DialogTitle>
            {title}
            {includeCloseIcon &&
               <IconButton onClick={() => { handleDialogState(false) }} sx={{ float: "right" }}>
                  <CloseRounded />
               </IconButton>
            }
         </DialogTitle>

         <DialogContent>
            {children}
            <p>{message}</p>
         </DialogContent>

         <DialogActions className="dialog-actions">
            <Button className="dialog-cancel-btn" onClick={() => { handleDialogState(false) }}>{cancelLabel}</Button>
            {(handleAction && counter) &&
               <Button className="dialog-action-btn" onClick={handleAction}>{`${actionLabel.toUpperCase()} (${counter} s)`}<CircularProgress /></Button>
            }
            {(handleAction && !counter) &&
               <Button className="dialog-action-btn" onClick={handleAction}>{`${actionLabel.toUpperCase()}`}</Button>}
         </DialogActions>
      </Dialog>
   );
}

export default CustomDialogChildren;