import { Box, Checkbox, Divider, FormControlLabel, Grid } from '@mui/material';
import ratingApi from 'api/rating';
import { LottieFeedbackSuccess, LottieSuccessConfetti } from 'assets/lottie/LottieAnimations';
import { MessageIcon } from 'assets/mui/MuiIcons';
import CustomDelayedOrHiddenLottieAnimation from 'components/Asset/CustomDelayedOrHiddenLottieAnimation';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomDialogBasedOnScreenChildren from 'components/Dialog/CustomDialogBasedOnScreenChildren';
import { getDialogTypeAnimation } from 'components/Dialog/animation/dialogTypeAnimation';
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeaderChildren from 'components/Header/CustomTopHeaderChildren';
import RatingtLoadingSkeleton from 'components/Loading/RatingtLoadingSkeleton';
import CustomRating from 'components/Rating/CustomRating';
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTypography from 'components/Typography/CustomTypography';
import { lottieOrderRatingOptions, lottieOrderSuccessRatingOptions } from 'constants/animations/lottieOptions';
import { getDefaultRatingObject } from 'constants/defaultObjects';
import { requestTextFieldLimit } from 'constants/textFieldLimit';
import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateOutletSplashConfiguration } from 'store/splash/splashAction';
import { checkoutCommentTextareaBoxInfoCharStyle, checkoutCommentTextareaBoxStyle } from 'themes/checkout/checkoutTheme';
import { mainTag, orderIconStyle, ratingCheckbox, ratingInfoText, ratingMessageTitleTextStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The AddOutletRatingCOmponent, that presents a new outlet's order rating component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AddOutletRatingCOmponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { outletURL } = useParams();
    const params = new URLSearchParams(location.search);
    const ss = params.get("ss");

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        order: {},
        outlet: {},
        rating: getDefaultRatingObject(),
        ratingStyle: "star",
        isAdded: false,
        showSplash: false,
    });
    const [isLoaded, setIsLoaded] = useState(false);
    const [disableSendButton, setDisableSendButton] = useState(true);
    // login
    const [isOpenLogin, setIsOpenLogin] = useState(false);

    // splash
    const dispatch = useDispatch();
    const outletSplashConfiguration = useSelector(state => state.splash[outletURL] || null);
    const [splashCount, setSplashCount] = useState(0);
    const [showSplash, setShowSplash] = useState(true);


    useEffect(() => {
        getRating();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && ss === "1") {
            const interval = setInterval(() => {
                setSplashCount((prevCount) => prevCount + 1);
            }, 1000); // Increment count every second

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ss]);

    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && splashCount >= outletSplashConfiguration?.splashConfiguration.durationInSeconds && showSplash) {
            setShowSplash(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [splashCount]);

    const [dialog, setDialog] = useState({
        isOpen: false,
        type: "success",
        message: ""
    });
    // create a counter to enable the resend code logic after 30sec
    const timerIdRef = useRef(0);
    const [counter, setCounter] = useState(120);

    /**
     * Callback that handles the start of the time interval for the resend code button to be shown on end.
     * @returns nothing
     */
    function startHandler() {
        if (timerIdRef.current) {
            return;
        }
        timerIdRef.current = setInterval(() => setCounter((prevCounter) => (prevCounter === 0) ? stopHandler() : prevCounter - 1), 1000);
    }

    /**
     * Callback that handles the end of the time interval for the resend code button to be shown.
     */
    function stopHandler() {
        clearInterval(timerIdRef.current);
        timerIdRef.current = 0;
        return 0;
    }


    //change document title
    useDocumentTitle(`${t('rating.outlet.pageTitle')}`);

    /**
     * The rest endpoint to get the outlet order rating details.
     */
    function getRating(sendButtonISPressed = true) {
        ratingApi.fetchOutletNew(outletURL).then((r) => {
            if (r.data.code === "SUCCESS" || r.data.code === "PERMISSION")
                Promise.resolve()
                    .then(() => {
                        if (sendButtonISPressed) {
                            if (r.data?.code === "PERMISSION") {
                                setIsOpenLogin(true);
                            } else {
                                setIsOpenLogin(false);
                                setDisableSendButton(true);
                            }
                        }
                    })
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            order: r.data.returnobject.order,
                            outlet: r.data.returnobject.outlet,
                            ratingStyle: r.data.returnobject.ratingStyle,
                            showSplash: r.data.returnobject.ss
                        });
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
                    .then(() => {
                        dispatch(updateOutletSplashConfiguration(
                            outletURL,
                            r.data.returnobject.outlet.settings.splashScreenConfiguration,
                            r.data.returnobject.organization.id,
                            r.data.returnobject.outlet.id,
                            r.data.returnobject.outlet.logo,
                            "menu"
                        ));
                    })
        }).catch((e) => {
            // console.log(e);
        });
    }

    /**
     * The rest endpoint to add the outlet order customer rating.
     */
    function addOrderRating() {
        if (disableSendButton) {
            setCounter(120);
            startHandler();
            handleDialogState(true, "info", t('request.alreadySendRequestMessage'))
        } else {
            setDisableSendButton(true);
            if (data.rating?.message?.length > 100) {
            } else {
                ratingApi.createOutlet(outletURL, data.rating).then((r) => {
                    if (r.data.code === "SUCCESS") {
                        formValChangeWithParentElementWithNameAndValue("isAdded", true, data, setData);
                    }
                }).catch((e) => {
                    // console.log(e);
                });
            }

        }
    }

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} type The type of the dialog 'success', 'fail' or 'info'.
     */
    function handleDialogState(isOpen, type = "success", message = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            type: type,
            message: message
        });
    }
console.log(isLoaded)
    // ===============================================================================RENDER
    if (!isLoaded) {
        return <CustomGrid role="main" id="request" sx={mainTag()}>
            <CustomTopHeaderChildren
                key="th"
                isOpen={isOpenLogin}
                handleLoginCheckout={() => getRating()}
                setIsOpen={setIsOpenLogin}
                pageRequireLoginRegistation={isOpenLogin}
            />
            <RatingtLoadingSkeleton />
        </CustomGrid>
    } else
        return (
            <CustomGrid role="main" id="rating" sx={mainTag()}>
                <CustomTopHeaderChildren
                    key="th"
                    isOpen={isOpenLogin}
                    handleLoginCheckout={() => getRating()}
                    setIsOpen={setIsOpenLogin}
                    pageRequireLoginRegistation={isOpenLogin}
                />

                <Grid container spacing={2} justifyContent={"center"} justifyItems={"center"} sx={{ width: "100%", margin: "0px 20px 10px" }}>
                    {data.isAdded &&
                        <>
                            <Grid item xs={12} md={12} sx={{ textAlign: "-webkit-center" }}>
                                <CustomDelayedOrHiddenLottieAnimation
                                    delay={0}
                                    duration={4700}
                                    lottieTag={
                                        <Lottie
                                            {...lottieOrderRatingOptions}
                                            animationData={LottieSuccessConfetti}
                                        />
                                    }
                                />
                                <CustomDelayedOrHiddenLottieAnimation
                                    delay={4700}
                                    // duration={1000}
                                    lottieTag={
                                        <Lottie
                                            {...lottieOrderSuccessRatingOptions}
                                            animationData={LottieFeedbackSuccess}
                                        />
                                    }
                                />
                                <CustomTypography variant="body2" text={t('rating.outlet.ratingInfo')} sx={ratingInfoText()} />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ textAlign: "center", marginTop: "30px" }}>
                                <CustomIconButton
                                    variant="outlined"
                                    onClick={() => navigate(`/${getLanguageFromURL()}/${outletURL}`)}
                                    label={t('rating.outlet.back')}
                                    sx={{ textTransform: "none" }}
                                />
                            </Grid>
                        </>
                    }

                    {!data.isAdded &&
                        <>
                            <Grid item xs={2} md={1}>
                                <MessageIcon sx={orderIconStyle} />
                            </Grid>

                            <Grid item xs={10} md={3} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={t('rating.outlet.message')} sx={ratingMessageTitleTextStyle()} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomRating
                                    size={"large"}
                                    iconStyle={data.outlet?.ratingStyle}
                                    value={data.rating.starRating}
                                    sx={{ alignItems: "center" }}
                                    labelSize={"40px"}
                                    onChange={(event) => {
                                        formValChangeWithParentElementWithNameAndValue("rating.starRating", event.target.value, data, setData)
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ alignSelf: "center", marginTop: "20px" }}>
                                <CustomTextArea
                                    placeholder={t('checkout.requestPlaceholder')}
                                    value={data.rating.comments}
                                    inputProps={{ maxLength: 100 }}
                                    maxLength={100}
                                    onChange={(event) => {
                                        let newValue = event.target.value.slice(0, 100);
                                        formValChangeWithParentElementWithNameAndValue("rating.message", newValue, data, setData);
                                    }}
                                    variant="outlined"
                                    sx={{ borderColor: "lightgrey", paddingTop: "15px" }}
                                    endDecorator={
                                        <Box sx={checkoutCommentTextareaBoxStyle}>
                                            <Divider flexItem={true} />
                                            <span style={{ color: data.rating?.message?.length === 100 ? "red" : "initial" }}>
                                                {`${data.rating?.message?.length}  `}
                                            </span>
                                            <span style={{ ...checkoutCommentTextareaBoxInfoCharStyle(), color: data.rating?.message?.length === 100 ? "red" : "initial" }}>
                                                {data.rating?.message?.length === 1 ? t('checkout.requestCharLimit') : t('checkout.requestCharsLimit')}
                                            </span>
                                            <span style={{ color: data.rating?.message?.length === 100 ? "red" : "initial" }}>
                                                {` /${requestTextFieldLimit}`}
                                            </span>
                                        </Box>
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ textAlign: "justify", marginTop: "30px" }}>
                                {/* {data.rating.starRating > 3 && */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.rating.consentSentToGoogleReview}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("rating.consentSentToGoogleReview", event.target.checked, data, setData)}
                                            sx={ratingCheckbox()}
                                        />}
                                    label={t('rating.outlet.checkboxInfo')}
                                    labelPlacement="end"
                                    sx={{
                                        '& .MuiFormControlLabel-label': { fontSize: "13px" }
                                    }}
                                />
                                {/* } */}
                            </Grid>

                            <CustomIconButton
                                variant="outlined"
                                onClick={addOrderRating}
                                label={t('rating.outlet.btn')}
                                disabled={disableSendButton}
                                sx={{ textTransform: "none", fontSize: "15px", marginTop: '20px' }}
                            />
                        </>
                    }


                    {dialog.isOpen &&
                        <CustomDialogBasedOnScreenChildren
                            isOpen={dialog.isOpen}
                            id="dialog"
                            // title={t(`request.popup.${dialog.type}`)}
                            actionLabel={dialog.type === "info" ? t("request.btns.Send") : ""}
                            cancelLabel={t("request.btns.ok")}
                            message={dialog.message}
                            action={dialog.type === "info" ? addOrderRating : undefined}
                            handleOpen={() => handleDialogState(true)}
                            handleClose={() => handleDialogState(false, "", "")}
                            actionColor="#e91e1e"
                            counter={counter}
                            children={getDialogTypeAnimation(dialog.type)}
                        />
                    }
                </Grid>
            </CustomGrid>
        );
}

export default AddOutletRatingCOmponent;