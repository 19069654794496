import { Divider, Grid } from "@mui/material";
import orderApi from "api/order";
import outletApi from "api/outlet";
import vivaPaymentApi from "api/vivaPayment";
import { LottieSuccessAnimation } from "assets/lottie/LottieAnimations";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomSinglePastOrderCard from "components/Card/order/CustomSinglePastOrderCard";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeader from "components/Header/CustomTopHeader";
import CustomTypography from "components/Typography/CustomTypography";
import { lottieDefaultOptions } from "constants/animations/lottieOptions";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getOrderSuccessText, lumanosimoTitleStyle, mainTag } from 'themes/defaultThemes';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The SuccessOrderComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SuccessOrderComponent() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { outletURL } = useParams();
    const params = new URLSearchParams(location.search);
    const outletID = params.get("outletID");
    const orderID = params.get("oid");
    const equipmentLabel = params.get("e");
    const tid = params.get("tid");//transaction id
    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        order: null,
        outlet: {}
    });

    useEffect(() => {
        getOutletURL();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the outlet url.
     */
    function getOutletURL() {
        if (outletURL === undefined && outletID !== null) {
            outletApi.fetchSimplified(outletID, getLanguageFromURL()).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            outlet: r.data.returnobject
                        });
                    })
                    .then(() => {
                        vivaPaymentApi.checkForVerifiedPayment(tid, outletID).then((k) => {
                            if (k.data)
                                navigate(`/${getLanguageFromURL()}/checkout/${r.data.returnobject?.url}/success?oid=${orderID}`);
                            else
                                navigate(`/${getLanguageFromURL()}/checkout/${r.data.returnobject?.url}/fail`);
                        }).catch((e) => {
                            // console.log(e);
                        });
                    })
            }).catch((e) => {
                // console.log(e);
            });
        }

        if (orderID !== undefined && orderID !== null && orderID !== "") {
            orderApi.fetchOne(orderID).then((r) => {
                if (r.data.code === "SUCCESS") {
                    setData({
                        ...data,
                        order: {
                            ...r.data.returnobject.order,
                            outlet: r.data.returnobject.outlet
                        },
                        equipment: r.data.returnobject.equipment
                    });
                }
            }).catch((e) => {
                // console.log(e);
            });
        }

    }

    //change document title
    useDocumentTitle(t('order.success.tab'));

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="test2" sx={mainTag()}>
            <CustomTopHeader key="th" outletURL={outletID ? data?.outlet?.url : outletURL} />

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12} md={12} sx={{ textAlign: "-webkit-center" }}>
                            <Lottie
                                {...lottieDefaultOptions}
                                animationData={LottieSuccessAnimation}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomTypography variant="body2" text={t('order.success.infoPayment')} sx={getOrderSuccessText} />
                        </Grid>

                        {data.order?.loyaltyPointsEarned > 0 &&
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="span" text={t('order.success.loyaltyPointsGainBefore')} sx={{ fontSize: "10px", ...lumanosimoTitleStyle() }} />
                                <CustomTypography variant="span" text={` ${data.order?.loyaltyPointsEarned} `} sx={{ fontWeight: "bold", fontSize: "14px" }} />
                                <CustomTypography variant="span" text={t('order.success.loyaltyPointsGainAfter')} sx={{ fontSize: "10px", ...lumanosimoTitleStyle() }} />
                            </Grid>
                        }
                    </Grid>
                </Grid>

                {data.order !== null &&
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Grid item xs={12} md={12} sx={{ marginBottom: "50px", marginTop: "20px" }}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                <CustomTypography variant="span" text={t('order.success.orderNumber')} sx={{ fontSize: "22px" }} />
                                <CustomTypography variant="span" text={`  #${data.order?.orderNumber}`} sx={{ fontWeight: "bold", fontSize: "22px" }} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomSinglePastOrderCard
                                    order={data.order}
                                    outlet={data.order?.outlet}
                                    equipment={data.equipment}
                                    handlePastOrderAddFeedbackClick={() => navigate(`/${getLanguageFromURL()}/order/${orderID}/feedback/add`)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {outletURL !== null &&
                    <Grid item xs={12} md={12}>
                        <CustomIconButton
                            variant="outlined"
                            onClick={() => navigate(`/${getLanguageFromURL()}/${outletURL}/?e=${equipmentLabel}`)}
                            label={t('order.success.back')}
                            sx={{textTransform: "none"}}
                        />
                    </Grid>
                }
            </Grid>
        </CustomGrid>
    );
}

export default SuccessOrderComponent;