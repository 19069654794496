import { Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

/**
 * The OutletActionsLoadingSkeleton, that display a box with animated skeletons untils the outlet actions page is ready to render.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OutletActionsLoadingSkeleton({ }) {

    return (
        <Box sx={{ width: "100%", padding: "10px 40px" }}>
        <Grid container spacing={2} justifyContent={"center"} justifyItems={"center"} sx={{ width: "100%", margin: "0px 20px 10px" }}>
                <Grid item xs={6}>
                    <Skeleton variant="rounded" height={80} />
                </Grid>
                <Grid item xs={6}>
                    <Skeleton variant="rounded" height={80} />
                </Grid>
                <Grid item xs={6}>
                    <Skeleton variant="rounded" height={80} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default OutletActionsLoadingSkeleton;