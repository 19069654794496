import { Facebook as FacebookIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
//import facebookLogo from "../../assets/images/facebookLogo2.png";
//import "../../styles/login/login.css";

const FacebookLoginButton = () => {
  const handleLogin = (response) => {
    // TODO: add login logic here
    response.language = navigator.language.substring(0, 2);
    console.log(response);
  };

  return (
    <FacebookLogin
      render={(renderProps) => (
        <Button
          fullWidth
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#000",
            backgroundColor: "#fff",
            borderRadius: "12px",
            fontWeight: 700,
            textTransform: "none",
            boxShadow: "0px 3px 9px 0px #00000029",
            padding: "10px",
            "&:hover": {
              color: "#000",
              backgroundColor: "#fff",
            },
          }}
          onClick={() => renderProps.onClick()}
        >
          <FacebookIcon style={{ marginRight: "10px" }} /> Log in with Facebook
        </Button>
      )}
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      fields={"name, email, picture, birthday, gender, timezone"}
      // scope="public_profile,user_birthday"
      callback={handleLogin}
      //cssClass='my-facebook-login-button'
      //icon={facebookLogo}

      redirectUri='http://localhost:3000/en'
    />
  );
};

export default FacebookLoginButton;
