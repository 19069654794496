export const topHeaderBox = () => {
    return {
        flexGrow: 1,
        // marginTop: "75px",
        background: "white"
    };
}

export const topHeaderBoxAppBar = () => {
    return {
        background: "white",
        height: "65px",
        alignItems: "right",
        width: "100%"
    };
}

export const topHeaderBoxAppBarToolbar = () => {
    return {
        height: "64px",
        // right: "0",
        // position: "absolute",
        width: "100%",
        padding: "0!important"
    };
}

export const topHeaderBoxAppBarToolbarAlert = () => {
    return {
        right: 0,
        paddingRight: "10px"
    };
}

export const topHeaderBoxAppBarToolbarAlertLink = () => {
    return {
        marginLeft: "10px"
    };
}

export const topHeaderBoxAppBarToolbarBox = () => {
    return {
        flexGrow: 1,
        alignSelf: 'flex-end',
        textAlign: "right",
        alignSelf: "center"
        // position: "absolute",
        // right: 0
    };
}

export const topHeaderBoxAppBarToolbarBoxMenu = () => {
    return {
        '& .MuiPaper-root': {
            left: "auto!important",
            right: "0!important",
            width: "fit-content"
        }
    };
}