import { removeTrailingCommaSpace } from "utils/functions";

export function constructOutletAdress(addressDetails) {
    let address = "";
    if (addressDetails?.address !== null) {
        address += addressDetails.address + " "
    }
    if (addressDetails?.city !== null) {
        address += addressDetails.city + ", "
    }
    if (addressDetails?.postalCode !== null) {
        address += addressDetails.postalCode + ", "
    }
    if (addressDetails?.state !== null) {
        address += addressDetails.state + ", "
    }
    if (addressDetails?.country !== null) {
        address += addressDetails.country.name
    }

    return removeTrailingCommaSpace(address);
}

export default class locations {
    static constructOutletAdress(addressDetails) { return constructOutletAdress(addressDetails); }
}