import {
    Grid
} from '@mui/material';
import { InfoIcon, OutletInfoIcon } from 'assets/mui/MuiIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { outletDetailsIconStyle, outletDetailsTextStyle } from 'themes/defaultThemes';

/**
 * The CustomOutletInformationCard, that display outlet info.
 * 
 * @param {fucntion} handleOutletInfoClick Callback that handles the outlet info dialog to open.
 *
 * @returns {JSX.Element} A React component that renders outlet info.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomOutletInformationCard({ handleOutletInfoClick }) {
    const { t } = useTranslation();

    return (
        <Grid item xs={12} md={12} onClick={handleOutletInfoClick}>
            <Grid container sx={{ textAlign: "left" }}>
                <Grid item xs={2} md={2}>
                    <InfoIcon sx={outletDetailsIconStyle} />
                </Grid>

                <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                    <CustomTypography variant="body2" text={t('outlet.outletInformation')} sx={outletDetailsTextStyle()} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CustomOutletInformationCard;