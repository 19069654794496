
import { FormHelperText } from '@mui/material';
import React from 'react';
import Textarea from '@mui/joy/Textarea';

/**
 * The Custom Text Field, that display customized {@link Textarea}.
 * 
 * @param {string} id the text field id
 * @param {string} name the text field name
 * @param {string} type the text field type
 * @param {string} className the text field class
 * @param {string} defaultValue the text field default value
 * @param {string} placeholder the text field placeholder
 * @param {string} helperText the text field helper text
 * @param {string} variant The variant to use. @default 'outlined'
 * @param {string} country the text field language code
 * @param {string} flag the text field language flag
 * @param {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @param {boolean} error if text field is at an error state
 * @param {function} onChange function that triggered when value is changed
 * 
 * @returns {JSX.Element} A React component that renders a text area.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTextArea({ id = "", name = "", error, className, defaultValue, value, placeholder, helperText, variant, onChange, sx, endDecorator, inputProps, maxLength }) {

    return (
        <>
            <Textarea
                // variant={variant}
                id={id}
                minRows={2}
                name={name}
                className={className}
                error={error}
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
                inputprops={inputProps}
                maxLength={maxLength}
                style={{
                    padding: "11px",
                    height: "auto",
                    ...sx
                }}
                endDecorator={endDecorator}
                onChange={onChange}
            />

            <FormHelperText>{helperText}</FormHelperText>
        </>
    );
}

export default CustomTextArea;