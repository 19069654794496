import {
    CardMedia,
    Grid,
    ListItem
} from '@mui/material';
import CustomTypography from 'components/Typography/CustomTypography';
import { menuItemPhotoDivStyle, menuParentCategoryDescriptionHeaderTextStyle, menuParentCategoryHeaderTextStyle, menuPhotoDivStyle } from 'themes/defaultThemes';
import { getMenuPhoto } from 'utils/functions/assets';

/**
 * The CustomMenuInstance, that display each menu item.
 * 
 * @param {object} item the menu item details
 * @param {string} priceColor the color for price to have
 * @param {string} lang the lang code
 * @param {fucntion} handleItemDialogState Callback that handles the open item details dialog for more details and to fill in fields to add one or more to cart.
 *
 * @returns {JSX.Element} A React component that renders a menu item.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuInstance({
    organizationID,
    menu,
    handleMenuClick,
    selectedLayout = "LIST"
}) {
    let menuPhoto = null;
    if (menu.settings?.photos?.length > 0) {
        if (menu.settings.photos.find((photo) => photo.cover === true)?.imageSrc) {
            menuPhoto = menu.settings.photos.find((photo) => photo.cover === true)?.imageSrc
            if (menuPhoto === null) {
                menuPhoto = menu.settings.photos[0]?.imageSrc
            }
        }
    }

    return (
        <Grid item xs={selectedLayout === "LIST" ? 12 : 6}>
            <ListItem onClick={() => handleMenuClick(true, menu.id)}
                sx={{
                    width: selectedLayout.includes("LIST") ? "inherit" : "-webkit-fill-available",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    minHeight: "50px",
                    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                    background: menu.settings?.menuBackgroundColor
                }}
            >
                <Grid container >
                    {selectedLayout === "LIST" &&
                        <>
                            <Grid item xs={7} md={7}>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <CustomTypography variant="body2" text={menu.name} sx={menuParentCategoryHeaderTextStyle({ fontSize: menu.settings?.menuHeaderFontSize, color: menu.settings?.menuHeaderColor })} />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        {menu.description?.length > 0 &&
                                            <CustomTypography variant="body2" text={menu.description} sx={menuParentCategoryDescriptionHeaderTextStyle({ fontSize: menu.settings?.menuDescFontSize, color: menu.settings?.menuDescColor })} />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                            {menuPhoto !== null &&
                                <Grid item xs={5} md={5}>
                                    <CardMedia
                                        sx={menuItemPhotoDivStyle(menu.settings?.menuPhotoStyle)}
                                        image={getMenuPhoto(organizationID, menu.id, menuPhoto)}
                                    />
                                </Grid>
                            }
                        </>
                    }

                    {selectedLayout === "GRID" &&
                        <>
                            <Grid item xs={12} md={12}>
                                {menuPhoto !== null &&
                                    <CardMedia
                                        sx={menuPhotoDivStyle(menu.settings?.menuPhotoStyle)}
                                        image={getMenuPhoto(organizationID, menu.id, menuPhoto)}
                                    />
                                }
                                {/* {menu.settings?.photos?.length === 0 &&
                                    <CardMedia
                                        sx={menuPhotoDivStyle(menu.settings?.menuPhotoStyle, false)}
                                    />
                                } */}
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                <CustomTypography variant="body2" text={menu.name} sx={menuParentCategoryHeaderTextStyle({ fontSize: menu.settings?.menuHeaderFontSize, color: menu.settings?.menuHeaderColor, textAlign: "-webkit-center" })} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                {menu.description?.length > 0 &&
                                    <CustomTypography variant="body2" text={menu.description} sx={menuParentCategoryDescriptionHeaderTextStyle({ fontSize: menu.settings?.menuDescFontSize, color: menu.settings?.menuDescColor })} />
                                }
                            </Grid>
                        </>
                    }
                </Grid>
            </ListItem>
        </Grid>
    );
}

export default CustomMenuInstance;