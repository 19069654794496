import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';

/**
 * The rest request which attempts to fetch outlet actions.
 * 
 * @param {*} outletUrl The outlet url to find the allowed button actions
 * @returns an object refering to the success or failure of the request
 */
export async function fetchActions(outletUrl, equipmentLabel, isCustomer = false, ss = false) {
    let data = {
        isCustomer: isCustomer,
        ss: ss
    };
    if (outletUrl) {
        data = { ...data, outletUrl: outletUrl }
    }
    if (equipmentLabel) {
        data = { ...data, equipmentLabel: equipmentLabel }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}outlet/actions`, data));
}

/**
 * The rest request which attempts to fetch outlet wifi configuration.
 * 
 * @param {*} outletUrl The outlet url to find the allowed button actions
 * @returns an object refering to the success or failure of the request
 */
export async function fetchWifiConfiguration(outletUrl, isCustomer = false) {
    let data = {
        isCustomer: isCustomer
    };
    if (outletUrl) {
        data = { ...data, outletUrl: outletUrl }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}outlet/wifi`, data));
}

/**
 * The rest request which attempts to fetch outlet info.
 * 
 * @param {*} outletUrl The outlet url to find the outlet extra info
 * @returns an object refering to the success or failure of the request
 */
export async function fetchInfo(outletUrl) {
    let data = {
        lang: getLanguageFromURL()
    };

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}display/outlet/${outletUrl}`, data));
}

/**
 * The rest request which attempts to fetch outlet basic info.
 * 
 * @param {*} outletID The outlet id
 * @returns an object refering to the success or failure of the request
 */
export async function fetchSimplified(outletID, lang) {
    return await request.get(includeParamsToUrl(`${API}display/outlet/${outletID}/check`));
}

/**
 * The rest request which attempts to fetch outlet menu languages details.
 * 
 * @param {*} outletID The outlet id
 * @returns an object refering to the success or failure of the request
 */
export async function fetchOutletMenuLanguages(outletID) {
    return await request.get(includeParamsToUrl(`${API}display/outlet/${outletID}/languages`));
}

const outletApi = {
    fetchActions,
    fetchWifiConfiguration,
    fetchInfo,
    fetchSimplified,
    fetchOutletMenuLanguages
}

export default outletApi;