import EuroIcon from '@mui/icons-material/Euro';
import {
    CardMedia,
    Grid,
    ListItem
} from '@mui/material';
import CustomChip from 'components/Chip/CustomChip';
import CustomTypography from 'components/Typography/CustomTypography';
import CustomTypographyWithIcon from 'components/Typography/CustomTypographyWithIcon';
import { menuItemDescriptionHeaderTextStyle, menuItemHeaderTextStyle, menuItemPhotoDivStyle, menuItemPriceHeaderTextStyle, menuItemPriceIconStyle } from 'themes/defaultThemes';
import { findOfferCoverPhoto, replaceLastTwoChars } from 'utils/functions';
import { getOfferPhoto } from 'utils/functions/assets';

/**
 * The CustomMenuItem, that display each menu offer.
 * 
 * @param {object} item the menu item details
 * @param {string} priceColor the color for price to have
 * @param {string} lang the lang code
 * @param {fucntion} handleItemDialogState Callback that handles the open item details dialog for more details and to fill in fields to add one or more to cart.
 *
 * @returns {JSX.Element} A React component that renders a menu offer.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuOffer({
    organizationID,
    offer,
    handleOfferDialogState,
    iconPositionStart = false,
    menuConfiguration,
    selectedLayout = "LIST"
}) {
    let offerCoverPhoto = findOfferCoverPhoto(offer);

    return (
        <ListItem onClick={() => offer.available ? handleOfferDialogState(true, offer) : undefined}
            sx={{ width: selectedLayout.includes("LIST") ? "inherit" : 170, marginBottom: "10px", background: menuConfiguration?.itemBackgroundColor, opacity: (offer.available ? 1 : 0.4) }}
        >
            <Grid container>
                {selectedLayout === "LIST" &&
                    <>
                        <Grid item xs={7} md={7}>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={offer.name} sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })} />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    {offer.description?.length > 0 &&
                                        <CustomTypography variant="body2" text={offer.description} sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemDescFontSize, color: menuConfiguration?.itemDescColor })} />
                                    }
                                </Grid>

                                {(offer?.price != null && offer?.price > 0) &&
                                    <Grid item xs={12} md={12}>
                                        <CustomTypographyWithIcon
                                            variant="body2"
                                            text={offer.price}
                                            sx={menuItemPriceHeaderTextStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })}
                                            icon={<EuroIcon sx={menuItemPriceIconStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })} />}
                                            iconPositionStart={iconPositionStart}
                                        />
                                    </Grid>
                                }

                                {(offer?.price != null && offer?.price > 0) &&
                                    <Grid item xs={12} md={12}>
                                        <CustomChip
                                            label={"1 + 1"}
                                            sx={{
                                                background: replaceLastTwoChars(menuConfiguration.chipTextColor, "1c"),
                                                color: menuConfiguration.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                                            }} />
                                    </Grid>
                                }

                                {offer?.percentage > 0 &&
                                    <Grid item xs={12} md={12}>
                                        <CustomChip
                                            // label={}
                                            sx={{
                                                background: replaceLastTwoChars(menuConfiguration.chipTextColor, "1c"),
                                                color: menuConfiguration.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                                            }} />
                                    </Grid>
                                }

                                {(offer?.percentage != null && offer?.percentage > 0) &&
                                    <Grid item xs={12} md={12}>
                                        <CustomTypographyWithIcon
                                            variant="body2"
                                            text={offer.percentage}
                                            sx={menuItemPriceHeaderTextStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })}
                                            icon={<EuroIcon sx={menuItemPriceIconStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })} />}
                                            iconPositionStart={iconPositionStart}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        {offerCoverPhoto !== null && offerCoverPhoto != '' &&
                            <Grid item xs={5} md={5}>
                                <CardMedia
                                    sx={{ ...menuItemPhotoDivStyle(menuConfiguration?.itemPhotoStyle), opacity: (offer.available ? 1 : "0.3") }}
                                    image={getOfferPhoto(organizationID, offer.id, offerCoverPhoto)}
                                />
                            </Grid>
                        }
                    </>
                }

                {selectedLayout === "GRID" &&
                    <>
                        <Grid item xs={12} md={12}>
                            <CardMedia
                                sx={{ ...menuItemPhotoDivStyle(menuConfiguration?.itemPhotoStyle), opacity: (offer.available ? 1 : "0.3") }}
                                image={offerCoverPhoto !== null && offerCoverPhoto != '' ? getOfferPhoto(organizationID, offer.id, offerCoverPhoto) : undefined}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                            <CustomTypography variant="body2" text={offer.name} sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            {offer.description?.length > 0 &&
                                <CustomTypography variant="body2" text={offer.description} sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemDescFontSize, color: menuConfiguration?.itemDescColor })} />
                            }
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomTypographyWithIcon
                                variant="body2"
                                text={offer.price}
                                sx={menuItemPriceHeaderTextStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })}
                                icon={<EuroIcon sx={menuItemPriceIconStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })} />}
                                iconPositionStart={iconPositionStart}
                            />
                        </Grid>
                    </>
                }
            </Grid>
        </ListItem>
    );
}

export default CustomMenuOffer;