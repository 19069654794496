import React from "react";
import CustomGrid from 'components/Grid/CustomGrid';
import { loyaltyEmptyInfoTextStyle, loyaltyTitleStyle, mainTag } from 'themes/defaultThemes';
import useDocumentTitle from "utils/useDocumentTitle";
import CustomLoyaltyCard from "components/Card/loyalty/CustomLoyaltyCard";
import { useTranslation } from "react-i18next";
import loyaltyApi from "api/loyalty";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { getLanguageFromURL } from "utils/language";
import { Grid, InputLabel, Stack } from "@mui/material";
import { useEffect } from "react";
import CustomTopHeader from "components/Header/CustomTopHeader";
import { lottieLoyaltyOptions } from "constants/animations/lottieOptions";
import { LottieLoyalty } from "assets/lottie/LottieAnimations";
import Lottie from "lottie-react";
import WebFont from "webfontloader";
import { formValChangeWithParentElementWithNameAndValue } from "utils/functions";
import { SearchIcon } from "assets/mui/MuiIcons";
import CustomTextFieldWithIcons from "components/Text/CustomTextFieldWithIcons";

/**
 * The LoyaltyComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function LoyaltyComponent() {
    const { t } = useTranslation();
    const { outletURL } = useParams();

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        loyalties: [],
        userFullName: ""
    });

    const [isLoaded, setIsLoaded] = useState(false);

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);

    // for search
    const [search, setSearch] = useState({
        outletUrl: outletURL,
        organizationName: null
    });

    useEffect(() => {
        getLoyalties();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getLoyalties();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
    }, [search.organizationName])

    /**
     * The rest endpoint to get the outlet loyalties.
     */
    function getLoyalties() {
        let data = {
            ...search,
            lang: getLanguageFromURL()
        }
        loyaltyApi.fetchAll(data).then((r) => {
            Promise.resolve()
                .then(() => {
                    if (r.data.code === "SUCCESS") {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            loyalties: r.data.returnobject.loyalties,
                            userFullName: r.data.returnobject.userFullName
                        });
                    }
                })
                .then(() => {
                    setIsFirstTime(false);
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        });
    }

    //change document title
    useDocumentTitle(t("loyalty.label"));

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="test2" sx={mainTag()}>
            <CustomTopHeader key="th" outletURL={outletURL} />

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} md={12}>
                            <InputLabel sx={loyaltyTitleStyle()}>{t('loyalty.label')}</InputLabel>
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ margin: "0 10px" }}>
                            <CustomTextFieldWithIcons
                                type="text"
                                name="organizationName"
                                id="organizationName"
                                label={t('loyalty.organizationName')}
                                required={false}
                                defaultValue={search.organizationName}
                                endIcon={<SearchIcon />}
                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("organizationName", event.target.value, search, setSearch)}
                            />
                        </Grid>

                        <Grid item>
                            <Stack direction="row" sx={{ padding: "10px", alignItems: "center" }}>
                                <Grid container gap={2}>
                                    {data.loyalties?.map((loyalty) => (
                                        <CustomLoyaltyCard
                                            cardProperties={loyalty.loyaltyConfiguration.cardProperties}
                                            category={loyalty.loyaltyCategory}
                                            logoImgSrc={loyalty.logoImgSrc}
                                            organizationID={loyalty.loyaltyCategory.organizationID}
                                            userFullName={data.userFullName}
                                        />
                                    ))}

                                    {data.loyalties?.length === 0 &&
                                        <div style={{ textAlign: "center" }}>
                                            <Lottie
                                                animationData={LottieLoyalty}
                                                {...lottieLoyaltyOptions}
                                            />

                                            <InputLabel sx={loyaltyEmptyInfoTextStyle()}>{search.organizationName && search.organizationName !== null && search.organizationName !== "" ? t('loyalty.emptyOrganization') : t('loyalty.empty')}</InputLabel>
                                        </div>
                                    }
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomGrid>
    );
}

export default LoyaltyComponent;