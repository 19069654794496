import { useRef, useEffect } from 'react'

function useDocumentBodyClass(bodyClass, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.body.className);

  useEffect(() => {
    document.body.className = bodyClass;
  }, [bodyClass]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.body.className = defaultTitle.current;
    }
  }, [prevailOnUnmount])
}

export default useDocumentBodyClass