import { useState, useEffect } from 'react';

function CustomDelayedOrHiddenLottieAnimation({ delay, duration, lottieTag }) {
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            setShowAnimation(true);
        }, delay);

        const hideTimer = setTimeout(() => {
            setShowAnimation(false);
        }, delay + duration);

        return () => {
            clearTimeout(delayTimer);
            clearTimeout(hideTimer);
        };
    }, [delay, duration]);

    return showAnimation ? lottieTag : null;
}

export default CustomDelayedOrHiddenLottieAnimation;