export const lottieDefaultOptions = {
    loop: false,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "500px",
    },
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export const lottieLoyaltyOptions = {
    loop: true,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "400px",
        marginTop: "-120px"
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieCouponOptions = {
    loop: true,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "400px",
        marginTop: "-20px"
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieOrderOptions = {
    loop: true,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "400px",
        marginTop: "-120px"
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieOrderRatingOptions = {
    loop: false,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "300px",
        duration: 6000, 
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieScannerOptions = {
    loop: true,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "300px",
        duration: 6000, 
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieOrderSuccessRatingOptions = {
    loop: false,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "300px",
        // duration: 4000, 
    },
    initialSegment: [0, 45],
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieOrderFeedbacksOptions = {
    loop: false,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "360px",
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieDeleteAccountOptions = {
    loop: false,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "360px",
    },
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
