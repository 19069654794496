
export function getDocumentTitle(outlet, defaultTitle) {
    if(outlet !== null) {
        return outlet.name;
    }
    return defaultTitle;
}

export default class functions {
    static getDocumentTitle(outlet, defaultTitle) { return getDocumentTitle(outlet, defaultTitle); };
}