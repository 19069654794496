import EN from "./languages/en";
import EL from "./languages/el";

export const resources = {
  en: {
    translation: EN
  },
  el: {
    translation: EL
  }
}
