export const outletSectionCard = () => {
    return {
        maxWidth: "99%",
        marginLeft: "1.5%",
        marginTop: "10px",
        marginRight: "1.5%"
    };
}

export const outletSectionCardIcon = () => {
    return {
        position: "absolute",
        right: "30px",
        paddingTop: "10px"
    };
}

export const outletBackBtnIcon = () => {
    return {
        position: "absolute",
        left: "10px",
        padding: "5px",
        background: "black",
        fill: "white",
        borderRadius: "20px",
        width: "20px"
    };
}

export const outletSectionCardMedia = () => {
    return {
        height: 340,
        backgroundPosition: "initial"
    };
}

export const outletSectionCardStack = () => {
    return {
        justifyContent: "left",
        alignItems: "center"
    };
}

export const outletSectionCardStackTypography = () => {
    return {
        fontWeight: "bold",
        fontSize: "22px",
        marginRight: "10px",
        width: "fit-content",
        textAlign: "left",
    };
}

export const outletSectionCardStackIcon = () => {
    return {
        color: "orange",
        placeItems: "center",
        width: 20
    };
}
