import React from "react";
import { useTranslation } from "react-i18next";
import logo from 'assets/images/logo.png';
import useDocumentBodyClass from "utils/useDocumentBodyClass";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Error Page 403, that displays a page not found message.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Page403() {
    const { t } = useTranslation();

    //change body class
    useDocumentBodyClass('no-touch bg-usee error')
    //change document title
    useDocumentTitle('Usee | Error')

    return (
        <div id="layout-container">
            <div className="layout-panel">
                <div id="logo" onClick={() => { window.location.href = '/'; }}><img alt="" src={logo} /></div>
                <h1>{t('errorPages.accessIsForbidden')}</h1>
            </div>
        </div>
    );
}

export default Page403;