import {
    Grid
} from '@mui/material';
import { LanguageIcon, NavigateNextIcon } from 'assets/mui/MuiIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { languageIconStyle, languageTextStyle } from 'themes/defaultThemes';
import { getFlagIcon } from 'utils/functions/language';


/**
 * The CustomLanguageCard, that display customer current language.
 * 
 * @param {object} lang the current customer language
 * @param {fucntion} handleLanguageClick Callback that handles the language update dialog to open.
 *
 * @returns {JSX.Element} A React component that renders customer current language.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomLanguageCard({ lang = "en", handleLanguageClick }) {
    const { t } = useTranslation();

    return (
        <Grid item xs={12} md={12} onClick={handleLanguageClick}>
            <Grid container sx={{ textAlign: "left" }}>
                <Grid item xs={2} md={2}>
                    <LanguageIcon sx={languageIconStyle} />
                </Grid>

                <Grid item xs={4} md={4} sx={{ alignSelf: "center", marginTop: "-5px" }}>
                    <CustomTypography variant="body2" text={`${t('settings.language')}`} sx={languageTextStyle()} />
                </Grid>

                <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                    <img src={getFlagIcon(lang)} width="30" alt={lang} style={{ placeSelf: "center", marginRight: "10px" }} />
                </Grid>
                
                <Grid item xs={2} md={2} sx={{ alignSelf: "center", marginTop: "-5px" }}>
                    <CustomTypography variant="body2" text={`(${lang.toUpperCase()})`} sx={languageTextStyle()} />
                </Grid>

                <Grid item xs={2} md={2}>
                    <NavigateNextIcon sx={languageIconStyle} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CustomLanguageCard;