import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

/**
 * The RatingtLoadingSkeleton, that display a box with animated skeletons 
 * untils the rating page is ready to render.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function RatingtLoadingSkeleton() {

    return (
        <Box sx={{ width: "100%", padding: "10px 40px", textAlign: "-webkit-center" }}>
            <Skeleton variant="rounded" width={"100%"} height={50} sx={{ fontSize: "17px", marginTop: "20px", marginBottom: "20px", marginleft: "40%" }} />

            {/* Skeleton for rating star */}
            <Skeleton variant="rounded" width={150} height={50} sx={{ fontSize: "17px", marginTop: "20px", marginBottom: "20px", marginleft: "40%" }} />

            {/* Skeleton for message */}
            <Skeleton variant="rounded" width={"100%"} height={200} sx={{ fontSize: "17px", marginTop: "40px", marginBottom: "20px", marginleft: "40%" }} />

            {/* Skeleton for send button */}
            <Skeleton variant="rounded" width={100} height={50} sx={{ borderRadius: "40px", marginTop: "60px" }} />
        </Box>
    );
}

export default RatingtLoadingSkeleton;