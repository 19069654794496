import React from "react";
import CustomGrid from 'components/Grid/CustomGrid';
import { orderEmptyInfoTextStyle, orderTitleStyle, mainTag, pastOrderMoreOrdersButtonStyle } from 'themes/defaultThemes';
import useDocumentTitle from "utils/useDocumentTitle";
import { useTranslation } from "react-i18next";
import orderApi from "api/order";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { getLanguageFromURL } from "utils/language";
import { Grid, InputLabel, Stack } from "@mui/material";
import { useEffect } from "react";
import CustomTopHeader from "components/Header/CustomTopHeader";
import { lottieOrderOptions } from "constants/animations/lottieOptions";
import { NoOrders } from "assets/lottie/LottieAnimations";
import Lottie from "lottie-react";
import CustomPastOrderCard from "components/Card/order/CustomPastOrderCard";
import CustomIconButton from "components/Button/CustomIconButton";
import { MORE_ORDER_ADD_SIZE } from "constants/fieldProperties";
import { formValChangeWithParentElementWithNameAndValue } from "utils/functions";

/**
 * The OrdersComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OrdersComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { outletURL } = useParams();

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        orders: [],
        userFullName: "",
        pagination: {
            size: 10,
            page: 0
        }
    });

    const [enableMoreButton, setEnableMoreButton] = useState(true);

    // for search
    const [search, setSearch] = useState({
        outletUrl: outletURL,
        lang: "en"
    });

    useEffect(() => {
        getOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pagination.size]);

    /**
     * The rest endpoint to get the outlet orders.
     */
    function getOrders() {
        let requestData = {
            ...search,
            lang: getLanguageFromURL()
        }
        orderApi.fetchAll(requestData, data.pagination).then((r) => {
            if (r.data.code === "SUCCESS") {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    orders: r.data.returnobject.orders,
                    pagination: {
                        ...data.pagination,
                        size: r.data.returnobject.orders.size
                    }
                });

                if (r.data.returnobject.orders.totalPages <= r.data.returnobject.orders.number + 1) {
                    setEnableMoreButton(false)
                }
            }
        }).catch((e) => {
            // console.log(e);
        });
    }

    //change document title
    useDocumentTitle(t("order.label"));

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="test2" sx={mainTag()}>
            <CustomTopHeader key="th" outletURL={outletURL} />

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} md={12}>
                            <InputLabel sx={orderTitleStyle()}>{t('order.label')}</InputLabel>
                        </Grid>

                        <Grid item>
                            <Stack direction="row" sx={{ padding: "10px", alignItems: "center" }}>
                                <Grid container gap={1}>
                                    {data.orders?.content?.map((order) =>
                                        <CustomPastOrderCard
                                            order={order}
                                            handlePastOrderClick={() => navigate(order.id)}
                                            handlePastOrderPayClick={() => navigate(`/${getLanguageFromURL()}/pay/order/${order.id}`)}
                                            handlePastOrderAddFeedbackClick={() => navigate(`${order.id}/feedback/add`)}
                                            userAuth={data.userAuth}
                                        />
                                    )}

                                    {enableMoreButton &&
                                        <Grid item xs={12} md={12}>
                                            <CustomIconButton
                                                sx={pastOrderMoreOrdersButtonStyle()}
                                                onClick={() => formValChangeWithParentElementWithNameAndValue("pagination.size", data.pagination.size + MORE_ORDER_ADD_SIZE, data, setData)}
                                                variant="outlined"
                                                label={t('order.moreOrders')}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12} md={12}>
                                        {data.orders === null || data.orders?.content?.length === 0 &&
                                            <div style={{ textAlign: "center" }}>
                                                <Lottie
                                                    animationData={NoOrders}
                                                    {...lottieOrderOptions}
                                                />

                                                <InputLabel sx={orderEmptyInfoTextStyle()}>{t('order.empty')}</InputLabel>
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomGrid>
    );
}

export default OrdersComponent;