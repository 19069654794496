import {
    orderBackBtnStyle,
    orderCartItemQuantityTextStyle,
    orderCartTitleTextStyle,
    orderCommentTextStyle,
    orderDiscountTextStyle,
    orderDiscountTextsStyle,
    orderDiscountValueTextStyle,
    orderFontAwesomeIconStyle,
    orderHeaderTextStyle,
    orderIconStyle,
    orderItemFontAwesomeIconStyle,
    orderItemHeaderTextStyle,
    orderItemMuiIconStyle,
    orderItemNoteTextStyle,
    orderItemPriceTextStyle,
    orderItemTaxPriceTextStyle,
    orderItemTaxTextStyle,
    orderItemTotalPriceTextStyle,
    orderItemTotalTextStyle,
    orderReviewGridStyle,
    orderTextStyle
} from "themes/defaultThemes"

export const cartBackBtnStyle = () => {
    return orderBackBtnStyle()
}

export const cartIconStyle = () => {
    return orderIconStyle()
}

export const cartFontAwesomeIconStyle = () => {
    return orderFontAwesomeIconStyle()
}

export const cartHeaderTextStyle = () => {
    return orderHeaderTextStyle()
}

export const cartTextStyle = () => {
    return orderTextStyle()
}

export const cartCartTitleTextStyle = () => {
    return {
        ...orderCartTitleTextStyle(),
        textAlign: "left"
    }
}

export const cartReviewGridStyle = () => {
    return orderReviewGridStyle()
}

export const cartCartItemQuantityTextStyle = () => {
    return orderCartItemQuantityTextStyle()
}

export const cartCommentTextStyle = () => {
    return orderCommentTextStyle()
}

export const cartItemHeaderTextStyle = () => {
    return orderItemHeaderTextStyle()
}
export const cartItemNoteTextStyle = () => {
    return orderItemNoteTextStyle()
}
export const cartItemTaxTextStyle = () => {
    return orderItemTaxTextStyle()
}

export const cartItemTaxPriceTextStyle = () => {
    return orderItemTaxPriceTextStyle()
}

export const cartItemTotalTextStyle = () => {
    return orderItemTotalTextStyle()
}

export const cartItemTotalPriceTextStyle = () => {
    return orderItemTotalPriceTextStyle()
}

export const cartDiscountTextStyle = () => {
    return orderDiscountTextStyle()
}

export const cartDiscountValueTextStyle = () => {
    return orderDiscountValueTextStyle()
}

export const cartItemMuiIconStyle = () => {
    return orderItemMuiIconStyle()
}

export const cartDiscountTextsStyle = () => {
    return orderDiscountTextsStyle()
}

export const cartItemFontAwesomeIconStyle = () => {
    return orderItemFontAwesomeIconStyle()
}

export const cartItemPriceTextStyle = () => {
    return orderItemPriceTextStyle()
}
