import { request } from 'constants/alias';
import { API } from "constants/config";

/**
 * The rest request which attempts to fetch the outlet checkout page details.
 * 
 * @param outletUrl the entered outlet label
 * 
 * @returns an object with an attribute containing the checkout page details
 */
export async function fetchCheckoutPage(outletUrl, lang = "en", loyaltyCatgory = "", equipmentLabel = "") {
    let data = {};
    if (lang !== undefined && lang !== "") {
        data = { ...data, lang: lang }
    }
    if (loyaltyCatgory !== "") {
        data = { ...data, loyaltyCatgory: loyaltyCatgory }
    }
    if (equipmentLabel !== "") {
        data = { ...data, equipmentLabel: equipmentLabel }
    }

    return await request.get(`${API}display/checkout/${outletUrl}`, {params: data});
}


const checkoutApi = {
    fetchCheckoutPage
}

export default checkoutApi;