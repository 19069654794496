import React from 'react';
import { Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { dividerStyle, outletInfoHeaderTextStyle, outletInfoTextStyle } from 'themes/defaultThemes';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
// import { findTimeZone } from 'utils/functions/calendar';

function BusinessHours({ outlet }) {
    const { t } = useTranslation();
    const { workingHours, timeFormat, hoursOutput, displayMode } = outlet;
    // const timezone = findTimeZone(workingHours?.timezone);
    // const offsetTimezoneInMilliseconds = timezone?.substring(1, 3) * 60 * 60 * 1000; // +XX:00 in milliseconds
    const is12HourFormat = timeFormat === "TWELVE_HOURS";
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    function createHoursArray() {
        const hoursArray = [];

        daysOfWeek.forEach(day => {
            const dayHours = workingHours?.hours[day];
            if (dayHours && dayHours.length > 0) {
                let values = dayHours?.map((hour) => {
                    if (hour?.open) {
                        const startTimeStr = hour?.start;
                        const endTimeStr = hour?.end;
                        // console.log(startTimeStr,
                            // new Date(`${startTimeStr}`),
                            // new Date(`${startTimeStr?.substring(0, startTimeStr?.length - 1)}`),
                            // new Date(`${startTimeStr?.substring(0, startTimeStr?.length - 1)}+00:00`),
                            // new Date(`${startTimeStr?.substring(0, startTimeStr?.length - 1)}${timezone}`), timezone)
                        // TODO: fix it
                        const startTime = startTimeStr !== null ? new Date(`${startTimeStr?.substring(0, startTimeStr?.length - 1)}+0000`) : "";
                        const endTime = endTimeStr !== null ? new Date(`${endTimeStr?.substring(0, endTimeStr?.length - 1)}+0000`) : "";

                        const startTimeWithTimezone = startTime !== "" ? startTime : "";
                        const endTimeWithTimezone = endTime !== "" ? endTime : "";

                        const formattedStartTime = startTimeWithTimezone !== "" ? findStartTime(startTimeWithTimezone, is12HourFormat) : "";
                        const formattedEndTime = endTimeWithTimezone !== "" ? findEndTime(endTimeWithTimezone, is12HourFormat) : "";
                        // console.log(formattedStartTime, formattedEndTime, formattedStartTime !== "" || formattedEndTime !== "")

                        if (formattedStartTime !== "" || formattedEndTime !== "")
                            return { start: formattedStartTime, end: formattedEndTime }
                    }
                })
                if (values[0] !== undefined)
                    hoursArray.push({
                        key: day,
                        value: values
                    });
            }
        });
        return (hoursArray);
    };
    const hoursBusiness = createHoursArray();


    // ===================================== FORMATTERS

    // Helper function to format hours
    function formatHoursHour(start, end) {
        return start !== "" || end !== "" ? `${start} - ${end}` : (hoursOutput === "TABLE" ? 'Closed' : "");
    };

    // Convert start time to a readable format
    function findStartTime(startTime, is12HourFormat) {
        return (startTime) ? new Date(startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: is12HourFormat }) : '';
    }

    // Convert end time to a readable format
    function findEndTime(endTime, is12HourFormat) {
        console.log(!isNaN(endTime))
        return (endTime) ? new Date(endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: is12HourFormat }) : '';
    }

    // Add vacation if applicable
    function vacationString(vacations) {
        return vacations && vacations.length > 0
            ? ` | ${new Date(vacations[0].start).toLocaleDateString()} - ${new Date(vacations[0].end).toLocaleDateString()}`
            : '';
    }

    // ===================================== RENDER

    // Group days with identical hours for consolidated display
    const groupDaysWithIdenticalHours = () => {
        const grouped = {};
        hoursBusiness?.forEach((dayData) => {
            const hoursString = dayData?.value?.map(v => formatHoursHour(v?.start, v?.end)).join(', ');
            if (grouped[hoursString] && displayMode === "CONSOLIDATED") {
                grouped[hoursString].push(t(`workingHours.${dayData.key}`));
            } else {
                grouped[hoursString] = [t(`workingHours.${dayData.key}`)];
            }
        });
        return Object.entries(grouped).map(([hours, days]) => ({ days, hours }));
    };

    const groupedHours = groupDaysWithIdenticalHours();

    return (
        <>
            {hoursBusiness?.length > 0 &&
                <>
                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        <CustomTypography variant="body2" text={t('outlet.workingHours')} sx={outletInfoHeaderTextStyle()} />
                    </Grid>


                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        {hoursOutput === 'INLINE' &&
                            <CustomTypography variant="body2" text={groupedHours?.map(({ days, hours }) => `${days.join(', ')} ${hours}`).join('; ')} sx={outletInfoTextStyle()} />
                        }

                        {hoursOutput === "TABLE" &&
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableBody>
                                        {groupedHours?.map(({ days, hours }, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {displayMode === 'CONSOLIDATED' ? days.join(', ') : days[0]}
                                                </TableCell>
                                                <TableCell align="right">{
                                                    <>
                                                        {hours.split(', ').map((interval, index) => (
                                                            // Display each time interval on a new line within the cell
                                                            <React.Fragment key={index}>
                                                                {interval}
                                                                {index < hours.split(', ').length - 1 ? <br /> : ''}
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                }</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }

                        <Divider light flexItem sx={dividerStyle()} />
                    </Grid>
                </>
            }
        </>
    );
};

export default BusinessHours;
