const EL = {
  register: "Εγγραφή",
  registerSuccess: "Έχετε εγγραφεί με επιτυχία.",
  signIn: "Σύνδεση",
  signUpHere: "Εγγραφείτε εδώ!",
  forgotPassword: "Ξεχάσατε τον κωδικό;",
  noAccount: "Δεν έχετε λογαριασμό;",
  haveAccount: "Έχετε ήδη λογαριασμό;",
  backToSignIn: "Πίσω στη Σύνδεση",
  username: "Όνομα χρήστη",
  usernameRequired: "Το όνομα χρήστη είναι υποχρεωτικό",
  usernameRule: "Το όνομα χρήστη πρέπει να είναι τουλάχιστον 4 χαρακτήρες και να περιλαμβάνει μόνο γράμματα, αριθμούς, ενώ η κάτω παύλα είναι προαιρετική.",
  password: "Κωδικός πρόσβασης",
  aboutLabel: "Σχετικά",
  aboutURLPath: "https://usee.gr",
  signInURLPath: "/el/sign-in",
  forgotPasswordURLPath: "/el/forgot-password",
  errorPages: {
    pageNotFound: 'Η σελίδα που ψάχνετε δεν βρέθηκε!!',
    accessIsForbidden: 'Δεν σας επιτρέπεται η πρόσβαση σε αυτήν τη σελίδα!!',
    authorizationFailed: 'Δεν έχετε εξουσιοδοτηθεί να έχετε πρόσβαση σε αυτήν τη σελίδα!!',
    somethingWentWrong: 'Ουπς! Κάτι πήγε στραβά. Παρακαλώ επικοινωνήστε με το διαχειριστή του συστήματος.',
  },
  actions: {
    add: "Προσθήκη",
    create: "Δημιουργία",
    clickHere: "εδώ",
    delete: "Διαγραφή",
    edit: "Επεξεργασία",
    new: "Νέο",
    newItem: "Νέο",
    overview: "Επισκόπηση",
    save: "Αποθήκευση",
    view: "Προβολή",
    selectOption: "Επιλέξτε μια επιλογή",
    search: "Αναζήτηση",
    searchFor: "Αναζήτηση για",
    upload: "Ανέβασμα",
    download: "Λήψη",
    cancel: "Ακύρωση",
    send: "Αποστολή",
    update: "Ενημέρωση",
  },
  button: {
    sendCode: "Αποστολή κωδικού",
    update: "Ενημέρωση"
  },
  auth: {
    alert: {
      please: "Παρακαλώ"
    },
    register: "Εγγραφή",
    login: "Σύνδεση",
    loginSuccessfully: "Συνδεθήκατε επιτυχώς.",
    registerSuccessfully: "Επιτυχής εγγραφή.",
    logoutSuccessfully: "Επιτυχής αποσύνδεση.",
    registerEmail: "Εγγραφή με email",
    loginEmail: "Σύνδεση με email",
    logout: "Αποσύνδεση",
    phone: "Αριθμός τηλεφώνου",
    enterPhone: "Εισάγετε τον αριθμό τηλεφώνου σας",
    enterPhoneVerfCode: "Θα λάβετε έναν 4ψήφιο κωδικό επιβεβαίωσης στο",
    createAccount: "Δημιουργία λογαριασμού",
    vverifyCode: "Επιβεβαίωση του αριθμού τηλεφώνου σας",
    codeIsSentTo: "Ο κωδικός εστάλη στον αριθμό",
    didNotReceive: "Δεν λάβατε τον κωδικό;",
    resendNewCode: "Επαναποστολή νέου κωδικού",
    consentSignUpOTPStart: "Παρέχοντας τον αριθμό του τηλεφώνου μου, συμφωνώ και αποδέχομαι τη",
    consentSignUpOTPAnd: "και",
    consentSignUpOTPAfter: "στη χρήση της εφαρμογής Usee.",
    loginRegisterInfo: "Πληροφορίες σχετικά με τη σύνδεση ή την εγγραφή σας.",
    privacyPolicyLabel: "Πολιτική Απορρήτου",
    termsOfUseLabel: "Όροι Χρήσης",
    privacyPolicyURLPath: "/el/privacy-policy",
    termsOfUseURLPath: "/el/terms-of-use",
    info: "Πληροφορίες σχετικά με τη σύνδεσή ή την εγγραφή σας.",
    infoDetails: "Στο email που χρησιμοποιείτε κατά την εγγραφή, θα σας στέλνουμε την επιβεβαίωση των παραγγελιών σας, ενημερώσεις για τις αποκλειστικές προσφορές που σας εξασφαλίζουμε, καθώς και νέα καταστήματα που εισέρχονται στο efood και σας εξυπηρετούν. Φυσικά, μπορείτε να εγγραφείτε από τη λίστα μας οποτεδήποτε. Για περισσότερες πληροφορίες μπορείτε να δείτε την πολιτική απορρήτου μας.",
    checkoutLoginInfo: "Για να συνεχίσετε την παραγγελία σας, πρέπει πρώτα να συνδεθείτε."
  },
  isError: {
    label: "Η τιμή απαιτείται.",
    mobileNumber: "Η τιμή απαιτείται.",
    code: "Η τιμή απαιτείται.",
    email: "Το email απαιτείται.",
    password: "Ο κωδικός πρόσβασης απαιτείται.",
    firstName: "Το όνομα απαιτείται.",
    lastName: "Το επώνυμο απαιτείται.",
    username: "Το όνομα χρήστη απαιτείται.",
    phone: "Ο αριθμός τηλεφώνου απαιτείται.",
    newPasswordLength: "Ο κωδικός πρόσβασης πρέπει να έχει τουλάχιστον 8 χαρακτήρες.",
    passwordsUnMatch: "Ο νέος κωδικός και ο κωδικός επαλήθευσης δεν ταιριάζουν.",
    accountAlreadyExists: "Η διεύθυνση email χρησιμοποιείται ήδη για άλλο λογαριασμό. Δοκιμάστε να συνδεθείτε αντί για εγγραφή.",
    "Email already being used for another account": "Η διεύθυνση email χρησιμοποιείται ήδη για άλλο λογαριασμό. Δοκιμάστε να συνδεθείτε αντί για εγγραφή.",
    "Phone already being used for another account": "Ο αριθμός τηλεφώνου χρησιμοποιείται ήδη για άλλο λογαριασμό. Δοκιμάστε να συνδεθείτε αντί για εγγραφή."
  },
  customer: {
    lang: "Γλώσσα",
    username: "Όνομα χρήστη",
    password: "Κωδικός πρόσβασης",
    firstName: "Όνομα",
    lastName: "Επώνυμο",
    email: "Email",
    phone: "Κινητό",
    currentPassword: "Τρέχων κωδικός",
    newPassword: "Νέος κωδικός",
    verifyPassword: "Επιβεβαίωση νέου κωδικού",
    createPassword: "Δημιουργία κωδικού",
    changePassword: "Αλλαγή κωδικού",
    retypePassword: "Επαναλάβετε τον κωδικό",
    profile: {
      label: "Προφίλ",
      "Profile has been updated successfully": "Το προφίλ ενημερώθηκε με επιτυχία",
    },
    language: {
      label: "Προτιμώμενη Γλώσσα"
    },
    deleteAccount: {
      label: "Διαγραφή λογαριασμού",
      desc: "Διαγράψτε μόνιμα τα δεδομένα σας από τη Usee και όλα τα καταστήματα.",
      info: "Σημειώστε ότι η διαγραφή του λογαριασμού σας θα έχει τις παρακάτω συνέπειες:",
      info1: "Δεν θα έχετε πλέον πρόσβαση στον λογαριασμό σας",
      info2: "Θα διαγράψουμε όλα τα δεδομένα που σχετίζονται με τον λογαριασμό σας. Θα διατηρήσουμε τα δεδομένα που έχουμε νομική υποχρέωση να αποθηκεύσουμε.",
      info3: "Δεν θα μπορείτε να αναιρέσετε τη διαγραφή.",
      actionInfo: "Είστε σίγουρος ότι θέλετε να προχωρήσετε;",
      confirmByTypingCodeBefore: "Επιβεβαιώστε πληκτρολογώντας",
      confirmByTypingCodeAfter: "παρακάτω.",
      btn: "ΜΟΝΙΜΗ Διαγραφή",
      codesNotMatching: "Ο κωδικός που εισαγάγατε δεν ταιριάζει με τον δοθέντα. Προσπαθήστε ξανά.",
      successTitle: "Ο Λογαριασμός Διαγράφηκε",
      success: "Τώρα έχουμε διαγράψει μόνιμα τον χρήστη σας. Λυπούμαστε που σας βλέπουμε να φεύγετε. Είστε πάντα ευπρόσδεκτοι να επιστρέψετε!"
    }
  },
  menu: {
    sidenav: {
      categories: "Κατηγορίες"
    },
    appBar: {
      profile: "Προφίλ",
      myOrders: "Οι παραγγελίες μου",
      myRatings: "Οι αξιολογήσεις μου",
      myCoupons: "Τα κουπόνια μου",
      loyalty: "Πόντοι",
      messages: "Μηνύματα",
      notifications: "Ειδοποιήσεις",
      settings: "Ρυθμίσεις",
      logout: "Αποσύνδεση"
    },
    noMenus: "Δεν βρέθηκαν μενού αυτή τη στιγμή.",
    back: {
      categories: "Κατηγορίες",
      parentCategories: "Μενού"
    }
  },
  menus: {
    label: "Μενού",
    info: {
      label: "Πληροφορίες",
      payment: {
        paymentMethods: "Μέθοδοι πληρωμής",
        card: "Κάρτα",
        paypal: "Paypal",
        cash: "Μετρητά"
      },
      aboutStore: "Σχετικά με το κατάστημα",
      address: "Διεύθυνση καταστήματος",
      workingHours: "Ώρες λειτουργίας"
    },
    reviews: {
      label: "Κριτικές",
      reviewComment: "Βασισμένο στις τελευταίες 365 ημέρες",
      userReviews: "Κριτικές χρηστών",
      beforeUserReviewsDescription: "Δείτε αναλυτικά τις",
      afterUserReviewsDescription: "κριτικές για το κατάστημα."
    },
    dialog: {
      comments: "Σχόλια",
      buttons: {
        addToCart: "Προσθήκη στο καλάθι",
        continue: "Συνέχεια",
        close: "Κλείσιμο"
      }
    }
  },
  cart: {
    label: "Καλάθι",
    emptyLabel: "Κενό Καλάθι",
    emptyDescription: "Συμπληρώστε το καλάθι σας με αντικείμενα.",
    options: {
      delivery: "Παράδοση",
      dineIn: "Κατανάλωση στο κατάστημα",
      pickUp: "Παραλαβή"
    },
    discount: "Έκπτωση",
    totalAmmount: "Συνολικό ποσό",
    analyzeOrder: "Ανάλυση παραγγελίας",
    allergies: {
      label: "Αλλεργίες",
      celery: "Σέλινο",
      eggs: "Αυγά",
      fish: "Ψάρι",
      gluten: "Γλουτένη",
      lupin: "Λούπινο",
      milk: "Γάλα",
      molluscs: "Μαλάκια",
      mustard: "Μουστάρδα",
      nuts: "Ξηροί καρποί",
      soya: "Σόγια",
      sesame: "Σησάμι",
      wheat: "Σιτάρι",
      pleaseSelectAllThatApply: "Επιλέξτε ό,τι ισχύει"
    },
    equipmentLabel: "Τραπέζι:",
    order: {
      label: "Παραγγελία",
      confirmed: "Επιβεβαιωμένη",
    }
  },
  outlet: {
    reviews: "Ανατροφοδοτήσεις",
    outletInformation: "Πληροφορίες καταστήματος",
    backButton: "Πίσω",
    workingHours: "Ώρες λειτουργίας",
    address: "Διεύθυνση",
    socialProfiles: "Κοινωνικά δίκτυα",
    vatNumber: "Αριθμός ΦΠΑ",
    telephones: "Τηλέφωνα",
    photos: "Φωτογραφίες",
    // page
    type: {
      PAYMENT: "Πληρωμή",
      CUSTOMER_REQUEST: "Κλήση σερβιτόρου",
      MENU: "Παραγγελία",
      OTHER: "Άλλο",
      WIFI: "Wifi"
    },
  },
  wifi: {
    ssid: "Όνομα",
    password: "Κωδικός",
    locationName: "Όνομα Τοποθεσίας",
    encryption: "Κρυπτογράφηση",
    empty: "Δεν υπάρχουν διαθέσιμες Wifi πληροφορίες αυτή τη στιγμή.",
    copyNotification: "Επιτυχής αντιγραφή κωδικού."
  },
  request: {
    title: "Αιτήσεις",
    pageTitle: "Κλήση Σερβιτόρου",
    request: "Έχετε κάποια συγκεκριμένη αίτηση στο μυαλό σας;",//"Ποιες είναι οι ανάγκες σας αυτή τη στιγμή;"
    btns: {
      send: "Αποστολή",
      ok: "Εντάξει"
    },
    info: "Συμπληρώστε με ένα σύντομο κείμενο...",
    requestCharLimit: "χαρακτήρας",
    requestCharsLimit: "χαρακτήρες",
    requestPlaceholder: "Πληκτρολογήστε εδώ…",
    allowedRequestTypes: {
      PAYMENT: "Πληρωμή",
      CLEAN_TABLE: "Καθαριότητα τραπεζιού",
      MENU: "Παραγγελία",
      OTHER: "Άλλο",
    },
    popup: {
      success: "Επιτυχία",
      fail: "Αποτυχία",
      info: "Πληροφορίες",
      alreadySendRequestMessage: "Έχετε ήδη αποστείλει μια αίτηση.",
      successMessage: "Η αίτηση υποβλήθηκε με επιτυχία.",
      failMessage: "Η αίτηση δεν υποβλήθηκε."
    }
  },
  languages: {
    EN: "Αγγλικά",
    DE: "Γερμανικά",
    FR: "Γαλλικά",
    IT: "Ιταλικά",
    RU: "Ρωσικά",
    EL: "Ελληνικά",
    UK: "Ουκρανικά",
    ES: "Ισπανικά",
    NL: "Ολλανδικά",
    CS: "Τσέχικα",
    PL: "Πολωνικά",
    BG: "Βουλγαρικά",
    AR: "Αραβικά",
    ET: "Εσθονικά",
    LT: "Λιθουανικά",
    RO: "Ρουμανικά",
    TR: "Τουρκικά",
    en: "Αγγλικά",
    de: "Γερμανικά",
    fr: "Γαλλικά",
    it: "Ιταλικά",
    ru: "Ρωσικά",
    el: "Ελληνικά",
    uk: "Ουκρανικά",
    es: "Ισπανικά",
    nl: "Ολλανδικά",
    cs: "Τσέχικα",
    pl: "Πολωνικά",
    bg: "Βουλγαρικά",
    ar: "Αραβικά",
    et: "Εσθονικά",
    lt: "Λιθουανικά",
    ro: "Ρουμανικά",
    tr: "Τουρκικά"
  },
  loyalty: {
    label: "Πρόγραμμα Πιστότητας",
    empty: "Δεν έχετε ακόμη κάρτες πιστότητας",
    emptyOrganization: "Δεν υπάρχει κάρτα πιστότητας για αυτό το κατάστημα",
    points: "πόντοι",
    organizationName: "Όνομα Οργανισμού"
  },
  coupon: {
    label: "Κουπόνια",
    empty: "Δεν έχετε ακόμη κουπόνια",
    emptyOrganization: "Δεν υπάρχουν κουπόνια για αυτό το κατάστημα",
    organizationName: "Όνομα Οργανισμού",
    expiresOn: "Λήγει στις:",
    code: "Κωδικός:"
  },
  checkout: {
    label: "Ολοκλήρωση παραγγελίας",
    backButton: "Πίσω",
    paymentTotal: "Συνολικό ποσό πληρωμής",
    paymentMethod: "Μέθοδος πληρωμής: ",
    cart: "Καλάθι",
    persons: "Άτομα",
    pvcRecyclingTax: "Φόρος ανακύκλωσης PVC συμπεριλαμβανομένου",
    discount: "Έκπτωση:",
    loyalty: "Πιστότητα",
    coupon: "Κουπόνι:",
    comments: "Σχόλια",
    info: "Συμπληρώστε με ένα σύντομο κείμενο...",
    requestCharLimit: "χαρακτήρας",
    requestCharsLimit: "χαρακτήρες",
    requestPlaceholder: "Πληκτρολογήστε εδώ…",
    buttons: {
      sendOrder: "Αποστολή παραγγελίας",
      payOrder: "Πληρωμή παραγγελίας",
      sendOrderDisabledAlert: "Οι παραγγελίες είναι απενεργοποιημένες. Ένας υπάλληλος θα σας βοηθήσει σύντομα.",
    },
    discount: {
      couponNotExists: "Έχετε κάποιο κωδικό κουπονιού; Κάντε κλικ εδώ...",
      couponExists: "Υπάρχουν διαθέσιμα κουπόνια.",
      textWithAvailableCoupons: "Πληκτρολογήστε τον κωδικό κουπονιού ή επιλέξτε ένα κουπόνι και κερδίστε έκπτωση στην παραγγελία σας.",
      textWithNoAvailableCoupons: "Πληκτρολογήστε τον κωδικό κουπονιού και κερδίστε έκπτωση στην παραγγελία σας.",
      invalid: "Δεν υπάρχουν κουπόνια με αυτόν τον κωδικό."
    },
    payment: {
      paymentMethods: "Μέθοδοι πληρωμής",
      card: "Κάρτα",
      paypal: "Paypal",
      cash: "Μετρητά",
    },
    equipmentInvalid: "Η εισαγόμενη ετικέτα εξοπλισμού δεν βρέθηκε ή δεν είναι ενεργή. Παρακαλούμε δοκιμάστε ξανά!",
    tax: "ΦΠΑ: ",
    loyaltyPointsToEarn: "Εάν προχωρήσετε, θα κερδίσετε",
    points: "πόντους",
    availableTooltip: "Διαθέσιμη ποσότητα"
  },
  order: {
    label: "Παλαιές Παραγγελίες",
    singleLabel: "Η παραγγελία σας",
    empty: "Δεν έχετε ακόμη παραγγελίες",
    notFound: "Η παραγγελία δεν βρέθηκε",
    addFeedback: "Προσθήκη Αξιολόγησης",
    moreOrders: "Περισσότερες Παραγγελίες",
    backButton: "Πίσω στις παραγγελίες",
    outletName: "Κατάστημα:",
    equipmentLabel: "Τοποθεσία:",
    orderTotal: "Σύνολο παραγγελίας:",
    paymentTotal: "Συνολική πληρωμή",
    paymentMethod: "Μέθοδος πληρωμής: ",
    cart: "Καλάθι",
    rating: "Η γνώμη σας μετράει",
    ratingDescription: "Αξιολογήστε την παραγγελία σας",
    allergies: "Οι Αλλεργίες σας",
    notes: "Σημείωση:",
    pvcRecyclingTax: "Φόρος ανακύκλωσης PVC συμπεριλαμβανομένου",
    discount: "Έκπτωση:",
    loyalty: "Πιστότητα",
    coupon: "Κουπόνι:",
    unPaidOrders: "Μη πληρωμένες παραγγελίες",
    unPaidOrdersButton: "Πληρωμή παραγγελίας",
    rating: {
      orderLabel: "Παραγγελία",
      label: "Αξιολόγησης",
      message: "Μήνυμα Αξιολόγησης",
      checkboxInfo: "Επιλέξτε για αυτόματη αποστολή των σχολίων σας στις Κριτικές της Google.",
      ratingInfo: "Σας ευχαριστούμε που επιλέξατε να παρέχετε αξιολόγηση! Η πολύτιμη σας συμβολή μας βοηθά να βελτιώσουμε την υπηρεσία μας για εσάς και τους άλλους. Εκτιμούμε τη συμμετοχή σας στον προσδιορισμό της εμπειρίας της κοινότητάς μας!",
      btn: "Αξιολογήστε Τώρα",
      back: "Επιστροφή στην Παραγγελία"
    },
    fail: {
      backButton: "Ολοκλήρωση παραγγελίας",
      infoPayment: "Η πληρωμή σας απέτυχε. Παρακαλούμε προσπαθήστε ξανά."
    },
    success: {
      tab: "Επιτυχία",
      backButton: "Ολοκλήρωση παραγγελίας",
      infoPayment: "Η παραγγελία σας ολοκληρώθηκε με επιτυχία.",
      orderNumber: "Αριθμός παραγγελίας:",
      back: "Επιστροφή στην αρχική"
    },
    tip: {
      label: "tip",
      checkout: {
        label: "Επιλέξτε φιλοδώρημα",
        info: "Το προσωπικό μας θα είναι ευγνώμων."
      }
    }
  },
  item: {
    notes: "Σημειώσεις",
    notesInfo: "Σημειώσεις (εάν υπάρχουν ειδικές οδηγίες)",
    itemCharLimit: "χαρακτήρας",
    itemCharsLimit: "χαρακτήρες",
    allergies: "Αλλεργίες",
    pvcRecyclingTax: "Φόρος ανακύκλωσης PVC",
    points: "πόντοι",
    caloriesShort: "Καλ",
    proteinShort: "Πρωτ",
    carbShort: "Υδατ",
    min: "Ελάχ.",
    max: "Μέγ.",
    nutrients: {
      label: "Θρεπτικά συστατικά",
      grams: "Γραμμάρια",
      gramsAfterLabel: "γρ",
      gramsDetailsLabel: "Ποσότητα ανά ",
      gramsPortionLabel: "μερίδα",
      energy: "Ενέργεια",
      enrgeyAfterLabel: "ΜΤ",
      calories: "Θερμίδες",
      caloriesAfterLabel: "κκαλ",
      protein: "Πρωτεΐνες",
      proteinAfterLabel: "γρ",
      carb: "Υδατάνθρακες",
      carbAfterLabel: "γρ",
      sugars: "Ζάχαρα",
      sugarsDetailsLabel: "(εκ των οποίων Σάκχαρα)",
      sugarsAfterLabel: "γρ",
      fat: "Λιπαρά",
      fatDetailsLabel: "Συνολικά Λιπαρά",
      fatAfterLabel: "γρ",
      sat_fat: "Κορεσμένα λιπαρά",
      sat_fatDetailsLabel: "(εκ των οποίων Κορεσμένα)",
      sat_fatAfterLabel: "γρ",
      trans_fat: "Τρανς λιπαρά",
      trans_fatDetailsLabel: "(εκ των οποίων Τρανς)",
      trans_fatAfterLabel: "γρ",
      fiber: "Ινες",
      fiberDetailsLabel: "(εκ των οποίων ινες)",
      fiberAfterLabel: "γρ",
      sodium: "Νάτριο",
      sodiumAfterLabel: "γρ",
      vitaminA: "Βιταμίνη Α",
      vitaminAAfterLabel: "μg",
      vitaminC: "Βιταμίνη C",
      vitaminCAfterLabel: "μg",
      calcium: "Κάλιο",
      calciumAfterLabel: "mg",
      iron: "Σίδηρος",
      ironAfterLabel: "mg",
      salt: "Αλάτι",
      saltAfterLabel: "γρ",
    },
    requiredChoice: "Απαραίτητη επιλογή",
    optionalChoice: "Προαιρετική επιλογή",
    ok: "Εντάξει"
  },
  offers: {
    label: {
      en: "Offers",
      el: "Προσφορές"
    }
  },
  settings: {
    label: "Ρυθμίσεις",
    language: "Γλώσσα: ",
    updateLanguage: "Ενημέρωση Γλώσσας"
  },
  socialProfile: {
    label: "Κοινωνικά Προφίλ",
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    LINKEDIN: "Linkedin",
    PINTEREST: "Pinterest",
    SNAPCHAT: "Snapchat",
    TIKTOK: "TikTok",
    TWITTER: "Twitter",
    YOUTUBE: "Youtube"
  },
  workingHours: {
    label: "Ωράριο Λειτουργίας",
    monday: "Δευτέρα",
    tuesday: "Τρίτη",
    wednesday: "Τετάρτη",
    thursday: "Πέμπτη",
    friday: "Παρασκευή",
    saturday: "Σάββατο",
    sunday: "Κυριακή"
  },
  rating: {
    outlet: {
      pageTitle: "Αξιολόγηση Καταστήματος",
      label: "Αξιολόγηση",
      message: "Μήνυμα Αξιολόγησης",
      checkboxInfo: "Επιλέξτε για αυτόματη αποστολή των σχολίων σας στις Κριτικές της Google.",
      ratingInfo: "Σας ευχαριστούμε που επιλέξατε να παρέχετε αξιολόγηση! Η πολύτιμη σας συμβολή μας βοηθά να βελτιώσουμε την υπηρεσία μας για εσάς και τους άλλους. Εκτιμούμε τη συμμετοχή σας στον προσδιορισμό της εμπειρίας της κοινότητάς μας!",
      btn: "Αξιολογήστε Τώρα",
      back: "Επιστροφή στο Κατάστημα"
    },
    label: "Αξιολογήσεις",
    outletName: "Όνομα Καταστήματος",
    emptyOutlet: "Δεν υπάρχουν αξιολογήσεις γι' αυτό το κατάστημα",
    emptyRatings: "Δεν έχουν προστεθεί ακόμα αξιολογήσεις.",
    moreRatings: "Περισσότερες Αξιολογήσεις",
    basedOnBefore: "Βασισμένο σε",
    basedOnAfter: "αξιολογήσεις",
  },
  qrCode: {
    scan: "Σάρωση του QR Code",
    info: "Σκανάρετε τον κωδικό QR για να συνεχίσετε. Κρατήστε τη συσκευή σας σταθερή και ευθυγραμμίστε τον κωδικό QR εντός του πλαισίου."
  }
}

export default EL;
