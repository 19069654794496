import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { Delete } from "@mui/icons-material";
import { Alert, Badge, Box, Button, Card, CardActionArea, CircularProgress, Divider, Grid, InputLabel, Stack, Tooltip, useMediaQuery } from "@mui/material";
import checkoutApi from "api/checkout";
import couponApi from "api/coupon";
import equipmentApi from 'api/equipment';
import orderApi from 'api/order';
import requestApi from "api/request";
import vivaPaymentApi from 'api/vivaPayment';
import { FontAwesomeIcon, faCreditCard, faLeaf, faMoneyBill } from 'assets/fontAwesome/FontAwesomeIcons';
import { ArrowBackIcon, EditIcon, MessageIcon, NavigateNextIcon, PercentIcon, PersonIcon, PlaceIcon, ReceiptIcon } from "assets/mui/MuiIcons";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomSingleCardItem from 'components/Card/cart/CustomSingleCardItem';
import CustomItemCard from "components/Card/item/CustomItemCard";
import CustomChip from "components/Chip/CustomChip";
import CustomDialogBasedOnScreen from "components/Dialog/CustomDialogBasedOnScreen";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeaderChildren from "components/Header/CustomTopHeaderChildren";
import CheckoutLoadingSkeleton from "components/Loading/CheckoutLoadingSkeleton";
import CheckoutCouponPopover from "components/Popover/CheckoutCouponPopover";
import CheckoutEquipmentPopover from 'components/Popover/CheckoutEquipmentPopover';
import CheckoutPaymentMethodPopover from 'components/Popover/CheckoutPaymentMethodPopover';
import CheckoutPersonsPopover from 'components/Popover/CheckoutPersonsPopover';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultRequestObject } from "constants/defaultObjects";
import { requestTextFieldLimit } from "constants/textFieldLimit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { clearOutletCart, updateCartComments, updateCartCoupon, updateCartEquipment, updateCartOrderId, updateCartPaymentMethod, updateCartPersons, updateCartTaxPercentage, updateCartTipAmount, updateItemInCart } from "store/cart/cartActions";
import { checkoutCommentTextareaBoxInfoCharStyle, checkoutCommentTextareaBoxStyle } from 'themes/checkout/checkoutTheme';
import { ItemPaper, autDrawerPaperStyle, cartButtonLabelItemStyle, cartButtonStackStyle, cartButtonTotalAmmountItemStyle, cartButtonTotalAmmountTextStyle, mainTag, orderBackBtnStyle, orderCartTitleTextStyle, orderDiscountTextStyle, orderDiscountTextsStyle, orderDiscountValueTextStyle, orderFontAwesomeIconStyle, orderHeaderTextStyle, orderIconStyle, orderItemFontAwesomeIconStyle, orderItemMuiIconStyle, orderItemTaxPriceTextStyle, orderItemTaxTextStyle, orderItemTotalPriceTextStyle, orderItemTotalTextStyle, orderReviewGridStyle, orderTextStyle, orderTipCardActionStyle, orderTipCardActionTextPriceStyle, orderTipCardActionTextStyle, orderTipCardStyle } from 'themes/defaultThemes';
import { calculateLoyaltyPointsEarn, calculateQuantitiesSum, calculateTax, calculateTotalAmount } from 'utils/cart-functions';
import { findCurrency, priceTextWithCurrency } from "utils/functions/order";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The CheckoutComponent, that presents a the order checkout page.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CheckoutComponent() {
    const isMobile = useMediaQuery('(max-width:468px)');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { outletURL } = useParams();
    const params = new URLSearchParams(location.search);
    const equipmentLabel = params.get("e");
    const [searchParams, setSearchParams] = useSearchParams();

    // from outlet settings
    const [enableCart, setEnableCart] = useState(false);

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        organization: null,
        outlet: null,
        equipment: null,
        customerCoupons: [],
        couponStatuses: [],
    });

    // login
    const [isOpenLogin, setIsOpenLogin] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    // isLoaded to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getCheckout(false);
        // dispatch(updateCartPaymentMethod(data.outlet?.id, "card"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //change document title
    useDocumentTitle(t("checkout.label"));


    // cart
    const cart = useSelector(state => state.cart.carts[data.outlet?.id]?.cart || { equipment: { label: equipmentLabel }, paymentMethod: "cash", persons: 1 });
    const dispatch = useDispatch();

    /**
     * The rest endpoint to get the outlet orders.
     */
    // TODO: include a rule for not enable to create order
    function getCheckout(sendButtonISPressed = true) {
        checkoutApi.fetchCheckoutPage(outletURL, getLanguageFromURL(), "", equipmentLabel).then((r) => {
            if (sendButtonISPressed) {
                if (r.data?.code === "PERMISSION") {
                    setIsOpenLogin(true);
                } else {
                    setIsOpenLogin(false);
                }
            }

            if (r?.data?.code !== "INVALID") {
                dispatch(updateCartEquipment(r.data.returnobject?.outlet?.id, r.data.returnobject?.equipment))
                dispatch(updateCartPaymentMethod(r.data.returnobject?.outlet?.id, r.data.returnobject?.outlet?.settings?.paymentMethods))
                dispatch(updateCartTaxPercentage(r.data.returnobject?.outlet?.id, r.data.returnobject?.outlet?.settings?.taxType === "EXCLUSIVE" ? r.data.returnobject?.outlet?.settings?.taxNumberPercentage : 0))
                setEnableCart(r.data.returnobject.outlet.settings.enableCart);
                setData({
                    ...data,
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                    equipment: r.data.returnobject.equipment,
                    customerCoupons: r.data.returnobject.customerCoupons,
                    couponStatuses: r.data.returnobject.couponStatuses,
                    userAuth: r.data.returnobject.userAuth
                });
                setIsLoaded(true);
            }
        }).catch((e) => {
            // console.log(e);
            setIsLoaded(true);
            setIsOpenLogin(false);
        });
    }


    function payOrder(order) {
        let orderDetails = {
            organizationID: data.organization.id,
            amount: order.totalAmmount,
            isCustomer: true,
            order: order,
            paymentType: "O"
        }
        if (order?.tipAmount > 0) {
            orderDetails.amount += order?.tipAmount;
        }
        setLoadingBtn(true);
        vivaPaymentApi.getPaymentUrl(orderDetails).then((r) => {
            if (r.data.code === "SUCCESS") {
                window.location.href = `${r.data.returnobject.redirectURL}&outletURL=${outletURL}&oid=${r.data?.returnobject?.orderID}`;
                if (r.data?.returnobject?.orderID) dispatch(updateCartOrderId(data.outlet?.id, r.data.returnobject.orderID))
                setLoadingBtn(false);
            } else if (r.data.code === "PERMISSION") {
                setIsOpenLogin(true);
                setLoadingBtn(false);
            }
        }).catch((e) => {
            // console.log(e);
        });
    }

    function addOrder() {
        if (validateNewEquipmentLabel(cart?.equipment?.label)) {
            if (data.userAuth === null && data.outlet?.settings?.allowActionFromAuthenticatedUserOnly && data.outlet?.settings?.allowOrdesFromAuthenticatedUsersOnly) {
                setIsOpenLogin(true);
            } else {
                let order = {
                    organizationID: cart?.organizationID,
                    outletID: cart?.outletID,
                    equipmentID: cart?.equipment?.id,
                    equipmentLabel: cart?.equipment?.label,
                    customer: { id: "true", firstOrder: false }, //fill in from backend
                    items: cart?.items,
                    allergies: cart?.allergies,
                    pvcRecyclingTax: cart?.pvcRecyclingTax,
                    comments: cart?.comments,
                    discount: cart?.discount,
                    loyaltyPointsEarned: calculateLoyaltyPointsEarn(cart),
                    quantitiesSum: calculateQuantitiesSum(cart),
                    totalAmmount: calculateTotalAmount(cart),
                    tipAmount: cart?.tipAmount * calculateTotalAmount(cart),
                    status: "PENDING",
                    type: cart?.type,
                    paymentMethod: cart?.paymentMethod.toLowerCase(),
                    persons: cart?.persons,
                    isPaid: false,
                    currencyCode: data.outlet?.settings?.currency,
                    vivaPaymentsDetails: {
                        token: "",
                        transactionId: "",
                        email: "",
                        amount: calculateTotalAmount(cart),
                        orderCode: "",
                        statusId: "",
                        fullName: "",
                        insDate: "",
                        cardNumber: "",
                        currencyCode: "",
                        customerTrns: "",
                        merchantTrns: "",
                        transactionTypeId: null,
                        recurringSupport: false,
                        totalInstallments: 0,
                        cardCountryCode: "",
                        cardIssuingBank: "",
                        currentInstallment: 0,
                        cardUniqueReference: "",
                        cardTypeId: 0,
                        digitalWalletId: 0,
                        orderHasBeenSentByScheduledJob: false,
                    }
                }

                if (!data.outlet?.settings?.enableOrder) {
                    requestApi.create(getDefaultRequestObject(outletURL, equipmentLabel, "ORDER", order), true).then((r) => {
                    }).catch((e) => {
                    });
                    setButtonDisabled(true);
                } else {
                    // if (cart?.paymentMethod === "cash" || (cart?.paymentMethod === "card" )) {
                    if (cart?.id === null || cart?.id === undefined || cart?.id === "") {
                        setLoadingBtn(true);
                        order = {
                            ...order,
                            cart: order?.items.map(item => ({
                                id: item.id,
                                name: item.name,
                                quantity: item.quantity,
                                price: item.price,
                                pvcRecyclingTax: item.pvcRecyclingTax,
                                notes: item.notes,
                                done: false,
                                modifiers: item.modifierListIds.flatMap(modifierList =>
                                    modifierList?.modifiers.map(modifier => ({
                                        id: modifier.id,
                                        name: modifier.name, // This needs a lookup or mapping
                                        price: modifier?.price
                                    }))
                                ),
                                unavailable: false,
                                unavailableQuantity: 0,

                                regularItem: item.regularItem,
                                happyHourItem: item.happyHourItem,
                                comboItem: item.comboItem,
                                offerItem: item.offerItem,
                                parentCategoryID: item.parentCategoryID,
                                parentCategoryName: item.parentCategoryName,
                                categoryID: item.categoryID,
                                categoryName: item.categoryName,
                                menuID: item.menuID,
                                menuName: item.menuName
                            }))
                        }
                        order.items = undefined;

                        if (order.paymentMethod === "card") {
                            order.status = "PENDING_PAYMENT"
                            payOrder(order);
                        } else if (order.paymentMethod === "cash") {
                            orderApi.create(order).then((r) => {
                                if (r.data.code === "SUCCESS") {
                                    navigate(`/${getLanguageFromURL()}/checkout/${outletURL}/success?e=${equipmentLabel}&oid=${r.data.returnobject.id}`);
                                    dispatch(clearOutletCart(data.outlet.id))
                                    setLoadingBtn(false);
                                } else if (r.data?.code === "PERMISSION") {
                                    setIsOpenLogin(true);
                                    setLoadingBtn(false);
                                }
                            }).catch((e) => {
                                // navigate(`/${getLanguageFromURL()}/checkout/${outletURL}/fail?e=${equipmentLabel}&outletURL=${outletURL}`);
                            });
                        }
                    }
                }
            }
        }
    }

    // ========================= SPLASH 
    const [splashCount, setSplashCount] = useState(0);
    const [showSplash, setShowSplash] = useState(false);

    // ========================= PERSONS 
    const [personsAnchorEl, setPersonsAnchorEl] = useState(null);

    function updatePersonsOpenCallback(event) {
        setPersonsAnchorEl(event.currentTarget);
    }

    function updatePersonsCloseCallback() {
        setPersonsAnchorEl(null);
    }

    function updatePersonsCallback(newValue) {
        dispatch(updateCartPersons(data.outlet?.id, newValue));
        // Optionally close the popover
        // updatePersonsCloseCallback();
    }

    const openPersons = Boolean(personsAnchorEl);


    // ========================= COUPON 
    const [couponAnchorEl, setCouponAnchorEl] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const [couponInvalid, setCouponInvalid] = useState(false);

    function updateCouponOpenCallback(event) {
        setCouponAnchorEl(event.currentTarget);
    }

    function updateCouponCloseCallback() {
        setCouponAnchorEl(null);
    }

    function updateCouponValue(event) {
        setCoupon(event.target.value);
    }

    function updateCouponCallback(couponCode, selectedCoupon) {
        if (couponCode !== null) {
            validateSelectedCoupon(couponCode);
        } else if (selectedCoupon !== null) {
            selectedCoupon.organization = data.organization;
            dispatch(updateCartCoupon(data.outlet.id, selectedCoupon))
            setCouponAnchorEl(null);
        }
    }

    const openCoupon = Boolean(couponAnchorEl);

    /**
     * The rest endpoint to validate the new inputed equipment label and return its details (id, label).
     */
    function validateSelectedCoupon() {
        couponApi.validateCouponCode(data.organization?.id, coupon).then((r) => {
            if (r.data?.code === "SUCCESS" && r.data?.returnobject !== null) {
                let coupon = r.data?.returnobject;
                coupon.organization = data.organization;
                dispatch(updateCartCoupon(data?.outlet?.id, coupon))
                // Optionally close the popover
                updateCouponCloseCallback();
                setCouponInvalid(false);
                setCouponAnchorEl(null);
            } else {
                setCouponInvalid(true);
            }
        }).catch((e) => {
            // console.log(e);
            setCouponInvalid(true);
        });
    }

    // ========================= EQUIPMENT 
    const [equipmentAnchorEl, setEquipmentAnchorEl] = useState(null);
    const [equipment, setEquipment] = useState('');
    const [equipmentInvalid, setEquipmentInvalid] = useState(false);

    function updateEquipmentOpenCallback(event) {
        setEquipment(cart?.equipment?.label)
        setEquipmentAnchorEl(event.currentTarget);
    }

    function updateEquipmentCloseCallback() {
        setEquipmentAnchorEl(null);
    }

    function updateEquipmentValue(event) {
        setEquipment(event.target.value);
    }

    function updateEquipmentCallback() {
        validateNewEquipmentLabel(equipment);
    }

    const openEquipment = Boolean(equipmentAnchorEl);

    /**
     * The rest endpoint to validate the new inputed equipment label and return its details (id, label).
     */
    function validateNewEquipmentLabel(eq) {
        return new Promise((resolve, reject) => {
            equipmentApi.validateExistance(data?.outlet?.id, eq).then((r) => {
                if (r.data?.code === "SUCCESS" && r.data?.returnobject !== null) {
                    dispatch(updateCartEquipment(data?.outlet?.id, r.data?.returnobject))
                    // Optionally close the popover
                    updateEquipmentCloseCallback();
                    setEquipmentInvalid(false);
                    updateURLParameter('e', r.data?.returnobject?.label);
                    return true;
                } else {
                    setEquipmentInvalid(true);
                    return false;
                }
            }).catch((e) => {
                // console.log(e);
                setEquipmentInvalid(true);
                return false;
            });
        });
    }

    function updateURLParameter(param, value) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(param, value);
        navigate(`?${newSearchParams.toString()}`);
    };
    // ========================= Payment Method 
    const [paymmentMethodAnchorEl, setPaymmentMethodAnchorEl] = useState(null);

    function updatePaymmentMethodOpenCallback(event) {
        setPaymmentMethodAnchorEl(event.currentTarget);
    }

    function updatePaymmentMethodCloseCallback() {
        setPaymmentMethodAnchorEl(null);
    }

    function updatePaymmentMethodCallback(newValue) {
        dispatch(updateCartPaymentMethod(data.outlet.id, [newValue]));
        // Optionally close the popover
        // updatePaymmentMethodCloseCallback();
    }

    const openPaymmentMethod = Boolean(paymmentMethodAnchorEl);

    // ================================== DIALOG

    // pop dialog for 
    const [dialog, setDialog] = useState({
        isOpen: false,
        type: "success",
        message: "",
        item: null,
        cartIndex: -1,
        cartItemDefault: null
    });
    /**
     * Function that handles the item itemDialog open or close state.
     * @property {boolean} isOpen If the values is `true`, the itemDialog should be open and visible.
     * @property {object} item the item to be displayed
     */
    function handleItemDialogState(isOpen, item, cartIndex = -1, cartItemDefault) {
        // console.log(cartIndex)
        setDialog({
            ...dialog,
            isOpen: isOpen,
            type: "",
            message: "",
            item: item,
            cartIndex: cartIndex,
            cartItemDefault: cartItemDefault
        });
    }

    /**
     * Function that handles the visualize modal open or close state.
     * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            item: null,
            cartIndex: -1,
            cartItemDefault: null
        });
    }

    function updateItemToCartCallback(cartItem, modifiersPrice) {
        dispatch(updateItemInCart(data.outlet.id, cartItem.id, cartItem, modifiersPrice, dialog.cartIndex))
    }

    function body() {
        return <CustomItemCard
            organizationID={data.organization.id}
            outletURL={outletURL}
            itemID={dialog.item?.id}
            isOpen={dialog.isOpen}
            action={updateItemToCartCallback}
            handleOpen={handleItemDialogState}
            menuConfiguration={undefined}
            outletAllowComments={data.outlet.settings.allowComments}
            enableCart={enableCart}
            currency={data.outlet.currency}
            cartIndex={dialog.cartIndex}
            cartItemDefault={dialog.cartItemDefault}
        />
    }

    // ===============================================================================RENDER
    if (!isLoaded) {
        return <CustomGrid role="main" id="checkout" sx={mainTag()}>
            <CustomTopHeaderChildren
                key="th"
                isOpen={isOpenLogin}
                handleLoginCheckout={() => getCheckout()}
                setIsOpen={setIsOpenLogin}
                pageRequireLoginRegistation={isOpenLogin}
            />
            <CheckoutLoadingSkeleton isMobile={isMobile} />
        </CustomGrid>
    } else {
        return (
            <CustomGrid role="main" id="checkout" sx={mainTag()}>
                <CustomTopHeaderChildren
                    key="th"
                    isOpen={isOpenLogin}
                    // handleLoginCheckout={() => getCheckout()}
                    setIsOpen={setIsOpenLogin}
                    pageRequireLoginRegistation={isOpenLogin}
                />

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        <CustomIconButton
                            icon={<ArrowBackIcon />}
                            sx={orderBackBtnStyle()}
                            onClick={() => navigate(-1)}
                            label={t('checkout.backButton')}
                        />
                    </Grid>

                    {/* <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={2} md={2}>
                            {(data.outlet?.logo && data.outlet?.logo !== "" && data.outlet?.logo !== null) &&
                                <CardMedia
                                    component="img"
                                    src={`${MINIO_HOST}organization/${data.outlet?.organizationID}/outlet/${data.outlet?.id}/${data.outlet.logo}`}
                                    // alt={"logo"}
                                    sx={{ ...pastOrderLogoStyle(), textAlign: "center" }}
                                />
                            }
                        </Grid>

                        <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                            <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t('order.outletName')} sx={orderHeaderTextStyle()} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={data.outlet?.name} sx={orderTextStyle()} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> */}

                    <Grid item xs={6} md={6} sx={{ marginTop: "20px" }}>
                        <Grid container sx={{ width: "90%", marginLeft: "30px" }} >
                            <Grid item xs={isMobile ? 2 : 4} md={isMobile ? 2 : 4} sx={{ alignSelf: "center" }}>
                                <PlaceIcon sx={orderIconStyle} />
                            </Grid>

                            <Grid item xs={isMobile ? 10 : 8} md={isMobile ? 10 : 8} sx={{ alignSelf: "center" }}>
                                <Grid container sx={{ marginLeft: (isMobile ? "30px" : "10px"), textAlign: "left" }}>
                                    <Grid item xs={12} md={12}>
                                        <CustomTypography variant="body2" text={t('cart.equipmentLabel')} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={"auto"} md={"auto"} sx={{ display: "flex" }}>
                                        <CustomTypography variant="body2" text={cart?.equipment?.label} sx={{ ...orderTextStyle(), width: "auto" }} />
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateEquipmentOpenCallback} /></Tooltip>
                                        <CheckoutEquipmentPopover
                                            anchorEl={equipmentAnchorEl}
                                            open={openEquipment}
                                            handleClose={updateEquipmentCloseCallback}
                                            inputValue={cart?.equipment?.label}
                                            handleInputChange={updateEquipmentValue}
                                            handleUpdate={updateEquipmentCallback}
                                            equipmentInvalid={equipmentInvalid}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {equipmentInvalid &&
                                            <CustomTypography variant="body2" text={t('checkout.equipmentInvalid')} sx={{ color: "red" }} />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} md={6} sx={{ marginTop: "20px" }}>
                        <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                <PersonIcon sx={orderIconStyle} />
                            </Grid>

                            <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={12} md={12}>
                                        <CustomTypography variant="body2" text={t('checkout.persons')} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                        <CustomTypography variant="body2" text={cart?.persons} sx={{ ...orderTextStyle(), width: "auto" }} />
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updatePersonsOpenCallback} /></Tooltip>
                                        <CheckoutPersonsPopover
                                            anchorEl={personsAnchorEl}
                                            open={openPersons}
                                            handleClose={updatePersonsCloseCallback}
                                            inputValue={cart?.persons}
                                            handleUpdate={updatePersonsCallback}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} md={12} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Grid container sx={{ width: "90%", paddingLeft: "20px" }}>
                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                {cart?.paymentMethod === "cash" &&
                                    <FontAwesomeIcon key={"icon-cash"} icon={faMoneyBill} style={orderFontAwesomeIconStyle()} />
                                }
                                {cart?.paymentMethod === "card" &&
                                    <FontAwesomeIcon key={"icon-card"} icon={faCreditCard} style={orderFontAwesomeIconStyle()} />
                                }
                            </Grid>

                            <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={12} md={12}>
                                        <CustomTypography variant="body2" text={t('order.paymentMethod')} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                        <CustomTypography variant="body2" text={cart?.paymentMethod} sx={{ ...orderTextStyle(), width: "auto" }} />
                                        {data.outlet?.settings?.paymentMethods?.length === 2 &&
                                            <>
                                                <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updatePaymmentMethodOpenCallback} /></Tooltip>

                                                <CheckoutPaymentMethodPopover
                                                    anchorEl={paymmentMethodAnchorEl}
                                                    open={openPaymmentMethod}
                                                    handleClose={updatePaymmentMethodCloseCallback}
                                                    inputValue={cart?.paymentMethod}
                                                    handleUpdate={updatePaymmentMethodCallback}
                                                />
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

                <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                    <Grid container gap={1}>
                        <Grid item xs={12} md={12}>
                            <InputLabel sx={orderCartTitleTextStyle()}>{t('checkout.cart')}</InputLabel>
                        </Grid>

                        {cart?.items?.map((cartItem, index) =>
                            <Grid item xs={12} md={12}>
                                <CustomSingleCardItem outlet={data.outlet} cartItem={cartItem} currency={data.outlet?.currency} handleItemDialogState={handleItemDialogState} cartIndex={index} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {
                    cart?.pvcRecyclingTax > 0 &&
                    <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                        <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                <FontAwesomeIcon key={"icon-env-tax"} icon={faLeaf} style={orderItemFontAwesomeIconStyle()} />
                            </Grid>

                            <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={t('checkout.pvcRecyclingTax')} sx={orderItemTaxTextStyle()} />
                            </Grid>

                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={priceTextWithCurrency(data.outlet?.currency, cart?.pvcRecyclingTax)} sx={orderItemTaxPriceTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {
                    (cart?.discount === null || cart?.discount?.coupon === null) &&
                    <Grid item xs={12} md={12} sx={orderReviewGridStyle()} >
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={2} md={2}>
                                <FontAwesomeIcon key={"coupon"} icon={faTicket} style={orderFontAwesomeIconStyle()} />
                                {data.customerCoupons?.length > 0 &&
                                    <Badge badgeContent={data.customerCoupons.length} color="primary" sx={{ marginBottom: "50px" }} />
                                }
                            </Grid>

                            <Grid item xs={8} md={8} sx={{ alignSelf: "center" }} onClick={null}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={12} md={12}>
                                        <CustomTypography variant="body2" text={data.customerCoupons?.length > 0 ? t('checkout.discount.couponExists') : t('checkout.discount.couponNotExists')} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={2} md={2}>
                                <NavigateNextIcon sx={orderIconStyle} onClick={updateCouponOpenCallback} />
                            </Grid>
                        </Grid>

                        <CheckoutCouponPopover
                            anchorEl={couponAnchorEl}
                            open={openCoupon}
                            handleClose={updateCouponCloseCallback}
                            organization={data.organization}
                            existingCoupons={data.customerCoupons}
                            handleUpdate={updateCouponCallback}
                            handleInputChange={updateCouponValue}
                            inputValue={coupon}
                            couponInvalid={couponInvalid}
                        />
                    </Grid>
                }

                {
                    (cart?.discount !== null && cart?.discount?.coupon !== null) &&
                    <Grid item xs={12} md={12} sx={orderReviewGridStyle()} onClick={(event) => updateCouponOpenCallback(event)}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left", alignItems: "center" }}>
                            <Grid item xs={2} md={2}>
                                <FontAwesomeIcon key={"coupon"} icon={faTicket} style={orderFontAwesomeIconStyle()} />
                            </Grid>

                            <Grid item xs={8} md={8} sx={{ alignSelf: "center" }} onClick={null}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left", alignItems: "center" }}>
                                    <Grid item xs={4} md={4}>
                                        <CustomTypography variant="body2" text={t('coupon.code')} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <CustomTypography variant="body2" text={cart?.discount?.coupon?.code} sx={orderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <CustomChip label={`${cart?.discount?.coupon?.value} ${findCurrency(data.outlet?.currency)}`} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={2} md={2}>
                                <Delete sx={orderIconStyle} onClick={() => dispatch(updateCartCoupon(data.outlet?.id, null))} />
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {
                    (cart?.discount !== null && cart?.discount?.loyalty !== null && data.customerCoupons?.size > 0) &&
                    <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                        <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                <PercentIcon sx={orderItemMuiIconStyle} />
                            </Grid>

                            <Grid item xs={10} md={10}>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <CustomTypography variant="body2" text={t('checkout.discount')} sx={orderDiscountTextStyle()} />
                                    </Grid>

                                    {cart?.discount?.loyalty?.discountPercentage > 0 &&
                                        <Grid item xs={12} md={12}>
                                            <Grid container>
                                                <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                    <CustomTypography variant="body2" text={`${t('order.loyalty')} (${cart?.discount?.loyalty?.name}):`} sx={orderDiscountTextsStyle()} />
                                                </Grid>

                                                <Grid item xs={2} md={2}>
                                                    <CustomTypography variant="body2" text={`${cart?.discount?.loyalty?.discountPercentage} %`} sx={orderDiscountValueTextStyle()} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    {cart?.discount?.coupon?.value > 0 &&
                                        <Grid item xs={12} md={12}>
                                            <Grid container>
                                                <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                    <CustomTypography variant="body2" text={t('checkout.coupon')} sx={orderDiscountTextsStyle()} />
                                                </Grid>
                                                <Grid item xs={2} md={2}>
                                                    <CustomTypography variant="body2" text={priceTextWithCurrency(data.outlet?.currency, cart?.discount?.coupon?.value)} sx={orderItemTotalPriceTextStyle()} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Divider light flexItem sx={{ borderRightWidth: 5, width: "80%", marginLeft: "11%", marginTop: "20px" }} />

                {
                    cart?.taxPercent > 0 &&
                    <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                        <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                {/* <ReceiptIcon sx={orderItemMuiIconStyle} /> */}
                            </Grid>

                            <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={`${t('checkout.tax')}  (${cart?.taxPercent}%)`} sx={orderItemTotalTextStyle()} />
                            </Grid>

                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={priceTextWithCurrency(data.outlet?.currency, calculateTax(cart))} sx={orderItemTotalPriceTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <ReceiptIcon sx={orderItemMuiIconStyle} />
                        </Grid>

                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={t('checkout.paymentTotal')} sx={orderItemTotalTextStyle()} />
                        </Grid>

                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={priceTextWithCurrency(data.outlet?.currency, calculateTotalAmount(cart))} sx={orderItemTotalPriceTextStyle()} />
                        </Grid>

                        {calculateLoyaltyPointsEarn(cart) > 0 &&
                            <Grid item xs={12} md={12} sx={{ alignSelf: "center", marginTop: "20px" }}>
                                <CustomTypography variant="body2" text={`${t('checkout.loyaltyPointsToEarn')} ${calculateLoyaltyPointsEarn(cart)} ${t('checkout.points')}`} sx={{ ...orderItemTotalPriceTextStyle(), fontWeight: "200" }} />
                            </Grid>
                        }
                    </Grid>
                </Grid>

                {
                    cart?.paymentMethod === "card" &&
                    <Grid item xs={12} md={12} sx={{ marginTop: "20px", ...orderReviewGridStyle(), padding: "10px 0" }}>
                        <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                            <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={t('order.tip.checkout.label')} sx={orderItemTotalTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={t('order.tip.checkout.info')} sx={{ ...orderItemTotalPriceTextStyle(), fontWeight: "200" }} />
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ alignSelf: "center", marginBottom: "20px" }}>
                                <Grid container gap={1} sx={{ marginTop: "10px" }}>
                                    <Grid item xs={2.7}>
                                        <Card
                                            variant="outlined"
                                            onClick={() => dispatch(updateCartTipAmount(data.outlet?.id, 0))}
                                            sx={orderTipCardStyle(cart?.tipAmount === 0)}
                                        >
                                            <CardActionArea sx={orderTipCardActionStyle()}>
                                                <CustomTypography variant="body1" text={0} sx={orderTipCardActionTextStyle()} />
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={2.7}>
                                        <Card
                                            variant="outlined"
                                            onClick={() => dispatch(updateCartTipAmount(data.outlet?.id, 0.05))}
                                            sx={orderTipCardStyle(cart?.tipAmount === 0.05)}
                                        >
                                            <CardActionArea sx={orderTipCardActionStyle()}>
                                                <CustomTypography variant="body1" text={"5%"} sx={orderTipCardActionTextStyle()} />
                                                <CustomTypography variant="body1" text={priceTextWithCurrency(data.outlet?.currency, calculateTotalAmount(cart) * 0.05)} sx={orderTipCardActionTextPriceStyle()} />
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={2.7}>
                                        <Card
                                            variant="outlined"
                                            onClick={() => dispatch(updateCartTipAmount(data.outlet?.id, 0.1))}
                                            sx={orderTipCardStyle(cart?.tipAmount === 0.1)}
                                        >
                                            <CardActionArea sx={orderTipCardActionStyle()}>
                                                <CustomTypography variant="body1" text={"10%"} sx={orderTipCardActionTextStyle()} />
                                                <CustomTypography variant="body1" text={priceTextWithCurrency(data.outlet?.currency, calculateTotalAmount(cart) * 0.1)} sx={orderTipCardActionTextPriceStyle()} />
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={2.7}>
                                        <Card
                                            variant="outlined"
                                            onClick={() => dispatch(updateCartTipAmount(data.outlet?.id, 0.15))}
                                            sx={orderTipCardStyle(cart?.tipAmount === 0.15)}
                                        >
                                            <CardActionArea sx={orderTipCardActionStyle()}>
                                                <CustomTypography variant="body1" text={"15%"} sx={orderTipCardActionTextStyle()} />
                                                <CustomTypography variant="body1" text={priceTextWithCurrency(data.outlet?.currency, calculateTotalAmount(cart) * 0.15)} sx={orderTipCardActionTextPriceStyle()} />
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {
                    (cart?.comments !== null && data.outlet?.settings?.allowComments) &&
                    <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                        <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                            <Grid item xs={2} md={2}>
                                <MessageIcon sx={orderIconStyle} />
                            </Grid>

                            <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={t('checkout.comments')} sx={orderItemTotalTextStyle()} />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                                <CustomTextArea
                                    placeholder={t('checkout.requestPlaceholder')}
                                    value={cart?.comments}
                                    inputProps={{ maxLength: 100 }}
                                    maxLength={100}
                                    onChange={(event) => {
                                        let newValue = event.target.value.slice(0, 100);
                                        // formValChangeWithParentElementWithNameAndValue("order.comments", newValue, data, setData);
                                        dispatch(updateCartComments(data.outlet.id, newValue));
                                    }}
                                    variant="outlined"
                                    sx={{ borderColor: "lightgrey", paddingTop: "15px" }}
                                    endDecorator={
                                        <Box sx={checkoutCommentTextareaBoxStyle}>
                                            <Divider flexItem={true} />
                                            <span style={{ color: cart?.comments?.length === 100 ? "red" : "initial" }}>
                                                {`${cart?.comments?.length}  `}
                                            </span>
                                            <span style={{ ...checkoutCommentTextareaBoxInfoCharStyle(), color: cart?.comments?.length === 100 ? "red" : "initial" }}>
                                                {cart?.comments?.length === 1 ? t('checkout.requestCharLimit') : t('checkout.requestCharsLimit')}
                                            </span>
                                            <span style={{ color: cart?.comments?.length === 100 ? "red" : "initial" }}>
                                                {` /${requestTextFieldLimit}`}
                                            </span>

                                        </Box>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

                <Grid item xs={12} md={12} sx={{ marginTop: "30px", marginBottom: "50px" }}>
                    <Button
                        variant="extended"
                        onClick={addOrder}
                        disabled={loadingBtn || buttonDisabled}
                        // disabled={cart?.paymentMethod === "cash" || (cart?.paymentMethod === "card" && cart?.isPaid === true)}
                        sx={{
                            width: "300px",
                            borderRadius: "10px",
                            position: 'fixed',
                            bottom: 16,
                            height: "40px",
                            left: "10%",
                            background: (data.outlet?.settings?.buttonBackgroundColor && !loadingBtn && !buttonDisabled) ? data.outlet?.settings?.buttonBackgroundColor : "lightgrey",
                            color: (data.outlet?.settings?.buttonColor && !loadingBtn && !buttonDisabled) ? data.outlet?.settings?.buttonColor : "darkgrey",
                            ":hover": { background: (data.outlet?.settings?.buttonBackgroundColor && !loadingBtn && !buttonDisabled) ? data.outlet?.settings?.buttonBackgroundColor : `grey` }
                        }}>
                        <Stack direction="row" spacing={2} sx={cartButtonStackStyle()}>
                            <ItemPaper sx={cartButtonLabelItemStyle(cart?.paymentMethod === "card")}>
                                {cart?.paymentMethod === "cash" ? t('checkout.buttons.sendOrder') : t('checkout.buttons.payOrder')}
                                {loadingBtn && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "47a6d2",
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </ItemPaper>

                            {cart?.paymentMethod === "card" &&
                                <ItemPaper sx={cartButtonTotalAmmountItemStyle()}>
                                    <CustomTypography
                                        variant="body2"
                                        text={`${calculateTotalAmount(cart) + cart?.tipAmount * calculateTotalAmount(cart)} ${findCurrency(data.outlet?.currency)}`}
                                        sx={cartButtonTotalAmmountTextStyle()}
                                    />
                                </ItemPaper>
                            }
                        </Stack>
                    </Button>

                    {buttonDisabled && <Alert severity="error">{t('checkout.buttons.sendOrderDisabledAlert')}</Alert>}
                </Grid>

                <CustomDialogBasedOnScreen
                    isOpen={dialog.isOpen}
                    handleClose={() => { handleDialogState(false) }}
                    paperSx={!isMobile ? autDrawerPaperStyle() : { ...autDrawerPaperStyle(), padding: dialog.item !== null ? "0 0 10px 0" : "initial" }}
                    body={body()}
                />
            </CustomGrid>
        );
    }
}

export default CheckoutComponent;