import { Box, Checkbox, Divider, FormControlLabel, Grid } from '@mui/material';
import feedbackApi from 'api/feedback';
import { LottieFeedbackSuccess, LottieSuccessConfetti } from 'assets/lottie/LottieAnimations';
import { MessageIcon } from 'assets/mui/MuiIcons';
import CustomDelayedOrHiddenLottieAnimation from 'components/Asset/CustomDelayedOrHiddenLottieAnimation';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeader from "components/Header/CustomTopHeader";
import CustomRating from 'components/Rating/CustomRating';
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTypography from 'components/Typography/CustomTypography';
import { lottieOrderRatingOptions, lottieOrderSuccessRatingOptions } from 'constants/animations/lottieOptions';
import { getDefaultRatingObject } from 'constants/defaultObjects';
import { requestTextFieldLimit } from 'constants/textFieldLimit';
import Lottie from 'lottie-react';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { checkoutCommentTextareaBoxInfoCharStyle, checkoutCommentTextareaBoxStyle } from 'themes/checkout/checkoutTheme';
import { mainTag, orderIconStyle, ratingCheckbox, ratingInfoText, ratingMessageTitleTextStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/functions';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The AddOrderRatingComponent, that presents a new outlet's order rating component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AddOrderRatingComponent() {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { orderID } = useParams();

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        order: {},
        outlet: {},
        rating: getDefaultRatingObject("ORDER"),
        ratingStyle: "star",
        isAdded: false
    });

    useEffect(() => {
        getOrderRating();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //change document title
    useDocumentTitle(`${t('order.rating.orderLabel')} | ${t('order.rating.label')}`);

    /**
     * The rest endpoint to get the outlet order rating details.
     */
    function getOrderRating() {
        feedbackApi.fetchNew(orderID).then((r) => {
            if (r.data.code === "SUCCESS") {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    order: r.data.returnobject.order,
                    outlet: r.data.returnobject.outlet,
                    ratingStyle: r.d4.returnobject.ratingStyle
                });
            }
        }).catch((e) => {
            // console.log(e);
        });
    }

    /**
     * The rest endpoint to add the outlet order customer rating.
     */
    function addOrderRating() {
        if (data.rating?.message?.length > 100) {
        } else {
            feedbackApi.create(orderID, data.rating).then((r) => {
                if (r.data.code === "SUCCESS") {
                    formValChangeWithParentElementWithNameAndValue("isAdded", true, data, setData);
                }
            }).catch((e) => {
                // console.log(e);
            });
        }
    }

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="rating" sx={mainTag()}>
            <CustomTopHeader key="th" />

            <Grid container spacing={2} justifyContent={"center"} justifyItems={"center"} sx={{ width: "100%", margin: "0px 20px 10px" }}>
                {data.isAdded &&
                    <>
                        <Grid item xs={12} md={12} sx={{ textAlign: "-webkit-center" }}>
                            <CustomDelayedOrHiddenLottieAnimation
                                delay={0}
                                duration={4700}
                                lottieTag={
                                    <Lottie
                                        {...lottieOrderRatingOptions}
                                        animationData={LottieSuccessConfetti}
                                    />
                                }
                            />
                            <CustomDelayedOrHiddenLottieAnimation
                                delay={4700}
                                // duration={1000}
                                lottieTag={
                                    <Lottie
                                        {...lottieOrderSuccessRatingOptions}
                                        animationData={LottieFeedbackSuccess}
                                    />
                                }
                            />
                            <CustomTypography variant="body2" text={t('order.rating.ratingInfo')} sx={ratingInfoText()} />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ textAlign: "center", marginTop: "30px" }}>
                            <CustomIconButton
                                variant="outlined"
                                onClick={() => navigate(location.pathname.replace(/\/feedback\/add$/, '').replace("/order", "/myorders"))}
                                label={t('order.rating.back')}
                                sx={{ textTransform: "none" }}
                            />
                        </Grid>
                    </>
                }

                {!data.isAdded &&
                    <>
                        <Grid item xs={2} md={2}>
                            <MessageIcon sx={orderIconStyle} />
                        </Grid>

                        <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={t('order.rating.message')} sx={ratingMessageTitleTextStyle()} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomRating
                                size={"large"}
                                iconStyle={data.outlet?.ratingStyle}
                                value={data.rating.starRating}
                                sx={{ alignItems: "center" }}
                                labelSize={"40px"}
                                onChange={(event) => {
                                    formValChangeWithParentElementWithNameAndValue("rating.starRating", event.target.value, data, setData)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ alignSelf: "center", marginTop: "20px" }}>
                            <CustomTextArea
                                placeholder={t('checkout.requestPlaceholder')}
                                value={data.rating.comments}
                                inputProps={{ maxLength: 100 }}
                                maxLength={100}
                                onChange={(event) => {
                                    let newValue = event.target.value.slice(0, 100);
                                    formValChangeWithParentElementWithNameAndValue("rating.message", newValue, data, setData);
                                }}
                                variant="outlined"
                                sx={{ borderColor: "lightgrey", paddingTop: "15px" }}
                                endDecorator={
                                    <Box sx={checkoutCommentTextareaBoxStyle}>
                                        <Divider flexItem={true} />
                                        <span style={{ color: data.rating?.message?.length === 100 ? "red" : "initial" }}>
                                            {`${data.rating?.message?.length}  `}
                                        </span>
                                        <span style={{ ...checkoutCommentTextareaBoxInfoCharStyle(), color: data.rating?.message?.length === 100 ? "red" : "initial" }}>
                                            {data.rating?.message?.length === 1 ? t('checkout.requestCharLimit') : t('checkout.requestCharsLimit')}
                                        </span>
                                        <span style={{ color: data.rating?.message?.length === 100 ? "red" : "initial" }}>
                                            {` /${requestTextFieldLimit}`}
                                        </span>
                                    </Box>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ textAlign: "justify", marginTop: "30px" }}>
                            {data.rating.starRating > 3 &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.rating.consentSentToGoogleReview}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("rating.consentSentToGoogleReview", event.target.checked, data, setData)}
                                            sx={ratingCheckbox()}
                                        />}
                                    label={t('order.rating.checkboxInfo')}
                                    labelPlacement="end"
                                    sx={{
                                        '& .MuiFormControlLabel-label': { fontSize: "13px" }
                                    }}
                                />
                            }
                        </Grid>

                        <CustomIconButton
                            variant="outlined"
                            onClick={addOrderRating}
                            label={t('order.rating.btn')}
                            sx={{ textTransform: "none", fontSize: "15px", marginTop: '20px' }}
                        />
                    </>
                }
            </Grid>
        </CustomGrid>
    );
}

export default AddOrderRatingComponent;