import { getLanguageFromURL } from "utils/language";

export function openInNewTab(url) {
    window.open(url, '_blank');
}

export function isLogoutPathRedirect() {
    const path = window.location.pathname;
    const basePath = `/${getLanguageFromURL()}`;

    // Check if the current path matches any of the specified paths
    return path === `${basePath}/settings` || 
           path === `${basePath}/profile` || 
           path === `${basePath}/mycoupons` || 
           path === `${basePath}/myratings` || 
           path === `${basePath}/myorders` || 
           path === `${basePath}/loyalty` || 
           path === `${basePath}/coupon` || 
           path === `${basePath}/vivaWalletSuccessPaymentPage`;
}

export default class links {
    static openInNewTab(url) { return openInNewTab(url); }
}