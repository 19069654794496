import { LottieFail, LottieInvalid, LottieSuccessAnimation } from "assets/lottie/LottieAnimations"
import { lottieDefaultOptions } from "constants/animations/lottieOptions"
import Lottie from "lottie-react";

export const getDialogTypeAnimation = (dialogType) => {
    return <>
        {dialogType === "success" &&
            <Lottie
                {...lottieDefaultOptions}
                animationData={LottieSuccessAnimation}
            />
        }

        {dialogType === "fail" &&
            <Lottie
                {...lottieDefaultOptions}
                animationData={LottieFail}
            />
        }

        {["invalid", "info"].includes(dialogType) &&
            <Lottie
                {...lottieDefaultOptions}
                animationData={LottieInvalid}
            />
        }
    </>
}