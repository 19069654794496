import { getLangOptions } from "constants/langOptions";
import i18n from "i18n";
import { isStringContainsTextFromArrayOfSubstrings } from "./functions";

/**
 * Change the language.
 * 
 * @param {String} langCode the language code to be set (if given)
 */
export function changeLanguage(langCode = undefined) {
    let lang = (langCode === undefined) ? getLanguageFromURL() : langCode;
    i18n.changeLanguage(lang.toLowerCase());
    updateBrowserLanguage(lang.toLowerCase())

}
export function updateBrowserLanguage(newLang) {
    const currentPath = window.location.pathname;
    let newPath;

    // Replace existing language prefix if present
    if (currentPath.startsWith('/en/')) {
        newPath = currentPath.replace(/^\/en\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/el/')) {
        newPath = currentPath.replace(/^\/el\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/de/')) {
        newPath = currentPath.replace(/^\/de\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/fr/')) {
        newPath = currentPath.replace(/^\/fr\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/it/')) {
        newPath = currentPath.replace(/^\/it\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/ru/')) {
        newPath = currentPath.replace(/^\/ru\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/uk/')) {
        newPath = currentPath.replace(/^\/uk\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/es/')) {
        newPath = currentPath.replace(/^\/es\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/nl/')) {
        newPath = currentPath.replace(/^\/nl\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/cs/')) {
        newPath = currentPath.replace(/^\/cs\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/pl/')) {
        newPath = currentPath.replace(/^\/pl\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/bg/')) {
        newPath = currentPath.replace(/^\/bg\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/ar/')) {
        newPath = currentPath.replace(/^\/ar\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/et/')) {
        newPath = currentPath.replace(/^\/et\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/lt/')) {
        newPath = currentPath.replace(/^\/lt\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/ro/')) {
        newPath = currentPath.replace(/^\/ro\//, `/${newLang}/`);
    } else if (currentPath.startsWith('/tr/')) {
        newPath = currentPath.replace(/^\/tr\//, `/${newLang}/`);
    } else {
        // If no existing language prefix, prepend with new language
        newPath = `/${newLang}${currentPath}`;
    }

    // Redirect to the new path
    window.location.pathname = newPath;
};

/**
 * Get current language from url path. If language code not matching 
 * the available languages returns default language code.
 * 
 * @returns the lang code
 */
export function getLanguageFromURL() {
    let pageUrl = window.location.pathname;
    if (isStringContainsTextFromArrayOfSubstrings(pageUrl, getLangOptions().map(function (e) { return '/' + e }))) {
        return pageUrl.split("/")[1];
    }
    return "en";
}


export function getLanguageForLocale() {
    let language = getLanguageFromURL();
    switch (language) {
        case "en":
            return "enUS";
        case "el":
            return "elGR";
        default:
            return "enUS";
    }
}


export default class functions {
    static changeLanguage(langCode) { return changeLanguage(langCode); };
    static getLanguageFromURL() { return getLanguageFromURL(); };
    static getLanguageForLocale() { return getLanguageForLocale(); };
}