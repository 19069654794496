import React from 'react';
import Popover from '@mui/material/Popover';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextField from 'components/Text/CustomTextField';
import { orderHeaderTextStyle, textFieldError } from 'themes/defaultThemes';
import { SearchIcon } from 'assets/mui/MuiIcons';
import CustomCustomerCouponCard from 'components/Card/coupon/CustomCustomerCouponCard';
import CustomTypography from 'components/Typography/CustomTypography';

function CheckoutCouponPopover({ anchorEl, open, handleClose, couponInvalid, inputValue, handleInputChange, handleUpdate, existingCoupons, organization }) {
  const id = open ? 'simple-popover' : undefined;
  const { t } = useTranslation();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box style={{ padding: '20px' }}>
        <Grid container gap={1}>
          <Grid item xs={12} md={12}>
            <CustomTypography variant="body2" text={existingCoupons?.length > 0 ? t('checkout.discount.textWithAvailableCoupons') : t('checkout.discount.textWithNoAvailableCoupons')} sx={orderHeaderTextStyle()} />
          </Grid>

          <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
            <CustomTextField
              type="text"
              id="couponCode"
              name="couponCode"
              required={true}
              defaultValue={inputValue}
              onChange={handleInputChange}
              error={couponInvalid}
              helperText={couponInvalid ? t('checkout.discount.invalid') : ''}
              sx={textFieldError()}
            />
          </Grid>

          <Grid item xs={3} md={3} sx={{ alignSelf: "center" }}>
            <Button
              onClick={(event) => handleUpdate(event.target.value, null)}
              color="primary"
              startIcon={<SearchIcon />}
              variant="contained"
              sx={{ borderRadius: "30px" }}
            >
              {/* {t('button.update')} */}
            </Button>
          </Grid>


          <Grid item xs={12} md={12}>
            {existingCoupons?.map((coupon) => (
              <CustomCustomerCouponCard
                coupon={coupon}
                organization={organization}
                onClick={handleUpdate}
              />

            ))}
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
}

export default CheckoutCouponPopover;