import { request } from "constants/alias";
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from "utils/functions";
import { getLanguageFromURL } from "utils/language";

/**
 * The rest request which attempts to get the orders list from the database.
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the orders list
 */
export async function fetchAll(search, pageable) {
  let data = {
    forCustomer: true,
    lang: search.lang
  };

  if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer/order`, pageable !== undefined ? { ...pageable, ...data } : data));
  else return await request.get(includeParamsToUrl(`${API}customer/order`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get the orders list from the database.
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the orders list
 */
export async function fetchAllUnpaid(search, pageable) {
  let data = {
    forCustomer: true,
    lang: search.lang,
  };
  if (search.equipmentLabel !== "") {
    data = { ...data, equipmentLabel: search.equipmentLabel }
  }
  if (search.equipmentID !== "") {
    data = { ...data, equipmentID: search.equipmentID }
  }
  if (search.outletURL !== undefined && search.outletURL !== "") {
    data = { ...data, outletURL: search.outletURL }
  }


  if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer/order/unpaid`, pageable !== undefined ? { ...pageable, ...data } : data));
  else return await request.get(includeParamsToUrl(`${API}customer/order/unpaid`, pageable !== undefined ? pageable : {}));
}


export async function create(order) {
  return await request.post(`${API}order`, order);
}

export async function pay(data) {
  return await request.post(`${API}customer/order/pay`, data);
}

/**
 * The rest request which attempts to get an existing order information.
 * @param {*} orderID The id of the customer order to be fetched
 * @returns an object with an attribute containing the current infromation of the order
 */
export async function fetchOne(orderID) {
  let data = {
    lang: getLanguageFromURL()
  };

  if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer/order/${orderID}`, data));
  else return await request.get(includeParamsToUrl(`${API}customer/order/${orderID}`));
}

const orderApi = {
  fetchAll,
  fetchAllUnpaid,
  create,
  pay,
  fetchOne
};

export default orderApi;
