import {
    Grid, Tooltip
} from '@mui/material';
import { EditIcon } from 'assets/mui/MuiIcons';
import CartItemPopover from 'components/Popover/CartItemPopover';
import CustomTypography from 'components/Typography/CustomTypography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { removeItemFromCart, updateItemInCart } from 'store/cart/cartActions';
import { orderCartItemQuantityTextStyle, orderItemHeaderTextStyle, orderItemNoteTextStyle, orderTextStyle } from 'themes/defaultThemes';
import { priceTextWithCurrency } from 'utils/functions/order';

/**
 * The CustomSingleCardItem, that display each customer cart item.
 * 
 * @param {object} cartItem the cart item  details
 * @param {string} currency the outlet currency
 *
 * @returns {JSX.Element} A React component that renders a cart item single instance
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSingleCardItem({ outlet, cartItem, currency, cartIndex, handleItemDialogState }) {
    const { t } = useTranslation();
    const [modifiersPrice, setModifiersPrice] = useState(0);
    const [modifiersText, setModifiersText] = useState("");

    useEffect(() => {
        cartModifiersToText(cartItem.modifierListIds);
    }, []);

    function cartModifiersToText(modifierLists) {
        let text = "";
        let modifiersTotalPrice = 0;
        modifierLists?.map((modifierList, outerIndex) => {
            modifierList.modifiers?.map((modifier, innerIndex) => {
                text += `${modifier.nameEN ? modifier.nameEN : modifier.name}${innerIndex <= modifierList.modifiers.length - 1 && outerIndex < modifierLists.length - 1 ? ", " : " "}`;
                // if (modifier?.price > 0)
                //     modifiersTotalPrice += modifier.price;
            });
        });

        setModifiersPrice(modifiersTotalPrice);
        setModifiersText(text);
    }

    // cart
    const cart = useSelector(state => state.cart.carts[outlet?.id]?.cart || null);
    const dispatch = useDispatch();

    // ========================= ITEM 
    const [itemAnchorEl, setItemAnchorEl] = useState(null);

    function updateItemOpenCallback(event) {
        setItemAnchorEl(event.currentTarget);
    };

    function updateItemCloseCallback() {
        setItemAnchorEl(null);
    };

    function updateItemQuantityCallback(newValue) {
        let newItem = cart?.items[cartIndex];
        if (newItem.id === cartItem.id) {
            newItem.quantity = newValue;
        }
        dispatch(updateItemInCart(outlet.id, newItem.id, newItem, modifiersPrice, cartIndex));
        // Optionally close the popover
        // updateItemCloseCallback();
    };

    function removeItemCallback() {
        dispatch(removeItemFromCart(outlet.id, cartItem?.id, cartIndex));
        updateItemCloseCallback();
    };

    const openItem = Boolean(itemAnchorEl);
    return (
        <>
            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={3} md={3} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="span" text={cartItem?.quantity} sx={orderCartItemQuantityTextStyle()} />
                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateItemOpenCallback} /></Tooltip>
                        <CartItemPopover
                            anchorEl={itemAnchorEl}
                            open={openItem}
                            handleClose={updateItemCloseCallback}
                            inputValue={cartItem?.quantity}
                            handleUpdate={updateItemQuantityCallback}
                            removeItemCallback={removeItemCallback}
                        />
                    </Grid>

                    <Grid item xs={7} md={7} sx={{ alignSelf: "center" }} onClick={() => handleItemDialogState(true, cartItem, cartIndex, cartItem)}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={cartItem?.name} sx={orderItemHeaderTextStyle()} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={modifiersText} sx={orderTextStyle()} />
                            </Grid>

                            {cartItem.notes.length > 0 &&
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={`${t('order.notes')} ${cartItem?.notes}`} sx={orderItemNoteTextStyle()} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }} onClick={() => handleItemDialogState(true, cartItem, cartIndex, cartItem)}>
                        <CustomTypography variant="body2" text={priceTextWithCurrency(currency, (cartItem.totalPrice * cartItem.quantity) + modifiersPrice)} sx={orderItemNoteTextStyle()} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default CustomSingleCardItem;