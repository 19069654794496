import { request } from 'constants/alias';
import { API } from "constants/config";

/**
 * The rest request which attempts to view the authenticated user profile.
 * @returns an object refering to the success or failure of the request
 */
export async function profile() {
    return await request.get(`${API}customer/profile`);
}

/**
 * The rest request which attempts to update an existing customer.
 * @param {*} data A JSON object which contains the updated infromation of the user
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}customer`, data);
}

/**
 * The rest request which attempts to create a new user.
 * @param {*} data A JSON object which contains new user infromation
 * @returns an object refering to the success or failure of the request
 */
export async function register(data) {
    return await request.post(`${API}customer`, data);
}

/**
 * The rest request which attempts to view the authenticated user language preferrence.
 * @returns an object refering to the success or failure of the request
 */
export async function language() {
    return await request.get(`${API}customer/language`);
}

/**
 * The rest request which attempts to update an existing customer preferred language.
 * @param {*} data A JSON object which contains the updated infromation of the cusstomer language
 * @returns an object refering to the success or failure of the request
 */
export async function updateLanguagePreferrence(data) {
    return await request.put(`${API}customer/language`, data);
}

/**
 * The rest request which attempts to request otp code for reset password
 *
 * @param {*} data A JSON object which contains the updated infromation of the user password
 * @returns an object refering to the success or failure of the request
 */
export async function forgotPassword(data) {
    return await request.post(`${API}customer/forgotPassword?emailOrUsername=` + data);
}

/**
 * The rest request to delete a specific user.
 * @param {*} id The id of the user to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}customer/${id}`);
}

const userApi = {
    profile,
    register,
    language,
    updateLanguagePreferrence,
    update,
    forgotPassword,
    deleteById
}

export default userApi;