import { Grid, useMediaQuery } from "@mui/material";
import userApi from "api/user";
import DeleteAccountCard from "components/Card/account/DeleteAccountCard";
import DeleteAccountDeleteCard from "components/Card/account/DeleteAccountDeleteCard";
import CustomLanguageCard from 'components/Card/language/CustomLanguageCard';
import CustomLanguageUpdateCard from 'components/Card/language/CustomLanguageUpdateCard';
import CustomDialogBasedOnScreen from "components/Dialog/CustomDialogBasedOnScreen";
import CustomDialogChildren from 'components/Dialog/CustomDialogChildren';
import { getDialogTypeAnimation } from 'components/Dialog/animation/dialogTypeAnimation';
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeader from 'components/Header/CustomTopHeader';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import { getMultiLangOptions } from 'constants/multilangOptions';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/material.css';
import { useNavigate } from "react-router-dom";
import AuthService from "services/auth.service";
import { mainTag } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/functions';
import { generateCode } from "utils/functions/account";
import { changeLanguage, getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The SettingsComponent that triggers the modification of an existing customer prefered language.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SettingsComponent() {
   const { t, i18n } = useTranslation();
   const isMobile = useMediaQuery('(max-width:468px)');
   const navigate = useNavigate();
   const deleteCode = generateCode();

   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);

   /**
    * @type {object}
    * @property {object} userAuth the authenticated customer infromation
    * @property {object} customer an empty object if add or the selected customer to be editted
    * @property {array} languages a list of the different languages
    */
   const [data, setData] = useState({
      userAuth: null,
      language: getMultiLangOptions()[0],
      languages: getMultiLangOptions(),
   });

   // pop dialog for delete
   const [dialog, setDialog] = useState({
      isOpen: false,
      type: "success",
      message: ""
   });

   const [deleteIsClicked, setDeleteIsClicked] = useState(false);

   //change document title
   useDocumentTitle(t('settings.label'));

   useEffect(() => {
      getcustomerLang();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   /**
    * The rest endpoint to get the customer default (add) or current (edit) information.
    */
   function getcustomerLang() {
      userApi.language().then((r) => {
         setData({
            ...data,
            userAuth: r.data.returnobject.userAuth,
            language: getLanguageFromURL() ? getLanguageFromURL() : r.data.returnobject.language
         });
         setIsLoaded(true);
      }).catch((e) => {
         // console.log(e);
      })
   }
   

   function updateLanguagePreferrence() {
      let requestData = {
         language: data.language
      }

      userApi.updateLanguagePreferrence(requestData).then((r) => {
         if (r.data.code === "SUCCESS") {
            handleDialogState(false);
            changeLanguage(data.language);
         }
         handleAnimationDialogState(true, (r.data.code === "SUCCESS") ? "success" : "info", r.data.message)
      }).catch((e) => {
         // console.log(e);
      })
   }

   function deleteAccountPermanently() {
      userApi.deleteById(data.userAuth?.id).then((r) => {
         if (r.data.code === "SUCCESS") {
            handleDialogState(false);
            AuthService.logout();
            navigate("/success/account/delete");
         }
      }).catch((e) => {
         // console.log(e);
      })
   }
   const [open, setOpen] = useState(false);

   /**
    * Function that handles the visualize modal open or close state.
    * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleAnimationDialogState(isOpen, type = "success", message = "") {
      setDialog({
         ...dialog,
         isOpen: isOpen,
         type: type,
         message: message
      });
   }

   /**
    * Function that handles the visualize modal open or close state.
    * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleDialogState(isOpen) {
      setOpen(isOpen);
   }

   // ===============================================================================RENDER

   function body() {
      const lang = data.language;
      return deleteIsClicked ?
         <DeleteAccountDeleteCard
            handleClose={() => handleDialogState(false)}
            handleDeleteClick={deleteAccountPermanently}
            deleteCode={deleteCode}
         />
         :
         <CustomLanguageUpdateCard
            language={lang}
            userLanguages={data.languages}
            updateLanguagePreferrence={updateLanguagePreferrence}
            updateLanguageField={(event) => formValChangeWithParentElementWithNameAndValue("language", event.target.value, data, setData)}
         />
   }

   // ===============================================================================RENDER

   if (!isLoaded) {
      return (
         <LoadingSkeleton lines={9} />
      );
   } else {
      return (
         <CustomGrid role="main" id="test2" sx={mainTag()}>
            <CustomTopHeader key="th" />

            <Grid container spacing={2} sx={{margin: "10px"}}>
               <CustomLanguageCard
                  lang={data.language}
                  handleLanguageClick={() => handleDialogState(true)}
               />

               <DeleteAccountCard
                  handleDeleteNavigationIconClick={() => { setDeleteIsClicked(true); handleDialogState(true) }}
               />
            </Grid>

            <CustomDialogBasedOnScreen
               isOpen={open}
               handleClose={() => { handleDialogState(false) }}
               body={body()}
            />

            {dialog.isOpen &&
               <CustomDialogChildren
                  isOpen={dialog.isOpen}
                  id="dialog"
                  title={t(`request.popup.${dialog.type}`)}
                  cancelLabel={t("request.btns.ok")}
                  message={dialog.message}
                  handleOpen={handleAnimationDialogState}
                  actionColor="#e91e1e"
                  children={getDialogTypeAnimation(dialog.type)}
               />
            }
         </CustomGrid>
      );
   }
}

export default SettingsComponent;