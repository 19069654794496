import { Button, Grid } from '@mui/material';
import { LottieScanQR } from "assets/lottie/LottieAnimations";
import CustomGrid from "components/Grid/CustomGrid";
import CustomTopHeader from "components/Header/CustomTopHeader";
import CustomTypography from "components/Typography/CustomTypography";
import { lottieScannerOptions } from "constants/animations/lottieOptions";
import Lottie from "lottie-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { QrReader } from 'react-qr-reader';
import { lumanosimoTitleStyle, mainTag } from "themes/defaultThemes";

/**
 * Qr code reader page
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function QrReaderComponent({ }) {
    const { t } = useTranslation();
    const [scanResult, setScanResult] = useState('');
    const [openScan, setOpenScan] = useState(false);

    function handleScan(data) {
        if (data) {
            setScanResult(data);
            window.location.href = data; // Redirect to the scanned link
        }
    };

    function handleError(err) {
        // console.error(err);
    };

    return (
        <CustomGrid role="main" id="request" sx={mainTag()}>
            <CustomTopHeader key="th" />

            <Grid container spacing={2} justifyContent={"center"} justifyItems={"center"} sx={{ width: "100%", padding: "20px"}}>
                {openScan &&
                    <>
                        <Grid item xs={12}>
                            <QrReader
                                delay={300}
                                style={{ width: '100%' }}
                                onError={handleError}
                                onScan={handleScan}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTypography
                                variant="body2"
                                text={t('qrCode.info')}
                                sx={lumanosimoTitleStyle()}
                            />
                        </Grid>
                    </>
                }

                {!openScan &&
                    <Grid item xs={12}>
                        <Lottie
                            {...lottieScannerOptions}
                            animationData={LottieScanQR}
                        />

                        <Button variant="contained" color="primary" onClick={() => setOpenScan(true)}>{t('qrCode.scan')}</Button>
                    </Grid>
                }

                <Grid item xs={12}>
                    {scanResult && (
                        <p>Scan Result: <a href={scanResult}>{scanResult}</a></p>
                    )}
                </Grid>
            </Grid>
        </CustomGrid>
    );
}

export default QrReaderComponent;