import { requestButton } from "themes/defaultThemes"

// REQUESTS
export const getRequestButton = (selectedType, key, type, label) => {
    return {
        keyValue: `btn-${key}`,
        backgroundColor: "#dcf1f7",
        label: label,
        sx: requestButton(),
        variant: 'outlined',
        isFocused: selectedType === type
    }
}
export const getRequestButtonIcon = () => {
    return {
        width: 100,
        height: 100,
        fill: "#54b1d2"
    }
}

// OUTLET
export const getOutletActionButton = (key, label) => {
    return {
        keyValue: `btn-${key}`,
        backgroundColor: "#dcf1f7",
        label: label,
        sx: requestButton(),
        variant: 'outlined'
    }
}
export const getOutletActionButtonIcon = () => {
    return {
        width: 100,
        height: 100,
        fill: "#54b1d2"
    }
}


// =========== AUTH
export const PASSWORD_REQUIRED_LENGTH = 8;

// =========== ORDER
export const MORE_ORDER_ADD_SIZE = 10;

// =========== RATING
export const MORE_RATING_ADD_SIZE = 10;