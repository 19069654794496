import { request } from "constants/alias";
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from "utils/functions";

/**
 * The rest request which attempts to get the ratings list from the database.
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the ratings list
 */
export async function fetchAll(search, pageable) {
  let data = {};
  if (search.customerID !== undefined && search.customerID !== "") {
    data = { ...data, customerID: search.customerID }
  }
  if (search.organizationID !== undefined && search.organizationID !== "") {
    data = { ...data, organizationID: search.organizationID }
  }
  if (search.outletID !== undefined && search.outletID !== "") {
    data = { ...data, outletID: search.outletID }
  }

  if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}display/rating`, pageable !== undefined ? { ...pageable, ...data } : data));
  else return await request.get(includeParamsToUrl(`${API}display/rating`, pageable !== undefined ? pageable : {}));
}

export async function fetchCounts(search) {
  let data = {};
  if (search.customerID !== undefined && search.customerID !== "") {
    data = { ...data, customerID: search.customerID }
  }
  if (search.organizationID !== undefined && search.organizationID !== "") {
    data = { ...data, organizationID: search.organizationID }
  }
  if (search.outletID !== undefined && search.outletID !== "") {
    data = { ...data, outletID: search.outletID }
  }

  if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}display/rating/analytics`, data));
  else return await request.get(includeParamsToUrl(`${API}display/rating/analytics`, null));
}

/**
 * The rest request which return required information to be presented on add outlet rating page.
 * 
 * @returns an object with an attribute containing the requested information to be presented on add outlet rating page
 */
export async function fetchOutletNew(outletURL) {
  return await request.get(`${API}rating/outlet/${outletURL}`);
}

/**
 * The rest request which attempts to create a new outlet rating.
 * 
 * @param {*} data A JSON object which contains new outlet rating infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function createOutlet(outletURL, data) {
  return await request.post(`${API}rating/outlet/${outletURL}`, data);
}

const ratingApi = {
  fetchAll,
  fetchCounts,
  fetchOutletNew,
  createOutlet
};

export default ratingApi;
