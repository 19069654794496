import {
    Button,
    Grid
} from '@mui/material';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTypography from 'components/Typography/CustomTypography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteAccountDialogActionsConfirmTextStyle, deleteAccountDialogActionsQuestionTextStyle, deleteAccountDialogHeaderTextStyle, deleteAccountDialogTextStyle } from 'themes/defaultThemes';

/**
 * The DeleteAccountDeleteCard, that display the delete account action dialog card.
 * @param {fucntion} handleDeleteClick Callback that handles the removal of the account
 *
 * @returns {JSX.Element} A React component that renders delete account actions dialog card
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function DeleteAccountDeleteCard({ deleteCode, handleDeleteClick, handleClose }) {
    const { t } = useTranslation();
    const [inputCode, setInputCode] = useState("");
    const [showErrorText, setShowErrorText] = useState(false);

    function deleteAccount() {
        if (inputCode === deleteCode) handleDeleteClick();
        else setShowErrorText(true);
    }

    return (
        <Grid item xs={12} md={12} sx={{ marginBottom: "40px" }}>
            <Grid container gap={1} sx={{ textAlign: "left", padding: "0 20px" }}>
                <Grid item xs={12} md={12} sx={{ justifyContent: "center" }}>
                    <CustomTypography variant="body1" text={t('customer.deleletAccount.label')} sx={deleteAccountDialogHeaderTextStyle()} />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: "60px" }}>
                    <CustomTypography variant="span" text={`${t('customer.deleletAccount.info')}`} sx={deleteAccountDialogTextStyle()} />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
                    <CustomTypography variant="span" text={`- ${t('customer.deleletAccount.info1')}`} sx={deleteAccountDialogTextStyle()} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <CustomTypography variant="span" text={`- ${t('customer.deleletAccount.info2')}`} sx={deleteAccountDialogTextStyle()} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <CustomTypography variant="span" text={`- ${t('customer.deleletAccount.info3')}`} sx={deleteAccountDialogTextStyle()} />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                    <CustomTypography variant="span" text={`${t('customer.deleletAccount.actionInfo')}`} sx={deleteAccountDialogActionsQuestionTextStyle()} />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: "30px", justifyContent: "center" }}>
                    <CustomTypography variant="span" text={`${t('customer.deleletAccount.confirmByTypingCodeBefore')} \`${deleteCode}\` ${t('customer.deleletAccount.confirmByTypingCodeAfter')}`} sx={deleteAccountDialogActionsConfirmTextStyle()} />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: "30px", justifyContent: "center" }}>
                    <CustomTextField
                        type={"text"}
                        name="inputCode"
                        id="inputCode"
                        value={inputCode}
                        error={showErrorText}
                        helperText={showErrorText ? t('customer.deleletAccount.codesNotMatching') : undefined}
                        onChange={(event) => setInputCode(event.target.value)}
                    />
                </Grid>
                <Grid item xs={6} sx={{ marginTop: "60px", justifyContent: "center" }}>
                    <Button variant="outlined" color="error" onClick={() => deleteAccount()}>{t('customer.deleletAccount.btn')}</Button>
                </Grid>
                <Grid item xs={5.7} sx={{ marginTop: "60px", textAlign: "right" }}>
                    <Button variant="outlined" color="error" onClick={handleClose}>{t('actions.cancel')}</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DeleteAccountDeleteCard;