export const getThemeColor = () => {
    return {
        "light": {
            ButtonBackgroundColor: "#54b1d2",
            deleteButtonBackgroundColor: "#ac2020",
            deleteButtonBackgroundColorHover: "#800606",
            viewButtonBackgroundColor: "#54b1d2",
            viewButtonBackgroundColorHover: "#1b4e67",
            editButtonBackgroundColor: "#e99f35",
            saveButtonBackgroundColor: "#54b1d2",
            saveButtonFontColor: "#fff",
            addButtonBackgroundColor: "#54b1d2",
            addButtonFontColor: "#fff",
            editButtonBackgroundColorHover: "#d88611",
            trueCcontrolButtonColor: "#2a8b46",
            falseControlButtonColor: "#c43333",
            fabBackgroundColor: "#54b1d2",
            fabBackgroundColorHover: "#126481",
            fabFontColor: "#fff",
            actions: {
                WORKING: "#6495ed",
                PENDING: "#ffa500",
                DONE: "#008000",
                CANCELED: "#ff0000"
            }
        },
        "dark": {
            ButtonBackgroundColor: "#1b4e67",
            deleteButtonBackgroundColor: "#721010",
            deleteButtonBackgroundColorHover: "#590404",
            editButtonBackgroundColor: "#c78930",
            editButtonBackgroundColorHover: "#bf7711",
            saveButtonBackgroundColor: "#186e8c",
            saveButtonFontColor: "#fff",
            viewButtonBackgroundColor: "#1b4e67",
            viewButtonBackgroundColorHover: "#10374a",
            trueCcontrolButtonColor: "#1c6531",
            falseControlButtonColor: "#651c1c",
            fabBackgroundColor: "#205d7b",
            fabFontColor: "#fff",
            addButtonBackgroundColor: "#186e8c",
            addButtonFontColor: "#fff",
            actions: {
                WORKING: "#284e90",
                PENDING: "#98670d",
                DONE: "#025202",
                CANCELED: "#731111"
            }
        }
    }
}