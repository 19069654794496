import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

/**
 * The OutletWifiLoadingSkeleton, that display a box with animated skeletons untils the outlet wifi page is ready to render.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OutletWifiLoadingSkeleton({ }) {

    return (
        <Box sx={{ width: "100%", padding: "10px 20px", textAlign: "-webkit-center" }}>
            <Grid container spacing={3} justifyContent={"center"} justifyItems={"center"} sx={{ width: "100%" }}>
                <Grid item xs={12}>
                    <Skeleton variant="rounded" height={180} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant="rounded" height={180} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant="rounded" height={180} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default OutletWifiLoadingSkeleton;