import { Card, CardActionArea, CardMedia, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDefaultBarcodeConfiguration } from 'constants/loyaltyCardProperties';
import { getLanguageFromURL } from 'utils/language';
import { MINIO_HOST } from 'constants/hosts';
import CustomTypography from 'components/Typography/CustomTypography';
import { logoStyle } from 'themes/defaultThemes';

/**
 * The Custom Loyalty Card, that display customized {@link Card}.
 * 
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {object} category loyalty card details
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomLoyaltyCardFrontSide({ category, sx, onClick, cardProperties,
    logoImgSrc = "",
    organizationID = "",
    userFullName = ""
}) {

    const { t } = useTranslation();

    return (
        <Card variant="outlined"
            key={`card-${category.id}-front`}
            sx={{ ...sx }}
            onClick={onClick}
        >
            <CardActionArea sx={{ justifyContent: 'flex-end', height: "100%", width: "100%", paddingLeft: "16px", textAlign: "-webkit-center" }} key={`card- back-ontent-${category.id}`}>
                <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                    <Grid item xs={12} md={12} sx={{ height: "100px" }}>
                        {(cardProperties.showLogo && logoImgSrc !== "" && logoImgSrc !== null) &&
                            <CardMedia
                                component="img"
                                src={`${MINIO_HOST}organization/${organizationID}/${logoImgSrc}`}
                                // alt={"logo"}
                                sx={{ ...logoStyle(), textAlign: "center" }}
                            />
                        }
                    </Grid>

                    <Grid item xs={8} md={8}>
                        <Grid container direction="column" spacing={0} sx={{ justifyContent: "center" }}>
                            <Grid item xs={12} md={12} sx={{ height: "25px" }}>
                                <CustomTypography level="h6" sx={{
                                    fontSize: 'md',
                                    color: category.baseColor,
                                    textAlign: "left",
                                    height: "25px",
                                    paddingBottom: 0,

                                }} text={userFullName} />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ height: "25px" }}>
                                <CustomTypography level="h6" sx={{
                                    fontSize: 'md',
                                    color: category.baseColor,
                                    // width: '75%',
                                    textAlign: "left",
                                    opacity: "0.7",
                                    paddingTop: 0,
                                    height: "25px"
                                }} text={cardProperties.showLoyaltyMemberNumber ? getDefaultBarcodeConfiguration().value : ""} />

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={4} md={4}>
                        <Grid container direction="column" spacing={0} sx={{ justifyContent: "center" }}>
                            <Grid item xs={12} md={12} sx={{ height: "25px" }}>
                                <CustomTypography level="h6"
                                    sx={{ fontSize: 'xs', color: category.baseColor, opacity: "0.7", paddingBottom: 0 }}
                                    text={cardProperties.showPoints ? `${category.targetPoints} ${t('loyalty.points')}` : ""}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ height: "25px" }}>
                                <CustomTypography level="h6"
                                    sx={{ fontSize: 'xs', color: category.baseColor, marginLeft: "5px!important" }}
                                    text={cardProperties.showLevel ? category.name : ""}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
}

export default CustomLoyaltyCardFrontSide;