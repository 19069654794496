import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to validate a user provided equipment.
 * @param {*} outletID The id of the equipment outlet id
 * @param {*} equipmentLabel The id of the equipment to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the equipment
 */
export async function validateExistance(outletID, equipmentLabel) {
    let data = {};
    if (outletID !== null && outletID !== "" && outletID !== undefined) {
        data.outletID = outletID;
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}equipment/validate/${equipmentLabel}`, data));
    else return await request.get(includeParamsToUrl(`${API}equipment/validate/${equipmentLabel}`));
}

const equipmentApi = {
    validateExistance
}

export default equipmentApi;