import { Typography } from '@mui/material';
import React from 'react';

/**
 * The Custom Typography, that display customized {@link Typography}.
 * 
 * @param {string} text the typography text
 * @param {string} variant Applies the theme typography styles.
 * @param {object} sx defining system overrides as well as additional CSS styles
 *
 * @returns {JSX.Element} A React component that renders a typography.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTypography({ text = "", variant = "body2", sx, onClick }) {
    return (
        <Typography sx={sx} variant={variant} onClick={onClick}>
            {text}
        </Typography>
    );
}

export default CustomTypography;