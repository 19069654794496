import {
    CardMedia,
    Divider,
    Grid,
    ListItem
} from '@mui/material';
import { AddIcon, PaymentIcon, ReviewsIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import Google from 'components/Login/GoogleLoginButton';
import CustomRating from 'components/Rating/CustomRating';
import CustomTypography from 'components/Typography/CustomTypography';
import { MINIO_HOST } from 'constants/hosts';
import { useTranslation } from 'react-i18next';
import { orderHeaderTextStyle, orderIconStyle, orderReviewGridStyle, pastOrderAddFeedbackButtonIconStyle, pastOrderAddFeedbackButtonStyle, pastOrderCartTextStyle, pastOrderDateTextStyle, pastOrderLogoStyle, pastOrderOutletTextStyle, ratingIconStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';

/**
 * The CustomPastOrderCard, that display each customer past order.
 * 
 * @param {object} order the past order details
 * @param {string} lang the lang code
 * @param {fucntion} handlePastOrderClick Callback that handles redirects past order details page.
 *
 * @returns {JSX.Element} A React component that renders a past order.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPastOrderCard({ order, handlePastOrderClick, handlePastOrderPayClick, handlePastOrderAddFeedbackClick, userAuth }) {
    const { t } = useTranslation();

    function cartToText() {
        let text = "";
        order.cart.map((cartItem) =>
            text += `${cartItem.quantity}x ${cartItem.name}, `
        );

        return text;
    }

    return (
        <>
            <Grid item xs={12} md={12}>
                <ListItem onClick={handlePastOrderClick}>
                    <Grid container sx={{ width: "90%", paddingLeftt: "10%" }}>
                        <Grid item xs={2} md={2} sx={{ paddingRight: "10px" }}>
                            {(order.outlet.logo && order.outlet.logo !== "" && order.outlet.logo !== null) &&
                                <CardMedia
                                    component="img"
                                    src={`${MINIO_HOST}organization/${order.outlet.organizationID}/outlet/${order.outlet.id}/${order.outlet.logo}`}
                                    // alt={"logo"}
                                    sx={{ ...pastOrderLogoStyle(), textAlign: "center", width: "40px", height: "auto" }}
                                />
                            }
                        </Grid>

                        <Grid item xs={10} md={10}>
                            <Grid container sx={{ marginLeft: "10px" }}>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={order?.outlet?.name} sx={pastOrderOutletTextStyle()} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={`${dateTimeFormatter(order?.dateCreated, "DD/MM/yyyy - HH:mm")} - ID: ${order?.id}`} sx={pastOrderDateTextStyle()} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={cartToText()} sx={pastOrderCartTextStyle()} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItem>
            </Grid>

            {!order.hasGivenFeedback && order.outlet.allowReviews && userAuth !== null &&
                <Grid item xs={5} md={5}>
                    <CustomIconButton
                        sx={pastOrderAddFeedbackButtonStyle()}
                        iconSx={pastOrderAddFeedbackButtonIconStyle()}
                        onClick={handlePastOrderAddFeedbackClick}
                        variant="outlined"
                        icon={<AddIcon sx={pastOrderAddFeedbackButtonIconStyle()} />}
                        label={t('order.addFeedback')}
                    />
                </Grid>
            }

            {order.hasGivenFeedback &&
                <Grid item xs={12} md={12} sx={orderReviewGridStyle()}>
                    <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                        <Grid item xs={2} md={2}>
                            <ReviewsIcon sx={orderIconStyle} />
                        </Grid>
                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                            <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                <Grid item xs={10} md={10}>
                                    <CustomRating
                                        readOnly={true}
                                        size={"medium"}
                                        iconStyle={order?.outlet?.ratingStyle}
                                        value={order?.rating?.starRating}
                                        sx={{ alignItems: "center" }}
                                    />
                                </Grid>
                                {order?.rating?.hasSentToGoogleReview &&
                                    <Grid item xs={2} md={2}>
                                        <Google sx={ratingIconStyle} />
                                    </Grid>
                                }

                                <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
                                    {order?.rating?.message != "" &&
                                        <CustomTypography variant="body2" text={order?.rating?.message} sx={orderHeaderTextStyle()} />
                                    }
                                </Grid>

                                <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
                                    <CustomTypography variant="body2" text={`${dateTimeFormatter(order?.rating?.dateCreated, "DD/MM/yyyy - HH:mm")} `} sx={{ ...pastOrderDateTextStyle(), textAlign: "right" }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {!order?.paid && order?.outlet?.paymentMethods?.includes("card") &&
                <Grid item xs={5} md={5}>
                    <CustomIconButton
                        sx={pastOrderAddFeedbackButtonStyle()}
                        iconSx={pastOrderAddFeedbackButtonIconStyle()}
                        onClick={handlePastOrderPayClick}
                        variant="outlined"
                        icon={<PaymentIcon sx={pastOrderAddFeedbackButtonIconStyle()} />}
                        label={t('order.unPaidOrdersButton')}
                    />
                </Grid>
            }

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />
        </>
    );
}

export default CustomPastOrderCard;