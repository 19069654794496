import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to fetch the viva payment url 
 * 
 * @param search 
 * 
 * @returns an object with an attribute containing the vat details
 */
export async function getPaymentUrl(search) {
    let data = { customer: true, amount: search.amount, order: search.order, paymentType: search.paymentType };
    if (search.organizationID !== undefined && search.organizationID !== "") {
        data = { ...data, organizationId: search.organizationID }
    }
    if (search.buyerID !== undefined && search.buyerID !== "") {
        data = { ...data, buyerId: search.buyerID }
    }
    return await request.post(`${API}vivaWallet/getPaymentUrl`, data);
}

export async function checkForVerifiedPayment(transactionId, outletID) {
    let data = {};
    if (outletID !== undefined && outletID !== "" && outletID !== null) {
        data = { ...data, outletID: outletID }
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}vivaWallet/checkForVerifiedPayment/${transactionId}`, data));
    else return await request.get(includeParamsToUrl(`${API}vivaWallet/checkForVerifiedPayment/${transactionId}`));
}

const vivaPaymentApi = {
    getPaymentUrl,
    checkForVerifiedPayment
}

export default vivaPaymentApi;