import { Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

/**
 * The CheckoutLoadingSkeleton, that display a box with animated skeletons untils a checkout page is ready to render.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CheckoutLoadingSkeleton({ isMobile }) {

    return (
        <Box sx={{ width: "100%", padding: "10px 40px" }}>
            {/* Skeleton for back button */}
            <Skeleton variant="rounded" width={100} height={30} />

            {/* Skeleton for euipment, persons, payment method */}
            <Grid container spacing={2} gap={2}>
                <Grid item xs={5} md={5} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%" }} >
                        <Grid item xs={isMobile ? 2 : 4} md={isMobile ? 2 : 4} sx={{ alignSelf: "center" }}>
                            <Skeleton variant="circular" width={40} height={40} />
                        </Grid>

                        <Grid item xs={isMobile ? 10 : 8} md={isMobile ? 10 : 8} sx={{ alignSelf: "center" }}>
                            <Grid container sx={{ marginLeft: (isMobile ? "30px" : "10px"), textAlign: "left" }}>
                                <Grid item xs={12} md={12}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={5} md={5} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", marginLeft: "30px" }} >
                        <Grid item xs={isMobile ? 2 : 4} md={isMobile ? 2 : 4} sx={{ alignSelf: "center" }}>
                            <Skeleton variant="circular" width={40} height={40} />
                        </Grid>

                        <Grid item xs={isMobile ? 10 : 8} md={isMobile ? 10 : 8} sx={{ alignSelf: "center" }}>
                            <Grid container sx={{ marginLeft: (isMobile ? "30px" : "10px"), textAlign: "left" }}>
                                <Grid item xs={12} md={12}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={5} md={5}>
                    <Grid container sx={{ width: "90%" }} >
                        <Grid item xs={isMobile ? 2 : 4} md={isMobile ? 2 : 4} sx={{ alignSelf: "center" }}>
                            <Skeleton variant="circular" width={40} height={40} />
                        </Grid>

                        <Grid item xs={isMobile ? 10 : 8} md={isMobile ? 10 : 8} sx={{ alignSelf: "center" }}>
                            <Grid container sx={{ marginLeft: (isMobile ? "30px" : "10px"), textAlign: "left" }}>
                                <Grid item xs={12} md={12}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", marginTop: "30px" }} />

            {/* Skeleton for cart */}
            <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
                <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={4} md={4}>
                    </Grid>

                    <Grid item xs={4} md={4}>
                        <Skeleton variant="rounded" width={"100%"} height={50} sx={{ fontSize: "17px", marginTop: "20px", marginBottom: "20px", marginleft: "40%" }} />
                    </Grid>

                    <Grid item xs={4} md={4}>
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
                        <Grid container sx={{ width: "100%" }}>
                            <Grid item xs={2} md={2}>
                                <Skeleton variant="circular" width={30} height={30} />
                            </Grid>

                            <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                <Skeleton variant="rounded" width="90%" height={30} />
                            </Grid>

                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                <Skeleton variant="rounded" width="100%" height={30} />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
                        <Grid container sx={{ width: "100%" }}>
                            <Grid item xs={2} md={2}>
                                <Skeleton variant="circular" width={30} height={30} />
                            </Grid>

                            <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                <Skeleton variant="rounded" width="90%" height={30} />
                            </Grid>

                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                <Skeleton variant="rounded" width="100%" height={30} />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
                        <Grid container sx={{ width: "100%" }}>
                            <Grid item xs={2} md={2}>
                                <Skeleton variant="circular" width={30} height={30} />
                            </Grid>

                            <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                <Skeleton variant="rounded" width="90%" height={30} />
                            </Grid>

                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                <Skeleton variant="rounded" width="100%" height={30} />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", marginTop: "30px" }} />

            {/* Skeleton for payment total */}
            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={2} md={2}>
                        <Skeleton variant="circular" width={40} height={40} />
                    </Grid>

                    <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                        <Skeleton variant="rounded" width="90%" height={40} />
                    </Grid>

                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <Skeleton variant="rounded" width="100%" height={40} />
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ alignSelf: "center", marginTop: "10px" }}>
                        <Skeleton variant="rounded" width="100%" height={30} />
                    </Grid>
                </Grid>
            </Grid>

            {/* Skeleton for comments */}
            <Grid item xs={12} md={12} sx={{ marginTop: "50px" }}>
                <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={2} md={2}>
                        <Skeleton variant="circular" width={40} height={40} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Skeleton variant="rounded" width="100%" height={40} />
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ alignSelf: "center", marginTop: "5px" }}>
                        <Skeleton variant="rounded" width="100%" height={100} />
                    </Grid>
                </Grid>
            </Grid>

            {/* Skeleton for send/pay button */}
            <Skeleton variant="rounded" width="100%" height={30} sx={{ marginTop: "20px" }} />

            {/* Skeleton for first section */}
            {/* <Skeleton variant="text" /> */}
        </Box>
    );
}

export default CheckoutLoadingSkeleton;