export function validatePhoneNumber(phoneNumber) {
    // Create a regular expression for phone numbers
    const phoneRegex = /^\+[0-9]{1,3}\.[0-9]{4,14}(?:x.+)?$/;

    // Check if the phone number matches the regular expression
    if (!phoneRegex.test(phoneNumber)) {
        return 'Invalid phone number';
    }

    // If the phone number is valid, return null
    return "";
}

/**
 * Validates the email format using a regular expression.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Whether the email address is valid or not.
 */
export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export default class formValidation {
    static validatePhoneNumber(phoneNumber) { return validatePhoneNumber(phoneNumber) }
    static isValidEmail(email) { return isValidEmail(email); }
}