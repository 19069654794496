import { request } from 'constants/alias';
import { API } from "constants/config";

/**
 * The rest request which request a customer logout action to be performed.
 * @returns an object refering to the success or failure of the request
 */
export async function logout() {
    return await request.post(`${API}auth/logout`);
}

/**
 * The rest request which attempts to login user from social buttons.
 * @param {*} data A JSON object which contains the token and the method
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function socialLogin(data) {
    return await request.post(`${API}auth/slogin`, data);
}

const authApi = {
    logout,
    socialLogin
}

export default authApi;