
import { combineReducers } from 'redux';
import cartReducer from './cart/cartReducer';
import scanReducer from './scan/scanReducer';
import splashReducer from './splash/splashReducer';

const rootReducer = combineReducers({
  cart: cartReducer,
  scan: scanReducer,
  splash: splashReducer
  // other reducers can be added here
});

export default rootReducer;