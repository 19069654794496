import ar from "assets/images/flags/ar.png";
import bg from "assets/images/flags/bg.png";
import cs from "assets/images/flags/cs.png";
import de from "assets/images/flags/de.png";
import el from "assets/images/flags/el.png";
import en from "assets/images/flags/en.png";
import es from "assets/images/flags/es.png";
import et from "assets/images/flags/et.png";
import fr from "assets/images/flags/fr.png";
import it from "assets/images/flags/it.png";
import lt from "assets/images/flags/lt.png";
import nl from "assets/images/flags/nl.png";
import pl from "assets/images/flags/pl.png";
import ro from "assets/images/flags/ro.png";
import ru from "assets/images/flags/ru.png";
import tr from "assets/images/flags/tr.png";
import uk from "assets/images/flags/uk.png";

export function getFlagIcon(country) {
    // Evaluate the variable to get the component to render
    let flag = en;

    switch (country) {
        case 'en':
            flag = en;
            break;
        case 'es':
            flag = es;
            break;
        case 'ar':
            flag = ar;
            break;
        case 'bg':
            flag = bg;
            break;
        case 'cs':
            flag = cs;
            break;
        case 'de':
            flag = de;
            break;
        case 'el':
            flag = el;
            break;
        case 'et':
            flag = et;
            break;
        case 'fr':
            flag = fr;
            break;
        case 'it':
            flag = it;
            break;
        case 'lt':
            flag = lt;
            break;
        case 'nl':
            flag = nl;
            break;
        case 'pl':
            flag = pl;
            break;
        case 'ro':
            flag = ro;
            break;
        case 'ru':
            flag = ru;
            break;
        case 'tr':
            flag = tr;
            break;
        case 'uk':
            flag = uk;
            break;
        // Add more cases as needed for other component types
        default:
            flag = en;
    }

    return flag;
}

export default class functions {
    static getFlagIcon(country) { return getFlagIcon(country); };
}