import { Divider, Grid } from '@mui/material';
import CustomTypography from 'components/Typography/CustomTypography';
import CustomMenuItem from './CustomMenuItem';

/**
 * The CustomMenuCategory, that display each category
 *
 * @param {object} category the menu category details
 * @param {function} handleItemDialogState Callback that handles the item details dialog to open
 * 
 * @returns {JSX.Element} A React component that renders a menu category.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuCategory({
    category,
    handleItemDialogState,
    isFirst = false,
    organizationID,
    menuConfiguration,
    selectedLayout
}) {
    return (
        <>
            <CustomTypography variant="h5" text={category?.name} sx={{ marginBottom: "10px", marginTop: isFirst ? 0 : "50px" }} />
            {/* , fontSize: menuConfiguration?.itemHeaderFontSize */}

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", marginBottom: "12px" }} />

            <Grid container gap={1.5}>
                {category?.items?.map((item) =>
                    <CustomMenuItem
                        item={item}
                        handleItemDialogState={(isOpen, item)=> handleItemDialogState(isOpen, item, null, undefined, category)}
                        organizationID={organizationID}
                        menuConfiguration={menuConfiguration}
                        selectedLayout={selectedLayout}
                    />
                )}
            </Grid>
        </>
    );
}

export default CustomMenuCategory;