import { Divider, Skeleton, SwipeableDrawer } from '@mui/material';
import { Box } from '@mui/system';
import { drawerTopStyle } from 'themes/defaultThemes';

/**
 * The CustomSwippableDrawer, that display a custom swippable drawer.
 * 
 * @param {string} anchor the position of the drawer
 * @param {boolean} open If `true`, the component is shown.
 * @param {*} children The children of the drawer
 * @param {boolean} disableBackdropTransition Disable the backdrop transition. This can improve the FPS on low-end devices. @default false 
 * @param {boolean} disableDiscovery If `true`, touching the screen near the edge of the drawer will not slide in the drawer a bit to promote accidental discovery of the swipe gesture. @default false
 * @param {number} swipeAreaWidth The width of the left most (or right most) area in `px` that the drawer can be swiped open from. @default 20
 * @param {number} onOpen Callback fired when the component requests to be opened.
 * @param {number} onClose Callback fired when the component requests to be closed.
 * @param {number| { appear?: number, enter?: number, exit?: number }} transitionDurationValue The duration for the transition, in milliseconds. You may specify a single timeout for all transitions, or individually with an object.
 * 
 * @returns a custom swippable drawer
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
const CustomSwippableDrawer = ({ anchor = "bottom", open, onClose, onOpen, children, transitionDurationValue,
    disableBackdropTransition = false, disableDiscovery = false, swipeAreaWidth = 52, keepMounted = true, paperSx
}) => {

    return (
        <SwipeableDrawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            disableBackdropTransition={disableBackdropTransition}
            disableDiscovery={disableDiscovery}
            swipeAreaWidth={swipeAreaWidth}
            transitionDuration={transitionDurationValue}
            ModalProps={{
                keepMounted: keepMounted
            }}
            sx={{
                '& .MuiDrawer-paper': {
                    borderRadius: anchor === "bottom" ? "10px 10px 0px 0px" : "initial",
                    padding: "10px 0",
                    alignItems: "center",
                    // overflowX: "clip"
                }
            }}
            PaperProps={{
                style: { maxHeight: 'calc(100vh - 56px)' }, // Again, adjust the 56px as needed
                sx: paperSx
            }}
        >
            {(anchor === "bottom") &&
                <>
                    <Box sx={drawerTopStyle(anchor)}>
                        <Skeleton variant="rect" width={40} height={10} sx={{ borderRadius: "40px", marginBottom: "10px", position: "relative", top: "10px" }} />
                    </Box>
                    <Divider />
                </>
            }
            <Divider />
            {children}
        </SwipeableDrawer>
    );
};

export default CustomSwippableDrawer;