import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { Routes } from 'react-router-dom/dist';
import { getLangOptions } from 'constants/langOptions';
import MenuViewComponent from 'pages/menu/menu-view.component';
import TestComponent from 'pages/test-component';
import Page401 from 'components/Error/Page401';
import Page403 from 'components/Error/Page403';
import Page404 from 'components/Error/Page404';
import Login from "pages/login/";
import RequestComponent from 'pages/request/request.component';
import OutletComponent from 'pages/outlet/outlet.component';
import FeedbackListComponent from 'pages/feedback/feedback-list.component';
import CheckoutComponent from 'pages/checkout/checkout.component';
import FailOrderComponent from 'pages/order/fail-order.component';
import SuccessOrderComponent from 'pages/order/success-order.component';
import SuccessAccountDeleteComponent from 'pages/customer/success-accout-delete.component';
import QrReaderComponent from 'pages/qr-reader.component';
import UnpaidOrdersComponent from 'pages/order/unpaid-orders.component';
import OrderComponent from 'pages/order/order.component';
import WifiComponent from 'pages/wifi/wifi-component';
import AddOutletRatingCOmponent from 'pages/feedback/add-outlet-rating.component';
import OutletActionsWebView from 'pages/outlet/outlet-actions-webview-component';

/**
 * The Anonymous Routes.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Anonymous() {
  const location = useLocation();
  let mylang = localStorage.getItem("i18nextLng").substring(0, 2);
  if (mylang === null || mylang === undefined) {
    mylang = "en";
    localStorage.setItem("i18nextLng", "en");
  }
  let theme = localStorage.getItem("theme");
  if (theme !== undefined && theme !== "light" && theme !== "dark" && theme !== "") {
    localStorage.setItem("theme", "light");
  }

  // Check if the path starts with /en/ or /gr/
  const path = location.pathname;
  if (!path.startsWith("/en") && !path.startsWith("/el")) {
    // If the path doesn't start with /en/ or /gr/, redirect to the same path with /en/ in front
    return <Navigate to={`/${mylang}${path}${location.search}`} replace />;
  }


  return (
    <Routes>
      {getLangOptions().map((lang, i) =>
        <>
          <Route key="outlet-feedbacks" caseSensitive path={`/${lang}/menu/:outletURL/feedbacks`} element={<FeedbackListComponent />} />
          <Route key="menu" caseSensitive path={`/${lang}/menu/:outletURL`} element={<MenuViewComponent />} />
          <Route key="request" caseSensitive path={`/${lang}/request/:outletURL`} element={<RequestComponent />} />
          <Route key="wifi" caseSensitive path={`/${lang}/wifi/:outletURL`} element={<WifiComponent />} />
          <Route key="checkout" caseSensitive path={`/${lang}/checkout/:outletURL`} element={<CheckoutComponent />} />
          <Route key="pay" caseSensitive path={`/${lang}/pay/:outletURL`} element={<UnpaidOrdersComponent />} />
          <Route key="pay-order" caseSensitive path={`/${lang}/pay/order/:orderID`} element={<OrderComponent />} />
          <Route key="checkout-fail" caseSensitive path={`/${lang}/checkout/:outletURL/fail`} element={<FailOrderComponent />} />
          <Route key="checkout-success" caseSensitive path={`/${lang}/checkout/:outletURL/success`} element={<SuccessOrderComponent />} />
          <Route key="outlet-rating-add" caseSensitive path={`/${lang}/rating/:outletURL/add`} element={<AddOutletRatingCOmponent />} />
          <Route key="outlet-webview" caseSensitive path={`/${lang}/:outletURL/webview`} element={<OutletActionsWebView />} />
          <Route key="401" caseSensitive path={`/${lang}/page-401`} element={<Page401 />} />
          <Route key="403" caseSensitive path={`/${lang}/page-403`} element={<Page403 />} />
          <Route key="404" caseSensitive path={`/${lang}/page-404`} element={<Page404 />} />
          <Route key="test" caseSensitive path={`/${lang}/test`} element={<TestComponent />} />
          <Route key='login' caseSensitive path={`/${lang}/login`} element={<Login />} />

          <Route key="outlet" caseSensitive path={`/${lang}/:outletURL`} element={<OutletComponent />} />
          <Route key="account-delete-success" caseSensitive path={`/${lang}/success/account/delete`} element={<SuccessAccountDeleteComponent />} />


          {/* Redirect to a specific page (e.g., NotFoundPage) when no other routes match */}
          <Route path="*" element={<QrReaderComponent />} />
        </>
      )}
      <Route key="empty" caseSensitive path="" element={<Navigate to={"/" + mylang} replace />} />
    </Routes>
  )
}

export default Anonymous;