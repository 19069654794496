import moment from "moment";

/**
 * Check if a string contains text from an array of substrings.
 * 
 * @param {String} str the full string
 * @param {*} substrings array of substrings to check
 * 
 * @returns true if string contains text from an array of substrings.
 */
export function isStringContainsTextFromArrayOfSubstrings(str, substrings) {
  return substrings.some(v => str.includes(v));
}

export function equalsIgnoreCase(str1, str2) {
  if (str1 === undefined || str2 === undefined) return false;
  return str1.toLowerCase() === str2.toLowerCase();
}

/**
 * Calcuates the days left between two days
 * 
 * @param {String} date1 the full string
 * @param {String} date2 the full string
 * 
 * @returns the remaing days count
 */
export function days_between(date1, date2 = new Date()) {

  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(new Date(date1) - new Date(date2));

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);

}

/**
 * Date formatter
 * 
 * @param {String} string the date to be formatted
 * @param {String} formatter the pattern for the date to be formatted
 * 
 * @returns the remaing days count
 */
export function dateTimeFormatter(string, formatter = "DD/MM/yyyy - HH:mm:ss") {
  return moment(new Date(string)).format(formatter);
}

/**
 * Create url params from an object.
 * 
 * @param {String} url the url to include the parameters
 * @param {object} params the list of parameteres to be included at the end of the url.
 * 
 * @returns the formatted url with the parameters at the end.
 */
export function includeParamsToUrl(url, params) {
  if (params === null || params === undefined) return url;
  return url + "?" + Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
}

/**
 * Insert element to array
 * 
 * @param {array} arr the previous array
 * @param {int} index the index of the object to be inserted at the arr
 * @param {object} newItem the new object to be inserted
 * 
 * @returns the new array with the added object at a specific index
 */
export function insertToArray(arr, index, newItem) {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
  ]
}

/**
 * Checks if the object is empty.
 * @param {*} obj the object 
 * @returns true if the object is empty
 */
export function objectIsEmpty(obj) {
  return Object.keys(obj).length === 0;
}

/**
 * Flter an array of objects based on an array of values at a specific field and return the
 * matching objects in the same order as values array.
 * 
 * @param {*} instances the instances ids
 * @param {*} instanceOptionList  the instace option list
 * @returns 
 */
export function findAllObjectsMatchInArrayOrdered(instances, instanceOptionList) {
  return instanceOptionList.filter((instance) => instances.includes(instance.id)).sort((a, b) => instances.indexOf(a.id) - instances.indexOf(b.id))
}


/**
 * Function that triggers the form value change.
 * 
 * @param {string} tagName the tag name
 * @param {string} tagValue the tag value
 * @param {*} data the data of page
 * @param {*} setData Callback that updates the stateful value
 */
export function formValChangeWithParentElementWithNameAndValue(tagName, value, data, setData) {
  const objectName = tagName.split(".")[0];
  const fieldNested = tagName.split(".")[2] !== undefined ? tagName.split(".")[2] : "";
  const fieldNested2 = tagName.split(".")[3] !== undefined ? tagName.split(".")[3] : "";
  const name = tagName.split(".")[1] !== undefined ? tagName.split(".")[1] : "";

  if (name === "")
    setData({
      ...data,
      [objectName]: value
    })
  else if (fieldNested2 !== "")
    setData({
      ...data,
      [objectName]: {
        ...data[objectName],
        [name]: {
          ...data[objectName][name],
          [fieldNested]: {
            ...data[objectName][name][fieldNested],
            [fieldNested2]: value
          }
        }
      }
    });
  else if (fieldNested === "") {
    setData({
      ...data,
      [objectName]: {
        ...data[objectName],
        [name]: value
      }
    })
  } else {
    setData({
      ...data,
      [objectName]: {
        ...data[objectName],
        [name]: {
          ...data[objectName][name],
          [fieldNested]: value
        }
      }
    })
  }
};

/**
 * Removes the trailing comma and space (", ") from the end of a string, if present.
 * @param {string} inputString - The input string to process.
 * @returns {string} A modified string with trailing comma and space removed.
 */
export function removeTrailingCommaSpace(inputString) {
  if (inputString.endsWith(", ")) {
    return inputString.substring(0, inputString.length - 2);
  }
  return inputString;
}

export function replaceLastTwoChars(inputString, replacement) {
  if (inputString === null || inputString === undefined)
    return "#47a6d21c";
  // Check if the inputString has at least 8 characters
  if (inputString.length >= 8) {
    // Use slice to get all characters except the last 2, and concatenate the replacement
    return inputString.slice(0, -2) + replacement;
  } else if (inputString = "#000") {
    return "#000000" + replacement
  } else if (inputString = "#fff") {
    return "#ffffff" + replacement
  } else {
    // Handle the case where the string has less than 8 characters
    // console.error("Input string should have at least 8 characters");
    return inputString + replacement;
  }
}

/**
 * Determines whether offers should be enabled based on whether at least one offer item exists in categories.
 * @param {object[]} offers - Array of offer objects.
 * @param {object[]} categories - Array of category objects.
 * @returns {boolean} True if offers should be enabled, otherwise false.
 */
export function enableOffersOnSelectedMenu(offers, categories) {
  if (offers?.length === 0) {
    return false; // No offers, so disable offers
  } else {
    // Get displayed offers based on categories
    let displayOffers = getDisplayedOffers(offers, categories);
    // Check if there are displayed offers
    if (displayOffers?.length > 0) {
      return true; // At least one offer should be displayed, so enable offers
    }
  }
  return false; // No displayed offers, so disable offers
}

/**
 * Checks if at least one item ID of an offer exists in categories items.
 * @param {object} offer - The offer object to check.
 * @param {string[]} categoryItemIds - Array of category item IDs.
 * @returns {boolean} True if at least one item ID of the offer exists in categories items, otherwise false.
 */
export function isOfferItemInCategories(offer, categoryItemIds) {
  return offer?.categories?.some(category =>
    category?.items?.some(item => categoryItemIds?.includes(item.id))
  )
}

/**
 * Filters offers based on whether they should be displayed (at least one of the item ID of the offer exists in categories items).
 * @param {object[]} offers - Array of offer objects.
 * @param {object[]} categories - Array of category objects.
 * @returns {object[]} Array of offer objects that should be displayed.
 */
export function getDisplayedOffers(offers, categories) {
  // Extract all item IDs from categories
  const categoryItemIds = categories?.flatMap(category => category.items.map(item => item.id));

  // Filter offers to get only those that should be displayed
  const displayedOffers = offers?.filter(offer =>
    isOfferItemInCategories(offer, categoryItemIds)
  )

  return displayedOffers;
}

export function findOfferCoverPhoto({ offer }) {
  // Check if offer exists and has photos
  if (offer?.photos !== null && offer?.photos?.length > 0) {
    // Find the photo with cover set to true
    const coverPhoto = offer.photos.find(photo => photo?.cover === true);

    // If cover photo found, return its image source, otherwise return null
    return coverPhoto ? coverPhoto.imageSrc : offer.photos[0]?.imageSrc;
  }

  return null; // Return null if no photos available
};

export default class functions {
  static isStringContainsTextFromArrayOfSubstrings(str, substrings) { return isStringContainsTextFromArrayOfSubstrings(str, substrings); }
  static equalsIgnoreCase(str1, str2) { return equalsIgnoreCase(str1, str2); }
  static days_between(date1, date2) { return days_between(date1, date2); }
  static dateTimeFormatter(string, formatter) { return dateTimeFormatter(string, formatter); }
  static includeParamsToUrl(url, params) { return includeParamsToUrl(url, params); }
  static insertToArray(arr, index, newItem) { return insertToArray(arr, index, newItem); }
  static objectIsEmpty(obj) { return objectIsEmpty(obj); }
  static formValChangeWithParentElementWithNameAndValue(tagName, value, data, setData) { return formValChangeWithParentElementWithNameAndValue(tagName, value, data, setData); }
  static findAllObjectsMatchInArrayOrdered(instances, instanceOptionList) { return findAllObjectsMatchInArrayOrdered(instances, instanceOptionList); }
  static removeTrailingCommaSpace(inputString) { return removeTrailingCommaSpace(inputString); }
}