import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { request } from 'constants/alias';
import { API, CRUD } from 'constants/config';
import { AuthProvider } from 'context/AuthContext';
import Cookies from 'js-cookie'

/**
 * The Auth Provider.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Authenticate({ children }) {

  const [state, setState] = useState({
    isAuthenticated: false,
    getAuthenticatedUserCompleted: false,
    user: {
      roles: ['anonymous'],
    },
    preferences: {},
    sidenav: {
      expanded: true,
      activeKey: '1'
    }
  });

  useEffect(() => {
    getAuthenticatedUser()
      .then(response => console.log(response))
      .catch(error => console.log(error));
  }, []);

  async function getAuthenticatedUser(data) {
    try {
      let token = localStorage.getItem("token");
      if (token && data) {
        token = data.cookie.value
      }

      let decoded_data = jwt_decode(token);
      if (decoded_data.exp < Math.floor(Date.now() / 1000)) {
        handleTokenExpiration();
        localStorage.removeItem("userID");
        localStorage.removeItem("userFirstName");
        return `Token for user ${decoded_data.sub} Expired!`

      }

      // localStorage.setItem('user', localStorage.getItem("rememberMe") === "true" ? decoded_data.sub : '');

      if (data) {
        localStorage.setItem("userFirstName", data.returnobject.firstName)
        localStorage.setItem("userID", data.returnobject.id)
        Cookies.set("auth_token", token)
        setState({
          ...state,
          isAuthenticated: true,
          getAuthenticatedUserCompleted: true,
          sidenav: {
            expanded: true,
            activeKey: '1'
          },
          user: {
            username: decoded_data.sub,
            roles: [decoded_data.role],
            id: data.returnobject.userid,
            firstName: data.returnobject.firstName,
            lastName: data.returnobject.lastName,
            email: data.returnobject.email,
            phone: data.returnobject.phone,
          },
          preferences: {},
        });
      } else {
        setState({
          ...state,
          isAuthenticated: true,
          getAuthenticatedUserCompleted: true,
          sidenav: {
            expanded: true,
            activeKey: '1'
          },
          user: {
            ...state.user,
            username: decoded_data.sub,
            roles: [decoded_data.role],
          },
          preferences: {},
        });
      }

      return `User ${decoded_data.sub} is authenticated`;

    } catch (ex) {
      // console.log(ex);

      const user = {
        roles: ['anonymous'],
      };

      setState({
        ...state,
        user: user,
        getAuthenticatedUserCompleted: true,
      });

      throw new Error(ex);

    }
  }

  /**
   * Function that triggers the re-authentication of user 
   * after token expires.
   */
  function handleTokenExpiration() {
    return new Promise((resolve, reject) => {
      // Clear request interceptor
      if ('requestInterceptor' in state.preferences) {
        // requestInterceptor is used to monitor unauthorized access to the platform
        request.interceptors.response.eject(state.preferences.requestInterceptor);
      }

      setTimeout(() => {

        setState({
          ...state,
          isAuthenticated: false,
          user: {
            roles: ['anonymous'],
          },
          preferences: {},
          getAuthenticatedUserCompleted: true
        });

      }, CRUD.delay);

      localStorage.removeItem("token");
    });

  }

  /**
   * Login REST call
   *
   * @param username
   * @param password
   * @param webAuthnBody - if U2F enabled else undefined
   * @returns {Promise<any>}
   */
  function handleLogin(requestData, webAuthnBody) {
    return new Promise((resolve, reject) => {

      if (webAuthnBody) {
        requestData.webAuthnBody = JSON.stringify(webAuthnBody);
      }

      request
        .post(`${API}auth/ulogin`, requestData)
        .then((response) => {
          localStorage.setItem("theme", "light");
          if (response.data.code === "SUCCESS") {
            let token = response.data.cookie.value
            // token = token.substring(8, token.length - 1);
            localStorage.setItem("token", token);
            // localStorage.setItem("refresh_token", response.data.refresh_token);
            // console.log(response.data)
            setTimeout(() => {
              getAuthenticatedUser(response.data)
                .then(response => console.log(response))
                .catch(error => console.error(error));
            }, CRUD.delay);
          } else {
            resolve(response)
          }

        })
        .catch((error) => {
          const { response: { status } } = error;

          if (status === 401 || status === 403) {
            reject('Wrong username or password');
          } else {
            reject('Something went wrong. Please contact the system administrator');
          }
        });
    });
  }

  function handleChangeUser(user) {
    setState({
      ...state,
      user: user,
    });
  }

  function handleChangeUserPreferences(preferences) {
    setState({
      ...state,
      preferences: preferences
    });
  }

  function handleLogoutNoRequest() {
    // Clear request interceptor
    if ('requestInterceptor' in state.preferences) {
      // requestInterceptor is used to monitor unauthorized access to the platform
      request.interceptors.response.eject(state.preferences.requestInterceptor);
    }
  }

  function handleLogout() {
    return new Promise((resolve, reject) => {
      // Clear request interceptor
      if ('requestInterceptor' in state.preferences) {
        // requestInterceptor is used to monitor unauthorized access to the platform
        request.interceptors.response.eject(state.preferences.requestInterceptor);
      }

      setTimeout(() => {

        setState({
          ...state,
          isAuthenticated: false,
          user: {
            roles: ['anonymous'],
          },
          preferences: {},
          getAuthenticatedUserCompleted: true,
        });

      }, CRUD.delay);

      localStorage.clear()

    });
  }

  /**
   * Function that handles the sidenav open/close status.
   */
  function handleSidenavStateChange(expanded) {
    setState({ ...state, sidenav: { ...state.sidenav, expanded: expanded !== undefined ? expanded : !state.sidenav.expanded } });
  }

  /**
   * Function that handles the sidenav active key value.
   */
  function handleSidenavActiveKeyChange(activeKey) {
    setState({ ...state, sidenav: { ...state.sidenav, activeKey: activeKey } });
  }



  // RENDER
  if (!state.getAuthenticatedUserCompleted) return '';

  const authProviderValue = {
    ...state,
    handleLogin: handleLogin,
    handleChangeUser: handleChangeUser,
    handleChangeUserPreferences: handleChangeUserPreferences,
    handleLogoutNoRequest: handleLogoutNoRequest,
    handleLogout: handleLogout,
    handleSidenavStateChange: handleSidenavStateChange,
    handleSidenavActiveKeyChange: handleSidenavActiveKeyChange
  };

  return (
    <AuthProvider value={authProviderValue}>
      {children}
    </AuthProvider>
  )
}

export default Authenticate;