import { ScanActionTypes } from "./ScanActionTypes";

const initialState = {
    e: "",
    o: ""
};

const scanReducer = (state = initialState, action) => {
    switch (action.type) {
        case ScanActionTypes.UPDATE_SCAN_DETAILS: {
            const { outletURL, equipmentLabel } = action.payload;
            return {
                ...state,
                e: equipmentLabel !== null && equipmentLabel !== undefined ? equipmentLabel : state.scan?.e,
                o: outletURL !== null && outletURL !== undefined ? outletURL : state.scan?.o
            };
        }
        // Implement additional cases for other properties
        default:
            return state;
    }
};

export default scanReducer;