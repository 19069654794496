import { ImageList, ImageListItem } from '@mui/material';
import { useState } from 'react';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'; // This only needs to be imported once in your app
import { getOutletPhoto } from 'utils/functions/assets';

function CustomImageList({ photos, organizationID, outletID }) {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <ImageList cols={2} rowHeight={164}>
            {photos?.map((photo, index) => (
                <ImageListItem key={photo}>
                    <img
                        src={getOutletPhoto(organizationID, outletID, photo)}
                        alt={photo}
                        loading="lazy"
                        onClick={() => {
                            setPhotoIndex(index);
                            setIsOpen(true);
                        }}
                    />
                </ImageListItem>
            ))}

            {isOpen && (
                <Lightbox
                    mainSrc={getOutletPhoto(organizationID, outletID, photos[photoIndex])}
                    nextSrc={getOutletPhoto(organizationID, outletID, photos[(photoIndex + 1) % photos.length])}
                    prevSrc={getOutletPhoto(organizationID, outletID, photos[(photoIndex + photos.length - 1) % photos.length])}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % photos.length)
                    }
                    sx={{
                        zIndex: "12000000000"
                    }}
                />
            )}
        </ImageList>
    )
}

export default CustomImageList;