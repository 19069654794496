import React from 'react';
import Popover from '@mui/material/Popover';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextField from 'components/Text/CustomTextField';
import { textFieldError } from 'themes/defaultThemes';
import { SearchIcon } from 'assets/mui/MuiIcons';

function CheckoutEquipmentPopover({ anchorEl, open, handleClose, inputValue, equipmentInvalid, handleInputChange, handleUpdate }) {
  const id = open ? 'simple-popover' : undefined;
  const { t } = useTranslation();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box style={{ padding: '20px' }}>
        <Grid container gap={1}>
          <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
            <CustomTextField
              type="text"
              id="persons"
              name="persons"
              required={true}
              defaultValue={inputValue}
              onChange={handleInputChange}
              error={equipmentInvalid}
              helperText={equipmentInvalid ? t('checkout.equipmentInvalid') : ''}
              sx={textFieldError()}
            />
          </Grid>

          <Grid item xs={3} md={3} sx={{ alignSelf: "center" }}>
            <Button
              onClick={handleUpdate}
              color="primary"
              startIcon={<SearchIcon />}
              variant="contained"
              sx={{ borderRadius: "30px" }}
            >
              {/* {t('button.update')} */}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
}

export default CheckoutEquipmentPopover;