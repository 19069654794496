import { request } from 'constants/alias';
import { API } from "constants/config";

/**
 * The rest request which return required information to be presented on add new order feedback page.
 * 
 * @returns an object with an attribute containing the requested information to be presented on add order feedback page
 */
export async function fetchNew(orderID) {
   return await request.get(`${API}order/${orderID}/feedback`);
}

/**
 * The rest request which attempts to create a new feedback .
 * 
 * @param {*} data A JSON object which contains new feedback infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(orderID, data) {
   return await request.post(`${API}order/${orderID}/feedback`, data);
}

const feedbackApi = {
   fetchNew,
   create
}

export default feedbackApi;