import { faTicket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    AccountCircle,
    Feedback as FeedbackIcon,
    Home as HomeIcon,
    Logout as LogoutIcon,
    Loyalty as LoyaltyIcon,
    Settings as SettingsIcon,
    ShoppingCart as ShoppingCartIcon
} from '@mui/icons-material';
import { Alert, Chip, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CustomLanguageUpdateCard from 'components/Card/language/CustomLanguageUpdateCard';
import CustomDialogBasedOnScreenChildren from 'components/Dialog/CustomDialogBasedOnScreenChildren';
import CustomLoginSignupDialog from 'components/Dialog/CustomLoginSignupDialog';
import CustomTypography from "components/Typography/CustomTypography";
import { getMultiLangOptions } from 'constants/multilangOptions';
import { useSnackbar } from 'hooks/useSnackbar';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import authService from 'services/auth.service';
import { topHeaderBox, topHeaderBoxAppBar, topHeaderBoxAppBarToolbar, topHeaderBoxAppBarToolbarAlert, topHeaderBoxAppBarToolbarAlertLink, topHeaderBoxAppBarToolbarBox, topHeaderBoxAppBarToolbarBoxMenu } from 'themes/topHeader';
import { getFlagIcon } from 'utils/functions/language';
import { changeLanguage, getLanguageFromURL } from 'utils/language';

/**
 * The CustomTopHeader.
 *
 * @param {string} userFirstName the user first name
 * @param {function} handleLoginPressed Callback that handles the login button pressed
 * @param {function} handleSignUpPressed Callback that handles the sign up button pressed
 * @param {function} handleLogout Callback that handles the logout pressed
 *
 * @returns {JSX.Element} A React component that renders a group of buttons that increase/decrease a counter.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */

function CustomTopHeader({
    handleLoginPressed,
    handleLogout,
    orders = 0,
    messages = 0,
    notifications = 0,
    isOpen = false,
    handleLoginCheckout,
    pageRequireLoginRegistation = true
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const userFirstName = localStorage.getItem("userFirstName");
    const { openSnackbar } = useSnackbar();

    const [lang, setLang] = useState(getLanguageFromURL());

    // last scan details
    const scanDetailsE = useSelector(state => state.scan?.e || null);
    const scanDetailsO = useSelector(state => state.scan?.o || null);

    const [dialog, setDialog] = useState({
        isOpen: isOpen,
        id: ""
    });

    /**
     * Callback that handles the menu popover to open
     * @param {*} event 
     */
    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Callback that close menu popover.
     */
    function handleClose() {
        setAnchorEl(null);
    };

    /**
     * Callback that handles the redirection to other page
     * @param {*} path the path to redirect to after pressing the option in the menu
     */
    function navigateToPage(path) {
        navigate(`/${getLanguageFromURL()}${path}`);
    };

    function handleLoginPressed() {
        setDialog({
            ...dialog,
            isOpen: true
        });
    }

    function handleLogout() {
        authService.logout(openSnackbar, t);
    }

    /**
     * Function that handles the item dialog open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     */
    function handleDialogState(isOpen) {
        setDialog({
            ...dialog,
            isOpen: isOpen
        });
    }

    // ====================== LANGUAGE
    const [langDialog, setLangDialog] = useState({
        isOpen: false,
    });

    /**
     * Function that handles the lang modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     */
    function handleLangDialogState(isOpen) {
        setLangDialog({
            ...langDialog,
            isOpen: isOpen,
        });
    }

    function updateLanguagePreferrence() {
        changeLanguage(lang);
        handleLangDialogState(false);
    }

    return (
        <Box sx={topHeaderBox()}>
            <AppBar position="fixed" style={topHeaderBoxAppBar()}>
                <Toolbar sx={topHeaderBoxAppBarToolbar()}>
                    <IconButton
                        size="large"
                        aria-label="navigate to home"
                        onClick={() => navigateToPage(`/${scanDetailsO}?e=${scanDetailsE}`)}
                    >
                        <HomeIcon
                            fontSize="medium"
                            sx={{
                                color: "darkgrey",
                            }}
                        />
                    </IconButton>

                    <Box sx={{ flexGrow: 1 }} /> {/* Spacing to push the following items to the right */}

                    {!userFirstName &&
                        <>
                            <Alert sx={topHeaderBoxAppBarToolbarAlert()} severity={pageRequireLoginRegistation ? "error" : "info"}>{pageRequireLoginRegistation ? t('auth.alert.please') : ""}
                                <Link style={topHeaderBoxAppBarToolbarAlertLink()} onClick={handleLoginPressed}>{`${t('auth.login')}/ ${t('auth.register')}`}</Link>
                                {/* <Link style={topHeaderBoxAppBarToolbarAlertLink()} onClick={handleLoginPressed}>{t('auth.login')}</Link>
                                    <CustomTypography variant='span' text=" / " />
                                    <Link onClick={handleSignUpPressed}>{t('auth.signUp')}</Link> */}
                            </Alert>
                            <img src={getFlagIcon(getLanguageFromURL())} width="30" alt={lang} style={{ placeSelf: "center", margin: "0 10px" }} onClick={() => handleLangDialogState(true)} />
                        </>
                    }


                    {userFirstName &&
                        <Box sx={topHeaderBoxAppBarToolbarBox()}>
                            {/* <IconButton size="large" aria-label="show new messages" color="inherit">
                                <Badge badgeContent={messages.length} color="primary">
                                    <MailIcon color='action' />
                                </Badge>
                            </IconButton> */}

                            {/* <IconButton
                                size="large"
                                aria-label="show new notifications"
                                color="inherit"
                            >
                                <Badge badgeContent={notifications.length} color="primary">
                                    <NotificationsIcon color='action' />
                                </Badge>
                            </IconButton> */}

                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                            >
                                <AccountCircle color="black" />
                                <CustomTypography text={userFirstName} sx={{ paddingLeft: "5px" }} />
                            </IconButton>

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                sx={topHeaderBoxAppBarToolbarBoxMenu()}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => navigateToPage("/profile")}>
                                    <ListItemIcon>
                                        <AccountCircle fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t('menu.appBar.profile')}</ListItemText>
                                </MenuItem>

                                <MenuItem onClick={() => navigateToPage("/myorders")}>
                                    <ListItemIcon>
                                        <ShoppingCartIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t('menu.appBar.myOrders')}</ListItemText>
                                    {orders > 0 &&
                                        <Chip label={orders} />
                                    }
                                </MenuItem>

                                <MenuItem onClick={() => navigateToPage("/myratings")}>
                                    <ListItemIcon>
                                        <FeedbackIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t('menu.appBar.myRatings')}</ListItemText>
                                </MenuItem>

                                <MenuItem onClick={() => navigateToPage("/loyalty")}>
                                    <ListItemIcon>
                                        <LoyaltyIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t('menu.appBar.loyalty')}</ListItemText>
                                </MenuItem>

                                <MenuItem onClick={() => navigateToPage("/mycoupons")}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon key={"coupon"} icon={faTicket} />
                                    </ListItemIcon>
                                    <ListItemText>{t('menu.appBar.myCoupons')}</ListItemText>
                                </MenuItem>

                                <Divider />

                                {/* <MenuItem onClick={() => navigateToPage("/messages")}>
                                    <ListItemIcon>
                                        <MailIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t('menu.appBar.messages')}</ListItemText>
                                    {messages > 0 &&
                                        <Chip label={messages} />
                                    }
                                </MenuItem>

                                <MenuItem onClick={() => navigateToPage("/notifications")}>
                                    <ListItemIcon>
                                        <NotificationsIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t('menu.appBar.notifications')}</ListItemText>
                                    {notifications > 0 &&
                                        <Chip label={notifications} />
                                    }
                                </MenuItem> */}

                                {/* <Divider /> */}

                                <MenuItem onClick={() => navigateToPage("/settings")}>
                                    <ListItemIcon>
                                        <SettingsIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t('menu.appBar.settings')}</ListItemText>
                                </MenuItem>

                                <Divider />

                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t('menu.appBar.logout')}</ListItemText>
                                </MenuItem>
                            </Menu>
                        </Box>
                    }
                </Toolbar>
            </AppBar>

            {(handleLoginCheckout !== undefined ? isOpen : dialog.isOpen) &&
                <CustomLoginSignupDialog
                    isOpen={handleLoginCheckout !== undefined ? isOpen : dialog.isOpen}
                    handleOpen={handleDialogState}
                    isFromCheckoutTriggered={isOpen}
                    handleLoginCheckout={handleLoginCheckout}
                />
            }

            {langDialog.isOpen &&
                <CustomDialogBasedOnScreenChildren
                    isOpen={langDialog.isOpen}
                    id="lang"
                    handleOpen={() => handleLangDialogState(true)}
                    handleClose={() => handleLangDialogState(false)}
                    actionColor="#e91e1e"
                    children={
                        <CustomLanguageUpdateCard
                            language={lang}
                            updateLanguagePreferrence={updateLanguagePreferrence}
                            updateLanguageField={(event) => setLang(event.target.value)}
                            userLanguages={getMultiLangOptions()}
                        />
                    }
                />
            }
        </Box>

    );
}

export default CustomTopHeader;