import { InfoIcon } from 'assets/mui/MuiIcons';
import { Badge, Card, CardContent, CardMedia, Grid, Stack } from "@mui/material";
import CustomImage from 'components/Asset/CustomImage';
import CustomTypography from "components/Typography/CustomTypography";
import { outletSectionCard, outletSectionCardIcon, outletSectionCardMedia, outletSectionCardStack, outletSectionCardStackTypography } from 'constants/defaultTheme';
import { getOutletCoverPhoto, getOutletLogo } from 'utils/functions/assets';
import CustomRating from 'components/Rating/CustomRating';

/**
 * The CustomOutletSection, that display outlet section on top of menu view page.
 * 
 * @author [Gina Chatzimarkaki]
 */
function CustomOutletSection({ outlet, handleOpen, organizationID = "", outletAverageRating = 0, outletCountRating = 0 }) {
    return (
        <Card sx={outletSectionCard()}>
            <InfoIcon sx={outletSectionCardIcon()} onClick={() => handleOpen(true, outlet)} />
            {outlet?.settings?.showCover &&
                <>
                    {outlet.settings.showCover && outlet.photos && outlet.photos.length > 0 &&
                        <CardMedia
                            sx={outletSectionCardMedia()}
                            image={getOutletCoverPhoto(organizationID, outlet.id, outlet.photos[0].imageSrc)}
                            title={outlet.name.en}
                        />
                    }

                    <CardContent>
                        <Stack direction="row" sx={outletSectionCardStack()}>
                            <Grid container sx={outletSectionCardStack()}>
                                {(outlet.settings?.showLogo && outlet.logo && outlet.logo !== "") &&
                                    <Grid item xs={"auto"}>
                                        <CustomImage
                                            src={getOutletLogo(organizationID, outlet.id, outlet.logo)}
                                            alt={outlet.name}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={7} sx={{ textAlign: "left" }}>
                                    <CustomTypography variant="span" text={outlet.name} sx={outletSectionCardStackTypography()} />
                                </Grid>

                                {(outlet.settings?.showReviewsAverageScore && outletAverageRating > 0) &&
                                    <>
                                        <Grid item xs={"auto"} sx={outletSectionCardStack()}>
                                            <CustomRating
                                                size={"small"}
                                                iconStyle={outlet?.settings?.ratingStyle}
                                                value={outletAverageRating}
                                                readOnly={true}
                                                sx={{ alignItems: "center" }}
                                                max={1}
                                            />
                                        </Grid>
                                        <Grid item sx={2}>
                                            <CustomTypography
                                                variant="body2"
                                                text={outletAverageRating.toFixed(1)}
                                                sx={{ fontSize: "14px", marginRight: "5px", }}
                                            />
                                        </Grid>
                                        <Grid item xs={"auto"} sx={{ textAlign: "left", marginLeft: "9px", }}>
                                            <Badge
                                                badgeContent={outletCountRating}
                                                color="primary"
                                                sx={{ marginBottom: "10px", "& .MuiBadge-badge": { fontSize: "9px", background: "#47a6d2" } }} />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Stack>
                    </CardContent>
                </>
            }
        </Card>
    );
}

export default CustomOutletSection;