import { SplashActionTypes } from "./splashActionTypes"

//outlets
const initialState = {};

const splashReducer = (state = initialState, action) => {
    switch (action.type) {
        case SplashActionTypes.UPDATE_SPLASH_CONFIGURATION_DETAILS: {
            const { outletURL, splashConfiguration, organizationID, outletID, photoImageSrc, splashPage } = action.payload;
            return {
                ...state,
                [outletURL]: {
                    splashConfiguration: splashConfiguration,
                    outletID: outletID,
                    organizationID: organizationID,
                    photoImageSrc: photoImageSrc,
                    splashPage: splashPage
                }
            };
        }
        // Implement additional cases for other properties
        default:
            return state;
    }
};

export default splashReducer;