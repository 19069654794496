import authApi from "api/auth";

import cookiesService from "services/cookies.service";
import { isLogoutPathRedirect } from "utils/functions/links";
import { getLanguageFromURL } from "utils/language";

/**
 * Function that triggered after the user logged out
 */
export function logout(openSnackbar, t) {
   authApi.logout().then((r) => {
      if (r.data.code === "SUCCESS") {
         localStorage.removeItem("userFirstName");
         localStorage.removeItem("userID");
         removeAccess();
         cookiesService.logOut();
         openSnackbar(t('auth.registerSuccessfully'), "#177910");
         if (isLogoutPathRedirect()) {
            goHome();
         } else {
            window.location.reload();
         }
      }
   }).catch((e) => {
      // console.log(e);
   })
}

/**
 * Function that goes to the front page
 **/
export function goHome() {
   window.location.href = `/${getLanguageFromURL()}`
}

/**
 * Function that triggers the update of the username after username value has change.
 *
 */
export function removeAccess() {
   localStorage.removeItem("token")
   //google   
   localStorage.removeItem('jwt');
}

export default class AuthService {
   static logout(openSnackbar, t) { return logout(openSnackbar, t) }
   static goHome() { return goHome() }
   static removeAccess() { return removeAccess() }
}
