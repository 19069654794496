import React from "react";
import { splashScreenContainer, splashScreenContainerImage, splashScreenContainerTextContainer, splashScreenContainerTextContainerText } from "themes/defaultThemes";
import { Grid, Grow, Box, Typography, CardMedia } from "@mui/material";
import { getOutletLogo } from "utils/functions/assets";

/**
 * The Splash Screen.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Splashscreen({ outletSplashConfiguration }) {

    return (
        <Grid container sx={{ ...splashScreenContainer(outletSplashConfiguration?.splashConfiguration.durationInSeconds), backgroundColor: outletSplashConfiguration?.splashConfiguration.backgroundColor }} justify="center" alignItems="center">
            <Grid item sx={{ width: "100%" }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Grow>
                        <>
                            {(outletSplashConfiguration?.splashConfiguration.showLogo && outletSplashConfiguration?.photoImageSrc != '') &&
                                <CardMedia
                                    component="img"
                                    src={`${getOutletLogo(outletSplashConfiguration?.organizationID, outletSplashConfiguration?.outletID, outletSplashConfiguration?.photoImageSrc)}`}
                                    alt="splash"
                                    sx={splashScreenContainerImage()}
                                />
                            }
                            <div style={splashScreenContainerTextContainer()}>
                                {outletSplashConfiguration?.splashConfiguration.showDescription && <Typography variant="h1" sx={{ ...splashScreenContainerTextContainerText(), color: `${outletSplashConfiguration?.splashConfiguration.descriptionColor}!important` }}>{outletSplashConfiguration?.splashConfiguration.description} </Typography>}
                            </div>
                        </>
                    </Grow>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Splashscreen;