import { Button, ButtonGroup } from "@mui/material";
import React from "react";

/**
 * The GroupedItemButtons, that display customized {@link ButtonGroup} with increment and decrement counter.
 * 
 * @param {number} counter the counter
 * @param {function} handleIncrement Callback that handles the increment of the counter
 * @param {function} handleDecrement Callback that handles the decrement of the counter
 *
 * @returns {JSX.Element} A React component that renders a group of buttons that increase/decrease a counter.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function GroupedItemButtons({ counter, handleIncrement, handleDecrement, sx }) {
    return (
        <ButtonGroup size="small" aria-label="small outlined button group" sx={{ right: 0 }}>
            <Button sx={sx} onClick={() => { if (handleDecrement) handleDecrement(counter) }} disabled={(counter > 1) ? false : true}>-</Button>
            <Button disabled>{counter}</Button>
            <Button sx={sx} onClick={() => { if (handleDecrement) handleIncrement(counter) }}>+</Button>
        </ButtonGroup>
    );
}

export default GroupedItemButtons;