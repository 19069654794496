import { faCreditCard, faLeaf, faMoneyBill } from 'assets/fontAwesome/FontAwesomeIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    CardMedia,
    Divider,
    Grid,
    InputLabel
} from '@mui/material';
import { MessageIcon, NavigateNextIcon, PercentIcon, PlaceIcon, ReceiptIcon, ReviewsIcon, ShoppingCartIcon } from 'assets/mui/MuiIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { MINIO_HOST } from 'constants/hosts';
import { useTranslation } from 'react-i18next';
import { orderCartTitleTextStyle, orderFontAwesomeIconStyle, orderHeaderTextStyle, orderIconStyle, orderItemTaxTextStyle, orderItemFontAwesomeIconStyle, orderItemHeaderTextStyle, orderItemPriceTextStyle, orderReviewGridStyle, orderTextStyle, pastOrderDateTextStyle, pastOrderLogoStyle, orderItemTaxPriceTextStyle, orderItemMuiIconStyle, orderItemTotalTextStyle, orderItemTotalPriceTextStyle, orderDiscountTextStyle, orderDiscountValueTextStyle, orderDiscountTextsStyle, ratingIconStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';
import { findCurrency, priceTextWithCurrency } from 'utils/functions/order';
import CustomSinglePastOrderCardItem from './CustomSinglePastOrderCardItem';
import { getAllergies } from 'utils/functions/allergy';
import CustomRatingCard from 'components/Rating/CustomRatingCard';

/**
 * The CustomSinglePastOrderCard, that display each customer past order.
 * 
 * @param {object} order the past order details
 * @param {fucntion} handlePastOrderAddFeedbackClick Callback that handles redirects past order add feedback page.
 *
 * @returns {JSX.Element} A React component that renders a past order.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSinglePastOrderCard({ order, outlet, equipment, handlePastOrderAddFeedbackClick, rating }) {
    const { t } = useTranslation();

    return (
        <>
            <Grid item xs={12} md={12}>
                <CustomTypography variant="body2" text={`${dateTimeFormatter(order.dateCreated, "DD/MM/yyyy - HH:mm")} - ID: ${order.id}`} sx={pastOrderDateTextStyle()} />
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2}>
                        {(outlet?.logo && outlet.logo !== "" && outlet.logo !== null) &&
                            <CardMedia
                                component="img"
                                src={`${MINIO_HOST}organization/${outlet.organizationID}/outlet/${outlet.id}/${outlet.logo}`}
                                // alt={"logo"}
                                sx={{ ...pastOrderLogoStyle(), textAlign: "center", width: "40px", height: "auto" }}
                            />
                        }
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('order.outletName')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={outlet.name} sx={orderTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <PlaceIcon sx={orderIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('order.equipmentLabel')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={equipment.label} sx={orderTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <ShoppingCartIcon sx={orderIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('order.orderTotal')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={`${findCurrency(outlet.currency)} ${order.totalAmmount}`} sx={orderTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {order?.tipAmount > 0 &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" te xt={t('order.orderTotal')} sx={orderHeaderTextStyle()} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomTypography variant="body2" text={`${findCurrency(outlet.currency)} ${order.tipAmount}`} sx={orderTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Grid item xs={12} md={12} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        {order.paymentMethod === "cash" &&
                            <FontAwesomeIcon key={"icon-cash"} icon={faMoneyBill} style={orderFontAwesomeIconStyle()} />
                        }
                        {order.paymentMethod === "card" &&
                            <FontAwesomeIcon key={"icon-card"} icon={faCreditCard} style={orderFontAwesomeIconStyle()} />
                        }
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('order.paymentMethod')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={order.paymentMethod} sx={orderTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {!order.hasGivenFeedback && outlet.allowReviews &&
                <Grid item xs={12} md={12} sx={orderReviewGridStyle()}>
                    <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                        <Grid item xs={2} md={2}>
                            <ReviewsIcon sx={orderIconStyle} />
                        </Grid>

                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }} onClick={handlePastOrderAddFeedbackClick}>
                            <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t('order.rating.label')} sx={orderHeaderTextStyle()} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t('order.ratingDescription')} sx={orderTextStyle()} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={2} md={2}>
                            <NavigateNextIcon sx={orderIconStyle} onClick={handlePastOrderAddFeedbackClick} />
                        </Grid>
                    </Grid>
                </Grid>
            }

            {order.hasGivenFeedback &&
                <CustomRatingCard outlet={outlet} rating={rating} />
            }

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

            <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                <Grid container gap={1}>
                    <Grid item xs={12} md={12}>
                        <InputLabel sx={orderCartTitleTextStyle()}>{t('order.cart')}</InputLabel>
                    </Grid>

                    {order?.cart?.map((cartItem) =>
                        <Grid item xs={12} md={12}>
                            <CustomSinglePastOrderCardItem cartItem={cartItem} currency={outlet.currency} />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {order.pvcRecyclingTax > 0 &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <FontAwesomeIcon key={"icon-env-tax"} icon={faLeaf} style={orderItemFontAwesomeIconStyle()} />
                        </Grid>

                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={t('order.pvcRecyclingTax')} sx={orderItemTaxTextStyle()} />
                        </Grid>

                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={priceTextWithCurrency(outlet.currency, order.pvcRecyclingTax)} sx={orderItemTaxPriceTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }

            {order?.discount?.loyalty?.discountPercentage > 0 || order?.discount?.coupon?.value > 0 &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <PercentIcon sx={orderItemMuiIconStyle} />
                        </Grid>

                        <Grid item xs={10} md={10}>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t('order.discount')} sx={orderDiscountTextStyle()} />
                                </Grid>

                                {order?.discount?.loyalty?.discountPercentage > 0 &&
                                    <Grid item xs={12} md={12}>
                                        <Grid container>
                                            <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                <CustomTypography variant="body2" text={`${t('order.loyalty')} (${order.discount.loyalty.name}):`} sx={orderDiscountTextsStyle()} />
                                            </Grid>

                                            <Grid item xs={2} md={2}>
                                                <CustomTypography variant="body2" text={`${order.discount.loyalty.discountPercentage} %`} sx={orderDiscountValueTextStyle()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {order?.discount?.coupon?.value > 0 &&
                                    <Grid item xs={12} md={12}>
                                        <Grid container>
                                            <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                <CustomTypography variant="body2" text={t('order.coupon')} sx={orderDiscountTextsStyle()} />
                                            </Grid>
                                            <Grid item xs={2} md={2}>
                                                <CustomTypography variant="body2" text={priceTextWithCurrency(outlet.currency, order.discount.coupon.value)} sx={orderItemTotalPriceTextStyle()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "80%", marginLeft: "11%", marginTop: "20px" }} />

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <ReceiptIcon sx={orderItemMuiIconStyle} />
                    </Grid>

                    <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={t('order.paymentTotal')} sx={orderItemTotalTextStyle()} />
                    </Grid>

                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={priceTextWithCurrency(outlet.currency, order.totalAmmount)} sx={orderItemTotalPriceTextStyle()} />
                    </Grid>
                </Grid>
            </Grid>

            {order.comments.length > 0 &&
                <Grid item xs={12} md={12} sx={orderReviewGridStyle()}>
                    <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                        <Grid item xs={2} md={2}>
                            <MessageIcon sx={orderIconStyle} />
                        </Grid>

                        <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={order.comments} sx={orderItemPriceTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }


            {order.allergies.length > 0 &&
                <>
                    <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

                    <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                        <Grid container gap={1}>
                            <Grid item xs={12} md={12}>
                                <InputLabel sx={orderCartTitleTextStyle()}>{t('order.allergies')}</InputLabel>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                {getAllergies(order.allergies)}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
}

export default CustomSinglePastOrderCard;