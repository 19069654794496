import { faCreditCard, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Divider,
    Grid,
    Stack
} from '@mui/material';
import outletApi from 'api/outlet';
import CustomImageList from 'components/Asset/CustomImageList';
import BusinessHours from 'components/Calendar/BusinessHours';
import CustomTypography from 'components/Typography/CustomTypography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dividerStyle, outletInfoHeaderTextStyle, outletInfoTextStyle } from 'themes/defaultThemes';
import { constructOutletAdress } from 'utils/functions/location';
import { getSocialFontAwesomeIcon } from 'utils/social';

/**
 * The CustomOutletInfoCard, that display outlet info.
 * 
 * @param {fucntion} handleOutletInfoClick Callback that handles the outlet info dialog to open.
 *
 * @returns {JSX.Element} A React component that renders outlet info.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomOutletInfoCard({
    organizationID,
    outletID,
    outletURL
}) {
    const { t } = useTranslation();
    const [outletInfo, setOutletInfo] = useState(null);

    useEffect(() => {
        getOutletInfo();
    }, []);

    /**
   * The rest endpoint to get the menu page details.
   */
    function getOutletInfo() {
        outletApi.fetchInfo(outletURL).then((r) => {
            Promise.resolve()
                .then(() => {
                    setOutletInfo(r.data.returnobject);
                })
        }).catch((e) => {
        });
    }


    return (
        <Grid container spacing={2} sx={{ width: "90%", paddingLeft: '5%' }}>
            <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                <CustomTypography variant="body2" text={t('menus.info.payment.paymentMethods')} sx={outletInfoHeaderTextStyle()} />
            </Grid>

            <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                {outletInfo?.paymentMethods?.includes("card") &&
                    <Stack direction="row">
                        <FontAwesomeIcon icon={faCreditCard} className="left-icon" />
                        <CustomTypography text={t('menus.info.payment.card')} sx={{ marginLeft: "10px" }} />
                    </Stack>
                }
                {outletInfo?.paymentMethods?.includes("cash") &&
                    <Stack direction="row">
                        <FontAwesomeIcon icon={faMoneyBill} className="left-icon" />
                        <CustomTypography text={t('menus.info.payment.cash')} sx={{ marginLeft: "10px" }} />
                    </Stack>
                }
            </Grid>

            <Divider light flexItem sx={dividerStyle()} />

            {outletInfo?.address && outletInfo.address !== null &&
                <>
                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        <CustomTypography variant="body2" text={t('outlet.address')} sx={outletInfoHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        <CustomTypography variant="body2" text={constructOutletAdress(outletInfo.address)} sx={outletInfoTextStyle()} />
                    </Grid>

                    <Divider light flexItem sx={dividerStyle()} />
                </>
            }

            {outletInfo?.telephones?.length > 0 &&
                <>
                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        <CustomTypography variant="body2" text={t('outlet.telephones')} sx={outletInfoHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                        {outletInfo.telephones.map((telephone, index) =>
                            <CustomTypography variant="body2" text={telephone} sx={outletInfoTextStyle()} />
                        )}
                    </Grid>

                    <Divider light flexItem sx={dividerStyle()} />
                </>
            }


            {outletInfo?.vatNumber && outletInfo.vatNumber !== null &&
                <>
                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        <CustomTypography variant="body2" text={t('outlet.vatNumber')} sx={outletInfoHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        <CustomTypography variant="body2" text={outletInfo.vatNumber} sx={outletInfoTextStyle()} />
                    </Grid>

                    <Divider light flexItem sx={dividerStyle()} />
                </>
            }

            {outletInfo?.socialProfiles?.length > 0 &&
                <>
                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        <CustomTypography variant="body2" text={t('outlet.socialProfiles')} sx={outletInfoHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        {outletInfo.socialProfiles.map((socialProfileType, index) =>
                            getSocialFontAwesomeIcon(t(`socialProfile.${socialProfileType.name}`), socialProfileType.url)
                        )}
                    </Grid>

                    <Divider light flexItem sx={dividerStyle()} />
                </>
            }

            {outletInfo?.workingHours?.hours &&
                <>
                    <BusinessHours outlet={outletInfo} />
                </>
            }

            {outletInfo?.showPhotos && outletInfo.photos?.length > 0 &&
                <>
                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                        <CustomTypography variant="body2" text={t('outlet.photos')} sx={outletInfoHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                        <CustomImageList organizationID={organizationID} outletID={outletID} photos={outletInfo.photos} />
                    </Grid>
                </>
            }
        </Grid>
    );
}

export default CustomOutletInfoCard;