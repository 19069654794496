import { Card, Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { outletSectionCard } from 'constants/defaultTheme';
import React from 'react';

/**
 * The MenuLoadingSkeleton, that display a box with animated skeletons untils a menu page is ready to render.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function MenuLoadingSkeleton({ isMobile = true }) {

    return (
        <Box sx={{ width: "100%", margin: "2px 1px", textAlign: "-webkit-center" }}>
            <Card sx={outletSectionCard()}>
                {/* Skeleton for back button */}
                <Skeleton variant="rounded" width={"auto"} height={310} />

                {/* Skeleton for euipment, persons, payment method */}
                <Grid container gap={2} sx={{ margin: "15px 15px 20px 30px", width: isMobile ? "85%" : "95%" }}>
                    <Grid item>
                        <Skeleton variant="rounded" width={50} height={40} />
                    </Grid>

                    <Grid item>
                        <Skeleton variant="rounded" width={120} height={40} />
                    </Grid>

                    <Box sx={{ flexGrow: 1 }} />

                    <Grid item>
                        <Skeleton variant="rounded" width={50} height={40} />
                    </Grid>
                </Grid>
            </Card>

            <Grid container gap={2} sx={{ width: "100%", padding: "10px" }}>
                <Grid item xs={5.7}>
                    <Skeleton variant="rounded" height={150} />
                </Grid>
                <Grid item xs={5.7}>
                    <Skeleton variant="rounded" height={150} />
                </Grid>
                <Grid item xs={5.7}>
                    <Skeleton variant="rounded" height={150} />
                </Grid>
                <Grid item xs={5.7}>
                    <Skeleton variant="rounded" height={150} />
                </Grid>
            </Grid>

            {/* Skeleton for send/pay button */}
            <Skeleton variant="rounded" width="80%" height={30} sx={{ marginTop: "20px" }} />
        </Box>
    );
}

export default MenuLoadingSkeleton;