import { createTheme, ThemeProvider } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';

/**
 * The Custom Chip, that display customized {@link Chip}.
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 * @property {string} color the control button color
 * @property {object} sx the
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomChip({ className = "", label = "", color = "default", rowIndex, text, sx }) {

    function getMuiTheme(sx) {
        return createTheme({
            components: {
                MuiChip: {
                    styleOverrides: {
                        root: {
                            background: "#dcf1f7",
                            color: "#54b1d2",
                            margin: "5px",
                            ...sx
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider key={label + "-theme-" + rowIndex} theme={getMuiTheme(sx)}>
            <Chip key={label + "-" + rowIndex} color={color} className={className} label={label} id={text} />
        </ThemeProvider>
    );
}

export default CustomChip;